import React from "react";
import { Alert, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Tabbed from "../../_app/layouts/Tabbed";

interface Props {
  selectedTab: string;
  showRoles: boolean;
  children: any;
}

function ManageUsersTabBar({ selectedTab, showRoles, children }: Props) {
  const classes = useStyles();
  const getInfoText = () => {
    if (selectedTab === "users") {
      return "Administer portal access by regulating user permissions and defining their capabilities within your account. Modifications will solely impact portal access.";
    }
    if (selectedTab === "roles") {
      return "Easily manage your user permissions using Roles. Create your Roles here and set it’s permissions, then simply assign the Role to the relevant users. They will then inherit the permissions of the role.";
    }
  };
  return (
    <Box className={classes.wrap}>
      <Alert severity="info" className={classes.info}>
        {getInfoText()}
      </Alert>
      <Tabbed
        selectedTab={selectedTab}
        tabs={[
          {
            value: "users",
            label: "Users",
            hidden: false,
          },
          {
            value: "roles",
            label: "Roles",
            hidden: !showRoles,
          },
        ]}
      >
        {children}
      </Tabbed>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      width: "100%",
    },
    info: {
      width: "fit-content",
      alignItems: "center",
      marginBottom: theme.spacing(1),
      "& a": {
        textDecoration: "none",
        color: theme.palette.primary.main,
      },
    },
  })
);

export default ManageUsersTabBar;

import { ThemeOptions } from "@mui/material/styles";

export const themeConfig: ThemeOptions = {
  spacing: 8,
  breakpoints: {
    keys: ["xs", "sm", "md", "lg", "xl"],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  direction: "ltr",
  shadows: [
    "none",
    "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)",
    "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
    "0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)",
    "0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)",
    "0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)",
    "0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)",
    "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
    "0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)",
    "0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)",
    "0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)",
    "0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)",
    "0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)",
    "0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)",
    "0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)",
    "0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)",
    "0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)",
    "0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)",
    "0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)",
    "0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: "'Open Sans', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    h1: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 600,
      fontSize: "2rem",
      lineHeight: 1.167,
    },
    h2: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 600,
      fontSize: "1.75rem",
      lineHeight: 1.2,
    },
    h3: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: 1.167,
    },
    h4: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: 1.235,
    },
    h5: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: 1.334,
    },
    h6: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: 1.6,
    },
    subtitle1: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.75,
    },
    subtitle2: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 500,
      fontSize: "0.875rem",
      lineHeight: 1.57,
    },
    body1: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    body2: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.43,
    },
    button: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 600,
      fontSize: "0.875rem",
      lineHeight: 1.75,
      textTransform: "none",
    },
    caption: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
    },
    overline: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 2.66,
      textTransform: "uppercase",
    },
  },
  shape: {
    borderRadius: 5,
  },
  transitions: {
    easing: {
      easeInOut: "cubic-bezier(0.4, 0, 0.2, 1)",
      easeOut: "cubic-bezier(0.0, 0, 0.2, 1)",
      easeIn: "cubic-bezier(0.4, 0, 1, 1)",
      sharp: "cubic-bezier(0.4, 0, 0.6, 1)",
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  components: {
    MuiCard: {
      defaultProps: {
        elevation: 4,
      },
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: "2rem",
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "filled" && {
            color: "#fff",
          }),
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: "32px !important",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        color: "inherit",
      },
      styleOverrides: {
        root: {
          fontWeight: 600,
          fontSize: "0.875rem",
        },
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        containedSecondary: {
          color: "#ffffff",
        },
        sizeLarge: {
          fontSize: "0.875rem",
          padding: "12px 24px",
        },
        sizeSmall: {
          padding: "3px 12px",
          fontSize: "0.875rem",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            backgroundColor: "#e9e9ec",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: "inherit",
          borderBottomColor: "#d3d2d7",
        },
        head: {
          color: "inherit",
          fontSize: ".75rem",
          fontWeight: 600,
          borderBottom: "1px solid #d3d2d7",
          borderTop: "1px solid #d3d2d7",
          paddingTop: 12,
          paddingBottom: 12,
          paddingRight: 0,
          paddingLeft: "16px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          border: "none",
        },
      },
    },
    //@ts-ignore // styles table head when sorting is enabled
    MUIDataTableHeadCell: {
      styleOverrides: {
        contentWrapper: {
          display: "inline",
          width: "100%",
        },
        sortAction: {
          fontSize: ".75rem",
        },
        fixedHeader: {
          top: "-1px",
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        paddingCheckbox: {
          border: "none",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            background: "none !important",
          },
          "&.Mui-selected:hover": {
            background: "none !important",
          },
        },
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      "@media (min-width:0px) and (orientation: landscape)": {
        minHeight: 48,
      },
      "@media (min-width:600px)": {
        minHeight: 64,
      },
    },
  },
  palette: {
    primary: {
      main: "#7a5aff",
    },
    secondary: {
      main: "#4ec4b0",
    },
    tertiary: {
      main: "#524b65",
    },
    success: {
      light: "#e0f8e5",
      main: "#51b063",
      dark: "#3d844a",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(39,29,62,0.87)",
      secondary: "rgba(39,29,62,0.54)",
      disabled: "rgba(39,29,62,0.38)",
    },
    common: {
      black: "#000000",
      white: "#ffffff",
    },
    grey: {
      "50": "#f8f7f9",
      "100": "#e9e9ec",
      "200": "#d3d2d7",
      "300": "#a9a5b2",
      "400": "#7d788c",
      "500": "#524b65",
      "600": "#271d3e",
      "700": "#1a142b",
      "800": "#140f1e",
      "900": "#000000",
      A100: "#d5d5d5",
      A200: "#aaaaaa",
      A400: "#303030",
      A700: "#616161",
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    divider: "rgba(39,29,62,0.12)",
    background: {
      paper: "#fff",
      default: "#e9e9ec",
    },
    action: {
      active: "rgba(39,29,62,0.38)",
      hover: "rgba(39,29,62,0.02)",
      hoverOpacity: 0.04,
      selected: "rgba(39,29,62,0.01)",
      selectedOpacity: 0.08,
      disabled: "rgba(39,29,62,0.54)",
      disabledBackground: "rgba(39,29,62,0.12)",
      disabledOpacity: 0.38,
      focus: "rgba(0, 0, 0, 0.12)",
      focusOpacity: 0.12,
      activatedOpacity: 0.12,
    },
  },
};

import React, { ReactNode } from "react";
import { Card, CardHeader, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useHasFeature } from "../../../feature/hooks";
import { useHasPermission } from "../../../permission/hooks";
import { Alert } from "@mui/material";
import UILoader from "../../../_app/components/UILoader";

interface Props {
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
  title?: string | Node;
  filter?: ReactNode;
  bodyPadding?: number;
  isLoading?: boolean;
  hasError?: boolean;
  featureFlag?: string | undefined;
  accessPermission?: string | undefined;
  children?: any;
}

const GraphWidget = ({
  width = 6,
  title = "",
  filter,
  bodyPadding = 12,
  isLoading = false,
  hasError = false,
  featureFlag,
  accessPermission = "",
  children,
}: Props) => {
  const classes = useStyles();
  const hasFeatureFlag = useHasFeature(featureFlag);
  const hasFeature = featureFlag ? hasFeatureFlag : true;
  const hasPermission = useHasPermission(accessPermission);

  if (!hasFeature || !hasPermission) return null;
  return (
    <Grid item xs={12} md={width} data-testid={title}>
      <Card raised className={classes.card} elevation={1}>
        {Boolean(title) && (
          <CardHeader
            className={classes.header}
            title={
              <Typography variant="h4" component="span">
                {title}
              </Typography>
            }
            action={filter}
          />
        )}
        <div className={classes.body} style={{ padding: bodyPadding }}>
          {isLoading ? (
            <UILoader className={classes.loader} />
          ) : hasError ? (
            <div className={classes.error}>
              <Alert severity="info">No data found</Alert>
            </div>
          ) : (
            children
          )}
        </div>
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {},
    header: {
      maxHeight: 52,
      "& ul": {
        margin: 0,
      },
    },
    loader: {
      margin: theme.spacing(2),
    },
    error: {
      width: "100%",
      margin: theme.spacing(2),
    },
    body: {
      borderTop: "1px solid #d3d2d7",
      display: "flex",
      flex: 1,
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default GraphWidget;

import React from "react";
import Icon from "@mui/icons-material/Notifications";
import SimpleNavWidget from "./SimpleNavWidget";

const UsageAlertsLink = ({ ...props }) => {
  return (
    <SimpleNavWidget
      title="Usage Alerts"
      description="Limit the potential of overusage and access charges."
      link="/usage-alerts"
      icon={<Icon />}
      {...props}
    />
  );
};

export default UsageAlertsLink;

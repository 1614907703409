import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Button, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BarsUpdateConfirm from "../components/BarsUpdateConfirm";
import BarGroups from "../components/BarGroups";
import { featureFlagsMap } from "../../feature/utils";
import { useBarsUpdate } from "../hooks";
import { useFeedbackAlerts, useReset } from "../../_app/hooks";
import UIButton from "../../_app/components/UIButton";
import UIDialog from "../../_app/components/UIDialog";
import { capitalize } from "../../_app/utils/format";
import { genericError } from "../../_app/utils/text";
import { SelectedParams } from "../../asset/screens/MobileManagement";
import { useMobileNetworks } from "../../asset/hooks";
import { permissionCodes } from "../../permission/hooks";
import { BarUpdate } from "../types";

interface Step {
  id: number;
  title?: string;
  info?: string | React.ReactElement;
  alert?: string | React.ReactElement;
  content?: ReactNode;
}

export const BarsUpdate = () => {
  const classes = useStyles();
  const history = useHistory();
  const contentRef = useRef<HTMLInputElement>(null);
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const location = useLocation<SelectedParams>();
  const assets = location.state?.assets;
  const filters = location.state?.filters;
  const singleAssetId = assets?.length === 1 ? assets?.[0].id : "";
  const [updates, setUpdates] = useState<BarUpdate[]>([]);
  const [resetKey, reset] = useReset("bars-form");

  useEffect(() => {
    if ((!assets || !assets?.length) && !filters) {
      setFeedbackAlertError("No mobile number selected");
      history.push({
        pathname: "/mobile-management",
      });
    }
  }, [assets, filters, history]);

  const {
    data: networksResp,
    error: networksError,
    isFetching,
  } = useMobileNetworks(
    assets?.map((a) => a?.id),
    filters
  );

  const {
    mutate: executeBarsUpdate,
    data: successMessage,
    isLoading: isUpdating,
  } = useBarsUpdate();

  const networks = networksResp || [];

  const onUpdate = () => {
    if (!updates.length) return;
    executeBarsUpdate({
      filters,
      assetIds: assets?.map((assets) => assets?.id),
      operations: updates.map((u) => ({
        name: u.name,
        value: u.value,
        network: u.network,
      })),
    });
  };

  const steps: Step[] = networks.map((n, i) => ({
    id: i,
    title: !singleAssetId ? `Manage ${capitalize(n) || "N/A"} Bars` : undefined,
    alert:
      n === "VODAFONE"
        ? "Please be aware when placing International bars, this excludes the Republic of Ireland. Standard international charges apply."
        : undefined,
    info:
      networks.length > 1 ? (
        <span>
          {" "}
          Some of the numbers you selected are on the
          {Boolean(n) ? <img alt={n + " logo"} src={require(`../../_app/images/logo-${n?.toLowerCase()}.png`)} /> : " N/A "}
          network. Please select the changes required to these numbers.
        </span>
      ) : undefined,
    content: (
      <BarGroups
        assetId={singleAssetId}
        network={n}
        resetKey={resetKey}
        updates={updates}
        setUpdates={setUpdates}
        disableApply={isUpdating || !updates?.length}
      />
    ),
  }));

  if (steps?.length) {
    steps?.push({
      id: steps?.length,
      title: "Confirmation",
      content: <BarsUpdateConfirm updates={updates} />,
    });
  }

  const [step, setStep] = useState(0);
  const isLastStep = step === steps?.length - 1;
  const isSecondToLastStep = step === steps?.length - 2;

  const onReset = () => {
    reset();
    setUpdates([]);
    setStep(0);
  };

  useEffect(() => {
    if (contentRef) {
      contentRef?.current?.scrollTo(0, 0);
    }
  }, [step]);

  const computeErrors = () => {
    let error = genericError();
    if (networksError) {
      error = networksError?.data?.message || error;
    } else {
      if (filters) return null;
      if (assets?.length) return null;
      error = "No mobile numbers selected";
    }
    return error;
  };
  const errors = computeErrors();

  return (
    <Main
      title={
        <>
          <div>
            <Typography variant="h1">{steps[step]?.title || "Manage Bars"}</Typography>
          </div>
          {Boolean(assets?.length || filters) && <Typography>({assets?.length || "All"} CLIs selected)</Typography>}
          {Boolean(updates.length) && (
            <Button className={classes.reset} variant="outlined" color="primary" onClick={onReset}>
              Reset
            </Button>
          )}
        </>
      }
      titleProps={{ className: classes.title }}
      data-cy="manage-bars-page"
      isLoading={isFetching}
      featureFlag={featureFlagsMap.MOBILE_BARS}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      {
        <>
          <div className={classes.content} ref={contentRef}>
            {Boolean(errors) && (
              <Alert severity="error" className={classes.info}>
                {errors}
              </Alert>
            )}
            {Boolean(steps[step]?.info) && (
              <Alert severity="info" className={classes.info}>
                {steps[step].info}
              </Alert>
            )}
            {Boolean(steps[step]?.alert) && (
              <Alert severity="warning" className={classes.info}>
                {steps[step].alert}
              </Alert>
            )}
            {steps.map((s, i) => {
              return (
                <div
                  style={{
                    display: i === step ? "block" : "none",
                    marginTop: "16px",
                  }}
                  key={s.id}
                >
                  {s.content}
                </div>
              );
            })}
          </div>
          <div className={classes.actions}>
            {step > 0 ? (
              <Button
                variant="outlined"
                color="primary"
                disabled={isUpdating}
                onClick={() => setStep(step - 1)}
                startIcon={<ArrowBackIcon />}
              >
                Previous
              </Button>
            ) : (
              <div />
            )}
            {step < steps?.length - 2 && (
              <Button
                variant="contained"
                color="primary"
                disabled={isUpdating}
                onClick={() => setStep(step + 1)}
                endIcon={<ArrowForwardIcon />}
              >
                Next
              </Button>
            )}
            {isSecondToLastStep && (
              <Button
                variant="contained"
                color="primary"
                disabled={isUpdating || !updates?.length}
                onClick={() => setStep(step + 1)}
                data-cy="apply-btn"
              >
                Apply
              </Button>
            )}
            {isLastStep && (
              <UIButton variant="contained" color="primary" onClick={onUpdate} disabled={!updates?.length} isLoading={isUpdating}>
                Confirm
              </UIButton>
            )}
          </div>
          <UIDialog
            title="Your Request Has Been Sent"
            open={Boolean(successMessage)}
            actions={[
              {
                label: "Close",
                props: { onClick: () => history.goBack(), variant: "outlined" },
              },
            ]}
          >
            {successMessage}
          </UIDialog>
        </>
      }
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      display: "flex",
      gap: "10px",
      alignItems: "flex-end",
      marginBottom: "10px",
      maxHeight: "38px",
      marginRight: "48px",
      [theme.breakpoints.down("md")]: {
        "& h1": {
          fontSize: "1.6rem",
        },
      },
    },
    actions: {
      display: "flex",
      width: "100%",
      minHeight: "42px",
      justifyContent: "space-between",
      alignItems: "center",
      gap: "1rem",
      overflow: "auto",
      marginTop: theme.spacing(1),
    },
    content: {
      width: "100%",
      height: "calc(100vh - 136px)",
      borderRadius: "5px",
      overflow: "auto",
      paddingLeft: "7px",
      paddingRight: "7px",
      paddingBottom: "7px",
    },
    info: {
      width: "100%",
      marginBottom: "5px",
      "& img": {
        objectFit: "contain",
        width: theme.spacing(2),
        margin: "0px 4px -2px",
      },
      [theme.breakpoints.down("md")]: {
        "& .MuiAlert-message": {
          fontSize: "0.7rem",
        },
        "& img": {
          width: "13px",
        },
      },
    },
    reset: {
      marginLeft: "auto",
    },
  })
);

export default BarsUpdate;

import React, { useState } from "react";
import { Theme, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import UIConfirm from "../../_app/components/UIConfirm";
import { Contact } from "../types";

interface Props {
  data: Contact;
  deleteAction(data: Contact): void;
  editAction(data: Contact): void;
}

const ManageContactsActionButton = ({ data, deleteAction, editAction }: Props) => {
  const classes = useStyles();
  const [warning, setWarning] = useState(false);

  return (
    <Grid item className={classes.ctr}>
      {Boolean(data.canDelete) && (
        <Button variant="outlined" onClick={() => setWarning(true)} data-cy="delete-contact-button" size="small" color="error">
          Delete
        </Button>
      )}
      <UIConfirm
        title="Delete this contact?"
        open={warning}
        setOpen={setWarning}
        onSubmitTitle="Delete"
        disableOnSubmit={!data.canDelete}
        isDelete={true}
        onConfirm={() => {
          if (!data.canDelete) return;
          deleteAction(data);
        }}
      >
        Are you sure you want to delete this contact?
      </UIConfirm>
      <Button variant="outlined" color="primary" onClick={() => editAction(data)} data-cy="edit-contact-button" size="small">
        Edit
      </Button>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      justifyContent: "flex-end",
      gap: theme.spacing(2),
    },
  })
);

export default ManageContactsActionButton;

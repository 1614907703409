import React, { ChangeEvent, ReactNode } from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import TableHeader from "./TableHeader";

interface Props {
  value?: boolean;
  selectedRows?: any[];
  ctrClass?: any;
  onChange?: (event: ChangeEvent<{}>, checked: boolean) => void;
  disabled?: boolean;
  children?: ReactNode;
}

const SelectAllHeader = ({ value = false, selectedRows = [], onChange, disabled = false, children }: Props) => {
  const indeterminate = Boolean(!value && selectedRows?.length);
  return (
    <TableHeader>
      <FormControlLabel
        control={<Checkbox color="primary" indeterminate={indeterminate} />}
        label="Select All"
        onChange={onChange}
        disabled={disabled}
        checked={value}
        data-cy="select-all"
      />
      {children}
    </TableHeader>
  );
};

export default SelectAllHeader;

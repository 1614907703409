import { DisabledReason, Permission } from "./api";
import { SimplePermission } from "../user/types";

export const formatDisabledReason = (reason: DisabledReason | null): string => {
  switch (reason) {
    case DisabledReason.ByRole:
      return "Disabled by role";
    case DisabledReason.ByLevel:
      return "Disabled by user level";
    default:
      return reason || "";
  }
};

export const simplifyPermissions = (permissionsList: Permission[]): SimplePermission[] =>
  permissionsList.flatMap((permission): SimplePermission[] => [
    { permissionId: Number(permission.id), enabled: Boolean(permission.value) },
    ...(permission.permissions ? simplifyPermissions(permission.permissions) : []),
  ]);
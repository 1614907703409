import React, { useState } from "react";
import CostCentreAccordion from "./CostCentreAccordion";
import { createStyles, makeStyles, UIAlert, UILoader, UITheme } from "../../_app/components";
import SearchBar from "../../asset/components/SearchBar";
import { unassignedCostCentreModel } from "../api";
import { useCostCentres, useFilteredCostCentres } from "../hooks";
import { CostCentre } from "../types";
import { formatCCSearchResults } from "../utils";

export const CostCentreList = () => {
  const classes = useStyles();
  const [search, setSearch] = useState("");
  const { data, isLoading } = useCostCentres();
  const costCentres = data ? [unassignedCostCentreModel, ...data.sort((a, b) => a.name.localeCompare(b.name))] : [];
  const { data: searchResult, isLoading: isSearching } = useFilteredCostCentres(search, { enabled: !!search });

  const pageData = !search ? costCentres : formatCCSearchResults(searchResult);

  const renderContent = () => {
    if (isLoading || isSearching) {
      return (
        <div className={classes.loader}>
          <UILoader />
        </div>
      );
    } else {
      if (!pageData.length) return <UIAlert severity="info">No assets found.</UIAlert>;
      return pageData?.map((cc: CostCentre) => <CostCentreAccordion costCentre={cc} key={cc?.id} expanded={!!search} />);
    }
  };

  return (
    <>
      <div className={classes.searchBox}>
        <SearchBar placeholder="Search by CLI or tag" onSearch={setSearch} hasSearchButton={false} />
      </div>
      {renderContent()}
    </>
  );
};

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    searchBox: {
      paddingBottom: theme.spacing(1),
      [theme.breakpoints.down("md")]: {
        width: "100%",
      },
      [theme.breakpoints.up("lg")]: {
        width: "30%",
      },
    },
  })
);

export default CostCentreList;

import React from "react";
import { withWidget } from "./components/Widget";
import { UICard } from "../../_app/components/UICard";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getHours } from "date-fns";
import { useIdentityUser } from "../../auth/hooks";
import { useBrandWelcomeText } from "../../brand/hooks";

const Welcome = () => {
  const classes = useStyles();
  const hour = getHours(new Date());
  const { data: identityUser } = useIdentityUser();

  const welcomeText = useBrandWelcomeText();
  const name = identityUser?.profile?.given_name ? `, ${identityUser?.profile?.given_name}` : "";
  return (
    <UICard light={false} height="auto" className={classes.ctr}>
      <Typography variant="h1">
        Good {hour < 12 ? " Morning" : hour < 17 ? "Afternoon" : "Evening"}
        {name}!
      </Typography>
      <Typography variant="h3">{welcomeText}</Typography>
    </UICard>
  );
};

const useStyles = makeStyles((theme) => ({
  ctr: {
    display: "flex",
    alignItems: "center",
    "& h1": {
      paddingBottom: theme.spacing(2),
    },
    minHeight: "fit-content !important",
  },
}));

export default withWidget(Welcome, "welcome");

import { useStore } from "../_app/hooks";

export function useHasFeature(key: string = "") {
  const { state } = useStore();
  return Boolean(state.features?.[key]?.enable);
}

export function useHasAnyFeature(keys: string[] | string = []) {
  const { state } = useStore();
  if (typeof keys === "string") {
    return Boolean(state.features?.[keys]?.enable);
  }
  let result = false;
  keys.map((key) => (result = result || Boolean(state.features?.[key]?.enable)));
  return result;
}

export function useGetFeatureProperty(key: string, property: string) {
  const { state } = useStore();
  return state.features?.[key]?.properties?.[property];
}

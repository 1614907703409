import React, { useEffect, useState } from "react";
import Main from "../../_app/layouts/Main";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Button, FormControlLabel, Grid, Switch } from "@mui/material";
import MUIDataTable, { MUIDataTableState } from "mui-datatables";
import { permissionCodes } from "../../permission/hooks";
import ManageBundleAlertsTabBar from "../components/BundleAlerts/ManageBundleAlertsTabBar";
import {
  useBundleAlertsAccounts,
  useUpdateBundleAlertsAccounts,
  useUpdateBundleAlertsAccountsDisableAll,
  useUpdateBundleAlertsAccountsEnableAll
} from "../hooks";
import { genericError } from "../../_app/utils/text";
import { useFeedbackAlerts } from "../../_app/hooks";
import { rowParser } from "../../_app/components/Table/helpers";
import UILoader from "../../_app/components/UILoader";
import { UserLevelFlag } from "../../user-level/types";
import ToggleButtons from "../../form/components/ToggleButtons";
import { BundleAlertAccount, BundleAlertUpdate } from "../types";
import { featureFlagsMap } from "../../feature/utils";

export const BundleAlertsAccount = () => {
  const {setFeedbackAlertError, setFeedbackAlertSuccess} = useFeedbackAlerts();
  const classes = useStyles();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [allOperation, setAllOperation] = useState("");

  const [accountList, setAccountList] = React.useState<any>();
  const [dirtyAccountList, setDirtyAccountList] = React.useState<any>({});

  const { data: acc, isFetching } = useBundleAlertsAccounts(page, rowCount);

  const resultHandle = {
    onSuccess: (response: string) => {
      setFeedbackAlertSuccess(response);
      reset();
    },
    onError: (response: string) => {
      setFeedbackAlertError(response || genericError());
      reset();
    },
  };

  const updateBundleAlertsAccounts = useUpdateBundleAlertsAccounts(resultHandle);
  const updateBundleAlertsAccountsEnableAll = useUpdateBundleAlertsAccountsEnableAll(resultHandle);
  const updateBundleAlertsAccountsDisableAll = useUpdateBundleAlertsAccountsDisableAll(resultHandle);

  const handleChange = (id: any, event: any) => {
    const updates = { ...dirtyAccountList };
    if (updates[id]) {
      delete updates[id];
    } else {
      updates[id] = { id, [event.target.name]: event.target.checked };
    }
    setDirtyAccountList(updates);
  };

  const reset = () => {
    setDirtyAccountList({});
    setAllOperation("");
  };

  const handleUpdateAction = () => {
    const updatedBundleAlerts = Object.values(dirtyAccountList);
    if (allOperation) {
      if (allOperation === "enabled-all") {
        updateBundleAlertsAccountsEnableAll.mutate();
      } else {
        updateBundleAlertsAccountsDisableAll.mutate();
      }
    } else {
      updateBundleAlertsAccounts.mutate(updatedBundleAlerts as BundleAlertUpdate[]);
    }
  };

  useEffect(() => {
    acc?.list?.map((item: BundleAlertAccount) => {
      return setAccountList((old: any) => {
        return {
          ...old,
          [item.accountId]: { id: item.accountId, enabled: item.enabled },
        };
      });
    });
  }, [acc]);

  const parsed =
    (accountList &&
      acc?.list?.map((item: BundleAlertAccount) => {
        let value = accountList[item.accountId].enabled;
        if (allOperation === "enabled-all") {
          value = true;
        } else if (allOperation === "disabled-all") {
          value = false;
        } else if (dirtyAccountList[item.accountId]) {
          value = dirtyAccountList[item.accountId].enabled;
        }
        return rowParser([
          item.accountCode,
          item.companyName,
          <FormControlLabel
            control={<Switch checked={value} onChange={(e) => handleChange(item.accountId, e)} name="enabled" color="primary" />}
            label={value ? "Enabled" : "Disabled"}
          />,
        ]);
      })) ||
    [];

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setAccountList(undefined);
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setAccountList(undefined);
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  return (
    <Main
      title="Bundle Alerts"
      data-cy="support-bundle-alerts-account-page"
      featureFlag={featureFlagsMap.BUNDLE_ALERTS}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      levelFlag={UserLevelFlag.Sub}
      needSelectedAccount={true}
    >
      <ManageBundleAlertsTabBar selectedTab="bundle-alerts-account">
        <Grid item xs={12} md={12}>
          {isFetching ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <div className={classes.tableCtr} data-cy="bundle-alerts-table">
              <Grid container item justifyContent="flex-end" className={classes.actions}>
                <Button
                  variant="contained"
                  color="primary"
                  data-cy="save-bundle-alert-button"
                  onClick={() => handleUpdateAction()}
                  disabled={(!Object.keys(dirtyAccountList).length && !allOperation) || !acc?.list.length}
                >
                  Save Changes
                </Button>
              </Grid>
              <Grid container item justifyContent="flex-end" className={classes.actions}>
                <ToggleButtons
                  value={allOperation}
                  onChange={(ev, value) => setAllOperation(value)}
                  options={[
                    { label: "Enable All", value: "enabled-all" },
                    { label: "Disable All", value: "disabled-all" },
                  ]}
                />
              </Grid>
              <MUIDataTable
                title=""
                data={parsed}
                columns={["Account", "Company Name", ""]}
                options={{
                  page,
                  rowsPerPage: rowCount,
                  rowsPerPageOptions: rowCountOptions,
                  count: acc?.total,
                  onTableChange: handleTableChange,
                  pagination: true,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  viewColumns: false,
                  sort: false,
                  search: false,
                  rowHover: true,
                  serverSide: true,
                  selectableRows: "none",
                  selectableRowsHeader: false,
                  setTableProps: () => ({ size: "small" }),
                  setRowProps: (row) => ({
                    "data-cy": `bundle-alert-id-${row?.[0]}`,
                  }),
                }}
              />
            </div>
          )}
        </Grid>
      </ManageBundleAlertsTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    actions: {
      marginBottom: theme.spacing(2),
    },
    tableCtr: {},
  })
);

export default BundleAlertsAccount;

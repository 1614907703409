import { Filter } from "../filter/types";
import { capitalize } from "../_app/utils/format";

export const communicationFilters = [
  {
    id: "creationDate",
    name: "date",
    label: "Date",
    type: "DATE_RANGE",
    resources: null,
    values: [],
    options: {},
    valueDisplayFunc: (val: any) => val?.split(",")?.join(" to ") ?? "",
    showToggle: true,
  },
  {
    id: "type",
    label: "Type",
    name: "type",
    type: "ENUM",
    values: [
      "COMMUNICATION",
      "DOCUMENT",
      "MARKETING",
    ],
    options: {},
    valueDisplayFunc: (val: any) => capitalize(val),
    showToggle: true,
  },
  {
    id: "subject",
    name: "subject",
    label: "Subject",
    type: "TEXT",
    resources: null,
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "recipient",
    name: "recipient",
    label: "Recipient",
    type: "TEXT",
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "associatedProduct",
    name: "associatedProduct",
    label: "Associated Product",
    type: "TEXT",
    values: [],
    options: {},
    showToggle: true,
  },
] as Filter[];
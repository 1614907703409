import { useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import { getWdrStatus, getWdrStatusForAsset, setWdrStatus, setWdrStatusForAsset } from "./api";
import { WdrStatus, WdrStatusChange, WdrStatusResponse } from "./types";
import { useFeedbackAlerts } from "../_app/hooks";

export function useWdrStatus(page: number, limit: number, queries?: any[], assets?: string[], options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<WdrStatusResponse, AjaxResponse>(
    ["wdr-status", { page, limit, queries, assets }],
    () => getWdrStatus(page, limit, queries, assets),
    {
      ...options,
      staleTime: 0,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message);
      },
    },
  );
}

export function useWdrStatusForAsset(assetId: string, options: any = {}) {
  return useQuery<WdrStatus, AjaxResponse>(["wdr-status", { assetId }], () => getWdrStatusForAsset(assetId), {
    ...options,
  });
}

export function useSetWdrStatus(values: any, options: any = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<string, AjaxResponse, any>(() => setWdrStatus(values), {
    ...options,
    onSuccess: (data) => {
      setFeedbackAlertSuccess(data);
      options?.onSuccess?.();
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useSetWdrStatusForAsset(assetId: string, options: any = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const queryClient = useQueryClient();

  return useMutation<string, AjaxResponse, any>((statusChange: WdrStatusChange) => setWdrStatusForAsset(statusChange), {
    ...options,
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["wdr-status", { assetId }]);
    },
  });
}
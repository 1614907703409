import React, { useState, useEffect } from "react";
import Main from "../../_app/layouts/Main";
import { Button, Card, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ManagePaymentsTabBar from "../components/ManagePaymentsTabBar";
import { permissionCodes } from "../../permission/hooks";
import { usePaymentDetails } from "../hooks";
import { useGetBalance } from "../../billing/hooks";
import { useGetOverdue } from "../../billing/hooks";
import { formatNumber } from "../../_app/utils/format";
import { featureFlagsMap } from "../../feature/utils";

const useStyles = makeStyles((theme) => ({
  directDebitCardDetails: {
    minHeight: "550px",
    padding: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: "center",
    "& span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1": {
      width: "100%",
    },
  },
  filledContentWrap: {
    minHeight: "410px",
    textAlign: "left",
  },
  radioWrap: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  radioCheckedWrap: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
}));

export const CardPayment = () => {
  const classes = useStyles();
  const selectedTab = "card-payment";
  const { data: balance } = useGetBalance();
  const { data: overdue } = useGetOverdue();

  const [accountNumber, setAccountNumber] = useState("");
  const [payment, setPayment] = React.useState("FULL_BALANCE");
  const [savedCard, setSavedCard] = React.useState("CARD1");

  const { data } = usePaymentDetails();

  useEffect(() => {
    if (data?.accountHoldersName) {
      setAccountNumber(data?.accountNumber);
    }
  }, [data]);

  const handlePaymentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPayment((event.target as HTMLInputElement).value);
  };

  const handleCardChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSavedCard((event.target as HTMLInputElement).value);
  };

  const handleUpdate = () => {
    return;
  };

  return (
    <Main
      title="Payment details"
      data-cy="card-payment-page"
      accessPermission={permissionCodes.ACCOUNT_PAYMENTS}
      needSelectedAccount={true}
      featureFlag={featureFlagsMap.CARD_PAYMENT}
    >
      <ManagePaymentsTabBar selectedTab={selectedTab}>
        <Grid container spacing={2}>
          <Grid xs={12} md={5} item>
            <Card className={classes.directDebitCardDetails}>
              <div className={classes.filledContentWrap} data-cy="payment-details-type">
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h2" gutterBottom>
                    Your Balance
                  </Typography>
                  <Typography variant="h2" gutterBottom>
                    {formatNumber(balance?.gross, 2, balance?.unit)}
                  </Typography>
                </Grid>
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h6" gutterBottom>
                    Overdue amount
                  </Typography>
                  <Typography variant="h6" color="error" gutterBottom>
                    {formatNumber(overdue?.gross, 2, overdue?.unit)}
                  </Typography>
                </Grid>
                <Typography gutterBottom component="div">
                  <hr />
                </Typography>
                <Typography variant="h6" gutterBottom>
                  I would like to pay
                </Typography>
                <FormControl component="fieldset" fullWidth>
                  <RadioGroup value={payment} onChange={handlePaymentChange}>
                    <FormControlLabel
                      value="FULL_BALANCE"
                      control={<Radio color="primary" />}
                      label={
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Typography variant="h6">Full balance</Typography>
                          <Typography variant="h6">{formatNumber(balance?.gross, 2, balance?.unit)}</Typography>
                        </Grid>
                      }
                      className={payment === "FULL_BALANCE" ? classes.radioCheckedWrap : classes.radioWrap}
                    />
                    <FormControlLabel
                      value="OVERDUE_AMOUNT"
                      control={<Radio color="primary" />}
                      label={
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Typography variant="h6">Overdue amount</Typography>
                          <Typography variant="h6">{formatNumber(overdue?.gross, 2, overdue?.unit)}</Typography>
                        </Grid>
                      }
                      className={payment === "OVERDUE_AMOUNT" ? classes.radioCheckedWrap : classes.radioWrap}
                    />
                    <FormControlLabel
                      value="PAYMENT_AMOUNT"
                      control={<Radio color="primary" />}
                      label={
                        <Grid container direction="row" justifyContent="space-between" alignItems="center">
                          <Typography variant="h6">Enter a payment amount</Typography>
                          <TextField name="paymentAmount" label="£" type="code" variant="outlined" size="small" />{" "}
                        </Grid>
                      }
                      className={payment === "PAYMENT_AMOUNT" ? classes.radioCheckedWrap : classes.radioWrap}
                    />
                  </RadioGroup>
                </FormControl>

                <Typography gutterBottom component="div">
                  <hr />
                </Typography>
                <Typography variant="h6" gutterBottom>
                  Saved cards
                </Typography>

                <FormControl component="fieldset" fullWidth>
                  <RadioGroup value={savedCard} onChange={handleCardChange}>
                    <FormControlLabel
                      value="CARD1"
                      control={<Radio color="primary" />}
                      label={<Typography variant="h6">Card ending {accountNumber}</Typography>}
                      className={savedCard === "CARD1" ? classes.radioCheckedWrap : classes.radioWrap}
                    />
                    <FormControlLabel
                      value="CARD2"
                      control={<Radio color="primary" />}
                      label={<Typography variant="h6">Card ending {accountNumber}</Typography>}
                      className={savedCard === "CARD2" ? classes.radioCheckedWrap : classes.radioWrap}
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              <Button variant="contained" color="primary" onClick={handleUpdate} data-cy="payment-button" fullWidth>
                Make a payment
              </Button>
            </Card>
          </Grid>
        </Grid>
      </ManagePaymentsTabBar>
    </Main>
  );
};

export default CardPayment;

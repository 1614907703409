import React, { ReactNode } from "react";
import { Theme, Card, CardContent, CardHeader, CardActions, Typography, CardProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import UILoader from "./UILoader";

export interface UICardProps extends CardProps {
  children: ReactNode;
  headerTitle?: ReactNode;
  headerProps?: any;
  light?: boolean;
  fetchingText?: string;
  isFetching?: boolean;
  isSuccess?: boolean;
  height?: string;
  padding?: string;
  style?: any;
  contentStyle?: any;
  errorMessage?: ReactNode;
  titleIndented?: boolean;
  className?: string;
  hasBorder?: boolean;
  elevation?: number;
  hasHover?: boolean;
  actions?: ReactNode[];
  isSimpleCard?: boolean;
}

export const UICard = ({
  children,
  headerTitle,
  headerProps = {},
  light = true,
  fetchingText,
  isFetching = false,
  isSuccess = true,
  height = "100%",
  padding = "12px",
  style = {},
  contentStyle = {},
  errorMessage,
  className = "",
  hasBorder = false,
  elevation = 1,
  hasHover = false,
  actions = [],
  isSimpleCard = false,
  ...props
}: UICardProps) => {
  const classes = useStyles({ light: light });
  if (isSimpleCard) {
    return (
      <Card elevation={elevation} className={className}>
        {children}
      </Card>
    );
  }
  return (
    <Card
      raised
      className={`
        ${classes.root}
        ${hasBorder && classes.bordered}
        ${hasHover && classes.hover}
        ${className}
      `}
      style={{ padding: headerTitle ? "0" : padding, ...style }}
      elevation={elevation}
      data-cy="ui-card"
      {...props}
    >
      <>
        {Boolean(headerTitle) && (
          <CardHeader
            title={headerTitle}
            className={classes.headerTitle}
            titleTypographyProps={{ align: "center" }}
            {...headerProps}
          />
        )}
        <CardContent className={classes.content} style={{ height: height, padding: padding, ...contentStyle }}>
          {!isFetching && isSuccess && children}
          {!isFetching && !isSuccess && <div className={classes.progress}>{errorMessage}</div>}
          {isFetching && (
            <div className={classes.progress}>
              <UILoader />
              {Boolean(fetchingText) && <Typography>{fetchingText}</Typography>}
            </div>
          )}
        </CardContent>
        {Boolean(actions.length) && <CardActions>{actions}</CardActions>}
      </>
    </Card>
  );
};

const useStyles = makeStyles<Theme, { light: boolean }>((theme: Theme) => ({
  root: (props) => ({
    height: "100%",
    backgroundColor: props.light ? theme.palette.background.paper : theme.palette.tertiary.main,
    color: props.light ? theme.palette.text.primary : theme.palette.primary.contrastText,
  }),
  bordered: {
    borderLeft: "3px solid",
    borderLeftColor: theme.palette.primary.main,
  },
  hover: {
    transition: "all .1s ease-in-out",
    cursor: "pointer",
    "&:hover": {
      transform: "scale(1.05)",
      opacity: 0.9,
    },
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "&:last-child": {
      paddingBottom: "0px",
    },
  },
  headerTitle: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    borderBottom: "1px solid #d3d2d7",
  },
  progress: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    "& p": {
      marginTop: "20px",
    },
  },
}));

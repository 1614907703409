import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Collapse, Divider, Link, List, ListItem, ListItemText } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { UICard } from "../../_app/components/UICard";
import { filtersIdMap } from "../../filter/utils";
import { BillCategory, BillElement, BillFilter } from "../types";
import { formatPackageName, formatPrice } from "../utils";

interface Props {
  cost: BillElement;
  name: string;
  isUnbilled: boolean;
}

export default function BillPackages({ cost, name, isUnbilled }: Props) {
  const classes = useStyles();
  const [openPackage, setOpenPackages] = useState(false);
  const history = useHistory();
  const hasBreakdown = Boolean(cost?.categories?.length);

  const itemProps: any = {
    onClick: () => setOpenPackages(!openPackage),
    className: classes.packageButton,
    id: "package",
    disableGutters: true,
  };
  if (hasBreakdown) itemProps.button = true;

  const formatCostFilter = (filter: BillFilter) => {
    let filterValues: string[] = [];
    Object.keys(filter).map((filterName: string) => {
      let filterValue = filter[filterName];
      if (filterName === filtersIdMap.BILL_PERIOD || filterName === filtersIdMap.SERVICES_BILL_PERIOD) {
        if (isUnbilled) filterValue = "UNBILLED-" + filterValue;
      }
      return filterValues.push(`${filterName}=${filterValue}`);
    });
    return `/${name === "usageCharges" ? "usage" : "services"}?${filterValues.join("&")}`;
  };

  return (
    <List className={classes.packageList}>
      <UICard isSimpleCard elevation={1}>
        <ListItem {...itemProps}>
          {openPackage ? (
            <KeyboardArrowUpIcon className={`${classes.icon} ${!hasBreakdown && classes.hide}`} />
          ) : (
            <KeyboardArrowDownIcon className={`${classes.icon} ${!hasBreakdown && classes.hide}`} />
          )}
          <ListItemText primary={formatPackageName(name)} data-cy={`cost_${name}`} />
          <Link
            underline="hover"
            onClick={() => cost?.frontendAnalysisFilters && history.push(formatCostFilter(cost?.frontendAnalysisFilters))}
            className={classes.link}
          >
            <div className={classes.price}>{formatPrice(cost?.net)}</div>
          </Link>
        </ListItem>
      </UICard>
      {hasBreakdown && (
        <UICard isSimpleCard elevation={1} className={classes.packageItem}>
          <Collapse in={openPackage} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {cost?.categories.map((sub: BillCategory) => {
                return (
                  <React.Fragment key={sub.name}>
                    <ListItem className={classes.packageName} data-cy={`sub_${sub?.name}`}>
                      <ListItemText primary={sub?.name} />
                      <Link
                        underline="hover"
                        onClick={() => {
                          sub?.frontendAnalysisFilters && history.push(formatCostFilter(sub?.frontendAnalysisFilters));
                        }}
                        className={classes.link}
                      >
                        <div className={classes.price}>{formatPrice(sub?.net)}</div>
                      </Link>
                    </ListItem>
                    <Divider />
                  </React.Fragment>
                );
              })}
            </List>
          </Collapse>
        </UICard>
      )}
    </List>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(1),
    },
    iconButton: {
      color: theme.palette.text.primary,
      paddingTop: "9px",
    },
    hide: {
      opacity: 0,
    },
    packageList: {
      "& .MuiListItem-gutters": {
        paddingRight: theme.spacing(1),
      },
    },
    packageButton: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    packageItem: {
      marginLeft: theme.spacing(3.5),
      marginTop: "1px",
    },
    packageName: {
      fontSize: "14px",
      "& .MuiTypography-body1": {
        fontSize: "14px",
      },
      paddingTop: "2px",
      paddingBottom: "2px",
    },
    link: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontWeight: "bold",
      paddingRight: theme.spacing(1),
    },
    price: {
      paddingRight: theme.spacing(1),
    },
  })
);

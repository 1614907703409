import React, { useState } from "react";
import { Alert, Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableState } from "mui-datatables";
import Main from "../../_app/layouts/Main";
import ManageSimActivationTabBar from "../components/ManageSimActivationTabBar";
import { useAllSimForActivation, useSimActivate } from "../hooks";
import UIConfirm from "../../_app/components/UIConfirm";
import { permissionCodes } from "../../permission/hooks";
import StatusChip from "../../_app/components/StatusChip";
import { rowParser } from "../../_app/components/Table/helpers";
import { useStore } from "../../_app/hooks";
import UILoader from "../../_app/components/UILoader";
import { featureFlagsMap } from "../../feature/utils";
import { DispatchedAsset } from "../types";
import { canActivateSim } from "../utils";

export interface TableConfig {
  title: string;
  columns: MUIDataTableColumnDef[];
}

const tableConfig: TableConfig = {
  title: "",
  columns: ["CLI/Identifier", "Tag", "Network", "SIM Number", "New SIM Number", "New SIM Type", { label: " ", name: "Actions" }],
};

export const SimActivate = () => {
  const classes = useStyles();
  const selectedTab = "sim-activate";
  const { state } = useStore();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [warning, setWarning] = useState(false);
  const [selectedSim, setSelectedSim] = useState<DispatchedAsset>();
  const accountSelected = Boolean(state.contextHierarchy?.id !== state.contextHierarchy?.lastParentId);

  const { data: sims, isFetching: isSimFetching } = useAllSimForActivation(page, rowCount, { enabled: accountSelected });
  const { mutate: executeSimActivate, isLoading: isActivating} = useSimActivate();

  const onSubmit = () => {
    if (canActivateSim(selectedSim as DispatchedAsset)) {
      executeSimActivate(selectedSim);
    }
  };

  const renderAction = (sim: DispatchedAsset) => {
    if (canActivateSim(sim)) {
      return (
        <Button
          variant="contained"
          color="primary"
          data-cy="activate-button"
          size="small"
          onClick={() => {
            setSelectedSim(sim);
            setWarning(true);
          }}
        >
          Activate
        </Button>
      );
    }
    return <StatusChip status={sim.requestStatus || ""} />;
  };

  const parsed =
    sims?.list.map((u) => {
      return rowParser([u.cli, u.tag, u.network, u.simNumber, u.newSimNumber, u.newSimType, renderAction(u)]);
    }) || [];

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  const showLoader = isSimFetching || isActivating;

  return (
    <Main
      title="Activate SIMs"
      data-cy="mobile-management-sim-activate-page"
      featureFlag={featureFlagsMap.SIM_ACTIVATE}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      needSelectedAccount
    >
      <ManageSimActivationTabBar selectedTab={selectedTab}>
        {showLoader ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <>
            <Alert severity="info" className={classes.info}>
              Ordered SIMs will appear on this page. Once you are ready to activate the SIM, simply click the 'Activate' button
              and it will be activated within twenty four hours.
            </Alert>
            <div className={classes.tableCtr}>
              <MUIDataTable
                title={tableConfig.title}
                data={parsed}
                columns={tableConfig.columns || []}
                options={{
                  onTableChange: handleTableChange,
                  pagination: true,
                  page,
                  rowsPerPage: rowCount,
                  rowsPerPageOptions: rowCountOptions,
                  count: sims?.total,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  sort: false,
                  search: false,
                  serverSide: true,
                  selectableRows: "none",
                  rowHover: true,
                  setTableProps: () => ({ size: "small" }),
                  setRowProps: (row) => ({ "data-cy": `row-id-${row?.[0]}` }),
                  viewColumns: false,
                }}
              />
            </div>
          </>
        )}
        <UIConfirm open={warning} setOpen={setWarning} onConfirm={onSubmit}>
          <strong data-cy="activate-confirmation">Are you sure you want to activate this SIM?</strong>
        </UIConfirm>
      </ManageSimActivationTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      height: "185px",
    },
    bottomCtr: {
      marginTop: theme.spacing(3),
    },
    tableCtr: {
      '& div[class*="MuiToolbar-root"]': {
        borderRadius: "0px",
      },
      '& div[class*="MUIDataTableFilterList-root-"]': {
        display: "none",
      },
    },
    disabledRow: {
      color: theme.palette.text.disabled,
    },
    accessTooltip: {
      whiteSpace: "pre-wrap",
    },
    info: {
      alignItems: "center",
      width: "fit-content",
      marginBottom: theme.spacing(3),
    },
  })
);

export default SimActivate;

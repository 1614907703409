import {
  createTicket,
  createTicketComment,
  getServiceRequestCount,
  getTicketById,
  getTicketComments,
  getTickets
} from "./api";
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import { CreateTicket, GetTicketCommentsResponse, GetTicketsResponse, ServiceRequestCount, Ticket } from "./types";
import { useFeedbackAlerts } from "../_app/hooks";
import { genericError } from "../_app/utils/text";

export function useServiceRequestCount(max: number, options = {}) {
  return useQuery<ServiceRequestCount[], AjaxResponse>(["serviceRequestCount", max], () => getServiceRequestCount(max), {
    ...options,
  });
}

export function useTickets(page: number, limit: number, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<GetTicketsResponse, AjaxResponse>(["tickets", { page, limit }], () => getTickets(page, limit), {
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    },
    ...options,
  });
}

export function useSingleTicket(id: string, options = {}) {
  return useQuery<Ticket, AjaxResponse>(["ticket", { id }], () => getTicketById(id), {
    ...options,
  });
}

export function useTicketComments(ticketId: string, limit: number = 25, options = {}) {
  return useInfiniteQuery<GetTicketCommentsResponse, AjaxResponse>(
    ["ticketComments", { ticketId }],
    ({ pageParam = 0 }) => getTicketComments({ ticketId, page: pageParam, pageSize: limit }),
    {
      getNextPageParam: ({ page, pageSize, total }: GetTicketCommentsResponse) => {
        const end = typeof total === "number" ? total : 100;
        if ((page + 1) * pageSize < end) {
          return page + 1;
        } else {
          return undefined;
        }
      },
      ...options,
    }
  );
}

export function useCreateTicket(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<Ticket, AjaxResponse, CreateTicket>(createTicket, {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(["tickets"]);
      options?.onSuccess?.();
      setFeedbackAlertSuccess("Ticket created");
    },
    onError: () => {
      setFeedbackAlertError(genericError())
    },
  });
}

export function useCreateTicketComment(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<any, any, any>(({ ticketId, comment }) => createTicketComment(ticketId, comment), {
    onMutate: async ({ ticketId, comment }) => {
      await queryClient.cancelQueries(["ticketComments", { ticketId }]);
      const prevSnapshot = queryClient.getQueryData(["ticketComments", { ticketId }]);
      queryClient.setQueryData(["ticketComments", { ticketId }], (old: any) => {
        const temp = {
          id: String(Date.now()),
          created: null,
          internal: true,
          comment,
        };
        if (!old) return { pages: [{ page: 0, pageSize: 25, list: [temp] }] };
        const oldPage1 = old.pages[0];
        const newPage1 = {
          ...oldPage1,
          list: [temp, ...oldPage1.list],
        };
        return {
          ...old,
          pages: [newPage1, ...old.pages.slice(1)],
        };
      });
      return { prevSnapshot };
    },
    onError: (error, { ticketId }, context: any) => {
      queryClient.setQueryData(["ticketComments", { ticketId }], context.prevSnapshot);
      setFeedbackAlertError(error?.data?.message || genericError());
    },
    onSuccess: (data, { ticketId }) => {
      if (ticketId) {
        queryClient.invalidateQueries(["ticketComments", { ticketId }]);
      }
      setFeedbackAlertSuccess("Comment added");
      options?.onSuccess?.();
    },
    ...options,
  });
}

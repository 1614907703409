import api from "../_app/api";
import { Brand, SupportContact } from "./types";

export const getBrand = async () => {
  const { data }: { data: Brand } = await api({
    method: "GET",
    url: "/brands/my",
  });
  return data;
};

export const getSupportContact = async (accountId: string) => {
  const { data }: { data: SupportContact } = await api({
    method: "GET",
    context_injection: true,
    url: `/accounts/${accountId}/support`,
  });
  return data;
};

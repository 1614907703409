import { Grid, Modal, Theme, useMediaQuery } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  [key: string]: any;
}

function Modaled({ open = true, width = 40, onClose = (props: any) => props.history.goBack(), children, ...rest }: Props) {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const close = () => onClose(rest);
  return (
    <Modal open={open} onClose={close} className={classes.modal}>
      <Grid container justifyContent="center" alignContent="center" className={classes.ctr}>
        <Grid
          style={{
            width: isSmallScreen ? "95vw" : width + "vw",
            height: "calc(100vh - 57px)",
            overflow: "auto",
            borderRadius: "8px",
          }}
        >
          {children}
        </Grid>
      </Grid>
    </Modal>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      height: "100%",
    },
    modal: {},
  })
);

export default Modaled;

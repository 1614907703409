import React, { ChangeEvent, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useHistory, useLocation } from "react-router-dom";
import { Alert, Button, Checkbox, FormControlLabel, Radio, RadioGroup, Typography } from "@mui/material";
import { useRequestAutoSwitch } from "../hooks";
import { useFeedbackAlerts, useMemoizedCallback } from "../../_app/hooks";
import { SelectedParams } from "../../asset/screens/MobileManagement";
import { featureFlagsMap } from "../../feature/utils";
import { permissionCodes } from "../../permission/hooks";
import Main from "../../_app/layouts/Main";
import { genericError } from "../../_app/utils/text";
import { UICard } from "../../_app/components";
import UILoader from "../../_app/components/UILoader";

export const SimSwitch = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation<SelectedParams>();
  const assets = location.state?.assets;
  const filters = location.state?.filters;
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const [isHandling, setIsHandling] = useState(false);
  const [type, setType] = useState("PAC");
  const [sendSms, setSendSms] = useState(true);

  useEffect(() => {
    if ((!assets || !assets?.length) && !filters) {
      setFeedbackAlertError("No mobile numbers selected");
      history.push({ pathname: "/mobile-management" });
    }
  }, [assets, filters, history]);

  const { mutate: makeRequest, data: requestId, isLoading, isSuccess, error } = useRequestAutoSwitch();

  const onSuccess = useMemoizedCallback(
    (requestId: string) => {
      setIsHandling(true);
      setTimeout(() => {
        history.push({
          pathname: "/mobile-management/sim-switch/results",
          state: { requestId },
          search: location.search,
        });
      }, 2000);
    },
    [assets]
  );

  useEffect(() => {
    if (error) {
      setFeedbackAlertError(error.data.message || genericError());
    }
  },[error, history, onSuccess]);

  useEffect(() => {
    if (isSuccess && requestId) onSuccess(requestId);
  }, [isSuccess, requestId, onSuccess]);

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    const target = ev.target;
    switch (target?.name) {
      case "auto-switch-type":
        return setType(target.value);
      case "send-sms":
        return setSendSms(target.checked);
    }
  };

  const onApply = () => {
    if (isLoading) return;
    const params = {
      assetIds: assets?.map((a) => a.id),
      filters,
      operations: [
        {
          requestType: type,
          sendSms: Boolean(sendSms),
        },
      ],
    };
    makeRequest(params);
  };

  const renderLabel = (label: string, sublabel: string) => {
    return (
      <div className={classes.label}>
        <Typography>{label}</Typography>
        <Typography variant="subtitle2">{sublabel}</Typography>
      </div>
    );
  };

  const renderError = () => {
    if (assets?.length) return null;
    if (filters) return null;
    return <Alert severity="error">No mobile numbers selected</Alert>;
  };

  return (
    <Main
      title="Auto Switch"
      data-cy="sim-switch-page"
      needSelectedAccount={true}
      featureFlag={featureFlagsMap.ASSETS}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      {renderError() || isHandling ? (
        <div className={classes.handling}>
          <UILoader size={50} />
          <Typography variant="subtitle1">We are handling your request</Typography>
        </div>
      ) : (
        <div className={classes.ctr}>
          <UICard hasBorder className={classes.card} padding="16px">
            <RadioGroup name="auto-switch-type" data-cy="auto-switch-type" value={type} onChange={onChange} color="primary">
              <FormControlLabel
                value="PAC"
                control={<Radio color="primary" />}
                label={renderLabel("Request PAC Code", "Switch providers and take your number with you")}
              />
              <FormControlLabel
                value="STAC"
                control={<Radio color="primary" />}
                label={renderLabel("Request STAC Code", "Switch providers and don't take your number with you")}
              />
              <FormControlLabel
                value="ETF"
                control={<Radio color="primary" />}
                label={renderLabel("Request Switching Information", "Learn how much you'd get charged if you switched providers")}
              />
            </RadioGroup>
          </UICard>
          <UICard hasBorder className={classes.card} padding="16px">
            <FormControlLabel
              label={renderLabel(
                "Send SMS to End User",
                "Send the requested information via SMS to each of the mobile numbers selected"
              )}
              name="send-sms"
              control={<Checkbox checked={sendSms} color="primary" onChange={onChange} />}
            />
          </UICard>
          <Button
            onClick={onApply}
            disabled={isLoading || isHandling}
            variant="contained"
            color="primary"
            data-cy="apply-btn"
            startIcon={isLoading && <UILoader size={20} />}
            className={classes.btn}
          >
            Apply
          </Button>
        </div>
      )}
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      paddingTop: theme.spacing(1.5),
    },
    card: {
      marginBottom: theme.spacing(3),
      height: "fit-content",
    },
    label: {
      display: "flex",
      flexDirection: "column",
      marginBottom: theme.spacing(1.5),
      marginTop: theme.spacing(1.5),
      "& > h6": {
        color: theme.palette.grey[400],
      },
    },
    btn: {
      width: 90,
    },
    handling: {
      display: "flex",
      height: "70vh",
      flex: "1",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      gap: "35px",
      marginTop: "30px",
    },
  })
);

export default SimSwitch;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import { Theme } from "@mui/material/styles";
import withStyles from "@mui/styles/withStyles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAccountsForParent, useMyAccounts } from "../../../account/hooks";
import SwitchAccountsDialog from "../../../user-level/components/SwitchAccountsDialog";
import { useUserLevelMap, useLeafUserLevel, useLowestUserLevel } from "../../../user-level/hooks";
import HierarchyBreadcrumbs from "../../../user-level/components/HierarchyBreadcrumbs";
import MasqueradingChip from "./MasqueradingChip";
import { useStore } from "../../hooks";
import { getLastParent } from "../../../context/utils";
import { useIdentityUser } from "../../../auth/hooks";
import { useUserData } from "../../../user/hooks";

interface MainProps {
  handleDrawerToggle: () => void;
}

const StyledMenu = withStyles({
  paper: {
    width: "249px",
    marginTop: "35px",
  },
})((props: MenuProps) => (
  <Menu
    id="menu-appbar"
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    keepMounted
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontSize: "14px",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
  },
}))(MenuItem);

function ToolbarMenu({ handleDrawerToggle }: MainProps) {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useStore();
  const [anchorEl, setAnchorEl] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { data: identityUser } = useIdentityUser();
  const { data: groups } = useMyAccounts();

  const parentAccounts = getLastParent(state.contextHierarchy)?.userAccessibleAccounts;
  const parentAccountId = parentAccounts?.[0].id;
  const { userLevel } = useUserData();

  const { data: accountsResp } = useAccountsForParent(
    {
      parent: parentAccountId || "",
      limit: 25,
    },
    { enabled: Boolean(parentAccountId) }
  );
  const accountsLength = accountsResp?.pages?.[0]?.list?.length;
  const userLevels = useUserLevelMap();
  const lowestUserLevel = useLowestUserLevel();
  const leafLevel = useLeafUserLevel();
  const showSwitchGroups = Boolean(identityUser && groups && groups.length > 1);
  const open = Boolean(anchorEl);
  const showSwitchAccounts = Boolean(
    identityUser &&
      userLevel !== lowestUserLevel?.id &&
      ((userLevel < leafLevel && accountsLength) ||
        (userLevel === leafLevel && accountsLength && accountsLength > 1) ||
        (parentAccounts?.length && parentAccounts?.length > 1))
  );

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const goToMyDetailsPage = () => {
    handleClose();
    history.push(`/profile`);
  };

  const goToSelectGroup = () => {
    handleClose();
    history.push(`/groups`);
  };

  const goToSwitchAccounts = () => {
    handleClose();
    setIsDialogOpen(true);
  };

  const handleContactSupport = () => {
    handleClose();
    history.push("/contact-support");
  };

  const handleLogout = () => {
    handleClose();
    history.push("/logout");
  };

  return (
    <AppBar position="fixed" className={classes.appBar} data-cy="app-bar">
      <Toolbar className={classes.toolbar}>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.menuButton}
          size="large"
        >
          <MenuIcon />
        </IconButton>
        <HierarchyBreadcrumbs />
        <div>
          <Typography
            data-cy="menu-appbar"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            variant="subtitle2"
            color="textPrimary"
            className={classes.userName}
            noWrap
          >
            <span data-cy="menu-username">
              | {identityUser?.profile.given_name} {identityUser?.profile.family_name}
            </span>
            <MasqueradingChip lite placement="left-start" className={classes.masquerade} />
            <ExpandMoreIcon style={{ color: "#524B65" }} />
          </Typography>
          <StyledMenu anchorEl={anchorEl} onClose={handleClose} open={open}>
            <StyledMenuItem onClick={goToMyDetailsPage} data-cy="my-details-link">
              My Details
            </StyledMenuItem>
            {showSwitchGroups && (
              <StyledMenuItem onClick={goToSelectGroup} data-cy="groups-link">
                Select {userLevels?.[10]?.name}
              </StyledMenuItem>
            )}
            {showSwitchAccounts && (
              <StyledMenuItem onClick={goToSwitchAccounts} data-cy="accounts-link">
                Switch {userLevels?.[30]?.name}s
              </StyledMenuItem>
            )}
            <StyledMenuItem onClick={handleContactSupport} data-cy="contact-support-link">
              Contact Support
            </StyledMenuItem>
            <StyledMenuItem onClick={handleLogout} data-cy="logout-link">
              Log Out
            </StyledMenuItem>
          </StyledMenu>
        </div>
      </Toolbar>
      <SwitchAccountsDialog title={"Switch " + userLevels?.[30]?.name + "s"} open={isDialogOpen} setOpen={setIsDialogOpen} />
    </AppBar>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: theme.palette.grey[100],
      flexGrow: 1,
      maxHeight: "64px",
      overflow: "hidden",
    },
    menuButton: {
      marginRight: "auto",
      [theme.breakpoints.up("sm")]: {
        display: "none",
      },
    },
    toolbar: {
      justifyContent: "flex-end",
      paddingRight: "10px",
    },
    userName: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      fontWeight: "bold",
    },
    masquerade: { marginLeft: "5px !important" },
  })
);

export default ToolbarMenu;

import React, { useEffect, useState } from "react";
import Main from "../../_app/layouts/Main";
import { useBillingAddress, useBillingAddressUpdate, useBillSettings, useBillSettingsUpdate } from "../hooks";
import BillingAddress from "../components/AccountDetails/BillingAddress";
import BillingType from "../components/AccountDetails/BillingType";
import { useFeedbackAlerts } from "../../_app/hooks";
import { permissionCodes } from "../../permission/hooks";
import { useUserLevelMap } from "../../user-level/hooks";
import { Alert, Grid, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const AccountDetails = () => {
  const classes = useStyles();
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();
  const userLevels = useUserLevelMap();
  const [onlyEmail, setOnlyEmail] = useState(false);
  const userLevelName = userLevels?.[30]?.name;

  const { data, isLoading: isBillingAddressLoading, isError: isAddressError, isSuccess: isAddressSuccess } = useBillingAddress();
  const {
    data: billSettings,
    isLoading: isSettingsLoading,
    isError: isSettingsError,
    isSuccess: isSettingsSuccess,
  } = useBillSettings();
  const { mutate: executeBillingAddressUpdate, isLoading: isBillingAddressUpdating } = useBillingAddressUpdate();
  const {
    mutate: executeBillSettingsUpdate,
    isLoading: isSettingsUpdating,
    isSuccess: settingsUpdateSuccess,
  } = useBillSettingsUpdate();

  useEffect(() => {
    if (settingsUpdateSuccess)
      setFeedbackAlertSuccess(onlyEmail ? "Billing notification email address was changed." : "Billing type changed.");
  }, [settingsUpdateSuccess]);

  return (
    <Main
      title={`${userLevelName} Details`}
      data-cy="account-details-page"
      isLoading={isBillingAddressLoading || isBillingAddressUpdating}
      accessPermission={permissionCodes.ACCOUNT_MANAGE}
      needSelectedAccount={true}
    >
      <div className={classes.spacer} />
      {isAddressError && isSettingsError ? (
        <Grid item>
          <Alert severity="info">Account details cannot be amended for sub-accounts.</Alert>
        </Grid>
      ) : (
        <>
          {isAddressSuccess && <BillingAddress address={data} handleUpdate={executeBillingAddressUpdate} />}
          {isSettingsSuccess && (
            <BillingType
              billSettings={billSettings}
              handleUpdate={executeBillSettingsUpdate}
              setOnlyEmail={setOnlyEmail}
              userLevelName={userLevelName}
              isLoading={isSettingsLoading || isSettingsUpdating}
            />
          )}
        </>
      )}
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacer: {
      display: "block",
      width: "100%",
      height: theme.spacing(1.5),
    },
  }),
);

export default AccountDetails;

import React, { useCallback, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import { Alert, Button, FormControlLabel, Switch } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";
import { featureFlagsMap } from "../../feature/utils";
import StatusChip from "../../_app/components/StatusChip";
import MUIDataTable, { MUIDataTableState } from "mui-datatables";
import { useFeedbackAlerts } from "../../_app/hooks";
import { SelectedParams } from "../../asset/screens/MobileManagement";
import { useSetWdrStatus, useWdrStatus } from "../hooks";
import UILoader from "../../_app/components/UILoader";
import { WdrStatus } from "../types";

export const DataRoaming = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const location = useLocation<SelectedParams>();
  const assets = location.state?.assets;
  const filters = location.state?.filters;
  const [canSubmit, setCanSubmit] = useState<Boolean>(false);
  const [changes, setChanges] = useState(new Map());
  const [parsed, setParsed] = useState([] as any);

  useEffect(() => {
    if ((!assets || !assets?.length) && !filters) {
      setFeedbackAlertError("No mobile numbers selected");
      history.push({ pathname: "/mobile-management" });
    }
  }, [assets, filters, history]);

  const { data: wdrStatus, isFetching } = useWdrStatus(
    page,
    rowCount,
    filters,
    assets?.map((asset) => asset.id)
  );

  const { mutate: executeChangeWdr} = useSetWdrStatus(changes, {
    onSuccess: () => {
      history.push("/mobile-management");
    }
  });

  const handleToggle = useCallback(
    (asset: WdrStatus) => {
      const statuses = new Map(changes);
      const mobile = statuses.get(asset.assetId);
      if (mobile !== undefined) {
        statuses.delete(asset.assetId);
      } else {
        statuses.set(asset.assetId, !asset.wdrValue);
      }
      setChanges(statuses);
      setCanSubmit(statuses.size > 0);
    },
    [changes]
  );

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
    }
  };

  const onSubmit = () => {
    executeChangeWdr(changes);
  };

  useEffect(() => {
    const list = wdrStatus?.list?.map((item: WdrStatus) => {
      let checked = changes.get(item.assetId) !== undefined ? changes.get(item.assetId) : item.wdrValue;

      return [
        item?.cli,
        item?.tag,
        item?.costCentre,
        item?.account,
        item?.pendingChange ? (
          <StatusChip status="Updates Pending" />
        ) : (
          <FormControlLabel
            control={<Switch onChange={() => handleToggle(item)} checked={checked} />}
            label={checked ? "Capped" : "Uncapped"}
          />
        ),
      ];
    });
    setParsed(list);
  }, [wdrStatus, changes, handleToggle]);

  return (
    <Main
      title="Worldwide Data Roaming"
      data-cy="data-roaming-page"
      isLoading={false}
      featureFlag={featureFlagsMap.DATA_ROAMING}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      <>
        <div className={classes.grid} data-cy="data-roaming-table">
          <Alert severity="info" className={classes.info}>
            Worldwide Legislation from OFCOM means that we will be capping your Worldwide data roaming at £45 ex. VAT per billing
            month and barring you from using any more data abroad for the rest of your billing period.
            <br />
            <br />
            To ensure your business can continue to enjoy the same level of flexibility at present and prevent us from restricting
            your data usage abroad please opt out now by uncapping the relevant subscriptions.
            <br />
            <br />
            Please note that by removing the cap, you will be liable for any data charges incurred including, but not limited to,
            access charges for roaming services as well as out of bundle data roaming charges.
          </Alert>
          {isFetching ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <MUIDataTable
              title=""
              data={parsed}
              columns={["CLI/Identifier", "Tag", "Cost Centre", "Account", "Uncap/Cap"]}
              options={{
                page,
                rowsPerPage: rowCount,
                rowsPerPageOptions: rowCountOptions,
                onTableChange: handleTableChange,
                count: wdrStatus?.total,
                pagination: true,
                download: false,
                elevation: 1,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                serverSide: true,
                viewColumns: false,
                selectableRows: "none",
                selectableRowsHeader: false,
                sort: false,
                search: false,
                rowHover: true,
                setTableProps: () => ({ size: "small" }),
                setRowProps: (row) => ({ "data-cy": `row-id-${row[0]}` }),
              }}
            />
          )}
        </div>
        <div className={classes.actions}>
          {!isFetching && (
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={onSubmit}
              disabled={!canSubmit}
              style={{ marginLeft: "auto" }}
              data-cy="save-btn"
            >
              Save Changes
            </Button>
          )}
        </div>
      </>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(5),
    },
    actions: {
      marginTop: theme.spacing(3),
    },
    grid: {
      width: "100%",
      marginTop: theme.spacing(1.5),
    },
    info: {
      marginBottom: theme.spacing(3),
      alignItems: "center",
    },
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
  })
);

export default DataRoaming;

import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import UIConfirm from "../../../../_app/components/UIConfirm";
import SelectAllHeader from "../../../../_app/components/Table/SelectAllHeader";

interface UsageAlertTableHeaderProps {
  selectAll: boolean;
  setSelectAll: (value: boolean) => void;
  selectedRows: number[];
  isFetching: boolean;
  showConfirm: boolean;
  setShowConfirm: (value: boolean) => void;
  handleDeleteAction: () => void;
}

const UsageAlertTableHeader: React.FC<UsageAlertTableHeaderProps> = ({
  selectAll,
  setSelectAll,
  selectedRows,
  isFetching,
  showConfirm,
  setShowConfirm,
  handleDeleteAction,
}) => (
  <SelectAllHeader
    onChange={() => setSelectAll(!selectAll)}
    disabled={isFetching}
    value={selectAll}
    selectedRows={selectedRows || []}
  >
    <Button
      variant="outlined"
      size="small"
      color="primary"
      data-cy="delete-usage-alerts-button"
      onClick={() => setShowConfirm(true)}
      disabled={!selectedRows.length}
      startIcon={<DeleteIcon />}
    >
      Delete
    </Button>
    <UIConfirm open={showConfirm} setOpen={setShowConfirm} onConfirm={handleDeleteAction}>
      Are you sure you want to delete selected usage alerts?
    </UIConfirm>
  </SelectAllHeader>
);

export default UsageAlertTableHeader;

import React, { useRef, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import { Button, Card, Grid, GridSize, Skeleton, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { useValidation } from "../../form/hooks";
import { useCreateTicketComment, useSingleTicket } from "../hooks";
import { useTriggerKey } from "../../_app/hooks";
import FieldRow from "../components/FieldRow";
import CommentsList from "../components/CommentsList";
import { randomFromInterval } from "../../_app/utils";
import { permissionCodes } from "../../permission/hooks";
import { formatDate } from "../../_app/utils/format";
import { featureFlagsMap } from "../../feature/utils";

export const TicketDetails = () => {
  const commentsRef = useRef<null | HTMLDivElement>(null);
  const [seeLatestComment, seeLatestCommentTrigger] = useTriggerKey();
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [comment, setComment] = useState<string>("");

  const { validationErrors, validateSingle, validateAll } = useValidation({ comment: { required: false } }, comment);

  const { mutate: executeComment } = useCreateTicketComment({
    onSuccess: () => {
      reset();
      scrollToComments();
      seeLatestCommentTrigger();
    }
  });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e?.target?.name;
    const val = e?.target?.value;
    setComment(val);
    validateSingle(key, val);
  };

  const onSubmit = () => {
    const errors = validateAll();
    if (errors.length <= 0) {
      executeComment({ ticketId: id, comment });
    }
  };

  const scrollToComments = () =>
    commentsRef?.current?.scrollIntoView?.({
      behavior: "smooth",
      block: "start",
    });
  const reset = () => setComment("");

  const { data: ticketDetails, isFetching } = useSingleTicket(id);

  const itemProps = {
    item: true,
    xs: 12 as GridSize,
    sm: 6 as GridSize,
    md: "auto" as GridSize,
  };

  const placeholder: any = () => {
    if (isFetching) return <Skeleton width={100 + randomFromInterval(5, 200)} />;
    return "-";
  };

  return (
    <Main
      title={`Support Case #${ticketDetails?.ourRef}`}
      data-cy="ticket-details-page"
      isLoading={false}
      featureFlag={featureFlagsMap?.TICKETS}
      accessPermission={permissionCodes.SUPPORT_ACCESS}
      showNav={false}
      showToolbar={false}
      showFooter={false}
      needSelectedAccount={true}
    >
      <>
        <div className={classes.actions} />
        <div className={classes.grid}>
          <Grid container data-cy="ticket-details" spacing={2}>
            <Grid item xs={12} md={12}>
              <Card elevation={1} className={classes.card}>
                <Grid container className={classes.grid} direction="column" spacing={2}>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow title={"Your Ref:"} value={ticketDetails?.yourRef || placeholder()} />
                  </Grid>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow title={"Subject:"} value={ticketDetails?.subject || placeholder()} />
                  </Grid>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow title={"Request Type:"} value={ticketDetails?.type || placeholder()} />
                  </Grid>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow title={"Account Id:"} value={ticketDetails?.accountId || placeholder()} />
                  </Grid>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow title={"Account Name:"} value={ticketDetails?.accountName || placeholder()} />
                  </Grid>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow title={"Contact:"} value={ticketDetails?.contact || placeholder()} />
                  </Grid>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow
                      title={"CLI/Identifier:"}
                      value={ticketDetails?.clis.length ? ticketDetails?.clis : placeholder()}
                    />
                  </Grid>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow title={"Description"} value={ticketDetails?.description || "-"} isHtml />
                  </Grid>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow title={"Status:"} value={ticketDetails?.status || placeholder()} />
                  </Grid>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow
                      title={"Created Date:"}
                      value={formatDate(ticketDetails?.created, "dd/MM/yyyy HH:mm:ss") || placeholder()}
                    />
                  </Grid>
                  <Grid className={classes.attrGrid} {...itemProps}>
                    <FieldRow
                      title={"Resolved Date:"}
                      value={formatDate(ticketDetails?.resolved, "dd/MM/yyyy HH:mm:ss") || placeholder()}
                    />
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} ref={commentsRef}>
              <Card elevation={1} className={classes.card} data-cy="ticket-comment-card">
                <TextField
                  className={classes.label}
                  multiline
                  rows={3}
                  name="comment"
                  label="Add Comment"
                  value={comment || ""}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.comment)}
                  helperText={validationErrors.comment}
                  onChange={onInputChange}
                  onFocus={scrollToComments}
                />
                <Grid container item xs={12} justifyContent="flex-end" alignContent="center" className={classes.actions}>
                  <Button variant="text" color="primary" size="medium" onClick={reset} className={classes.button}>
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={onSubmit}
                    className={classes.button}
                    disabled={comment === ""}
                  >
                    Add Comment
                  </Button>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <CommentsList ticketId={id} scrollToTop={seeLatestComment} />
            </Grid>
          </Grid>
        </div>
      </>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    flexBlock: {
      display: "flex",
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    card: {
      height: "100%",
      padding: theme.spacing(3),
    },
    actions: {
      height: theme.spacing(4),
      gap: 20,
    },
    grid: {
      width: "100%",
      padding: 15,
      justifyContent: "space-around",
      [theme?.breakpoints?.down("md")]: {
        justifyContent: "flex-start",
      },
    },
    attrGrid: {
      width: "100%",
      padding: "2px !important",
      marginBottom: 7,
      paddingLeft: theme.spacing(2),
      "& > div": {
        "& > p:first-child": {
          minWidth: 150,
        },
        gap: 10,
      },
    },
    commentGrid: {
      paddingBottom: theme.spacing(2),
    },
    label: {
      fontSize: "18px",
      fontWeight: "bold",
      paddingBottom: theme.spacing(3),
      flex: 1,
    },
    button: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    commentCard: {
      height: "100%",
      padding: theme.spacing(2),
    },
    comment: {
      borderRadius: "5px",
      padding: theme.spacing(2),
      borderLeft: "3px solid " + theme.palette.primary.main,
    },
  })
);

export default TicketDetails;

import { Filter } from "../filter/types";

export const USAGE_BILL_PERIODS_ENDPOINT = "/filters/CALL_ANALYSIS/values/bills";
export const SERVICE_BILL_PERIODS_ENDPOINT = "/filters/SERVICE_ANALYSIS/values/bills";

export const usageAnalysisFilters = [
  {
    id: "5",
    name: "Bill Period",
    type: "IDENTIFIER",
    resources: USAGE_BILL_PERIODS_ENDPOINT,
    values: [],
    options: {
      MAX_COUNT: "3",
      ALL_VALUES: "false",
    },
    showToggle: true,
  },
  {
    id: "12",
    name: "Invoice",
    type: "IDENTIFIER",
    resources: "/filters/CALL_ANALYSIS/values/invoices",
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "1",
    name: "Account",
    type: "IDENTIFIER",
    resources: "/filters/CALL_ANALYSIS/values/accounts",
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "3",
    name: "Cost Centre",
    type: "IDENTIFIER",
    resources: "/filters/CALL_ANALYSIS/values/costcentres",
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "7",
    name: "CLI/Identifier",
    type: "IDENTIFIER",
    resources: "/filters/CALL_ANALYSIS/values/clis",
    values: [],
    options: {
      BULK_INPUT: "true",
      SEARCH_BAR: "true",
    },
    showToggle: true,
  },
  {
    id: "2",
    name: "Usage Type",
    type: "ENUM",
    resources: null,
    values: [
      "CALLS",
      "INTERNATIONAL",
      "VOICEMAIL",
      "OUTBOUND_NGN",
      "DQ_SERVICES",
      "PREMIUM_CALL",
      "ROAMED_CALLS",
      "EU_ROAMED_CALLS",
      "OTHER",
      "SMS",
      "INTERNATIONAL_SMS",
      "PREMIUM_SMS",
      "ROAMED_SMS",
      "EU_ROAMED_SMS",
      "MPAY",
      "MMS",
      "ROAMED_MMS",
      "EU_ROAMED_MMS",
      "DATA",
      "ROAMED_DATA",
      "EU_ROAMED_DATA",
      "VIDEO",
    ],
    options: {},
    showToggle: false,
  },
  {
    id: "4",
    name: "Date Range",
    type: "DATE_RANGE",
    resources: null,
    values: [],
    options: {},
    showToggle: false,
  },
  {
    id: "11",
    name: "Product Type",
    type: "IDENTIFIER",
    resources: "/filters/CALL_ANALYSIS/values/products",
    values: [],
    options: {
      ALL_VALUES: "true",
      ALL_VALUES_DEFAULT: "true",
    },
    showToggle: false,
  },
  {
    id: "8",
    name: "Dialled Number(s)",
    type: "TEXT",
    resources: null,
    values: [],
    options: {},
    showToggle: false,
  },
  {
    id: "9",
    name: "Excluded Number(s)",
    type: "TEXT",
    resources: null,
    values: [],
    options: {},
    showToggle: false,
  },
  {
    id: "10",
    name: "Cost",
    type: "AMOUNT",
    resources: null,
    values: [],
    options: {},
    showToggle: false,
  },
  {
    id: "6",
    name: "Call Duration",
    type: "AMOUNT",
    resources: null,
    values: [],
    options: {},
    showToggle: false,
  },
] as Filter[];

export const serviceAnalysisFilters = [
  {
    id: "21",
    name: "Bill Period",
    type: "IDENTIFIER",
    resources: SERVICE_BILL_PERIODS_ENDPOINT,
    values: [],
    options: {
      MAX_COUNT: "3",
      ALL_VALUES: "false",
    },
    showToggle: true,
  },
  {
    id: "28",
    name: "Invoice",
    type: "IDENTIFIER",
    resources: "/filters/SERVICE_ANALYSIS/values/invoices",
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "22",
    name: "Account",
    type: "IDENTIFIER",
    resources: "/filters/CALL_ANALYSIS/values/accounts",
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "23",
    name: "Cost Centre",
    type: "IDENTIFIER",
    resources: "/filters/SERVICE_ANALYSIS/values/costcentres",
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "24",
    name: "CLI/Identifier",
    type: "IDENTIFIER",
    resources: "/filters/SERVICE_ANALYSIS/values/clis",
    values: [],
    options: {
      BULK_INPUT: "true",
      SEARCH_BAR: "true",
    },
    showToggle: true,
  },
  {
    id: "25",
    name: "Product",
    type: "IDENTIFIER",
    resources: "/filters/SERVICE_ANALYSIS/values/classifications",
    values: [],
    options: {},
    showToggle: false,
  },
  {
    id: "26",
    name: "Frequency",
    type: "ENUM",
    resources: "/filters/SERVICE_ANALYSIS/values/chargers",
    values: ["One Off", "Recurring"],
    options: {},
    showToggle: false,
  },
  {
    id: "27",
    name: "Cost",
    type: "AMOUNT",
    resources: null,
    values: [],
    options: {},
    showToggle: false,
  },
  {
    id: "29",
    name: "Category",
    type: "IDENTIFIER",
    resources: "/filters/SERVICE_ANALYSIS/values/categories",
    values: [],
    options: {},
    showToggle: false,
  },
] as Filter[];

import React, { useEffect } from "react";
import { Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { SsoLogout } from "../components/SsoLogout";
import Secondary from "../../_app/layouts/Secondary";
import { useIdentityUser, useJWTLogout } from "../hooks";

function Logout() {
  const { data: identityUser, isFetching } = useIdentityUser();
  if (isFetching) return null;
  if (identityUser?.profile.act) return <LogoutImpersonatedUser />;
  return <LogoutViaSSO />;
}

function LogoutViaSSO() {
  useEffect(() => {
    localStorage.clear();
  }, []);
  return <SsoLogout />;
}

function LogoutImpersonatedUser() {
  const classes = useStyles();
  useJWTLogout();
  return (
    <Secondary className={classes.ctr}>
      <Typography>Logging out masqueraded user...</Typography>
    </Secondary>
  );
}

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      width: "100%",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
    },
  }),
);

export default Logout;

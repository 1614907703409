import React, { useCallback, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import PermissionsViewer from "../../permission/components/PermissionsViewer";
import { Permission } from "../../permission/api";
import { Button } from "@mui/material";
import { usePermissions } from "../../permission/hooks";
import { useQueryClient } from "react-query";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UILoader from "../../_app/components/UILoader";

interface Props {
  userId?: string;
  roleId?: string;
  levelId?: string;
  propagateData?: (perms: Permission[]) => void;
}

export const EditUserPermissionForm = ({ userId, roleId, levelId, propagateData = () => null }: Props) => {
  const classes = useStyles();
  const queryClient = useQueryClient();

  const [showPermissions, setShowPermissions] = useState(true);
  const togglePermissionsView = () => setShowPermissions(!showPermissions);

  const { data: permissionsList, isLoading } = usePermissions(userId, roleId, levelId);

  const [dirtyPerms, setDirtyPerms] = useState<Permission[]>([]);
  useEffect(() => {
    if (permissionsList) {
      setDirtyPerms([...permissionsList]);
    }
  }, [permissionsList]);

  useEffect(() => {
    queryClient.invalidateQueries(["permissions", userId, roleId, levelId]);
  }, [queryClient, userId, roleId, levelId]);

  const onChange = (updated: any) => {
    setDirtyPerms(
      dirtyPerms?.map((p) => {
        if (p?.id === updated?.id) return updated;
        return p;
      })
    );
  };

  const propagate = useCallback(
    (perms: Permission[]) => {
      propagateData(perms);
    },
    [propagateData]
  );

  useEffect(() => {
    propagate(dirtyPerms);
  }, [dirtyPerms, propagate]);

  return (
    <Grid container className={classes.ctr}>
      <Grid item md={4} sm={1} />
      <Grid item md={8} sm={12} className={classes.editPermsCtr}>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={togglePermissionsView}
          data-cy="edit-permissions"
          startIcon={showPermissions ? <ArrowUpIcon /> : <ArrowDownIcon />}
        >
          {showPermissions ? "Hide" : "Edit"} Individual Permissions
        </Button>
        {isLoading && (
          <div className={classes.loader}>
            <UILoader size={20} />
          </div>
        )}
      </Grid>
      {showPermissions && !isLoading && <PermissionsViewer permissions={dirtyPerms} onChange={onChange} />}
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {},
    loader: {
      marginLeft: "5px",
      marginTop: "5px",
    },
    editPermsCtr: {
      display: "flex",
      marginBottom: "2em",
      flexDirection: "row",
    },
  })
);

export default EditUserPermissionForm;

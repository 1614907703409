import api from "../_app/api";

export type LogicMonitorToken = string;

const LM_TOKEN_KEY = "jwt";

export const getLogicMonitorToken = () => localStorage.getItem(LM_TOKEN_KEY);
export const saveLogicMonitorToken = (token: LogicMonitorToken) => {
  if (token) localStorage.setItem(LM_TOKEN_KEY, token);
};
export const deleteLogicMonitorToken = () => localStorage.removeItem(LM_TOKEN_KEY);

export const fetchLogicMonitorToken = async (account_id: string = "") => {
  const { data }: { data: LogicMonitorToken } = await api({
    method: "GET",
    url: `/logic-monitor/${account_id}/jwt`,
  });
  return data;
};

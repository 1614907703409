import React, { ReactNode } from "react";
import { Typography, useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Tooltip from "@mui/material/Tooltip";
import { useStore } from "../../_app/hooks";
import { useHeadUserLevel, useLowestUserLevel, useSubUserLevel } from "../hooks";
import { UserLevel } from "../types";
import { useUserData } from "../../user/hooks";

const HierarchyBreadcrumbs = () => {
  const classes = useStyles();
  const { state } = useStore();
  const lowestUserLevel = useLowestUserLevel();
  const headUserLevel = useHeadUserLevel();
  const subUserLevel = useSubUserLevel();
  const { userLevel, userAccounts } = useUserData();
  const isLowestLevel = userLevel === lowestUserLevel?.id;
  const crumbs: ReactNode[] = [];
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const showCodePrefix = (level: UserLevel) => {
    if (isSmallScreen) return false;
    return level?.id === headUserLevel?.id || level?.id === subUserLevel?.id;
  };

  const createCrumbsFromContext = (item: any): any => {
    if (!item) return null;
    if (item?.level?.id < userLevel) return null;
    const levelName = item?.level?.name;
    const mainLabel = isSmallScreen ? item?.code : item?.name;
    if (levelName) {
      crumbs.push(
        <Tooltip title={isSmallScreen ? item?.name : item?.code || ""} arrow key={item?.id}>
          <Typography variant="subtitle2" color="textPrimary" className={classes.levelOverflow}>
            {Boolean(levelName) && (
              <>
                <strong>{levelName}</strong>:&nbsp;
              </>
            )}
            {showCodePrefix(item?.level) && `(${item?.code}) `}
            {mainLabel}
          </Typography>
        </Tooltip>
      );
    }
    return createCrumbsFromContext(item?.parentContext);
  };

  if (isLowestLevel) {
    const cliList = userAccounts?.map((acc) => acc?.code);
    crumbs.push(
      <Typography variant="subtitle2" color="textPrimary" className={classes.levelOverflow} key={"low-level-user"}>
        <strong>{lowestUserLevel?.name}</strong>:&nbsp;
        {cliList?.join(", ")}
      </Typography>
    );
  } else {
    createCrumbsFromContext(state.contextHierarchy);
  }

  return (
    <Breadcrumbs
      separator={<Typography className={classes.comma}>,</Typography>}
      className={classes.breadcrumbsWrap}
      data-cy="user-level-breadcrumbs"
    >
      {crumbs.reverse()}
    </Breadcrumbs>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    breadcrumbsWrap: {
      padding: "0 8px",
      marginLeft: "250px",
      "& > ol": {
        justifyContent: "flex-end",
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "0px",
        maxHeight: "56px",
      },
    },
    comma: {
      color: theme.palette.tertiary.main,
      marginLeft: "-8px",
    },
    levelOverflow: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  })
);

export default HierarchyBreadcrumbs;

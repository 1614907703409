import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useHistory, useLocation } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { permissionCodes } from "../../permission/hooks";
import { UICard } from "../../_app/components";
import { Alert, Button, Checkbox, Grid, Skeleton, Typography } from "@mui/material";
import { useHasFeature } from "../../feature/hooks";
import { SelectedParams } from "../../asset/screens/MobileManagement";
import { useFeedbackAlerts } from "../../_app/hooks";
import { useBarsUpdate, useSingleBar } from "../../bars/hooks";
import UIConfirm from "../../_app/components/UIConfirm";
import { genericError } from "../../_app/utils/text";
import UILoader from "../../_app/components/UILoader";
import { featureFlagsMap } from "../../feature/utils";

export const SimLock = () => {
  const classes = useStyles();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const history = useHistory();
  const location = useLocation<SelectedParams>();
  const asset = location.state?.asset;
  const [orderNew, setOrderNew] = useState<boolean>(false);
  const { data: stolenBar, isFetching, error } = useSingleBar("stolen", asset?.id || "");
  const [stolenConfirm, setStolenConfirm] = useState<boolean>(false);
  const [stolen, setStolen] = useState<boolean>(false);
  const canOrder = useHasFeature(featureFlagsMap.SIM_ORDER) && stolen;

  const { mutate: executeBarsUpdate, data: successMessage, error: updateError, isLoading: isUpdating } = useBarsUpdate();

  const showStolenLoader = isFetching || isUpdating;

  const switchStolenBar = () => {
    executeBarsUpdate({
      assetIds: [asset?.id],
      operations: [
        {
          name: stolenBar?.name,
          network: asset?.network,
          value: !stolen,
        },
      ],
    });
  };

  const tableRows = (items: { title: string; val: string | JSX.Element }[]) => {
    return items?.map(({ title, val }) => {
      return (
        <Grid container key={title} className={classes.row}>
          <Grid item sm={3}>
            <Typography variant="h4" data-cy="sim-lock" component="div">
              {title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography data-cy="sim-lock-data" component="div">
              {val ?? <Skeleton width={150} />}
            </Typography>
          </Grid>
        </Grid>
      );
    });
  };

  useEffect(() => {
    if (stolenBar) setStolen(Boolean(stolenBar?.value));
  }, [stolenBar]);

  useEffect(() => {
    if (successMessage) setStolen((oldStolen) => !oldStolen);
  }, [successMessage]);

  useEffect(() => {
    if (!asset || updateError) {
      setFeedbackAlertError("No mobile number selected");
      history.push({ pathname: "/mobile-management" });
    }
  }, [asset, updateError, history]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (error) {
      setFeedbackAlertError(error?.data?.message || genericError());
      timer = setTimeout(() => {
        history.goBack();
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [error, history]);

  return (
    <Main
      title="Lost / Stolen Phone"
      data-cy="sim-lock-page"
      featureFlag={featureFlagsMap.SIM_LOCK}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      isLoading={false}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      <div className={classes.ctr}>
        <UICard hasBorder padding="16px">
          {tableRows([
            { title: "CLI/Identifier", val: asset?.cli },
            { title: "Account", val: asset?.accountCode },
          ])}
        </UICard>
        <UICard isFetching={orderNew} fetchingText={"Redirecting to Order New SIM"} hasBorder padding="16px">
          <Alert severity="warning" className={classes.info}>
            Please note: this will only apply a bar to the SIM, the handset will not be affected.
          </Alert>
          <Grid container className={classes.row}>
            <Grid item>
              <Typography variant="h4" data-cy="sim-lock" component="div" align="center" className={classes.checkbox}>
                {showStolenLoader ? (
                  <UILoader size={20} />
                ) : (
                  <Checkbox
                    disabled={showStolenLoader}
                    checked={stolen}
                    onChange={() => setStolenConfirm(true)}
                    color="primary"
                  />
                )}
              </Typography>
            </Grid>
            <Grid item>
              <Typography component="div">Apply Stolen bar to SIM</Typography>
            </Grid>
          </Grid>
        </UICard>
        <div>
          <Button
            onClick={() => {
              setOrderNew(true);
              return setTimeout(() => {
                history.push({
                  pathname: "/mobile-management/sim-order",
                  state: {
                    ...location.state,
                    from: "Lost/Stolen Phone",
                  },
                });
              }, 2000);
            }}
            disabled={!canOrder}
            variant="contained"
            color="primary"
          >
            Order New SIM
          </Button>
        </div>
      </div>
      <UIConfirm
        title={`Are you sure you want to ${stolen ? "remove" : "apply"} the stolen bar?`}
        setOpen={setStolenConfirm}
        open={stolenConfirm}
        onConfirm={switchStolenBar}
      >
        Changes may take a few hours to take effect.
      </UIConfirm>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      flexDirection: "column",
      gap: "15px 30px",
      height: "100%",
    },
    row: {
      margin: "10px 0",
      gap: theme.spacing(2),
      alignItems: "center",
      minWidth: 550,
    },
    checkbox: {
      height: "42px",
      width: "42px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    info: {
      alignItems: "center",
    },
  })
);

export default SimLock;

const actions = {
  SET_ERROR: "SET_ERROR",
  SET_SUCCESS: "SET_SUCCESS",
  SET_ALERT: "SET_ALERT",
  SET_CONTEXT_HIERARCHY: "SET_CONTEXT_HIERARCHY",
  SET_BRAND: "SET_BRAND",
  SET_FEATURES: "SET_FEATURES",
} as const;

export default actions;

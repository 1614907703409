import React from "react";
import Main from "../../_app/layouts/Main";
import { createStyles, makeStyles, UIAlert, UITheme } from "../../_app/components";
import ManageCostCentresTabBar from "../components/ManageCostCentresTabBar";
import NewCostCentre from "../components/NewCostCentre";
import CostCentreList from "../components/CostCentreList";
import { featureFlagsMap } from "../../feature/utils";
import { useHasFeature } from "../../feature/hooks";
import { permissionCodes, useHasPermission } from "../../permission/hooks";

export const ManageCostCentres = () => {
  const classes = useStyles();
  const hasEditPerm = useHasPermission(permissionCodes.COST_CENTRE_MANAGE);
  const hasEditFeature = useHasFeature(featureFlagsMap.COST_CENTRE_MANAGEMENT);

  return (
    <Main
      title={
        <>
          Manage Cost Centres
          {hasEditFeature && hasEditPerm && <NewCostCentre />}
        </>
      }
      data-cy="manage-cost-centres-page"
      needSelectedAccount
      featureFlag={featureFlagsMap.COST_CENTRE_MANAGEMENT}
      accessPermission={permissionCodes.COST_CENTRE_MANAGE}
    >
      <ManageCostCentresTabBar selectedTab="manage-cost-centres">
        <UIAlert severity="info" className={classes.info}>
          Cost centres allow you to organise and group your charges. A report providing a breakdown of you charges for cost centre
          costing purposes can be downloaded from the Bill Explorer every month.
        </UIAlert>
        <div className={classes.ctr}>
          <CostCentreList />
        </div>
      </ManageCostCentresTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    ctr: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      gap: "20px",
    },
    top: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
    },
    info: {
      alignItems: "center",
      width: "fit-content",
      marginBottom: theme.spacing(3),
    },
  })
);

export default ManageCostCentres;

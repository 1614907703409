import React from "react";
import { Typography, Button, Grid, Card } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ContactCard from "./ContactCard";
import FeedbackIcon from "@mui/icons-material/Feedback";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";
import { useBrand, useCustomerServices } from "../../../brand/hooks";
import { useHasFeature } from "../../../feature/hooks";
import UILoader from "../../../_app/components/UILoader";
import { featureFlagsMap } from "../../../feature/utils";
import { Brand } from "../../../brand/types";

export default function CustomerService() {
  const defaultEmail = "Unknown";
  const defaultPhone = "Unknown";
  const classes = useStyles();
  const { data: services, isFetching: servicesFetching } = useCustomerServices();
  const brand: Brand | null = useBrand();
  const hasFeature = useHasFeature(featureFlagsMap.CUSTOMER_SUPPORT);
  if (!hasFeature) return null;
  const faqUrl = brand?.faqUrl;
  return (
    <Grid item xs={12} data-cy="contact-support-card">
      <Card elevation={1} className={classes.card}>
        <Typography variant="h3" gutterBottom>
          Get in touch and let us know how we can help
        </Typography>
        {servicesFetching ? (
          <UILoader />
        ) : (
          <Grid container direction="row" spacing={2}>
            <ContactCard icon={<FeedbackIcon />}>
              <Button variant="outlined" className={classes.button}>
                <a
                  href={faqUrl || ""}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.buttonLink}
                  data-cy="contact-support-faq-button"
                >
                  <Typography variant="subtitle2">Take a look at our FAQs</Typography>
                </a>
              </Button>
            </ContactCard>
            <ContactCard icon={<CallIcon />}>
              <Typography variant="h4" className={classes.text}>
                CALL
              </Typography>
              <Typography variant="h3" className={classes.text}>
                {Boolean(services?.phone) ? services?.phone : defaultPhone}
              </Typography>
              {Boolean(services?.phone) && <Typography variant="body2">(Monday to Friday – 8:30am to 5:30pm)</Typography>}
            </ContactCard>
            <ContactCard icon={<EmailIcon />}>
              <Typography variant="h4" className={classes.text}>
                EMAIL
              </Typography>
              <div className={classes.emailWrap}>
                <Typography variant="h3" className={classes.emailText}>
                  {Boolean(services?.email) ? services?.email : defaultEmail}
                </Typography>
              </div>
            </ContactCard>
          </Grid>
        )}
      </Card>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(4),
      textAlign: "center",
    },
    button: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    },
    buttonLink: {
      color: theme.palette.tertiary.main,
      textDecoration: "none",
    },
    text: {
      paddingBottom: theme.spacing(1),
    },
    emailWrap: {
      width: "100%",
    },
    emailText: {
      wordWrap: "break-word",
    },
  })
);

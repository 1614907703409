import React from "react";
import Tabbed from "../../_app/layouts/Tabbed";

interface Props {
  selectedTab: string;
  children: any;
}

function ManageBillDashboardTabs({ selectedTab, children }: Props) {
  return (
    <Tabbed
      selectedTab={selectedTab}
      children={children}
      tabs={[
        { value: "bill-dashboard-monthly", label: "Monthly" },
        { value: "bill-dashboard-trends", label: "Trends" },
      ]}
    />
  );
}

export default ManageBillDashboardTabs;

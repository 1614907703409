import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Typography } from "@mui/material";
import { useValidation } from "../../../form/hooks";
import UIConfirm from "../../../_app/components/UIConfirm";
import { useHasFeature } from "../../../feature/hooks";
import StatusChip from "../../../_app/components/StatusChip";
import UILoader from "../../../_app/components/UILoader";
import { featureFlagsMap } from "../../../feature/utils";
import { usePaperBillCharge } from "../../hooks";

export interface Props {
  handleUpdate: (data: any) => void;
  setOnlyEmail: (value: boolean) => void;
  billSettings: any;
  userLevelName: string;
  isLoading?: boolean;
}

export default function BillingType({
  handleUpdate = () => null,
  setOnlyEmail = () => null,
  billSettings,
  userLevelName = "Account",
  isLoading = false,
}: Props) {
  const classes = useStyles();

  const hasTypeFeature = useHasFeature(featureFlagsMap.BILL_TYPE);
  const hasTypeUpdateFeature = useHasFeature(featureFlagsMap.BILL_TYPE_UPDATE);
  const canSwitchType = hasTypeFeature && hasTypeUpdateFeature;
  const isPending = billSettings?.status === "PENDING";

  const hasEmailFeature = useHasFeature(featureFlagsMap.BILL_NOTIFICATION_EMAIL);
  const canUpdateEmail = useHasFeature(featureFlagsMap.BILL_NOTIFICATION_EMAIL_UPDATE);

  const [warning, setWarning] = useState(false);
  const paperCharge = usePaperBillCharge();

  const ONLINE_TYPE = "ONLINE";
  const PAPER_TYPE = "PAPER";

  const [billingType, setBillingType] = useState(billSettings?.billType);
  const [requestUpdateBody, setRequestUpdateBody] = useState(billSettings);

  useEffect(() => {
    setBillingType(billSettings?.billType);
    setRequestUpdateBody(billSettings);
  }, [billSettings]);

  const validationConfig = {
    billEmail: { required: true },
  };

  const { validationErrors, validateSingle, validateAll } = useValidation(validationConfig, requestUpdateBody);

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    const val = event.target.value;
    setRequestUpdateBody({ ...requestUpdateBody, [key]: val });
    validateSingle(key, val);
  };

  const onSubmit = () => {
    const errors = validateAll();
    if (errors.length <= 0) {
      handleUpdate(requestUpdateBody);
    }
  };

  const switchBillingType = () => {
    setOnlyEmail(false);
    onSubmit();
  };

  const updateEmail = () => {
    setOnlyEmail(true);
    onSubmit();
  };

  const isSameAsOrigin = (): boolean => {
    return billSettings?.billEmail === requestUpdateBody?.billEmail;
  };

  const typeOfNextBilling = (billType: string) => {
    if (billType === ONLINE_TYPE) {
      return "paper billing";
    } else {
      return "online billing";
    }
  };

  const typeOfCurrentBilling = (billType: string) => {
    if (billType === ONLINE_TYPE) {
      return "online billing";
    } else {
      return "paper billing";
    }
  };

  const changeBillingType = (cancel: boolean) => {
    setWarning(!cancel);
    if (cancel) {
      setRequestUpdateBody({ ...requestUpdateBody, billType: billingType });
    } else if (billingType === PAPER_TYPE) {
      setRequestUpdateBody({ ...requestUpdateBody, billType: ONLINE_TYPE });
    } else {
      setRequestUpdateBody({ ...requestUpdateBody, billType: PAPER_TYPE });
    }
  };

  const billingTypeMessage = (currentBillingType: string) => {
    if (currentBillingType === ONLINE_TYPE) {
      return `Please note, a charge of ${paperCharge.parsed} per month will applied to your bill for this service`;
    } else {
      return (
        <>
          <Typography>Do you wish to activate online billing?</Typography>
          <TextField
            className={classes.notificationField}
            defaultValue={billSettings?.billEmail}
            name="billEmail"
            label="Email for Notifications"
            type="text"
            variant="outlined"
            size="small"
            fullWidth
            error={Boolean(validationErrors.billEmail)}
            helperText={validationErrors.billEmail}
            onChange={handleInput}
          />
        </>
      );
    }
  };

  return (
    <Grid item xs={12} md={6}>
      <Grid container className={classes.cardContainer}>
        {hasTypeFeature && (
          <Grid item xs={12} data-cy="billing-type-settings">
            <Card elevation={1} className={classes.card}>
              <Typography variant="h2" gutterBottom>
                My Billing Type Setting
              </Typography>
              <Typography className={classes.text}>
                Your {userLevelName?.toLowerCase()} is set to
                <b> {typeOfCurrentBilling(billingType)} </b>
              </Typography>
              {isLoading && (
                <div className={classes.loading}>
                  <UILoader />
                </div>
              )}
              {canSwitchType && !isLoading && (
                <div className={classes.actions}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => changeBillingType(false)}
                    data-cy="switch-billing-type-button"
                    disabled={isPending}
                  >
                    Switch to {typeOfNextBilling(billingType)}
                  </Button>
                  <UIConfirm
                    title={`Activate ${typeOfNextBilling(billingType)}`}
                    open={warning}
                    setOpen={() => changeBillingType(true)}
                    onConfirm={switchBillingType}
                    disableOnSubmit={typeOfNextBilling(billingType) === "online billing" && Boolean(validationErrors.billEmail)}
                    onSubmitTitle="Activate"
                  >
                    {billingTypeMessage(billingType)}
                  </UIConfirm>
                  {isPending && <StatusChip />}
                </div>
              )}
            </Card>
          </Grid>
        )}
        {hasEmailFeature && billingType === ONLINE_TYPE && (
          <Grid item xs={12} data-cy="account-billing-type">
            <Card elevation={1} className={classes.card}>
              <Typography variant="h2" gutterBottom>
                Billing Notification Email Address
              </Typography>
              <TextField
                className={classes.textField}
                name="billEmail"
                type="text"
                defaultValue={billSettings?.billEmail}
                variant="outlined"
                size="small"
                fullWidth
                error={Boolean(validationErrors.postCode)}
                helperText={validationErrors.postCode}
                onChange={handleInput}
                InputProps={{ readOnly: !canUpdateEmail }}
              />
              {canUpdateEmail && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={updateEmail}
                  data-cy="update-billing-type-button"
                  disabled={isSameAsOrigin()}
                  className={classes.button}
                >
                  Update
                </Button>
              )}
            </Card>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: "100%",
      padding: theme.spacing(4),
    },
    textField: {
      marginBottom: theme.spacing(4),
    },
    notificationField: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    text: {
      marginBottom: theme.spacing(4),
    },
    actions: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexWrap: "wrap",
      gap: theme.spacing(1.5),
    },
    button: {
      marginBottom: theme.spacing(-6.5),
    },
    cardContainer: {
      height: "100%",
      gridGap: theme.spacing(2),
    },
    loading: {
      textAlign: "center",
    },
  })
);

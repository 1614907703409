import * as React from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import List from "@mui/material/List";
import { Typography } from "@mui/material";

export default function FilterBox({
  item,
  resetSelection,
  addSelection,
  selection,
  setSelection,
  close,
  showReset,
  disableApply,
  children,
  ...props
}: any) {
  const classes = useStyles();

  return (
    <div {...props}>
      <List disablePadding>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.filterActions}>
          <div className={classes.titleCtr}>
            <Typography variant="caption" className={classes.filterTitle}>
              Filter by {item.name}
            </Typography>
            {item.MAX_COUNT && (
              <Typography component="p" variant="caption">
                You can select up to {item.MAX_COUNT} item/s
              </Typography>
            )}
          </div>
          <Button
            data-cy="reset-filter-button"
            size="small"
            onClick={() => resetSelection()}
            className={showReset ? classes.show : classes.hidden}
          >
            Reset
          </Button>
        </Grid>
        {children}
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" className={classes.filterAdd}>
          <Button
            data-cy="add-filter-button"
            variant="contained"
            size="small"
            color="primary"
            onClick={addSelection}
            disabled={disableApply ? disableApply : selection.length < 1}
          >
            Apply
          </Button>
          <Button data-cy="close-filter-button" size="small" onClick={() => close()}>
            Cancel
          </Button>
        </Grid>
      </List>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filterActions: {
      padding: theme.spacing(2),
    },
    filterAdd: {
      padding: theme.spacing(2),
    },
    filterTitle: {
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    titleCtr: {
      display: "flex",
      flexDirection: "column",
    },
    hidden: {
      display: "none",
    },
    show: {
      display: "initial",
    },
  })
);

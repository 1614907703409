import React, { useEffect, useState, useMemo, memo } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import TextField from "@mui/material/TextField";
import { debounce } from "@mui/material";
import UILoader from "../../_app/components/UILoader";

export interface Option {
  id?: number | string;
  value?: boolean | string | number | string[];
  label?: string | any;
  disabled?: boolean;
}

interface Props {
  data: Option[];
  ctrClass?: any;
  label?: string;
  name?: string;
  onChange?: any;
  value?: Option["value"];
  defaultValue?: Option["value"];
  multiple?: boolean;
  helperText?: string;
  error?: boolean;
  hasSearch?: boolean;
  defaultSearch?: string;
  searchList?: Option[];
  searchDebounce?: number;
  multiline?: boolean;
  hasNextPage?: boolean;
  infiniteScroll?: boolean;
  nextPage?: any;
  searchOn?: any;
  resetOnClose?: boolean;
  [key: string]: any;
}

const SelectDropdown = memo(
  ({
    ctrClass,
    label,
    name,
    data,
    onChange = () => null,
    value,
    defaultValue,
    multiple,
    error,
    helperText,
    multiline = false,
    hasSearch = false,
    infiniteScroll = false,
    hasNextPage,
    nextPage = () => null,
    searchOn = () => null,
    defaultSearch = "",
    searchList,
    searchDebounce = 300,
    resetOnClose = true,
    isFetching,
    ...props
  }: Props) => {
    const classes = useStyles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const searchFunc = useMemo(() => debounce(searchOn, searchDebounce), []);
    const fetchNextPage = useMemo(() => debounce(nextPage, 50), [nextPage]);
    const [search, setSearch] = useState(defaultSearch);
    const [options, setOptions] = React.useState<any>(data || []);

    useEffect(() => {
      if (Boolean(search) && searchList && searchList?.length > 0) {
        setOptions(searchList);
      } else {
        setOptions(data);
      }
    }, [data, searchList, search]);

    useEffect(() => {
      searchFunc(search);
    }, [search, searchFunc]);

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setSearch(val);
    };

    const onScroll = (e: any) => {
      const buffer = 15;
      const scrolled = e.target.scrollHeight - e.target.scrollTop;
      const total = e.target.clientHeight + buffer;
      const hitBottom = scrolled <= total;
      if (hitBottom && hasNextPage) {
        fetchNextPage();
      }
    };

    const renderOption = (item: any) => {
      return (
        <MenuItem value={item?.value} key={item?.id || item?.value} className={classes.listValue} disabled={item?.disabled}>
          {item?.label || item.value}
        </MenuItem>
      );
    };
    return (
      <FormControl
        variant="outlined"
        fullWidth
        size="small"
        className={`${multiline && classes.multiline} ${ctrClass}`}
        error={error}
        data-cy="select-dropdown"
        {...props}
      >
        <InputLabel id={name}>{label}</InputLabel>
        <Select
          labelId={name}
          id={name}
          defaultValue={defaultValue || ""}
          value={value}
          onChange={onChange}
          onClose={() => {
            if (resetOnClose) setSearch("");
          }}
          label={label}
          name={name}
          multiple={multiple && data?.length > 1}
          MenuProps={{
            anchorOrigin: {
              vertical: "center",
              horizontal: "center",
            },
            disablePortal: false,
            className: classes.body,
            autoFocus: false,
            MenuListProps: {
              onScroll: infiniteScroll ? onScroll : undefined,
              onFocus: (e: React.FocusEvent<HTMLUListElement, Element>) => {
                if (!hasNextPage) return;
                const el = e.currentTarget;
                if (el?.nodeName === "UL") {
                  el.style.height = el.clientHeight - 2 + "px";
                }
              },
            },
          }}
        >
          {hasSearch && (
            <TextField
              onKeyDown={(e) => e.stopPropagation()}
              placeholder="Search..."
              name="search"
              data-cy="search_component"
              size="small"
              variant="outlined"
              autoFocus
              type="text"
              fullWidth
              defaultValue={search}
              onChange={handleSearch}
              className={classes.searchItem}
            />
          )}
          {options?.map((item: any) => {
            return renderOption(item);
          })}
          {isFetching && <UILoader linear />}
        </Select>
        {Boolean(helperText) && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    multiline: {
      "& .MuiSelect-selectMenu": {
        height: "1.1876em",
      },
    },
    listValue: {
      "&.MuiListItem-root.Mui-selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    body: {
      "& > .MuiPaper-root ul": {
        maxHeight: 500,
        overflowY: "auto",
        paddingTop: "0px",
        paddingBottom: "0px",
      },
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignoptions: "center",
      height: "100px",
      flex: 1,
    },
    searchItem: {
      position: "sticky",
      top: "0px",
      backgroundColor: "white!important",
      zIndex: 100,
      padding: theme.spacing(1),
      "&:hover": {
        backgroundColor: "white!important",
      },
    },
  })
);

export default SelectDropdown;

import React from "react";
import { Button } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import { filtersIdMap } from "../../filter/utils";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useQueryClient } from "react-query";

interface Props {
  price: string;
  link: string[];
  tableType?: string;
  setTableType?: Function;
  setApplied: Function;
}

export const TotalToButton = ({ price, link, tableType, setTableType, setApplied }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();
  const queryParams = new URLSearchParams(location.search);
  if (!price) {
    return <></>;
  }

  const resetFilter = () => {
    const params: string[] = [];
    queryParams.forEach((value, key) => {
      // TODO: preffix check?
      params.push(key);
    });
    params.forEach((key) => {
      if (key !== filtersIdMap.BILL_PERIOD) {
        queryParams.delete(key);
      }
    });

    switch (tableType) {
      case "CLI":
        queryParams.append(filtersIdMap.CLI, link?.[0]);
        break;
      case "ACCOUNT":
        queryParams.append(filtersIdMap.ACCOUNT, link?.[0]);
        break;
      case "COST_CENTRE":
        queryParams.append(filtersIdMap.COST_CENTRE, link?.[1]);
        break;
    }
    history.replace({
      search: queryParams.toString(),
    });
  };

  const applyTotalFilters = async () => {
    resetFilter();
    if (setTableType) setTableType("ALL");
    setApplied(true);
    queryClient?.resetQueries("usageAnalysis");
  };

  return (
    <Button
      data-cy="summary-total-button"
      size="small"
      variant="text"
      color="primary"
      className={classes.button}
      onClick={applyTotalFilters}
    >
      {price + ""}
    </Button>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginRight: "-12px",
      marginLeft: "-12px",
    },
  })
);

import React, { useEffect, ReactNode } from "react";
import actions from "../store/Actions";
import LoadingOverlay from "../components/LoadingOverlay";
import { UserLevel } from "../../user-level/types";
import { useContextSetup } from "../../context/hooks";
import { getCachedContextHierarchy } from "../../context/utils";
import { Account } from "../../account/types";

export interface ContextHierarchy {
  code: string;
  id: string;
  lastParentId: string;
  level: UserLevel;
  name: string;
  parentContext: ContextHierarchy;
  parentFilterLevel: number;
  size: number | null;
  userId: string;
  userAccessibleAccounts: Account[];
}

function HierarchyContextProvider({ dispatch, children }: { dispatch: any; children: ReactNode }) {
  const isReady = useContextSetup();

  // Sync store with cached context
  useEffect(() => {
    const storedContextHierarchy = getCachedContextHierarchy();
    if (storedContextHierarchy?.lastParentId) {
      dispatch({
        type: actions.SET_CONTEXT_HIERARCHY,
        payload: storedContextHierarchy,
        skipStorage: true,
      });
    }
  }, [dispatch]);

  if (isReady) return <>{children}</>;
  return <LoadingOverlay />;
}

export default HierarchyContextProvider;

import React, { useEffect, useState } from "react";
import Main from "../../_app/layouts/Main";
import ManageBillDashboardTabs from "../components/ManageBillDashboardTabs";
import { Theme, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { featureFlagsMap } from "../../feature/utils";
import FiltersCard from "../../filter/components/FiltersCard";
import { useAllFilters } from "../../filter/hooks";
import { useQueryParam, useQueryParams } from "../../_app/hooks";
import Tabbed from "../../_app/layouts/Tabbed";
import CostByUsageType from "../components/graphs/CostByUsageType";
import { useCallback } from "react";
import { Query } from "../../filter/types";
import CostByProduct from "../components/graphs/CostByProduct";
import { useHistory } from "react-router-dom";
import { useBilledPeriods } from "../../billing/hooks";
import ServiceCostByType from "../components/graphs/ServiceCostByType";
import ServiceCostByProduct from "../components/graphs/ServiceCostByProduct";

export const PERIOD_FILTER = "80";
export const BillDashTrends = () => {
  const selectedTab = "bill-dashboard-trends";
  const classes = useStyles();
  const history = useHistory();
  const [type, setType] = useState("usage");
  const queryParams = useQueryParams();
  const period = useQueryParam(PERIOD_FILTER);

  const { data: periodList } = useBilledPeriods();

  const getParams = useCallback(() => {
    const filters: Query[] = [];
    queryParams.forEach((value, key) => {
      const values = value.split(",");
      values.forEach((query) => {
        if (key === PERIOD_FILTER) {
          const periods = query.split(" - ");
          filters.push({
            id: key,
            value: periods[0],
          });
          filters.push({
            id: key,
            value: periods[periods.length - 1],
          });
        } else {
          filters.push({
            id: key,
            value: query,
          });
        }
      });
    });
    return filters;
  }, [queryParams]);

  useEffect(() => {
    if (!period) {
      const endPeriod = periodList?.[0];
      const startPeriod = periodList?.[Math.min(6, periodList?.length - 1)];
      if (startPeriod && endPeriod) {
        queryParams.append(PERIOD_FILTER, startPeriod + " - " + endPeriod);
        history.replace({ search: queryParams.toString() });
      }
    }
  }, [periodList, period, queryParams, history]);

  return (
    <Main
      title="Billing Summary"
      data-cy="bill-dashboard-trends-page"
      featureFlag={featureFlagsMap.REPORT_GRAPHS}
      needSelectedAccount
    >
      <ManageBillDashboardTabs selectedTab={selectedTab}>
        <Grid container direction="row" className={classes.ctr}>
          <Grid item xs={12}>
            <FiltersCard className={classes.filtersCard} hasReset={false} fetchHook={useAllFilters} usage="GRAPHS_TRENDS" />
          </Grid>
          <Grid item xs={12}>
            <Tabbed
              selectedTab={type}
              handleTabChange={(_, val) => setType(val)}
              tabs={[
                { value: "usage", label: "Usage" },
                { value: "services", label: "Services" },
              ]}
            />
          </Grid>
          <div className={classes.widgetWrap}>
            <Grid container direction="row" spacing={3}>
              {(() => {
                switch (type) {
                  case "usage":
                    return (
                      <>
                        <CostByProduct queries={getParams()} />
                        <CostByUsageType queries={getParams()} />
                      </>
                    );
                  case "services":
                    return (
                      <>
                        <ServiceCostByProduct queries={getParams()} />
                        <ServiceCostByType queries={getParams()} />
                      </>
                    );
                  default:
                    break;
                }
              })()}
            </Grid>
          </div>
        </Grid>
      </ManageBillDashboardTabs>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {},
    filtersCard: {},
    widgetWrap: {
      borderRadius: 0,
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
      width: "100%",
      padding: "22px 5px",
      minHeight: "50vh",
    },
  })
);

export default BillDashTrends;

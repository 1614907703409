import React, { useEffect, useRef } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Card, Grid, Typography, Chip } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import TimelineDot from "@mui/lab/TimelineDot";
import { formatDate } from "../../_app/utils/format";
import { Skeleton } from "@mui/material";
import { useTicketComments } from "../hooks";
import UILoader from "../../_app/components/UILoader";
import UIHtml from "../../_app/components/UIHtml";

interface Props {
  ticketId: string;
  scrollToTop?: number;
}

export const CommentsList = ({ ticketId, scrollToTop }: Props) => {
  const classes = useStyles();
  const ref = useRef<null | HTMLDivElement>(null);
  const { data: commentsResp, fetchNextPage, hasNextPage, isFetching } = useTicketComments(ticketId);
  const comments = commentsResp?.pages.reduce((acc: any, page: any) => [...acc, ...page.list], []);
  useEffect(() => {
    if (scrollToTop) performScrollToTop();
  }, [scrollToTop]);

  const performScrollToTop = () => ref?.current?.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <Card
      ref={ref}
      elevation={1}
      className={classes.ctr}
      data-cy="ticket-notes-card"
      onScroll={(e: React.UIEvent<HTMLDivElement>) => {
        const target = e.target as Element;
        const buffer = 15;
        const scrolled = target.scrollHeight - target.scrollTop;
        const total = target.clientHeight + buffer;
        const hitBottom = scrolled <= total;
        if (hitBottom && hasNextPage) {
          fetchNextPage();
        }
      }}
    >
      {!comments?.length && !isFetching && (
        <div className={classes.emptyCtr}>
          <Typography variant="body1">No comments</Typography>
        </div>
      )}
      {comments?.map((_comment) => (
        <Card key={_comment.id} elevation={1} className={classes.commentItem}>
          <Grid container item alignItems="center" className={classes.grid}>
            <TimelineDot color="primary" />
            <Typography component="span" variant="subtitle2" className={classes.marginLeft}>
              {formatDate(_comment.created, "dd/MM/yyyy HH:mm:ss") || <Skeleton width={100} />}
            </Typography>
          </Grid>
          <Grid container item className={classes.grid}>
            <Typography component="span" variant="h3" className={classes.createdBy}>
              {_comment.createdBy || <Skeleton width={200} />}
            </Typography>
            {Boolean(_comment.internal) && (
              <Chip
                variant="outlined"
                color="primary"
                label="Daisy Customer Services"
                size="small"
                icon={<InfoIcon />}
                className={classes.marginLeft}
              />
            )}
          </Grid>
          <Grid item className={classes.grid}>
            <UIHtml html={_comment.comment} />
          </Grid>
        </Card>
      ))}
      {isFetching && (
        <div className={classes.emptyCtr}>
          <UILoader />
        </div>
      )}
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    emptyCtr: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    ctr: {
      maxHeight: "calc(100vh - 260px)",
      padding: theme.spacing(2),
      overflow: "auto",
    },
    marginLeft: {
      marginLeft: theme.spacing(2),
    },
    grid: {
      paddingBottom: theme.spacing(2),
    },
    createdBy: {
      fontWeight: 700,
    },
    commentItem: {
      borderRadius: "5px",
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
      borderLeft: "3px solid " + theme.palette.primary.main,
    },
  })
);

export default CommentsList;

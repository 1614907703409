import React from "react";
import ReactDOM from "react-dom";
import { AuthConfig } from "./modules/auth/types";
import { UserLevel } from "./modules/user-level/types";
import App from "./modules/_app/App";
import config from "./modules/_app/config";

declare global {
  interface Window {
    Cypress: any;
    HubSpotConversations: any;
    hsConversationsOnReady: any;
    authConfig: AuthConfig;
    userLevels: UserLevel[];
  }
}

if (config.configSentry?.enabled && config.configSentry?.dsn) {
  require("./modules/_app/lib/sentry");
}

if (config?.configGoogleTagManager?.gtmId) {
  require("./modules/_app/lib/googletagmanager");
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

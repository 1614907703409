import React, { useState } from "react";
import DetailItem from "../DetailItem";
import { useWdrStatusForAsset } from "../../../../data-roaming/hooks";
import AlertIcon from "@mui/icons-material/ErrorOutline";
import { UILoader } from "../../../../_app/components";
import { Box } from "../../../../_app/components/UIBox";
import { Typography } from "../../../../_app/components/UITypography";
import makeStyles from "@mui/styles/makeStyles";
import { Chip } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import WorldwideDataCapInfo from "./WorldwideDataCapInfo";
import WorldwideDataCapToggle from "./WorldwideDataCapToggle";
import { WdrStatus } from "../../../../data-roaming/types";

interface Props {
  assetId: string;
}

const WorldwideDataCapDetail = ({ assetId }: Props) => {
  const classes = useStyles();
  // Used store because capped setting is updated sooner than pendingChange
  const [capped, setCapped] = useState<boolean | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const { data, isLoading, isFetching } = useWdrStatusForAsset(assetId, {
    // Polling interval is set to 1 second if there is a pending change so we can fetch the latest status automatically after the change
    refetchInterval: (data: WdrStatus) => {
      if (data?.pendingChange) {
        return 1000;
      }
      return false;
    },
    onSuccess: (data: WdrStatus) => {
      if (data?.pendingChange === false || initialLoad) {
        setCapped(data?.wdrValue);
        setInitialLoad(false);
      }
    },
  });

  const pendingChange = data?.pendingChange || isFetching;
  const showToggle = !!data && capped !== null && !isLoading;

  const getValue = () => {
    // Show loader only for initial fetching
    if (isLoading) {
      return <UILoader data-cy={"wdr-loader"} size={25} />;
    }

    if (!data || capped === null) {
      return (
        <Box display="flex" alignItems="center">
          <AlertIcon className={classes.alertIcon} />
          <Typography variant="subtitle2" className={classes.alertText}>
            No information available
          </Typography>
        </Box>
      );
    }

    return (
      <Chip
        icon={capped ? <WarningIcon /> : <CheckCircleIcon />}
        label={capped ? "Capped" : "Uncapped"}
        color={capped ? "warning" : "success"}
        variant="outlined"
      />
    );
  };

  const title = (
    <Box>
      Worldwide Data Cap
      <WorldwideDataCapInfo />
    </Box>
  );

  return (
    <DetailItem
      title={title}
      value={getValue()}
      action={showToggle ? <WorldwideDataCapToggle pendingChange={pendingChange} capped={capped} assetId={assetId} /> : undefined}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    alertText: {
      color: "red",
    },
    alertIcon: {
      color: "red",
      marginRight: "8px",
    },
  }),
);

export default WorldwideDataCapDetail;

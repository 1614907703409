import React, { useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Link, Typography } from "@mui/material";
import EditPopup from "./EditPopup";
import UIHtml from "../UIHtml";

export interface UIAttrValueProps {
  title?: string;
  titleProps?: any;
  value?: string;
  valueProps?: any;
  horizontal?: boolean;
  editable?: boolean;
  isHtml?: boolean;
  isUpdating?: boolean;
  onSave?: Function;
  type?: string;
}

const UIAttrValue = ({
  type = "TEXT",
  title,
  value,
  titleProps = {},
  valueProps = {},
  horizontal = false,
  editable = false,
  isHtml = false,
  isUpdating = false,
  onSave,
  ...props
}: UIAttrValueProps) => {
  const classes = useStyles();
  const [editPopupEl, setEditPopupEl] = useState<any>(null);
  return (
    <div className={horizontal ? classes.wrapHorizontal : classes.wrap} {...props}>
      <Typography variant="subtitle2" data-cy="attr-key" className={classes.title} {...titleProps}>
        {title}
      </Typography>
      <Typography className={classes.value} component="span" data-cy="attr-val" {...valueProps}>
        {isHtml ? <UIHtml html={value} /> : value}
      </Typography>
      {editable && !horizontal && (
        <>
          <Link
            underline="none"
            className={classes.link}
            onClick={(ev: React.MouseEvent<HTMLAnchorElement>) => setEditPopupEl(ev?.currentTarget)}
          >
            {value ? "Edit" : "Add"}
          </Link>
          <EditPopup
            anchorEl={editPopupEl}
            setAnchorEl={setEditPopupEl}
            title={(value ? "Edit" : "Add") + " " + title}
            value={value}
            type={type}
            onSave={onSave}
            isUpdating={isUpdating}
          />
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
    },
    wrapHorizontal: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "baseline",
      width: "100%",
      padding: theme.spacing(0.5),
    },
    title: {
      color: theme.palette.grey[300],
      whiteSpace: "nowrap",
    },
    value: {},
    link: {
      cursor: "pointer",
    },
  })
);

export default UIAttrValue;

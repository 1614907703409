import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Public from "../../_app/layouts/Public";
import { useJWTAuth } from "../hooks";
import { Typography } from "@mui/material";
import UILoader from "../../_app/components/UILoader";

export const JWTLogin = () => {
  const classes = useStyles();
  const { isLoading } = useJWTAuth();
  return (
    <Public className={classes.ctr}>
      {isLoading && <UILoader className={classes.loader} />}
      <Typography>Logging you in</Typography>
    </Public>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      height: "calc(100vh)",
    },
    loader: {
      marginBottom: theme.spacing(3),
    },
  })
);

export default JWTLogin;

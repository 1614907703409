import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert } from "@mui/material";
import { Button, Grid, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Public from "../../_app/layouts/Public";
import { useHubUserExist } from "../../user/hooks";

const NoUser = () => {
  const classes = useStyles();
  const history = useHistory();
  const [timer, setTimer] = useState<number>(20);
  const { data: doesHubUserExist, isFetchedAfterMount } = useHubUserExist();

  const handleLogout = useCallback(() => {
    history.push("/logout");
  }, [history]);

  useEffect(() => {
    if (isFetchedAfterMount && doesHubUserExist) {
      history.push("/");
    }
  }, [history, isFetchedAfterMount, doesHubUserExist]);

  useEffect(() => {
    let interval = setInterval(() => {
      setTimer((old: number) => Math.max(old - 1, 0));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!timer && !doesHubUserExist) {
      handleLogout();
    }
  }, [handleLogout, timer, doesHubUserExist]);

  return (
    <Public data-cy="disabled-page" title="No User Access" className={classes.ctr}>
      <Grid item className={classes.alert}>
        <Alert severity="info">You do not have access, please contact your administrator</Alert>
      </Grid>
      <Button variant="outlined" onClick={handleLogout} data-cy="disabled-page-logout-button" className={classes.logoutButton}>
        Logout
      </Button>
      <Typography className={classes.countdown}>Logging out in {timer} seconds</Typography>
    </Public>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      gap: theme.spacing(3),
    },
    alert: {
      maxWidth: 450,
    },
    logoutButton: {
      textAlign: "center",
    },
    countdown: {
      justifyContent: "center",
      alignItems: "center",
    },
  })
);

export default NoUser;

import React from "react";
import { useHistory } from "react-router-dom";
import { Typography, Button } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { withWidget } from "./components/Widget";
import { UICard } from "../../_app/components/UICard";
import { formatNumber } from "../../_app/utils/format";
import { useUnbilledTotals } from "../../billing/hooks";
import { useHasPermission, permissionCodes } from "../../permission/hooks";
import { filtersIdMap } from "../../filter/utils";
import { UNBILLED } from "../../billing/utils";

interface Props {
  align?: "inherit" | "left" | "center" | "right" | "justify";
}

const Charges = ({ align = "center" }: Props) => {
  const history = useHistory();
  const { data: total, isFetching } = useUnbilledTotals();
  const hasBillingManagePermission = useHasPermission(permissionCodes.BILLING_MANAGE);
  const canViewMore = hasBillingManagePermission;

  return (
    <UICard isFetching={isFetching}>
      <Typography variant="h6">Current Unbilled Usage Charges</Typography>
      <Typography variant="h1" align={align}>
        {formatNumber(total, 2, "£")}
      </Typography>
      {canViewMore && (
        <Button
          className="widget-button"
          color="primary"
          variant="contained"
          onClick={() => history.push(`/bills?${filtersIdMap?.BILL_PERIOD}=${UNBILLED}`)}
          endIcon={<ArrowForwardIcon />}
        >
          View
        </Button>
      )}
    </UICard>
  );
};

export default withWidget(Charges, "usage-charges");

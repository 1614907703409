import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Card, CardActions, CardContent, Grid } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Main from "../../_app/layouts/Main";
import { useAllParamValues, useStore } from "../../_app/hooks";
import { LoadMoreFooter } from "../../_app/components/Table/LoadMoreFooter";
import { UIAlert } from "../../_app/components";
import UILoader from "../../_app/components/UILoader";
import ManageBillingTabBar from "../components/ManageBillingTabBar";
import BillPeriodFilter from "../components/BillPeriodFilter";
import { BillPeriodSummary } from "../components/BillPeriodSummary";
import { UnbilledCostsSummary } from "../components/UnbilledCostsSummary";
import { permissionCodes } from "../../permission/hooks";
import { filtersIdMap } from "../../filter/utils";
import { useBillDetails, useBilledPeriods, useUnbilledCosts } from "../hooks";
import { useHeadUserLevel } from "../../user-level/hooks";
import { ALL, PERIOD_LENGTH, UNBILLED, formatPeriodToMonthYear } from "../utils";
import { BillDetail } from "../types";

export const BillExplorer = () => {
  const selectedTab = "bills";
  const history = useHistory();
  const classes = useStyles();
  const { state } = useStore();
  const contextId = state.contextHierarchy?.id;
  const contextLevelId = state.contextHierarchy?.level?.id;
  const headUserLevel = useHeadUserLevel();
  const isHeadAccount = headUserLevel?.id === contextLevelId;

  const {
    data: billedPeriods,
    isFetching: isFetchingPeriods,
    isSuccess: isSuccessPeriods,
    refetch: refetchPeriods,
  } = useBilledPeriods();

  const periodParams: string[] = useAllParamValues(filtersIdMap?.BILL_PERIOD);
  const [selectedPeriods, setSelectedPeriods] = useState(periodParams);

  useEffect(() => {
    const isDetail = location.pathname.includes("period-details");
    if (!isDetail && JSON.stringify(selectedPeriods) !== JSON.stringify(periodParams)) setSelectedPeriods(periodParams);
  }, [periodParams, selectedPeriods, location.pathname]);

  const [isUnbilledShown, setIsUnbilledShown] = useState<boolean>(
    Boolean(selectedPeriods[0] === UNBILLED || !selectedPeriods.length),
  );

  const isLastShown = selectedPeriods[selectedPeriods.length - 1] === billedPeriods?.[billedPeriods?.length - 1];

  const billDetails = useBillDetails(selectedPeriods?.filter((p: string) => p !== UNBILLED) ?? [], isHeadAccount, {
    enabled: Boolean(billedPeriods?.length && selectedPeriods?.length && selectedPeriods?.[0] !== UNBILLED),
  });

  const isLoading = billDetails.some((query) => query.isLoading);

  const {
    data: unbilledCosts,
    isFetching: isFetchingUnbilled,
    isSuccess: isSuccessUnbilled,
    isError: isErrorUnbilled,
  } = useUnbilledCosts({ enabled: isUnbilledShown });

  useEffect(() => {
    refetchPeriods();
  }, [refetchPeriods, contextId]);

  const showMore = () => {
    const lastPeriodIndex = billedPeriods?.findIndex((p) => p === selectedPeriods?.[selectedPeriods?.length - 1]) ?? 0;
    const nextPeriods = billedPeriods?.slice(lastPeriodIndex + 1, lastPeriodIndex + PERIOD_LENGTH + 1) ?? [];
    const periodParam = [...(selectedPeriods ?? []), ...nextPeriods].map((p) => `${filtersIdMap?.BILL_PERIOD}=${p}`).join("&");
    history.push(`/bills?${periodParam}`);
  };

  const showUnbilledPeriods = () => {
    if (isFetchingUnbilled) {
      return (
        <div className={classes.progress}>
          <UILoader />
        </div>
      );
    } else {
      if (isErrorUnbilled) {
        return (
          <UIAlert className={classes.error} severity="error">
            Fetching unbilled periods failed.
          </UIAlert>
        );
      } else if (!unbilledCosts?.length) {
        return (
          <UIAlert className={classes.error} severity="info">
            There are no unbilled costs to view, currently.
          </UIAlert>
        );
      } else
        return unbilledCosts?.map((unbilledCost, i) => {
          return (
            <UnbilledCostsSummary
              key={unbilledCost.billingPeriod}
              unbilledCosts={unbilledCost}
              isFetching={isFetchingUnbilled}
              isSuccess={isSuccessUnbilled}
              isHeadAccount={isHeadAccount}
            />
          );
        });
    }
  };

  const showBilledPeriods = () => {
    if (billedPeriods?.length === 0) {
      return <UIAlert severity="info">No billed periods to show.</UIAlert>;
    } else
      return (
        <>
          {billDetails.map((billDetail, i) => {
            if (billDetail.isError) {
              return (
                <UIAlert className={classes.error} severity="error">
                  {`Fetching ${formatPeriodToMonthYear(selectedPeriods[i])} bill details failed.`}
                </UIAlert>
              );
            }
            if (billDetail.isSuccess) {
              return (
                <BillPeriodSummary
                  key={selectedPeriods?.[i]}
                  period={selectedPeriods?.[i]}
                  billDetails={billDetail?.data as BillDetail[]}
                  isFetching={billDetail.isFetching}
                  isSuccess={billDetail.isSuccess}
                  isHeadAccount={isHeadAccount}
                />
              );
            }
            return null;
          })}
          {isLoading && (
            <div className={classes.progress}>
              <UILoader />
            </div>
          )}
          {!isLastShown && selectedPeriods.length > 1 ? (
            <CardActions className={classes.cardActions}>
              <LoadMoreFooter hasMore={!isLastShown} onChangePage={showMore} />
            </CardActions>
          ) : null}
        </>
      );
  };

  return (
    <Main
      title="Bill Explorer"
      data-cy="bill-explorer-page"
      accessPermission={permissionCodes.BILLING_MANAGE}
      needSelectedAccount={true}
    >
      <ManageBillingTabBar selectedTab={selectedTab}>
        <Grid container direction="row" justifyContent="center" alignItems="flex-start" spacing={2}>
          <Grid item xs={12} md={3}>
            <Grid container>
              <BillPeriodFilter
                billedPeriods={[ALL, ...(billedPeriods ?? [])]}
                shownPeriods={selectedPeriods}
                isUnbilledShown={isUnbilledShown}
                setIsUnbilledShown={setIsUnbilledShown}
                isFetching={isFetchingPeriods}
                isSuccess={isSuccessPeriods}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} md={9}>
            <Card raised className={classes.root} elevation={1}>
              <CardContent className={classes.cardContent}>
                {isUnbilledShown ? showUnbilledPeriods() : showBilledPeriods()}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </ManageBillingTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      height: "100%",
      color: theme.palette.text.primary,
    },
    cardContent: {
      height: "100%",
      padding: "40px",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
      "&:last-child": {
        paddingBottom: "20px",
      },
      [theme.breakpoints.down("md")]: {
        padding: "12px",
      },
    },
    cardActions: {
      margin: "auto",
    },
    progress: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    error: {
      width: "100%",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(5),
    },
  }),
);

export default BillExplorer;

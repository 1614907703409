import React, { ReactNode } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import FeatureFlagsProvider from "./FeatureFlagsProvider";
import ThemeProvider from "./theme/ThemeProvider";
import Store from "../store/Store";
import GlobalAlertsProvider from "./GlobalAlertsProvider";
import LiveChatProvider from "./LiveChatProvider";
import ScrollToTopProvider from "./ScrollToTopProvider";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 30,
      retry: window.Cypress ? false : 1,
      refetchOnWindowFocus: false,
    },
  },
});

function Providers({ children }: { children: ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <Store>
        <ThemeProvider>
          <FeatureFlagsProvider>
            <Router>
              <GlobalAlertsProvider />
              <ScrollToTopProvider />
              <LiveChatProvider />
              {children}
            </Router>
          </FeatureFlagsProvider>
        </ThemeProvider>
        <ReactQueryDevtools
          initialIsOpen={false}
          toggleButtonProps={{
            style: { width: 20, height: 30, opacity: 0.4 },
          }}
        />
      </Store>
    </QueryClientProvider>
  );
}

export default Providers;

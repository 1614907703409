import React from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import UIAttrValue, { UIAttrValueProps } from "../../_app/components/UIAttrValue";

const FieldRow = (props: UIAttrValueProps) => {
  const classes = useStyles();
  return (
    <UIAttrValue titleProps={{ className: classes.title }} valueProps={{ className: classes.value }} horizontal {...props} />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      fontWeight: 600,
      minWidth: 160,
    },
    value: {
      width: 720,
    },
  })
);

export default FieldRow;

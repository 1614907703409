import React from "react";
import { useHistory } from "react-router-dom";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Main from "../../_app/layouts/Main";
import EditRoleForm from "../components/EditRoleForm";
import { permissionCodes, usePermissions } from "../../permission/hooks";
import { useRoleCreate } from "../hooks";
import { Role } from "../types";

export const AddRole = () => {

  const history = useHistory();
  const classes = useStyles();
  const { data: permissions, isFetching } = usePermissions();
  const { mutate: executeRoleCreate, isLoading: isCreating } = useRoleCreate({
    onSuccess: () => {
      history.push("/roles");
    }
  });

  const onSubmit = (role: Role) => {
    executeRoleCreate(role);
  };

  return (
    <Main title="Add Role" data-cy="add-role-page" accessPermission={permissionCodes.USERS_MANAGE} isLoading={isFetching}>
      <Card elevation={1} className={classes.card}>
        <Grid container>
          <EditRoleForm
            role={{ permissions }}
            submitLabel={"Submit"}
            handleSubmit={onSubmit}
            isSubmitting={isCreating}
            handleCancel={() => history.goBack()}
          />
        </Grid>
      </Card>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(5),
      width: "100%",
    },
  })
);

export default AddRole;

import React, { ReactNode } from "react";
import { Breakpoint, Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ButtonProps } from "@mui/material/Button";
import UIButton from "./UIButton";

interface Action {
  label?: string;
  props?: ButtonProps;
}

interface Props {
  title?: JSX.Element | string;
  children: ReactNode;
  open: boolean;
  maxWidth?: Breakpoint;
  actions: Action[];
}

const UIDialog = ({ title, children, open, maxWidth, actions = [] }: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={open} fullWidth maxWidth={maxWidth ?? "xs"}>
      <div className={classes.dialog}>
        {Boolean(title) && <DialogTitle>{title}</DialogTitle>}
        <DialogContent>{children}</DialogContent>
        <DialogActions className={classes.buttons}>
          {actions.map((action: Action) => {
            return (
              <UIButton {...(action?.props || {})} key={action.label}>
                {action.label}
              </UIButton>
            );
          })}
        </DialogActions>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      margin: theme.spacing(2),
    },
    buttons: {
      marginTop: theme.spacing(4),
      alignItems: "inherit",
    },
  })
);

export default UIDialog;

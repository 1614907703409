import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useEffect } from "react";
import { Popper, PopperProps } from "@mui/material";
import UILoader from "../../_app/components/UILoader";
import { useMemoizedCallback } from "../../_app/hooks";

interface Props {
  disabled?: boolean;
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  error?: boolean;
  filterSelectedOptions?: boolean;
  helperText?: React.ReactNode;
  options: Array<any>;
  loading: boolean;
  label?: string;
  multiple?: boolean;
  name?: string;
  placeholder?: string;
  renderOption?: (option: any, state?: any) => React.ReactNode;
  required?: boolean;
  onSearch: (query: string) => void;
  onSelect: (selected: any) => void;
  onBlur?: () => void;
  onClose?: () => void;
  value?: any;
  inputValue?: any;
  valueFrom?: any;
  textFrom?: any;
  searchOnFocus?: boolean;
  shrink?: boolean;
  getOptionDisabled?: (option: any) => boolean;
  noOptionsText?: string;
  openOnFocus?: boolean;
  ListboxProps?: any;
}

function Component({
  disabled,
  disableClearable,
  disableCloseOnSelect,
  error,
  filterSelectedOptions,
  helperText,
  options,
  loading,
  label,
  multiple,
  name,
  placeholder,
  renderOption,
  required,
  onSearch,
  onSelect,
  onBlur,
  onClose,
  value,
  inputValue,
  valueFrom,
  textFrom,
  searchOnFocus = true,
  shrink,
  getOptionDisabled,
  noOptionsText,
  openOnFocus = false,
  ListboxProps,
}: Props) {
  const [isOpen, setIsOpen] = useState(openOnFocus);

  const onSearchMemoized = useMemoizedCallback(onSearch, []);

  useEffect(() => {
    if (openOnFocus && !options.length) {
      onSearchMemoized("");
    }
  }, [openOnFocus, onSearchMemoized, options.length]);

  return (
    <Autocomplete
      disabled={disabled}
      disableClearable={disableClearable}
      disableCloseOnSelect={disableCloseOnSelect}
      size="small"
      options={options}
      loading={loading || false}
      multiple={multiple}
      renderOption={renderOption}
      value={value}
      inputValue={inputValue}
      isOptionEqualToValue={(selected, item) => {
        if (typeof selected === "object") {
          if (typeof valueFrom === "function") {
            return valueFrom(selected) === valueFrom(item);
          }

          return selected[valueFrom || "value"] === item[valueFrom || "value"];
        }

        return selected === item;
      }}
      getOptionLabel={(option) => {
        if (typeof option === "object") {
          if (typeof textFrom === "function") {
            return textFrom(option);
          }

          return option[textFrom || "text"];
        }

        return option;
      }}
      onChange={(event, selected) => {
        onSelect(selected);
        if (onClose) onClose();
        setIsOpen(false);
      }}
      getOptionDisabled={getOptionDisabled ? getOptionDisabled : undefined}
      autoComplete
      filterOptions={(options, state) => options}
      filterSelectedOptions
      onInputChange={(event, value, reason) => {
        if (typeof onSearch === "function" && (reason === "input" || reason === "clear")) {
          onSearch(value);
        }
      }}
      fullWidth
      onBlur={onBlur}
      onFocus={(event) => {
        setIsOpen(true);
        if (searchOnFocus && !options.length) {
          onSearch("");
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          error={error}
          helperText={helperText}
          fullWidth
          label={label}
          name={name}
          placeholder={placeholder}
          required={required || false}
          variant="outlined"
          InputLabelProps={{
            shrink: shrink,
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading || false ? <UILoader color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      noOptionsText={noOptionsText}
      open={isOpen}
      onOpen={() => setIsOpen(true)}
      onClose={() => setIsOpen(false)}
      PopperComponent={openOnFocus ? CustomPopper : undefined}
      ListboxProps={ListboxProps}
    />
  );
}

const CustomPopper = (props: PopperProps) => (
  <Popper
    {...props}
    placement="bottom"
    disablePortal
    modifiers={[
      {
        name: "preventOverflow",
        options: {
          enabled: true,
          boundariesElement: "window",
          tether: true,
        },
      },
    ]}
  />
);

export default Component;

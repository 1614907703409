import React from "react";
import { ReactNode } from "react";
import Tabbed from "../../_app/layouts/Tabbed";
import { Alert } from "@mui/material";
import { Theme } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

interface Props {
  selectedTab: string;
  children: ReactNode;
}

function MobileManagementTabBar({ selectedTab, children }: Props) {
  const classes = useStyles();
  return (
    <div className={classes.ctr}>
      <Alert severity="info" className={classes.info}>
        Take control of your mobiles by managing your bars, SIMs and more.
      </Alert>
      <Tabbed
        selectedTab={selectedTab}
        persistParams
        tabs={[
          {
            value: "mobile-management",
            label: "Management",
            hidden: false,
          },
          {
            value: "mobile-management-history",
            label: "History",
            hidden: false,
          },
        ]}
      >
        {children}
      </Tabbed>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      width: "100%",
    },
    info: {
      width: "fit-content",
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
  })
);

export default MobileManagementTabBar;

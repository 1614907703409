import React, { useMemo } from "react";
import Main from "../../_app/layouts/Main";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

import { Grid } from "@mui/material";
import MUIDataTable from "mui-datatables";
import { featureFlagsMap } from "../../feature/utils";
import { permissionCodes } from "../../permission/hooks";
import { TabPanel } from "@mui/lab";
import MobileManagementTabBar from "../components/MobileManagementTabBar";
import { useAssetsOperationHistoryInfinite } from "../hooks";
import { LoadMoreFooter } from "../../_app/components/Table/LoadMoreFooter";
import { formatDate } from "../../_app/utils/format";
import StatusChip from "../../_app/components/StatusChip";
import { rowParser } from "../../_app/components/Table/helpers";
import UILoader from "../../_app/components/UILoader";

export const MobileManagementHistory = () => {
  const classes = useStyles();

  const { data, isFetching, isFetchingNextPage, fetchNextPage, hasNextPage } = useAssetsOperationHistoryInfinite();

  const parsed = useMemo(() => {
    const pages: any = data?.pages?.map((page) => {
      return page?.list?.map((item) =>
        rowParser([
          item.caseId,
          item.cli || item.accountCode,
          item.action,
          item.actionDetails,
          formatDate(item.requestDate, "dd/MM/yyyy"),
          item.requestedBy,
          <StatusChip status={item.status} />,
        ])
      );
    });
    return [].concat.apply([], pages);
  }, [data]);

  return (
    <Main
      title="Mobile Management"
      data-cy="mobile-management-history-page"
      featureFlag={featureFlagsMap.ASSETS}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      needSelectedAccount
    >
      <MobileManagementTabBar selectedTab="mobile-management-history">
        <TabPanel value="mobile-management-history" className={classes.ctr}>
          <Grid item xs={12} md={12}>
            {isFetching && !isFetchingNextPage ? (
              <div className={classes.loader}>
                <UILoader />
              </div>
            ) : (
              <div data-cy="mobile-management-history-table" className={classes.tableCtr}>
                <MUIDataTable
                  title=""
                  data={parsed}
                  columns={["Case ID", "Identifier", "Action", "Action Details", "Date of Request", "Requested By", "Status"]}
                  options={{
                    download: false,
                    elevation: 1,
                    print: false,
                    responsive: "standard",
                    selectToolbarPlacement: "none",
                    filter: false,
                    viewColumns: false,
                    sort: false,
                    search: false,
                    serverSide: true,
                    rowHover: true,
                    selectableRows: "none",
                    selectableRowsHeader: false,
                    setTableProps: () => ({ size: "small" }),
                    setRowProps: (row) => ({ "data-cy": `row-id-${row?.[1]}` }),
                    customFooter: () => (
                      <LoadMoreFooter
                        disabled={isFetching || isFetchingNextPage}
                        hasMore={Boolean(hasNextPage)}
                        onChangePage={fetchNextPage}
                      />
                    ),
                  }}
                />
              </div>
            )}
          </Grid>
        </TabPanel>
      </MobileManagementTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      padding: 0,
    },
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    tableCtr: {},
  })
);

export default MobileManagementHistory;

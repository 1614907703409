import { useStore } from "../_app/hooks";
import { useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { getCachedContextHierarchy } from "../context/utils";
import { getSupportContact } from "./api";
import { SupportContact } from "./types";
import { DEFAULT_BRAND } from "./utils";

export function useBrand() {
  const { state } = useStore();
  return state.brand;
}

export function useBrandColors() {
  const brand = useBrand();
  if (brand) {
    return {
      primary: brand?.primaryColour,
      secondary: brand?.secondaryColour,
      tertiary: brand?.tertiaryColour,
      primary_text: brand?.primaryTextColour,
      secondary_text: brand?.secondaryTextColour,
      tertiary_text: brand?.tertiaryTextColour,
    };
  }
  return {
    primary: DEFAULT_BRAND.primaryColour,
    secondary: DEFAULT_BRAND.secondaryColour,
    tertiary: DEFAULT_BRAND.tertiaryColour,
  };
}

export function useBrandName() {
  const brand = useBrand();
  return brand?.name || DEFAULT_BRAND.name;
}

export function useLogo() {
  const brand = useBrand();
  return brand?.logoUrl || DEFAULT_BRAND.logoUrl;
}

export function useBrandWelcomeText() {
  const brand = useBrand();
  return brand?.welcomeText || "Welcome to " + DEFAULT_BRAND.name;
}

export function useLoginBackground() {
  const brand = useBrand();
  return brand?.loginBg || DEFAULT_BRAND.loginBg;
}

export function usePromotion() {
  const brand = useBrand();
  return brand?.promotion;
}

export function useFooterLinks() {
  const brand = useBrand();
  return brand?.footerLinks || [];
}

export function useFooterWidget1() {
  const brand = useBrand();
  return brand?.widgetArea1;
}

export function useFooterWidget2() {
  const brand = useBrand();
  return brand?.widgetArea2;
}

export function usePoweredByLogo() {
  return DEFAULT_BRAND.poweredByImg;
}

export function useCustomerServices(options = {}) {
  const contextId = getCachedContextHierarchy()?.id;
  return useQuery<SupportContact, AjaxResponse>(["contact", contextId], () => getSupportContact(contextId), {
    ...options,
  });
}

import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import ManageBillDashboardTabs from "../components/ManageBillDashboardTabs";
import { Theme, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { featureFlagsMap } from "../../feature/utils";
import UsageCost from "../components/graphs/UsageCost";
import ServiceChargesFrequency from "../components/graphs/ServiceChargesFrequency";
import UsageSummary from "../components/graphs/UsageSummary";
import FiltersCard from "../../filter/components/FiltersCard";
import TopCostAsset from "../components/graphs/TopCostAsset";
import TopDataUser from "../components/graphs/TopDataUser";
import CallCount from "../components/graphs/CallCount";
import { useAllFilters } from "../../filter/hooks";
import { useQueryParam, useQueryParams } from "../../_app/hooks";
import { useBilledPeriods } from "../../billing/hooks";
import CallDuration from "../components/graphs/CallDuration";
import { Query } from "../../filter/types";
import CallCost from "../components/graphs/CallCost";
import Tabbed from "../../_app/layouts/Tabbed";

export const BillDashMonthly = () => {
  const classes = useStyles();
  const selectedTab = "bill-dashboard-monthly";
  const history = useHistory();
  const [type, setType] = useState("summary");
  const queryParams = useQueryParams();
  const { data: periodList } = useBilledPeriods();
  const period = useQueryParam("70");

  const getParams = useCallback(() => {
    const filters: Query[] = [];
    queryParams.forEach((value, key) => {
      const values = value.split(",");
      values.forEach((query) => {
        filters.push({
          id: key,
          value: query,
        });
      });
    });
    return filters;
  }, [queryParams]);

  useEffect(() => {
    if (!period) {
      const defaultPeriod = periodList?.[0];
      if (defaultPeriod) {
        queryParams.append("70", defaultPeriod);
        history.replace({ search: queryParams.toString() });
      }
    }
  }, [periodList, period, queryParams, history]);

  return (
    <Main
      title="Billing Summary"
      data-cy="bill-dashboard-monthly-page"
      featureFlag={featureFlagsMap.REPORT_GRAPHS}
      needSelectedAccount
    >
      <ManageBillDashboardTabs selectedTab={selectedTab}>
        <Grid container direction="row" className={classes.ctr}>
          <Grid item xs={12}>
            <FiltersCard className={classes.filtersCard} hasReset={false} fetchHook={useAllFilters} usage={"GRAPHS_MONTHLY"} />
          </Grid>
          <Grid item xs={12}>
            <Tabbed
              selectedTab={type}
              handleTabChange={(_, val) => setType(val)}
              tabs={[
                { value: "summary", label: "Summary" },
                { value: "cli", label: "CLI/Identifiers" },
                { value: "usage", label: "Usage" },
              ]}
            />
          </Grid>
          <div className={classes.widgetWrap}>
            <Grid container direction="row" spacing={3}>
              {(() => {
                switch (type) {
                  case "summary":
                    return (
                      <>
                        <UsageSummary period={period} />
                        <UsageCost queries={getParams()} />
                        <ServiceChargesFrequency queries={getParams()} />
                      </>
                    );
                  case "cli":
                    return (
                      <>
                        <TopCostAsset queries={getParams()} />
                        <TopDataUser queries={getParams()} />
                      </>
                    );
                  case "usage":
                    return (
                      <>
                        <CallCount queries={getParams()} />
                        <CallDuration queries={getParams()} />
                        <CallCost queries={getParams()} />
                      </>
                    );
                  default:
                    break;
                }
              })()}
            </Grid>
          </div>
        </Grid>
      </ManageBillDashboardTabs>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {},
    filtersCard: {},
    widgetWrap: {
      width: "100%",
      minHeight: "51vh",
    },
  })
);

export default BillDashMonthly;

import React from "react";
import { useGetUsageAnalysisColumns, useGetUsageAnalysisSummary, useGetUsageAnalysisSummaryTotal } from "../hooks";
import { pushValueToRow } from "./helpers";
import { bytesToSize, formatCurrency } from "../../_app/utils/format";
import { rowParser, SortParam } from "../../_app/components/Table/helpers";
import { UsageAnalysisColumn, UsageAnalysisSummary } from "../types";

interface Props {
  tableTypes: string[];
  tableType: string;
  rowCount: number;
  sort: SortParam[];
  getParams: Function;
  applied: boolean;
  fetchColumns: string[];
  setTableType: Function;
  setApplied: Function;
}

export const useUsageAnalysisSummaryData = ({
  tableTypes,
  tableType,
  rowCount,
  sort,
  getParams,
  applied,
  fetchColumns,
  setTableType,
  setApplied,
}: Props) => {
  const {
    data: usageAnalysisSummaryResponse,
    isLoading: isSummaryLoading,
    isFetching,
    refetch: refetchSummary,
    fetchNextPage: fetchNextPageSummary,
    isFetchingNextPage,
    hasNextPage,
  } = useGetUsageAnalysisSummary(rowCount, sort, tableType, getParams(), {
    enabled: applied && tableType !== "ALL" && !!fetchColumns && !!fetchColumns.length && !!getParams().length,
  });

  const { data: usageAnalysisSummaryTotalResponse, isLoading: isTotalLoading } = useGetUsageAnalysisSummaryTotal(getParams(), {
    enabled: applied && tableType !== "ALL" && !!getParams().length,
  });

  const { data: tableCliColumns } = useGetUsageAnalysisColumns(tableType);
  let data: any = [];

  usageAnalysisSummaryResponse?.pages?.forEach((page) => {
    page.list?.map((ca: UsageAnalysisSummary) => {
      const row = [] as any;
      tableCliColumns?.forEach((column: UsageAnalysisColumn) => {
        pushValueToRow(row, ca, column, setApplied, tableType, setTableType);
      });

      return data.push(rowParser(row));
    });
  });
  const queryExecutionId = usageAnalysisSummaryResponse?.pages?.[0]?.queryExecutionId;

  const row = [""];
  if (tableType === tableTypes[1]) {
    row.push("", "", "", "");
  }
  if (tableType === tableTypes[2] || tableType === tableTypes[3]) {
    row.push("");
  }

  if (usageAnalysisSummaryTotalResponse && !isSummaryLoading) {
    const currencyCode = usageAnalysisSummaryTotalResponse.currencyCode;
    data.push([
      ...row,
      <strong>{formatCurrency(usageAnalysisSummaryTotalResponse.internationalCallsCost, currencyCode)}</strong>,
      <strong>{formatCurrency(usageAnalysisSummaryTotalResponse.domesticCallsCost, currencyCode)}</strong>,
      <strong>{formatCurrency(usageAnalysisSummaryTotalResponse.smsCost, currencyCode)}</strong>,
      <strong>{formatCurrency(usageAnalysisSummaryTotalResponse.mmsCost, currencyCode)}</strong>,
      <strong>{bytesToSize(usageAnalysisSummaryTotalResponse.roamedDataVolume, "KB")}</strong>,
      <strong>{formatCurrency(usageAnalysisSummaryTotalResponse.roamedDataCost, currencyCode)}</strong>,
      <strong>{bytesToSize(usageAnalysisSummaryTotalResponse.nonRoamedDataVolume, "KB")}</strong>,
      <strong>{formatCurrency(usageAnalysisSummaryTotalResponse.nonRoamedDataCost, currencyCode)}</strong>,
      <strong>{bytesToSize(usageAnalysisSummaryTotalResponse.allDataVolume, "KB")}</strong>,
      <strong>{formatCurrency(usageAnalysisSummaryTotalResponse.allDataCost, currencyCode)}</strong>,
      "",
      <strong>{formatCurrency(usageAnalysisSummaryTotalResponse.allCost, currencyCode)}</strong>,
    ]);
  }

  const isLoading = isSummaryLoading || isTotalLoading;

  return {
    data,
    isLoading,
    refetchSummary,
    fetchNextPageSummary,
    isFetchingNextPage,
    isFetching,
    hasNextPage,
    queryExecutionId,
  };
};
export default useUsageAnalysisSummaryData;

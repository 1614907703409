import React, { useState, useEffect } from "react";
import { Theme, FormControlLabel, Checkbox, Button, Paper, Grid, Typography, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import MUIDataTable from "mui-datatables";
import SelectDropdown from "../../../form/components/SelectDropdown";
import { useAlertActions } from "../../hooks";
import { useContactsInfinite } from "../../../contacts/hooks";
import { UsageAlert, AlertContact } from "../../types";
import { Contact } from "../../../contacts/types";

interface Props {
  usageAlert: UsageAlert;
  setAlert: Function;
}

export const SelectAction = ({ usageAlert, setAlert }: Props) => {
  const classes = useStyles();
  const [barActions, setBarActions] = useState([] as any);
  const [newContact, addNewContact] = useState<AlertContact | undefined>();

  const [contactsToNotify, setNotifyContacts] = useState<AlertContact[]>(usageAlert?.alertAction?.contacts || []);
  const [alertActionObject, setAlertActionObject] = useState(usageAlert?.alertAction || ({} as UsageAlert));
  const [parsedTableContacts, setParsedContacts] = React.useState<any[]>([]);

  const { data: alertActions } = useAlertActions(usageAlert?.product);
  const { data: contacts, fetchNextPage, hasNextPage } = useContactsInfinite();

  const createContactStr = (firstName: string, lastName: string, email: string, mobileNumber: string) => {
    return [`${firstName} ${lastName}`, email, mobileNumber].filter((str) => !!str).join(", ");
  };

  const contactsParsed = contacts?.pages.reduce(
    (acc: any, page: any) => [
      ...acc,
      ...page.list.map((c: Contact) => ({
        id: c.id,
        label: createContactStr(c.firstName, c.lastName, c.email, c.mobileNumber),
        value: c,
      })),
    ],
    []
  );

  useEffect(() => {
    const barActions = alertActions?.map((action) => {
      return {
        id: action.name,
        label: action.value,
        value: action.name,
      };
    });
    setBarActions(barActions);
  }, [alertActions, usageAlert]);

  useEffect(() => {
    setAlertActionObject((old) => {
      return { ...old, contacts: contactsToNotify };
    });
  }, [contactsToNotify]);

  useEffect(() => {
    setAlert((old: UsageAlert) => {
      return { ...old, alertAction: { ...alertActionObject } };
    });
  }, [alertActionObject, setAlert]);

  const onContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val: any = e?.target?.value;

    addNewContact({
      contactId: val?.id,
      email: val?.email,
      emailNotification: !!val?.email,
      forename: val?.firstName,
      surname: val?.lastName,
      mobileNumber: val?.mobileNumber,
      smsNotification: false,
    });
  };

  const onActionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e?.target.name;
    const val = e?.target.value;
    const checked = e?.target.checked;
    if (key === "notifyAssetsBySMS") {
      setAlertActionObject((old) => {
        return { ...old, [key]: checked };
      });
    } else {
      setAlertActionObject((old) => {
        return { ...old, [key]: val };
      });
    }
  };

  const onBarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e?.target.name;
    const val = e?.target.value;
    setAlertActionObject((old) => {
      return { ...old, [key]: { name: val } };
    });
  };

  const handleSubmit = () => {
    if (newContact) {
      setNotifyContacts((old: AlertContact[]) => {
        return [...old, newContact];
      });
    }
  };

  const onCheckboxCheck = React.useCallback(
    <K extends keyof AlertContact>(key: K, id: string) => {
      setNotifyContacts((prevContacts: AlertContact[]) => {
        return prevContacts.map((contact) => {
          if (contact.contactId === id) {
            return {
              ...contact,
              [key]: !contact[key] as AlertContact[K],
            };
          }
          return contact;
        });
      });
    },
    [setNotifyContacts]
  );

  const removeContact = React.useCallback(
    (id: string) => {
      setNotifyContacts(contactsToNotify.filter((c: AlertContact) => c.contactId !== id));
    },
    [contactsToNotify]
  );

  useEffect(() => {
    const parsed = contactsToNotify?.map((contact: AlertContact) => {
      return [
        createContactStr(contact.forename, contact.surname, contact.email, contact.mobileNumber),
        Boolean(contact.email) && (
          <Checkbox
            name="emailNotification"
            checked={contact.emailNotification && !!contact.email}
            onChange={() => onCheckboxCheck("emailNotification", contact.contactId)}
            disabled={!contact.email || !contact.smsNotification}
            color="primary"
          />
        ),
        Boolean(contact.mobileNumber) && (
          <Checkbox
            name="smsNotification"
            checked={contact.smsNotification && !!contact.mobileNumber}
            onChange={() => onCheckboxCheck("smsNotification", contact.contactId)}
            disabled={!contact.mobileNumber || !contact.emailNotification}
            color="primary"
          />
        ),
        <Button variant="contained" color="primary" onClick={() => removeContact(contact.contactId)} data-cy="delete">
          Delete
        </Button>,
      ];
    });

    setParsedContacts(parsed);
  }, [usageAlert, removeContact, onCheckboxCheck, contactsToNotify]);

  const getSelectedValue: any = React.useCallback(
    (contacts: any) => {
      return contacts?.find((item: Contact) => item.id === newContact?.contactId)?.value;
    },
    [newContact]
  );

  const canAddBar = usageAlert.level === "CLI" && usageAlert.product === "MOBILE";
  const canNotifyAssets = canAddBar;

  const tooltipText = "An SMS will be sent to the mobile number the alert is created against when the alert level is breached.";
  const tooltipAutoBarText = `An autobar can be selected which will restrict the use of a mobile number. This can be used to assist in management of costs & reduce unexpected "bill shock".`;
  const tooltipContactsText =
    "Select one or more contacts who should be notified when the alert is breached. Contacts can be added from the Manage Contacts page.";
  const tooltipAutoBarWarrningText =
    "We will use reasonable endeavours to apply the autobar, as soon as we become aware that the mobile number has breached the usage alert amount. However, in certain circumstances that are beyond our control, such as delays in call data being received from the network, overall charges may exceed the set limit and are still liable to be paid for under your terms & conditions.";
  return (
    <div>
      <Paper className={classes.paper}>
        <Grid container direction="row" spacing={1}>
          {canNotifyAssets && (
            <Grid item xs={12}>
              <div className={classes.tooltip}>
                <Typography variant="h3" gutterBottom>
                  Notify the user of the mobile number?
                </Typography>
                <Tooltip title={tooltipText} placement="right" arrow>
                  <InfoIcon color="secondary" data-cy="tooltip-action-notify-user" className={classes.infoIcon} />
                </Tooltip>
              </div>
              <FormControlLabel
                className={classes.checkbox}
                control={
                  <Checkbox
                    name="notifyAssetsBySMS"
                    checked={Boolean(usageAlert?.alertAction?.notifyAssetsBySMS)}
                    onChange={onActionChange}
                    color="primary"
                  />
                }
                label="Notify asset by SMS"
                labelPlacement="end"
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <div className={classes.tooltip}>
              <Typography variant="h3" gutterBottom>
                Notify contacts?
              </Typography>
              <Tooltip title={tooltipContactsText} placement="right" arrow>
                <InfoIcon color="secondary" data-cy="tooltip-action-notify-contacts" className={classes.infoIcon} />
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12} md={8}>
            <SelectDropdown
              name="contact"
              label="Select Contact"
              value={getSelectedValue(contactsParsed) || ""}
              data={contactsParsed || []}
              infiniteScroll={true}
              multiple={false}
              nextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              onChange={onContactChange}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <Button
              className={classes.addContact}
              variant="contained"
              color="primary"
              disabled={!!contactsToNotify.find((c) => c.contactId === newContact?.contactId) || !newContact}
              onClick={() => handleSubmit()}
              data-cy="add-contact"
            >
              Add Contact to Alert
            </Button>
          </Grid>
        </Grid>
        <div data-cy="notify-contacts-table" className={classes.table}>
          <MUIDataTable
            title=""
            data={parsedTableContacts}
            columns={["Contact", "Email", "SMS", { label: "", name: "" }]}
            options={{
              download: false,
              elevation: 1,
              print: false,
              responsive: "standard",
              selectToolbarPlacement: "none",
              filter: false,
              serverSide: false,
              viewColumns: false,
              sort: false,
              search: false,
              selectableRows: "none",
              rowHover: true,
              setRowProps: (row) => ({ "data-cy": `row-id-${row[0]}` }),
              setTableProps: () => ({ size: "small" }),
            }}
          />
        </div>
      </Paper>
      {canAddBar && (
        <Paper className={classes.paper}>
          <div className={classes.tooltip}>
            <Typography variant="h3" gutterBottom>
              Add an autobar?
            </Typography>
            <Tooltip title={tooltipAutoBarText} placement="right" arrow>
              <InfoIcon color="secondary" data-cy="tooltip-action-autobar" className={classes.infoIcon} />
            </Tooltip>
          </div>
          <SelectDropdown
            required
            name="bar"
            label="Add Bar"
            value={usageAlert?.alertAction?.bar?.name || ""}
            data={barActions}
            onChange={onBarChange}
            ctrClass={classes.barDropdown}
          />
          <span>
            <Tooltip title={tooltipAutoBarWarrningText} placement="right">
              <WarningIcon color="primary" className={classes.icon} data-cy="tooltip-warning-autobar" />
            </Tooltip>
          </span>
        </Paper>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      "& th:nth-child(2)": {
        paddingLeft: 22,
      },
      "& th:nth-child(3)": {
        paddingLeft: 22,
      },
    },
    checkbox: {
      margin: 0,
      marginBottom: theme.spacing(2),
    },
    paper: {
      padding: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
    addContact: {
      whiteSpace: "nowrap",
      marginBottom: theme.spacing(3),
      minWidth: 155,
    },
    barDropdown: {
      maxWidth: "270px",
    },
    tooltip: {
      display: "flex",
      gap: theme.spacing(1.5),
    },
    icon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(2),
    },
    infoIcon: {
      cursor: "pointer",
    },
  })
);

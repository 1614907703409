import React from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Bar from "react-chartjs-2";
import { useVerticalBarChartOptions, useCallCost } from "../../hooks";
import GraphWidget from "./GraphWidget";
import { Query } from "../../../filter/types";
import { useHasFeature } from "../../../feature/hooks";
import { featureFlagsMap } from "../../../feature/utils";

const featureFlag = featureFlagsMap.CALL_COST_GRAPH;

interface Props {
  queries: Query[];
}

const CallCost = ({ queries }: Props) => {
  const classes = useStyles();
  const {
    data = [],
    isFetching,
    isError,
  } = useCallCost(queries, {
    enabled: useHasFeature(featureFlag),
  });
  const hasError = isError || data?.datasets?.length <= 0;
  const options = useVerticalBarChartOptions(data?.unit);

  return (
    <GraphWidget title="Call Cost" isLoading={isFetching} hasError={hasError} featureFlag={featureFlag}>
      <div className={classes.barWrap}>
        <Bar data={data} options={options} type="bar" />
      </div>
    </GraphWidget>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  barWrap: {
    position: "relative",
    minHeight: 410,
    width: "100%",
  },
}));

export default CallCost;

import { useHasFeature } from "../../feature/hooks";
import { featureFlagsMap } from "../../feature/utils";
import { loadLiveChat } from "../lib/livechat";

function LiveChatProvider() {
  const hasFeature = useHasFeature(featureFlagsMap.LIVE_CHAT);
  if (hasFeature) loadLiveChat();
  return null;
}

export default LiveChatProvider;

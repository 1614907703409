import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

interface Props {
  containerProps?: any;
  headerProps?: any;
  headerLeftProps?: any;
  headerRightProps?: any;
  headerLeft: string | React.ReactNode;
  headerRight: string | React.ReactNode;
  bodyProps?: any;
  bodyContent?: string | React.ReactNode;
  onClick?: Function | void | undefined;
}

function UIStyledAccordion({
  containerProps = {},
  headerProps = {},
  headerLeftProps = {},
  headerRightProps = {},
  headerLeft,
  headerRight,
  bodyProps = {},
  bodyContent,
  onClick,
}: Props) {
  const classes = useStyles();

  return (
    <Accordion {...containerProps} className={`${classes.ctr} ${containerProps?.className}`} onChange={onClick}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        {...headerProps}
        className={`${classes.header} ${headerProps?.className}`}
      >
        <Typography component="span" variant="h4" {...headerLeftProps}>
          {headerLeft}
        </Typography>
        <Typography component="span" {...headerRightProps}>
          {headerRight}
        </Typography>
      </AccordionSummary>
      <AccordionDetails {...bodyProps}>
        <Typography component="span">{bodyContent}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      borderRadius: "5px",
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderLeft: "3px solid " + theme.palette.primary.main,
      "&::before": {
        opacity: "0",
      },
    },
    header: {
      "& > :first-child": {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      },
    },
  })
);

export default UIStyledAccordion;

import React from "react";
import {
  Grid,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormControl,
  Theme,
  Tooltip,
  Zoom,
  lighten,
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Permission } from "../api";
import { formatDisabledReason } from "../utils";

interface Props {
  permissions: Permission[];
  onChange?: (perm: any) => void;
  allowDisabling?: boolean;
}

const PermissionsViewer = ({ permissions = [], onChange = () => null, allowDisabling = true }: Props) => {
  const classes = useStyles();

  const onParentPermissionClick = (parent: any, value: any) => {
    onChange({
      ...parent,
      value,
      permissions: parent?.permissions.map((p: any) => ({
        ...p,
        value: false,
      })),
    });
  };

  const onChildPermissionClick = (child: any, value: boolean, parent: any) => {
    onChange({
      ...parent,
      value: value && !parent?.value ? true : parent?.value,
      permissions: parent?.permissions.map((p: any) => ({
        ...p,
        value: p?.id === child?.id ? value : p?.value,
      })),
    });
  };

  const isDisabled = (p: Permission) => allowDisabling && p?.disabled;

  const renderWithTooltip = (msg: string, children: any, key?: string) => {
    return (
      <Tooltip key={key} title={msg} TransitionComponent={Zoom} placement="top" arrow>
        {children}
      </Tooltip>
    );
  };

  return (
    <>
      {permissions?.map((parent: Permission) => {
        return (
          <React.Fragment key={parent?.id}>
            <Grid item md={4} xs={12} className={classes.groupTitleCtr} data-cy="permission-group">
              <Typography variant="h3" gutterBottom>
                {parent?.groupName}
              </Typography>
            </Grid>
            <Grid item md={8} xs={12}>
              <FormControl component="fieldset" className={classes.permRow}>
                <FormGroup className={classes.parentPerm} row>
                  {renderWithTooltip(
                    isDisabled(parent) ? formatDisabledReason(parent.disabledReason) : "",
                    <FormControlLabel
                      data-cy="permission-item"
                      value={parent?.value}
                      className={classes.label}
                      label={<Typography variant="subtitle1">{parent?.name}</Typography>}
                      labelPlacement="start"
                      control={
                        <Checkbox
                          color="primary"
                          disabled={isDisabled(parent)}
                          className={classes.checkbox}
                          checked={Boolean(parent?.value)}
                          data-cy={parent?.code}
                          onChange={(ev: any, value: boolean) => onParentPermissionClick(parent, value)}
                        />
                      }
                    />
                  )}
                </FormGroup>
                <FormGroup className={classes.permRows} row>
                  {parent?.permissions?.map((child: Permission) =>
                    renderWithTooltip(
                      isDisabled(child) ? formatDisabledReason(child.disabledReason) : "",
                      <FormControlLabel
                        key={child?.id}
                        data-cy="permission-item"
                        className={classes.label}
                        value={child?.value}
                        label={<Typography variant="subtitle1">{child?.name}</Typography>}
                        labelPlacement="start"
                        control={
                          <Checkbox
                            color="primary"
                            disabled={isDisabled(child)}
                            className={classes.checkbox}
                            checked={Boolean(child?.value)}
                            data-cy={child?.code}
                            onChange={(ev: any, value: boolean) => onChildPermissionClick(child, value, parent)}
                          />
                        }
                      />,
                      child?.id
                    )
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
          </React.Fragment>
        );
      })}
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    groupTitleCtr: {
      paddingTop: "7px",
    },
    permRow: {
      width: "100%",
      marginBottom: "4em",
      "& label": {
        width: "100%",
        justifyContent: "space-between",
      },
    },
    permRows: {
      "& :hover": {
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
      },
    },
    parentPerm: {
      borderBottom: "2px solid " + theme.palette.grey[300],
      "& :hover": {
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
      },
    },
    label: {
      marginLeft: 0,
      paddingLeft: theme.spacing(2),
      padding: "0px 9px 0px 15px",
    },
    checkbox: {
      paddingRight: 0,
    },
  })
);

export default PermissionsViewer;

import { useEffect } from "react";
import { login, loginCallback, silentLoginCallback } from "../sso-api";

export const AuthCallback = () => {
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    (async function getTokenAndRedirect() {
      const token = await loginCallback();
      if (token?.access_token) {
        window.location.href = window.location.origin;
      } else {
        timeout = setTimeout(() => login(), 500);
      }
    })();
    return () => clearTimeout(timeout);
  }, []);

  return null;
};

export const SilentAuthCallback = () => {
  useEffect(() => {
    silentLoginCallback();
  }, []);
  return null;
};

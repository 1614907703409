import { Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Alert } from "@mui/material";
import React from "react";
import { UserLevelFlag } from "../../../user-level/types";
import { useHasAuthority } from "../../../user-level/hooks";
import Tabbed from "../../../_app/layouts/Tabbed";

interface Props {
  selectedTab: string;
  children: any;
}

function ManageBundleAlertsTabBar({ selectedTab, children }: Props) {
  const classes = useStyles();
  const hasSubaccountLevel = useHasAuthority(UserLevelFlag.Sub);

  return (
    <div className={classes.wrap}>
      <Alert severity="info" className={classes.info}>
        Enabling bundle alerts will opt you into receiving notifications when you have nearly reached, or have reached your bundle
        allowance on your mobile. This is to help prevent bill shock and also put steps in place going forward to help prevent it
        from happening again.
      </Alert>
      <Alert severity="info" className={classes.info}>
        <strong>When we notify you</strong>
        {selectedTab === "bundle-alerts" ? (
          <>
            <br />
            An SMS will be sent to the mobile number when usage reaches 80% & 100% of the bundle allowance.
            <br />
            An email will be sent to the billing contact when any mobile number reaches 100% of the bundle allowance.
          </>
        ) : (
          <>
            <br />
            An email will also be sent to the billing contact when the usage of any Account Level Bundle reaches 80% & 100%.
          </>
        )}
      </Alert>
      <Alert severity="info" className={classes.info}>
        We will use reasonable endeavours to notify you as soon as we become aware that the mobile number has breached each
        threshold. However, in certain circumstances that are beyond our control, such as delays in call data being received from
        the network, there may be delays in our alert which may result in you exceeding your allowance. Any charges as a result
        are still liable to be paid for under your terms & conditions.
      </Alert>
      <Tabbed
        selectedTab={selectedTab}
        tabs={[
          { value: "bundle-alerts", label: "Individual", hidden: false },
          {
            value: "bundle-alerts-account",
            label: "Account",
            hidden: !hasSubaccountLevel,
          },
        ]}
      >
        {children}
      </Tabbed>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      flexDirection: "column",
      width: "100%",
    },
    info: {
      alignItems: "center",
      marginBottom: theme.spacing(1),
    },
  })
);

export default ManageBundleAlertsTabBar;

import { DispatchedAsset, SimType } from "./types";

export function getSimTypes() {
  return [
    { value: SimType.Triple, label: "Triple (Standard, Micro & Nano)" },
    { value: SimType.Esim, label: "E-Sim" },
  ];
}

export function canActivateSim(sim: DispatchedAsset) {
  const status = sim.requestStatus;
  return !(status === "PENDING" || status === "COMPLETE");
}

import React, { useState, useMemo } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { isEmail } from "../../_app/utils/index";
import { useValidation } from "../../form/hooks";
import StatusChip from "../../_app/components/StatusChip";
import SelectDropdownLegacy from "../../form/components/SelectDropdownLegacy";
import SubmitButtons from "../../form/components/SubmitButtons";
import { Contact } from "../types";
import { alertContactTypesMap, contactTypesMap } from "../utils";

export interface Props {
  contact: any;
  isSubmitting: boolean;
  handleSubmit: (data: Contact) => void;
  handleCancel: () => void;
  submitLabel?: string;
  onlyAlerts?: boolean;
}

export const EditContactForm = ({
  contact,
  isSubmitting = false,
  handleSubmit = () => null,
  handleCancel = () => null,
  submitLabel = "Submit",
  onlyAlerts = false,
}: Props) => {
  const classes = useStyles();
  const [data, setData] = useState(contact || {});
  const isPendingUpdates = Boolean(contact?.pendingChanges?.length);

  const typeList = useMemo(
    () =>
      Object.entries(onlyAlerts ? alertContactTypesMap : contactTypesMap).map((e) => {
        const [key, value] = e;
        return {
          id: key,
          value: key,
          label: value,
        };
      }) || [],
    [onlyAlerts]
  );

  // Validation
  const validationConfig: any = {
    firstName: { required: true },
    lastName: { required: true },
    mainNumber: {},
    mobileNumber: {},
    type: { required: true },
    email: {
      required: true,
      validate: (val: any) => {
        if (!isEmail(val)) return "Invalid email";
      },
    },
  };

  const { validationErrors, validateSingle, validateAll } = useValidation(validationConfig, { ...data });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const val = e.target.value;

    if (val === "search") return;
    setData({ ...data, [key]: val });
    validateSingle(key, val);
  };

  const onSubmit = () => {
    const errors = validateAll();
    if (errors.length <= 0) {
      handleSubmit(data);
    }
  };

  const onCancel = () => handleCancel();

  return (
    <>
      <Grid container data-cy="edit-contact-form">
        <Grid container direction="row">
          <Typography variant="h3" className={classes.title}>
            Contact Details
          </Typography>
          {isPendingUpdates && (
            <div className={classes.pendingCtr}>
              <StatusChip status="Updates Pending" />
            </div>
          )}
        </Grid>
        <Grid item>
          <TextField
            className={classes.textField}
            name="firstName"
            label="First name"
            type="text"
            value={data?.firstName || ""}
            variant="outlined"
            size="small"
            fullWidth
            error={Boolean(validationErrors.firstName)}
            helperText={validationErrors.firstName}
            onChange={onInputChange}
            disabled={isPendingUpdates}
          />
          <TextField
            className={classes.textField}
            name="lastName"
            label="Last name"
            type="text"
            value={data?.lastName || ""}
            variant="outlined"
            size="small"
            fullWidth
            error={Boolean(validationErrors.lastName)}
            helperText={validationErrors.lastName}
            onChange={onInputChange}
            disabled={isPendingUpdates}
          />
          <TextField
            className={classes.textField}
            name="mainNumber"
            label="Main number"
            type="phone"
            value={data?.mainNumber || ""}
            variant="outlined"
            size="small"
            fullWidth
            onChange={onInputChange}
            error={Boolean(validationErrors.contactNumber)}
            helperText={validationErrors.contactNumber}
            disabled={isPendingUpdates}
          />
          <TextField
            className={classes.textField}
            name="mobileNumber"
            label="Mobile number"
            type="phone"
            value={data?.mobileNumber || ""}
            variant="outlined"
            size="small"
            fullWidth
            onChange={onInputChange}
            error={Boolean(validationErrors.contactNumber)}
            helperText={validationErrors.contactNumber}
            disabled={isPendingUpdates}
          />
          <TextField
            className={classes.textField}
            name="email"
            label="Email / Username"
            type="email"
            value={data?.email || ""}
            variant="outlined"
            size="small"
            fullWidth
            error={Boolean(validationErrors.email)}
            helperText={validationErrors.email}
            onChange={onInputChange}
            disabled={isPendingUpdates}
          />
          <SelectDropdownLegacy
            name="type"
            label="Contact type"
            value={data?.type || ""}
            data={typeList}
            ctrClass={classes.textField}
            onChange={onInputChange}
            error={Boolean(validationErrors.type)}
            helperText={validationErrors.type}
            disabled={isPendingUpdates}
          />
        </Grid>
      </Grid>
      <SubmitButtons
        isSubmitting={isSubmitting}
        submitLabel={isPendingUpdates ? "" : submitLabel}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: theme.spacing(4),
    },
    textField: {
      marginBottom: theme.spacing(3),
    },
    pendingCtr: {
      marginLeft: theme.spacing(2),
      marginBottom: theme.spacing(4),
    },
  })
);

export default EditContactForm;

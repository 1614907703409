import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Main from "../../_app/layouts/Main";
import ManagePaymentsTabBar from "../components/ManagePaymentsTabBar";
import DirectDebitDialog from "../components/DirectDebitDialog";
import { PaymentDialogContentUnfilled } from "../components/PaymentDialogContentUnfilled";
import { PaymentDialogContentFilled } from "../components/PaymentDialogContentFilled";
import { PaymentDialogContentField } from "../components/PaymentDialogContentField";
import { usePaymentDetails, usePaymentDetailsUpdate } from "../hooks";
import { permissionCodes } from "../../permission/hooks";
import { useHasFeature } from "../../feature/hooks";
import { featureFlagsMap } from "../../feature/utils";

export const PaymentType = () => {
  const classes = useStyles();
  const selectedTab = "payment-type";
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [accountHolderName, setAccountHolderName] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [areDetailsEntered, setAreDetailsEntered] = useState(false);

  const { data, isLoading, isError, error } = usePaymentDetails();
  const hasDDUpdateFeature = useHasFeature(featureFlagsMap.DIRECT_DEBIT_UPDATE);

  useEffect(() => {
    if (data?.accountHoldersName) {
      setAreDetailsEntered(true);
      setAccountHolderName(data?.accountHoldersName);
      setSortCode(data?.sortCode);
      setAccountNumber(data?.accountNumber);
    }
    setPaymentType(data?.paymentType || "");
  }, [data, setAreDetailsEntered]);

  const { mutate: executePaymentDetailsUpdate } = usePaymentDetailsUpdate();

  const openDialog = () => setIsDialogOpen(!isDialogOpen);

  const handleContinue = (name: string, code: string, number: string) => {
    setAreDetailsEntered(true);
    executePaymentDetailsUpdate({
      paymentType: "DIRECT_DEBIT",
      accountHoldersName: name,
      accountNumber: number,
      sortCode: code,
    });
  };
  const errorMessage = error?.status === 403 ? "Payment details cannot be amended for sub-accounts." : error?.data?.message;

  return (
    <Main
      title="Payment details"
      data-cy="payment-page"
      isLoading={isLoading}
      accessPermission={permissionCodes.ACCOUNT_PAYMENTS}
      needSelectedAccount={true}
      featureFlag={featureFlagsMap.DIRECT_DEBIT}
    >
      {isError ? (
        <Grid item>
          <Alert severity="info">{errorMessage}</Alert>
        </Grid>
      ) : (
        <ManagePaymentsTabBar selectedTab={selectedTab}>
          <Grid container spacing={2}>
            {areDetailsEntered && paymentType === "DIRECT_DEBIT" ? (
              <Grid xs={12} md={6} xl={5} item>
                <Card className={classes.directDebitCardSetUp}>
                  <PaymentDialogContentFilled>
                    <PaymentDialogContentField name="Account holder" value={accountHolderName} />
                    <PaymentDialogContentField name="Sort code" value={sortCode} />
                    <PaymentDialogContentField name="Account number" value={accountNumber} />
                  </PaymentDialogContentFilled>
                  {hasDDUpdateFeature && (
                    <Button variant="contained" className={classes.cardButton} onClick={openDialog} data-cy="direct-debit-button">
                      {areDetailsEntered ? "Change Direct Debit" : "Set up Direct Debit"}
                    </Button>
                  )}
                </Card>
              </Grid>
            ) : (
              <Grid xs={12} md={6} xl={5} item>
                <Card className={classes.directDebitCardSetUp}>
                  <PaymentDialogContentUnfilled />
                  {hasDDUpdateFeature && (
                    <Button variant="contained" className={classes.cardButton} onClick={openDialog} data-cy="direct-debit-button">
                      {areDetailsEntered ? "Change Direct Debit" : "Set up Direct Debit"}
                    </Button>
                  )}
                </Card>
              </Grid>
            )}
            <DirectDebitDialog
              open={isDialogOpen}
              setOpen={setIsDialogOpen}
              onContinue={handleContinue}
              accountHolderName={accountHolderName}
              sortCode={sortCode}
              accountNumber={accountNumber}
            />
          </Grid>
        </ManagePaymentsTabBar>
      )}
    </Main>
  );
};

const useStyles = makeStyles((theme) => ({
  paymentType: {
    height: "550px",
    padding: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: "center",
  },
  directDebitCardSetUp: {
    height: "550px",
    padding: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: "white",
    backgroundColor: theme.palette.tertiary.main,
    textAlign: "center",
  },
  directDebitCardDetails: {
    height: "550px",
    padding: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: "center",
  },
  cardButton: {
    width: "100%",
    color: theme.palette.primary.main,
    backgroundColor: "white",
  },
}));

export default PaymentType;

import React from "react";
import SimpleNavWidget from "./SimpleNavWidget";
import Icon from "@mui/icons-material/PhoneIphone";

const ManageMobilesLink = ({ ...props }) => {
  return (
    <SimpleNavWidget
      title="Manage Mobiles"
      description="Manage your bars, bill limits and much more."
      link="/mobile-management"
      icon={<Icon />}
      {...props}
    />
  );
};

export default ManageMobilesLink;

import React from "react";
import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

interface PaymentDialogContentFieldProps {
  name: string;
  value: string | number;
}

export const PaymentDialogContentField = ({ name, value }: PaymentDialogContentFieldProps) => {
  const classes = useStyles();

  return (
    <div className={classes.detailFieldWrap}>
      <Typography variant="subtitle1" className={classes.fieldName}>
        {name}
      </Typography>
      <Typography variant="h4" data-cy={name}>
        {value}
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    detailFieldWrap: {
      display: "flex",
      alignItems: "baseline",
    },
    fieldName: {
      width: "140px",
      margin: `0 ${theme.spacing(3)} 8px 0`,
      textAlign: "right",
    },
  })
);

import React, { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useHistory, useLocation } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { permissionCodes } from "../../permission/hooks";
import { UICard } from "../../_app/components";
import { Grid, Skeleton, Typography } from "@mui/material";
import { featureFlagsMap } from "../../feature/utils";
import { SelectedParams } from "../../asset/screens/MobileManagement";
import { useFeedbackAlerts } from "../../_app/hooks";
import { capitalize } from "../../_app/utils/format";

export const SimUnlock = () => {
  const classes = useStyles();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const history = useHistory();
  const location = useLocation<SelectedParams>();
  const asset = location.state?.asset;

  useEffect(() => {
    if (!asset) {
      setFeedbackAlertError("No mobile number selected");
      history.push({ pathname: "/mobile-management" });
    }
  }, [asset, history]);

  const parsed = [
    { title: "Tag", val: asset?.tag || "-" },
    { title: "CLI", val: asset?.cli },
    {
      title: "SIM Number",
      val: asset?.simNumber || <Skeleton width={150} />,
    },
    { title: "Network", val: capitalize(asset?.network) },
    { title: "PUK Code", val: asset?.pukCode || <Skeleton width={100} /> },
  ];

  return (
    <Main
      title="SIM Unlock"
      data-cy="sim-unlock-page"
      featureFlag={featureFlagsMap.SIM_UNLOCK}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      <UICard hasBorder className={classes.ctr} padding="16px">
        {parsed?.map(({ title, val }, i) => {
          return (
            <Grid container key={`unlock-card_${title}`} className={classes.row} data-cy={`unlock-card_${i}`}>
              <Grid item sm={3}>
                <Typography variant="h4">{title}</Typography>
              </Grid>
              <Grid item>
                <Typography>{val || <Skeleton width={100} />}</Typography>
              </Grid>
            </Grid>
          );
        })}
      </UICard>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      flex: 1,
      marginTop: theme.spacing(1.5),
    },
    row: {
      marginBottom: "15px",
      gap: theme.spacing(1.5),
      alignItems: "center",
    },
  })
);

export default SimUnlock;

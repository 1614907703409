import React, { ReactNode } from "react";
import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import logo from "../images/direct-debit-logo-black.png";

interface PaymentDialogContentFilledProps {
  children: ReactNode;
}

export const PaymentDialogContentFilled = ({ children }: PaymentDialogContentFilledProps) => {
  const classes = useStyles();

  return (
    <div className={classes.filledContentWrap} data-cy="payment-details-filled">
      <div className={classes.directDebitLogoWrap}>
        <img src={logo} width={100} alt="direct-debit-logo" />
      </div>
      <Typography variant="h2" gutterBottom>
        My Direct Debit details
      </Typography>
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filledContentWrap: {
      height: "410px",
      textAlign: "left",
    },
    directDebitLogoWrap: {
      marginBottom: "32px",
    },
  })
);

import React, { useCallback, useEffect, useState } from "react";
import { Alert } from "@mui/material";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useAccountsForLevel, useUserData } from "../hooks";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { findMatchingById, isEmail, isEqual } from "../../_app/utils/index";
import { useAllRolesByAccountId } from "../../role/hooks";
import { Role } from "../../role/types";
import { Permission } from "../../permission/api";
import { useValidation } from "../../form/hooks";
import { useAllUserLevels } from "../../user-level/hooks";
import { useStore } from "../../_app/hooks";
import { UserLevelFlag } from "../../user-level/types";
import UIConfirm from "../../_app/components/UIConfirm";
import SelectDropdownLegacy from "../../form/components/SelectDropdownLegacy";
import SubmitButtons from "../../form/components/SubmitButtons";
import EditUserPermissionForm from "./EditUserPermissionForm";
import { useIdentityUser } from "../../auth/hooks";

export interface Props {
  user: any;
  newUser?: boolean;
  hasEmailConfirmation?: boolean;
  isSubmitting: boolean;
  handleSubmit: (data: any) => void;
  handleCancel: () => void;
  submitLabel?: string;
}

export const EditUserForm = ({
  user,
  newUser = false,
  hasEmailConfirmation = false,
  isSubmitting = false,
  handleSubmit = () => null,
  handleCancel = () => null,
  submitLabel = "Submit",
}: Props) => {
  const classes = useStyles();
  const { state } = useStore();
  const [data, setData] = useState(user || {});
  const [submitWarning, setSubmitWarning] = useState(false);
  const { data: identityUser } = useIdentityUser();
  const { userLevel } = useUserData();

  const NO_PARENT_LEVEL = 0;
  const PAGE_LIMIT = 25;

  const [parentLevel, setParentLevel] = useState(NO_PARENT_LEVEL);
  const [userLevelValue, setUserLevelValue] = useState(data?.userLevel || "");

  const [parentAccountQuery, setParentAccountQuery] = useState<any>(undefined);
  const [childAccountQuery, setChildAccountQuery] = useState<any>(undefined);

  // User Level
  const [userLevelList, setUserLevelList] = useState([] as any);
  const { data: userLevelsResponse } = useAllUserLevels();

  useEffect(() => {
    let list: any = [];
    if (identityUser) {
      userLevelsResponse?.map((level: any) =>
        list?.push({
          id: level?.id,
          label: level?.name,
          value: level?.id,
        }),
      );
    }
    if (list?.length && identityUser) {
      const userLevels = list?.filter((level: any) => level?.value >= userLevel && level?.value < 40);
      setUserLevelList(userLevels);
    }
  }, [userLevelsResponse, identityUser]);

  const parseListItemLabel = (item: any) => {
    let labels = [item?.name || item?.code];
    if (item?.level?.flag === UserLevelFlag.Head || item?.level?.flag === UserLevelFlag.Sub) {
      labels = [item?.code, item?.name];
    }
    if (item?.level?.flag === UserLevelFlag.Individual) {
      labels = [item?.code, item?.tag];
    }
    return labels?.filter(Boolean).join(" - ");
  };

  const parseChildListItem = useCallback((item: any) => {
    return {
      id: item?.id,
      label: parseListItemLabel(item),
      value: item,
    };
  }, []);

  const parseParentListItem = useCallback((item: any) => {
    let invoicingId;
    // in case of using parentInvoicingAccount (edit user)
    // we need to inject it to the Id of object
    if (!item?.id && item) {
      invoicingId = item;
    }
    return {
      id: item?.id || invoicingId,
      label: parseListItemLabel(item),
      value: item,
      disabled: item?.size === 0,
      title: item?.size === 0 ? "There are no options on this account currently." : "",
    };
  }, []);

  const constructParentList = useCallback(
    (pages?: any[]) => {
      return pages?.reduce((acc, page) => [...acc, ...page?.list?.map(parseParentListItem)], []);
    },
    [parseParentListItem],
  );

  const constructChildList = useCallback(
    (pages?: any[]) => {
      return pages?.reduce((acc, page) => [...acc, ...page?.list?.map(parseChildListItem)], []);
    },
    [parseChildListItem],
  );

  const injectParentCachedDefault = useCallback(
    (list: any[], item) => {
      const match = findMatchingById(item?.id, list);
      if (item?.id && !match && Boolean(list?.length)) {
        list?.unshift(parseParentListItem(item));
      }
      return list;
    },
    [parseParentListItem],
  );

  const injectChildCachedDefault = useCallback(
    (list: any[], items) => {
      items?.forEach((item: any) => {
        const match = findMatchingById(item?.id, list);
        if (item?.id && !match && Boolean(list?.length)) {
          list?.unshift(parseChildListItem(item));
        }
      });
      return list;
    },
    [parseChildListItem],
  );

  const [childAccounts, setChildAccounts] = useState(user?.accounts?.map((acc: any) => ({ ...acc })) || []);
  const [parentAccount, setParentAccount] = useState<any>(parseParentListItem(user?.parentInvoicingAccount) || "");

  // Roles
  const [roleList, setRoleList] = useState([] as any);
  const { data: rolesResponse } = useAllRolesByAccountId(
    {
      "account-id": parentAccount?.id ? parentAccount?.id : childAccounts?.[0]?.id,
      page: 0,
      "page-size": PAGE_LIMIT,
    },
    {
      enabled: Boolean(userLevelValue >= 30 && parentAccount?.id) || Boolean(userLevelValue <= 20 && childAccounts?.[0]?.id),
    },
  );
  useEffect(() => {
    if (rolesResponse?.list?.length) {
      const list = [{ id: "", label: "None", value: "" }];
      rolesResponse?.list?.map((role: Role) =>
        list.push({
          id: role?.id,
          label: role?.name,
          value: role?.id,
        }),
      );
      setRoleList(list);
    }
  }, [rolesResponse]);

  const [permissions, setPermissions] = useState([] as Permission[]);

  // Accounts
  const [accountList, setAccountList] = useState([] as any);
  const {
    data: accountsResponse,
    fetchNextPage: fetchNextAccount,
    hasNextPage: accountNextPage,
  } = useAccountsForLevel(
    userLevelValue + "",
    {
      "search-term": childAccountQuery,
      limit: PAGE_LIMIT,
      parent: parentAccount?.id || "",
    },
    {
      enabled: Boolean(userLevelValue),
    },
  );

  // Parent Accounts
  const [parentAccountList, setParentAccountList] = useState([] as any);
  const {
    data: parentAccountsResponse,
    fetchNextPage: fetchParentAccountNextPage,
    hasNextPage: parentAccountNextPage,
  } = useAccountsForLevel(
    parentLevel + "",
    {
      "search-term": parentAccountQuery,
      limit: PAGE_LIMIT,
    },
    {
      enabled: parentLevel !== NO_PARENT_LEVEL && parentLevel !== undefined,
    },
  );

  useEffect(() => {
    if (Boolean(accountsResponse?.pages?.length && accountsResponse?.pages?.[0]?.list?.length)) {
      const parent: number = accountsResponse?.pages?.[0]?.list?.[0]?.parentFilterLevel;
      const isHeadAccount: boolean = accountsResponse?.pages?.[0]?.list?.[0]?.level?.flag === "HEAD";
      if (parent === 0 || parentAccount) {
        const accountList: any = injectChildCachedDefault(constructChildList(accountsResponse?.pages), childAccounts);

        if (isHeadAccount && !parentAccount && parentAccountList?.length) {
          setAccountList([]);
        } else {
          setAccountList(accountList);
        }
      }

      if (isHeadAccount && parent === 0) {
        setParentLevel(20);
      } else if (!childAccountQuery) {
        setParentLevel(parent);
      }
    } else if (childAccountQuery && Boolean(accountsResponse?.pages?.[0]?.list?.length === 0)) {
      setAccountList([]);
    }
  }, [
    accountsResponse,
    childAccountQuery,
    constructChildList,
    injectChildCachedDefault,
    parentAccount,
    parentAccountList,
    childAccounts,
    data,
  ]);

  useEffect(() => {
    if (Boolean(parentAccountsResponse?.pages?.length && parentAccountsResponse?.pages?.[0]?.list?.length)) {
      const list = injectParentCachedDefault(constructParentList(parentAccountsResponse?.pages), parentAccount);
      setParentAccountList(list);
    } else if (parentAccountQuery && Boolean(parentAccountsResponse?.pages?.[0]?.list?.length === 0)) {
      setParentAccountList([]);
    }
  }, [parentAccountsResponse, parentAccountQuery, injectParentCachedDefault, constructParentList, parentAccount]);

  // Validation
  const validationConfig: any = {
    firstName: { required: true },
    lastName: { required: true },
    userLevel: {
      required: true,
    },
    role: {},
    childAccounts: { required: true },
    contactType: {},
    email: {
      required: true,
      validate: (val: any) => {
        if (!isEmail(val)) return "Invalid email";
      },
    },
  };
  if (hasEmailConfirmation) {
    validationConfig.emailConfirm = {
      validate: (val: any) => {
        if (!isEqual(val, data?.email)) return "Email does not match";
      },
    };
  }
  const { validationErrors, validateSingle, validateAll } = useValidation(validationConfig, { ...data, childAccounts });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name;
    const val = e.target.value;
    if (key === "userLevel") {
      setUserLevelValue(val);
      setChildAccounts([]);
      setParentLevel(0);
      setParentAccount("");
      setAccountList([]);
      setParentAccountList([]);
    }
    setData({ ...data, [key]: val });
    validateSingle(key, val);
  };

  const onParentAccountChange = (e: any) => {
    const val = e.target.value;
    if (val !== "search") {
      setChildAccounts([]);
      setAccountList([]);
      setParentAccount(val);
    }
  };

  const onAccountChange = (e: any) => {
    const val = e?.target.value;
    const accounts = val?.filter((e: any) => {
      return e !== "search";
    });
    if (accounts) {
      setChildAccounts(accounts);
      if (userLevelValue === 20 && accounts.length > 1) {
        // clear role for company level
        setData({ ...data, role: null });
      }
    } else {
      return;
    }
  };

  const onSubmit = () => {
    const errors = validateAll();
    if (errors.length <= 0) {
      const { emailConfirm, ...updatedUser } = data;
      handleSubmit({ ...updatedUser, permissions, accounts: childAccounts });
    }
  };

  const onCancel = () => handleCancel();

  const accountLabel = userLevelList?.find((level: any) => level.id === userLevelValue)?.label;

  const parentAccountLabel = userLevelsResponse?.find((level: any) => {
    return userLevelValue === 30 ? level.id === 20 : level.id === 30;
  })?.name;

  const contextHierarchy = state.contextHierarchy;

  const setDefaults = useCallback(() => {
    let parent, child;
    const contextUserLevel = Number(contextHierarchy?.level?.id);

    const removeContextMeta = (context: any) => {
      const old = { ...context };
      delete old?.parentContext;
      delete old?.lastParentId;
      return old;
    };

    if (contextUserLevel >= 10 && contextUserLevel <= 20) {
      parent = child = removeContextMeta(contextHierarchy);

      setUserLevelValue(contextUserLevel);
      setChildAccounts([parseChildListItem(child)]);
      setParentLevel(0);
      setParentAccount("");
      setAccountList([]);
    } else {
      parent = removeContextMeta(contextHierarchy?.parentContext);
      child = removeContextMeta(contextHierarchy);

      setUserLevelValue(contextUserLevel);
      setParentAccount(parseParentListItem(parent));
      setAccountList([]);
      setChildAccounts([parseChildListItem(child)]);
    }

    const userLevelKey = "userLevel";
    setData((data: any) => ({
      ...data,
      [userLevelKey]: contextUserLevel,
    }));
  }, [contextHierarchy, parseChildListItem, parseParentListItem]);

  useEffect(() => {
    if (newUser) {
      setDefaults();
    }
  }, [newUser, setDefaults]);

  const canShowRoleDropdown = Boolean(roleList?.length && childAccounts?.length);

  const MANAGE_CONTACT_CHECKED = permissions
    ?.filter((p: Permission) => p?.code === "ACCOUNT_MANAGE")
    ?.map((perm: Permission) => {
      const manageContactsPerm = perm?.permissions?.filter((childPerm: Permission) => childPerm?.code === "ACCOUNT_CONTACTS")[0]
        ?.value;
      return manageContactsPerm;
    });

  return (
    <>
      <Grid container data-cy="edit-user-form">
        <Grid item xs={12} md={4}>
          <Typography variant="h2" gutterBottom>
            User details
          </Typography>
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            className={classes.textField}
            name="email"
            label="Email / Username"
            type="email"
            value={data?.email || ""}
            variant="outlined"
            size="small"
            fullWidth
            error={Boolean(validationErrors.email)}
            helperText={validationErrors.email}
            onChange={onInputChange}
            disabled={Boolean(user?.email)}
          />
          {hasEmailConfirmation && (
            <TextField
              className={classes.textField}
              name="emailConfirm"
              label="Confirm Email / Username"
              type="email"
              value={data?.emailConfirm || ""}
              variant="outlined"
              size="small"
              fullWidth
              error={Boolean(validationErrors.emailConfirm)}
              helperText={validationErrors.emailConfirm}
              onChange={onInputChange}
            />
          )}
          <TextField
            className={classes.textField}
            name="firstName"
            label="First name"
            type="text"
            value={data?.firstName || ""}
            variant="outlined"
            size="small"
            fullWidth
            error={Boolean(validationErrors.firstName)}
            helperText={validationErrors.firstName}
            onChange={onInputChange}
          />
          <TextField
            className={classes.textField}
            name="lastName"
            label="Last name"
            type="text"
            value={data?.lastName || ""}
            variant="outlined"
            size="small"
            fullWidth
            error={Boolean(validationErrors.lastName)}
            helperText={validationErrors.lastName}
            onChange={onInputChange}
          />
          <SelectDropdownLegacy
            name="userLevel"
            label="User level"
            value={userLevelValue}
            data={userLevelList}
            ctrClass={classes.textField}
            onChange={onInputChange}
            error={Boolean(validationErrors.userLevel)}
            helperText={validationErrors.userLevel}
          />
          {parentLevel !== NO_PARENT_LEVEL && (
            <SelectDropdownLegacy
              name="parentAccount"
              label={parentAccountLabel}
              value={findMatchingById(parentAccount?.id, parentAccountList)?.value || ""}
              data={parentAccountList}
              selectedLevel={parentLevel || ""}
              searchOn={(q: any) => {
                setParentAccountQuery(q || undefined);
              }}
              searchList={parentAccountList}
              ctrClass={classes.textField}
              onChange={onParentAccountChange}
              nextPage={fetchParentAccountNextPage}
              hasNextPage={parentAccountNextPage}
              multiline
              infiniteScroll
              hasSearch
              hasNoOptionsText
            />
          )}
          {Boolean(
            accountList?.length ||
              parentAccount?.id ||
              childAccountQuery ||
              (accountList?.length === 0 && parentAccountList?.length === 0),
          ) && (
            <SelectDropdownLegacy
              name="childAccounts"
              label={accountLabel}
              value={
                childAccounts
                  ?.map((e: any) => {
                    const match = findMatchingById(e?.id, accountList)?.value;
                    if (match !== undefined) {
                      return match;
                    }

                    return null;
                  })
                  .filter(Boolean) || []
              }
              data={accountList || []}
              selectedLevel={data?.userLevel || ""}
              nextPage={fetchNextAccount}
              hasNextPage={accountNextPage}
              searchOn={(q: any) => setChildAccountQuery(q || undefined)}
              searchList={accountList || []}
              ctrClass={classes.textField}
              onChange={onAccountChange}
              error={Boolean(validationErrors.childAccounts)}
              helperText={validationErrors.childAccounts}
              multiline
              multiple={true}
              infiniteScroll
              hasSearch
              hasNoOptionsText
            />
          )}
          {childAccounts?.length >= 1 && (
            <div className={classes.chipWrap}>
              {childAccounts?.map((account: any) => {
                const accountChip = accountList?.map((list: any, index: number) => {
                  if (list?.id === account?.id) {
                    return <Chip key={list?.id || index} label={list?.label} variant="outlined" className={classes.chip} />;
                  } else {
                    return null;
                  }
                });
                return accountChip;
              })}
            </div>
          )}
          {canShowRoleDropdown && (
            <SelectDropdownLegacy
              name="role"
              label="Role"
              disabled={userLevelValue === 10 || (userLevelValue === 20 && childAccounts?.length > 1)}
              value={data?.role || ""}
              data={roleList}
              ctrClass={classes.textField}
              onChange={onInputChange}
              error={Boolean(validationErrors.role)}
              helperText={validationErrors.role}
            />
          )}
        </Grid>
      </Grid>
      <EditUserPermissionForm userId={user?.ssoId} roleId={data?.role} levelId={data?.userLevel} propagateData={setPermissions} />
      <SubmitButtons
        isSubmitting={isSubmitting}
        submitLabel={submitLabel}
        onSubmit={MANAGE_CONTACT_CHECKED[0] === true ? () => setSubmitWarning(true) : onSubmit}
        onCancel={onCancel}
      />
      <UIConfirm
        open={submitWarning}
        setOpen={setSubmitWarning}
        onConfirm={onSubmit}
        title="Are you sure you want to grant the Manage Contacts permission?"
      >
        <Alert severity="warning">
          Users with access to Manage Contacts can change all the authorised contacts on the account thereby taking full control
          of the account. This includes changing who is authorised to place orders and disconnect services.
        </Alert>
      </UIConfirm>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(5),
    },
    textField: {
      marginBottom: theme.spacing(3),
    },
    noAccountNotice: {
      marginLeft: theme.spacing(1.5),
      marginBottom: theme.spacing(3),
    },
    chipWrap: {
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(3),
    },
    chip: {
      margin: theme.spacing(0.5),
    },
  }),
);

export default EditUserForm;

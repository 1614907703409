import React, { ReactNode } from "react";
import { Alert } from "@mui/material";
import { Grid, Typography, TypographyProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CssBaseline from "@mui/material/CssBaseline";
import ToolbarMenu from "./components/ToolbarMenu";
import NavMenu from "./components/NavMenu";
import Footer from "./components/Footer";
import { useHasPermissions } from "../../permission/hooks";
import LoadingOverlay from "../components/LoadingOverlay";
import { useStore } from "../hooks";
import { useBrandName } from "../../brand/hooks";
import AccountSelector from "../../context/components/AccountSelector";
import { useHasAnyFeature } from "../../feature/hooks";
import { useHasAuthority, useIsHeadOrLoweUserLevel } from "../../user-level/hooks";
import UILoader from "../components/UILoader";
import { UserLevelFlag } from "../../user-level/types";

interface MainProps {
  children: ReactNode;
  title?: string | ReactNode;
  titleProps?: TypographyProps;
  contentProps?: any;
  isLoading?: boolean;
  accessPermission?: string | undefined | string[];
  featureFlag?: string | string[] | undefined;
  levelFlag?: UserLevelFlag;
  needSelectedAccount?: boolean;
  showNav?: boolean;
  showToolbar?: boolean;
  showFooter?: boolean;
  isModal?: boolean;
  accessGrantedCb?: Function;
}

function Main({
  title,
  titleProps,
  contentProps = {},
  isLoading,
  accessPermission = "",
  featureFlag,
  levelFlag,
  needSelectedAccount = false,
  showNav = true,
  showToolbar = true,
  showFooter = true,
  isModal = false,
  accessGrantedCb,
  children,
  ...rest
}: MainProps) {
  const { state } = useStore();
  const brandName = useBrandName();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const permissions =
    typeof accessPermission === "string" ? (accessPermission === "" ? [] : Array(accessPermission)) : accessPermission;
  const hasPermission = useHasPermissions(permissions, {
    refetchOnMount: true,
    staleTime: 60000,
  });
  const hasFeatureFlag = useHasAnyFeature(featureFlag);
  const hasFeature = featureFlag ? hasFeatureFlag : true;
  const hasLevel = useHasAuthority(levelFlag);

  React.useEffect(() => {
    document.title = `${brandName} ${typeof title === "string" ? "- " + title : ""}`;
  }, [brandName, title]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const isOnlyGroupSelected = Boolean(state.contextHierarchy?.id === state.contextHierarchy?.lastParentId);

  const isHeadOrLowerSelected = useIsHeadOrLoweUserLevel(!isOnlyGroupSelected && state.contextHierarchy);

  const showTitle = needSelectedAccount ? Boolean(title) && !isOnlyGroupSelected : Boolean(title);

  const renderContent = () => {
    if (hasPermission && hasFeature && needSelectedAccount && !isHeadOrLowerSelected) {
      return <AccountSelector />;
    }
    if (hasFeature && hasPermission && hasLevel) {
      setTimeout(() => {
        accessGrantedCb?.();
      });
      return children;
    }
    if (hasPermission === undefined) {
      return (
        <div className={classes.loader}>
          <UILoader />
        </div>
      );
    }

    return (
      <Grid item className={classes.error}>
        <Alert severity="info">You do not have permission to view this page.</Alert>
      </Grid>
    );
  };

  if (!state.contextHierarchy?.lastParentId) return <LoadingOverlay />;
  return (
    <div className={classes.root} {...rest}>
      <CssBaseline />
      {showNav && <NavMenu mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
      <div className={`${classes.ctr} ${isModal && classes.modal}`} data-cy="main-ctr">
        {showToolbar && <ToolbarMenu handleDrawerToggle={handleDrawerToggle} />}
        <main {...contentProps} className={`${classes.content} ${contentProps.className}`}>
          {showToolbar && <div className={classes.toolbar} />}
          <Grid container spacing={1}>
            {showTitle && (
              <Grid item xs={12} md={12}>
                <Typography variant="h1" className={classes.title} data-cy={"page-title"} {...titleProps}>
                  {title}
                </Typography>
              </Grid>
            )}
            {isLoading ? (
              <div className={classes.loader}>
                <UILoader />
              </div>
            ) : (
              <>{renderContent()}</>
            )}
          </Grid>
        </main>
        {showFooter && <Footer />}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    ctr: {
      backgroundColor: "#f0f4f8",
      flexGrow: 1,
      overflow: "hidden",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100vw",
      },
    },
    toolbar: theme.mixins.toolbar,
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      minHeight: "calc(100vh - 250px)",
      flex: 1,
    },
    content: {
      padding: theme.spacing(3),
      backgroundColor: "#ffffff",
      minHeight: "100vh",
    },
    title: {
      display: "flex",
      marginBottom: theme.spacing(1.5),
      justifyContent: "space-between",
    },
    error: {
      marginTop: theme.spacing(2),
    },
    modal: {
      "& main": {
        minHeight: 0,
      },
    },
  })
);

export default Main;

import React, { useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import DetailsCard from "./DetailsCard";
import DetailItem from "./DetailItem";
import { UIGrid } from "../../../_app/components";
import UITextInput from "../../../form/components/UITextInput";
import SelectDropdown, { Option } from "../../../form/components/SelectDropdown";
import { useSingleAsset, useUpdateTag } from "../../hooks";
import { useCostCentres, useCostCentresAssign } from "../../../account/hooks";
import { useHeadUserLevel } from "../../../user-level/hooks";
import { formatCostCentreCode, formatCostCentreOptions } from "../../../account/utils";
import { isObject } from "../../../_app/utils";
import { UNASSIGNED, UNASSIGNED_CODE } from "../../../account/api";
import MobileDetails from "./MobileDetails";

interface Props {
  showBars: () => void;
  hasBars: boolean;
}

export const Details = (props: Props) => {
  const { id } = useParams<{ id: string }>();
  const queryClient = useQueryClient();
  const { data: asset } = useSingleAsset(id);
  const accountLevel = useHeadUserLevel();

  // TAGS
  const [newTagValue, setNewTagValue] = useState(asset?.tag ?? "");
  const { mutate: executeUpdateTag, isLoading: isUpdatingTag } = useUpdateTag();
  const updateTag = () => executeUpdateTag({ assetId: id, tag: newTagValue });

  // COST CENTRES
  const { data: costCentres } = useCostCentres();
  const costCentreOptions = useMemo(() => formatCostCentreOptions(costCentres ?? []), [costCentres]);
  const assetCostCentreId =
    costCentreOptions?.find((cc) => formatCostCentreCode(cc.label) === formatCostCentreCode(asset?.costCentre))?.id ??
    UNASSIGNED_CODE;
  const [newCostCentreValue, setNewCostCentreValue] = useState(assetCostCentreId);
  const { mutate: executeUpdateCostCentre, isLoading: isUpdatingCostCentre } = useCostCentresAssign(undefined, {
    onSuccess: () => {
      queryClient.setQueryData(["asset", id], (old: any) => {
        return {
          ...(isObject(old) ? old : {}),
          costCentre: costCentreOptions.find((cc) => cc.id === newCostCentreValue)?.label,
        };
      });
    },
  });
  const updateCostCentre = () => {
    const cc = costCentreOptions?.find((cc) => cc.id === newCostCentreValue);
    if (cc) executeUpdateCostCentre({ assetIds: [id], costCentreIds: [cc.id] });
  };

  if (asset) {
    return (
      <UIGrid container spacing={2}>
        <DetailsCard title="Basic Details">
          <>
            <DetailItem title="CLI/Identifier" value={asset.cli} />
            <DetailItem
              title="Tag"
              value={asset.tag}
              editable
              isLoading={isUpdatingTag}
              onUpdate={updateTag}
              onCancel={() => setNewTagValue(asset.tag ?? "")}
            >
              <UITextInput title="Tag" hideLabel value={newTagValue} onChange={setNewTagValue} />
            </DetailItem>
            <DetailItem
              title="Cost Centre"
              value={asset.costCentre ?? UNASSIGNED}
              editable
              isLoading={isUpdatingCostCentre}
              onUpdate={updateCostCentre}
              onCancel={() => setNewCostCentreValue(assetCostCentreId)}
            >
              <SelectDropdown
                name="cost-centre-field"
                value={costCentreOptions?.find((cc: Option) => cc.id === newCostCentreValue)?.id}
                data={costCentreOptions}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewCostCentreValue(e.target.value)}
              />
            </DetailItem>
            <DetailItem title="Product Type" value={asset?.productType} />
            <DetailItem title={`${accountLevel?.name || "Account"} Number`} value={asset.accountCode} />
            <DetailItem title="Status" value={asset?.status || "N/A"} />
          </>
        </DetailsCard>
        {asset?.assetType === "MOBILE" && <MobileDetails asset={asset} {...props}></MobileDetails>}
      </UIGrid>
    );
  } else return null;
};

export default Details;

import React from "react";
import { Button, ButtonProps, CircularProgress, IconButton } from "@mui/material";

interface UIButtonProps extends ButtonProps {
  isIconButton?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
}

export const UIButton = ({ isIconButton = false, isLoading = false, children, ...props }: UIButtonProps) => {
  if (isIconButton) return <IconButton {...props}>{children}</IconButton>;
  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={isLoading ? <CircularProgress color="inherit" size={18} /> : undefined}
      {...props}
    >
      {children}
    </Button>
  );
};

export default UIButton;

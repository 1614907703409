import { unassignedCostCentreModel } from "./api";
import { CostCentre, FilterCostCentresResponse } from "./types";

export const formatCostCentreCode = (code?: string) => {
  if (code === unassignedCostCentreModel.code) {
    return unassignedCostCentreModel.name;
  } else if (code) {
    return code?.replaceAll?.(";", " | ");
  } else {
    return "";
  }
};

export const formatCostCentreOptions = (costCentres: CostCentre[]) => {
  const flattenFunc = (acc: any, cc: CostCentre) => {
    let options = [
      ...acc,
      {
        id: cc?.id,
        label: formatCostCentreCode(cc?.code),
        value: cc?.id,
      },
    ];
    if (cc?.subCostCentres?.length) {
      options = cc?.subCostCentres?.reduce(flattenFunc, options);
    }
    return options;
  };
  return [...flattenFunc([], unassignedCostCentreModel), ...(costCentres?.reduce(flattenFunc, []) || [])];
};

export const formatCCSearchResults = (searchResult?: FilterCostCentresResponse) => {
  const formatted: CostCentre[] = [];
  if (!searchResult) return formatted;
  if (searchResult.assetsWithoutCostCentre?.length)
    formatted.push({ ...unassignedCostCentreModel, assets: searchResult.assetsWithoutCostCentre });
  if (searchResult.costCentresWithAssets) formatted.push(...searchResult.costCentresWithAssets);
  return formatted;
};

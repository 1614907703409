import React from "react";
import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import { Button } from "@mui/material";
import { useHistory, useLocation } from "react-router-dom";

export interface Props {
  label?: string;
  btnProps?: any;
}

export const UIBack = ({ label, btnProps }: Props) => {
  const history = useHistory();
  const location = useLocation<any>();
  const text = label ?? "Back" + (location.state?.from ? ` To ${location.state?.from}` : "");
  return (
    <Button
      variant="text"
      color="primary"
      onClick={() => {
        history.goBack();
      }}
      {...btnProps}
    >
      <ArrowBackIos fontSize="small" /> {text}
    </Button>
  );
};

export default UIBack;

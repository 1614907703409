import React from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";

interface Props {
  selected: string;
  setRange: (range: string) => void;
}

function RangeSelector({ selected, setRange }: Props) {
  const classes = useStyles();
  const dashboardDateRanges = [
    {
      id: "24h",
      label: "Last 24 hours",
    },
    {
      id: "1wk",
      label: "This Week",
    },
    {
      id: "1mo",
      label: "This Month",
    },
    {
      id: "1yr",
      label: "This Year",
    },
  ];
  return (
    <ButtonGroup className={classes.group}>
      {dashboardDateRanges.map((range) => {
        return (
          <Button
            className={selected === range.id ? classes.button : ""}
            key={range.id}
            onClick={() => setRange(range.id)}
            variant={selected === range.id ? "contained" : "outlined"}
          >
            {range.label}
          </Button>
        );
      })}
    </ButtonGroup>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  group: {
    backgroundColor: theme.palette.common.white,
  },
  button: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
    },
    "&:active": {
      boxShadow: "none",
    },
  },
}));

export default RangeSelector;

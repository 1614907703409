import React from "react";
import { List, ListItem, ListItemText, Collapse } from "@mui/material";
import { Theme, lighten } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { ArticleTopic } from "../types";

interface MainProps {
  topic: ArticleTopic;
  handleClick: (id: ArticleTopic["articleId"]) => void;
  selectedArticleId?: string;
}
const HelpNavMenu = ({ topic, selectedArticleId, handleClick }: MainProps) => {
  const classes = useStyles();
  const subTopicLength = topic?.subTopics?.length;

  const [openTopic, setOpenTopic] = React.useState(false);

  const onClick = () => {
    handleClick(topic.articleId);
    setOpenTopic(!openTopic);
  };

  const selected = selectedArticleId === topic.articleId;
  const hasSubTopics = subTopicLength !== 0;
  const expand = openTopic && hasSubTopics;

  if (topic) {
    return (
      <>
        <ListItem button onClick={onClick} data-cy={`nav-help-article-${topic.articleId}`} dense>
          <ListItemText primary={topic.name} className={selected && !hasSubTopics ? classes.selected : ""} />
          {expand ? (
            <ExpandMore className={classes.moreIcon} />
          ) : !hasSubTopics ? (
            <></>
          ) : (
            <ChevronRightIcon className={classes.moreIcon} />
          )}
        </ListItem>
        {subTopicLength !== 0 && (
          <Collapse in={openTopic} timeout="auto" unmountOnExit>
            <List component="div" disablePadding className={classes.subList}>
              {topic?.subTopics?.map((subTopic: ArticleTopic, i: any) => (
                <HelpNavMenu
                  key={subTopic.articleId}
                  topic={subTopic}
                  handleClick={handleClick}
                  selectedArticleId={selectedArticleId}
                />
              ))}
            </List>
          </Collapse>
        )}
      </>
    );
  }

  return <></>;
};

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    selected: {
      "& > span": {
        color: theme.palette.primary.main,
        fontWeight: 600,
      },
    },
    icon: {
      marginRight: "20px",
      width: "20px",
      height: "20px",
    },
    moreIcon: {
      width: "18px",
      height: "18px",
    },
    subList: {
      backgroundColor: lighten(theme.palette.grey[100], 0.5),
    },
  })
);

export default HelpNavMenu;

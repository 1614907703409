import React from "react";
import { Box, FormControlLabel, Switch } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

interface Props {
  addSelection: (value?: string) => void;
  value: string | null;
  defaultValue: string;
  label: string;
}

export default function BooleanFilter({ addSelection, value, defaultValue, label }: Props) {
  const classes = useStyles();

  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    addSelection(event.target.checked.toString());
  };

  return (
    <FormControlLabel
      className={classes.formControlLabel}
      control={
        <Switch
          name="booleanFilter"
          size="small"
          checked={JSON.parse(value ?? defaultValue)}
          onChange={handleInput}
          color="primary"
        />
      }
      label={<Box className={classes.label}>{label}</Box>}
      labelPlacement="start"
    />
  );
}

const useStyles = makeStyles(() =>
  createStyles({
    formControlLabel: {
      margin: 0,
    },
    label: {
      fontWeight: 600,
      fontSize: "0.95rem",
    },
  })
);

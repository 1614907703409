export const GRAPH_COLORS = [
  "#1E88E5",
  "#43A047",
  "#FFB300",
  "#FB8C00",
  "#E53935",
  "#8E42AA",
  "#FF69B4",
  "#87CEEB",
  "#808000",
  "#000080",
  "#B22222",
  "#00FFFF",
];

export const CHARGE_FREQUENCY = {
  "0": "One off",
  "1": "Monthly",
  "3": "Quarterly",
  "12": "Annual",
};

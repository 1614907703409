import React, { ReactNode } from "react";
import { Grid, Card } from "@mui/material";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

interface ContactCardProps {
  icon: ReactNode;
  children: ReactNode;
}

const ContactCard = ({ icon, children }: ContactCardProps) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} lg={4}>
      <div className={classes.circleWrap}>
        <div className={classes.circle}>{icon}</div>
      </div>
      <Card variant="outlined" className={classes.info}>
        {children}
      </Card>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    circleWrap: {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      position: "relative",
      top: "35px",
    },
    circle: {
      width: "70px",
      height: "70px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "35px",
      border: `1px solid ${theme.palette.grey[200]}`,
      backgroundColor: "white",
    },
    info: {
      height: "200px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

export default ContactCard;

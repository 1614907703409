export enum UserLevelFlag {
  Head = "HEAD",
  Sub = "SUB",
  Individual = "INDIVIDUAL",
}
export interface UserLevel {
  id: number;
  name: string;
  flag: UserLevelFlag;
}

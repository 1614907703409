import { humanize } from "../_app/utils/format";
import { formatCostCentreCode } from "../account/utils";
import { getInvoiceNumbers } from "../billing/api";
import { UNBILLED, formatPeriodToMonthYear, mapInvoiceNumber } from "../billing/utils";
import { Filter } from "./types";

export const filtersIdMap = {
  ACCOUNT: "1",
  USAGE_TYPE: "2",
  COST_CENTRE: "3",
  DATA_RANGE: "4",
  BILL_PERIOD: "5",
  SERVICES_BILL_PERIOD: "21",
  SERVICES_ACCOUNT: "22",
  CALL_DURATION: "6",
  CLI: "7",
  DIALED_NUMBERS: "8",
  EXCLUDED_NUMBERS: "9",
  COST: "10",
  PRODUCT_TYPE: "11",
  STATUS: "420",
  ASSETS_COST_CENTRE: "44",
};

export const MIN_FILTERS_COUNT = 5;

export const formatMultipleBillPeriods = (values: string) => {
  const periods: any = values.split(",");
  const unbilledPeriods = periods.find((period: string) => period === UNBILLED);
  const billedPeriods = periods.filter((period: string) => period !== UNBILLED).map((p: string) => formatPeriodToMonthYear(p));

  return [...(unbilledPeriods?.length ? ["Unbilled"] : []), ...billedPeriods].join(", ");
};

export const addFormatFunctionToFilters = async (filters: Filter[]) => {
  filters.forEach(async (filter, index) => {
    filter.showToggle = index < MIN_FILTERS_COUNT;
    if (!filter.valueDisplayFunc) {
      if (filter.id === "61" || filter.id === "63") {
        filter.valueDisplayFunc = humanize;
      } else if (filter.id === "3" || filter.id === "23" || filter.id === "34" || filter.id === "44" || filter.id === "54") {
        filter.valueDisplayFunc = formatCostCentreCode;
      } else if (filter.id === "12" || filter.id === "28") {
        filter.valueDisplayFunc = mapInvoiceNumber;
        const invoiceNumbers = await getInvoiceNumbers();
        localStorage.setItem("INVOICE_NUMBERS", JSON.stringify(invoiceNumbers));
      } else if (filter.name === "Bill Period" && filter.type !== "PERIOD_RANGE") {
        filter.valueDisplayFunc = formatPeriodToMonthYear;
      }
    }
  });
  return filters;
};

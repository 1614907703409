import React from "react";
import { Button, Grid, Typography } from "@mui/material";
import SwitchAccountsDialog from "../../user-level/components/SwitchAccountsDialog";
import { useUserLevelMap } from "../../user-level/hooks";

function AccountSelector() {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const userLevels = useUserLevelMap();
  const userLevelName = userLevels?.[30]?.name?.toLowerCase();

  const goToSwitchAccounts = () => {
    setIsDialogOpen(true);
  };

  const isVowel = (c: string) => {
    return ["a", "e", "i", "o", "u"].indexOf(c?.[0]?.toLowerCase()) !== -1;
  };

  return (
    <Grid container direction="column" justifyContent="center" alignItems="center" style={{ height: "580px" }}>
      <Typography variant="h2" gutterBottom>
        Please select {isVowel(userLevelName) ? "an" : "a"} {userLevelName}
      </Typography>

      <Button variant="contained" color="primary" onClick={goToSwitchAccounts} data-cy="select_account_button">
        Select {userLevels?.[30]?.name}
      </Button>
      <SwitchAccountsDialog title={userLevels?.[10]?.name + "s"} open={isDialogOpen} setOpen={setIsDialogOpen} />
    </Grid>
  );
}

export default AccountSelector;

import React from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Bar from "react-chartjs-2";
import { useDataUser } from "../../hooks";
import GraphWidget from "./GraphWidget";
import { Query } from "../../../filter/types";
import { useHasFeature } from "../../../feature/hooks";
import { featureFlagsMap } from "../../../feature/utils";

const featureFlag = featureFlagsMap.DATA_USERS_GRAPH;
const options = {
  type: "bar",
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
      position: "bottom",
      labels: {
        filter: () => {},
      },
      title: {
        text: "Number and users",
        display: true,
      },
    },
    title: {
      display: false,
    },
    tooltip: {
      position: "nearest",
      backgroundColor: "#ffffff",
      titleColor: "#000000",
      bodyColor: "#000000",
      displayColors: false,
      padding: 15,
      borderColor: "#000000",
      caretSize: 0,
      borderWidth: 1,
      callbacks: {
        label: function (context: any) {
          var label = context?.dataset?.label || "Total Data Usage";
          if (context.parsed.y !== null) {
            label += context.parsed.y;
          }
          return label;
        },
        title: function (context: any) {
          var title = context?.[0]?.raw?.name;
          var number = context?.[0]?.raw?.number;

          return ["Name: " + title, "Number: " + number];
        },
      },
    },
  },
  parsing: {
    xAxisKey: "label",
    yAxisKey: "values.data",
  },
  skipNull: true,
  interaction: {
    mode: "point",
  },
  scales: {
    x: {
      ticks: {
        display: false,
      },
    },
  },
};

interface Props {
  queries: Query[];
}

const TopDataUser = ({ queries }: Props) => {
  const classes = useStyles();
  const {
    data = [],
    isFetching,
    isError,
  } = useDataUser(queries, {
    enabled: useHasFeature(featureFlag),
  });
  const hasError = isError || data?.length <= 0;

  return (
    <GraphWidget title="Top 10 Data Users (GBs)" isLoading={isFetching} hasError={hasError} featureFlag={featureFlag}>
      <div className={classes.horizontalBarWrap}>
        <Bar data={data} options={options} type="bar" />
      </div>
    </GraphWidget>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  horizontalBarWrap: {
    position: "relative",
    minHeight: 410,
    width: "100%",
  },
}));

export default TopDataUser;

import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { ToggleButtonGroupProps } from "@mui/lab";

export interface Props extends ToggleButtonGroupProps {
  options: { label: string; value: string }[];
  buttonProps?: any;
  fullWidth?: boolean;
}

export const ToggleButtons = ({ options = [], value, onChange, buttonProps, fullWidth }: Props) => {
  const classes = useStyles();
  return (
    <ToggleButtonGroup
      exclusive
      onChange={onChange}
      size="small"
      value={value}
      className={fullWidth ? classes.fullWidth : undefined}
    >
      {options.map((option) => {
        return (
          <ToggleButton key={option.value} value={option.value} className={classes.toggleBtn} {...buttonProps}>
            {option.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fullWidth: {
      width: "100%",
    },
    toggleBtn: {
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
  })
);

export default ToggleButtons;

import React from "react";
import Public from "../layouts/Public";
import { useHistory } from "react-router-dom";
import { Button, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";

export const Page404 = () => {
  const history = useHistory();
  const classes = useStyles();

  return (
    <Public className={classes.ctr}>
      <EmojiPeopleIcon color="primary" className={classes.icon} />
      <Typography variant="h1" gutterBottom color="secondary" fontWeight={900}>
        Oops!
      </Typography>
      <Typography variant="h3" gutterBottom align="center">
        The page you were looking for was not found
      </Typography>
      <Button variant="contained" color="secondary" onClick={() => history.push("/")} startIcon={<ArrowBackIcon />}>
        Go Back Home
      </Button>
    </Public>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      padding: theme.spacing(4),
    },
    icon: {
      fontSize: 150,
    },
  })
);

export default Page404;

import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import NavWidget from "./NavWidget";

interface Props {
  icon: any;
  title: string;
  description?: string;
  link: string;
}

const SimpleNavWidget = ({ icon, title, description, link, ...props }: Props) => {
  const classes = useStyles();

  return (
    <NavWidget icon={icon} title={title} link={link} {...props}>
      <Typography className={classes.desc}>{description}</Typography>
    </NavWidget>
  );
};

const useStyles = makeStyles((theme) => ({
  desc: {
    display: "-webkit-box",
    overflow: "hidden",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    textAlign: "center",
    marginTop: theme.spacing(2),
  },
}));

export default SimpleNavWidget;

import api from "../_app/api";
import { UserLevel } from "./types";

export const getAllUserLevels = async () => {
  const { data }: { data: UserLevel[] } = await api({
    method: "GET",
    url: "/user-levels",
    context_injection: true,
  });
  window.userLevels = data;
  return data;
};

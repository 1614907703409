import api from "../_app/api";

export interface GetPermissionsParams {
  userId?: string;
  roleId?: string;
  levelId?: string;
}

export interface Permission {
  readonly id?: string;
  name: string;
  code: string;
  value: boolean | string;
  disabled?: boolean;
  disabledReason: DisabledReason | null;
  groupName: string | null;
  permissions?: Permission[] | null;
}

export enum DisabledReason {
  ByRole = "BY_ROLE",
  ByLevel = "BY_LEVEL",
}

export const getPermissions = async (params: GetPermissionsParams) => {
  const { data }: { data: Permission[] } = await api({
    method: "GET",
    url: "/permissions",
    context_injection: true,
    params,
  });
  const parsed = data?.map(parsePermissions);
  return parsed;
};

export const getMyPermissions = async () => {
  const { data }: { data: Permission[] } = await api({
    method: "GET",
    url: "/permissions/my",
  });
  return data?.map(parsePermissions);
};

export const parsePermissions = (p: Permission) => {
  if (Boolean(p?.value)) p.value = p?.value === "true";
  if (p?.permissions?.length) p.permissions = p?.permissions?.map(parsePermissions);
  return p;
};

export const parsePermissionsForPost = (perms?: Permission[]) => {
  return perms?.map((p) => {
    const { disabled, ...copy } = p;
    const parsedChildren = p?.permissions?.map((p) => {
      const { disabled, ...copy } = p;
      return copy;
    });
    return {
      ...copy,
      permissions: parsedChildren,
    };
  });
};

import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useHistory, useLocation } from "react-router-dom";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableState } from "mui-datatables";
import Main from "../../_app/layouts/Main";
import { permissionCodes } from "../../permission/hooks";
import { featureFlagsMap } from "../../feature/utils";
import { useAutoSwitchResults } from "../hooks";
import { LinearProgress, Link } from "@mui/material";
import { Skeleton } from "@mui/material";
import StatusChip from "../../_app/components/StatusChip";
import { formatCurrency, formatDate } from "../../_app/utils/format";
import NA from "../../_app/components/NA";
import { AutoSwitchResult } from "../types";

export const SimSwitchResults = () => {
  const classes = useStyles();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [isComplete, setIsComplete] = useState(false);
  const history = useHistory();
  const location = useLocation<{ requestId: string }>();

  const { data = { list: [], page, total: 0, pageSize: rowCount } } = useAutoSwitchResults(
    location.state?.requestId,
    page,
    rowCount,
    {
      refetchInterval: !isComplete ? 2000 : false,
    }
  );

  const hasExistingCode = (item: AutoSwitchResult) => {
    return item.status === "FAILED" && item.reason === "There is an existing code for this number";
  };

  const columns: MUIDataTableColumnDef[] = [
    "Number",
    {
      name: `${data.list?.[0]?.autoSwitchType || ""} Code`,
      options: {
        display: data.list?.[0]?.autoSwitchType !== "ETF" ? "true" : "false",
      },
    },
    "Expiry Date",
    "Early Termination Fee",
    "Status",
    {
      name: "Reason",
      options: {
        display: data.list.find(hasExistingCode) ? "true" : "false",
      },
    },
  ];
  const placeholder = (item: AutoSwitchResult) => {
    return item.status === "PENDING" ? <Skeleton /> : <NA />;
  };
  const parsed =
    data.list.map((item) => [
      item.cli || placeholder(item),
      item.code || placeholder(item),
      formatDate(item.expiryDate, "dd/MM/yyyy") || placeholder(item),
      item.earlyTermination !== null ? formatCurrency(item.earlyTermination) : placeholder(item),
      <StatusChip
        status={item.status || undefined}
        {...(item.status === "FAILED" ? { href: "/sim-disconnect", component: "a", clickable: true } : {})}
      />,
      hasExistingCode(item) ? (
        <>
          There is an existing code for this number, you can find it on the{" "}
          <Link onClick={() => history.push("/sim-disconnect")} className={classes.link}>
            disconnection
          </Link>{" "}
          page
        </>
      ) : (
        <NA />
      ),
    ]) || [];

  useEffect(() => {
    function hasIncompleteResult() {
      return Boolean(
        data.list.find((item: AutoSwitchResult) => {
          return item.status === "PENDING";
        })
      );
    }
    setIsComplete(!hasIncompleteResult());
  }, [data]);

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setPage(0);
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  return (
    <Main
      title="Auto Switch - Confirmation"
      data-cy="auto-switch-conf-page"
      featureFlag={featureFlagsMap.AUTO_SWITCH}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      isLoading={!Boolean(data.list[0])}
      showNav={false}
      showToolbar={false}
      showFooter={false}
    >
      {!isComplete && (
        <div className={classes.loader}>
          <LinearProgress color="primary" />
        </div>
      )}
      <div data-cy="results-table" className={classes.tableCtr}>
        <MUIDataTable
          title={""}
          data={parsed}
          columns={columns}
          options={{
            onTableChange: handleTableChange,
            pagination: true,
            page,
            rowsPerPage: rowCount,
            rowsPerPageOptions: rowCountOptions,
            count: data?.total,
            download: false,
            elevation: 1,
            print: false,
            responsive: "standard",
            selectToolbarPlacement: "none",
            filter: false,
            sort: false,
            search: false,
            serverSide: true,
            selectableRows: "none",
            rowHover: true,
            setTableProps: () => ({ size: "small" }),
            viewColumns: false,
            setRowProps: (row) => {
              return {
                "data-cy": `row-id-${row?.[1]}`,
              };
            },
          }}
        />
      </div>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableCtr: {
      width: "100%",
      height: "calc(100vh - 108px)",
    },
    loader: {
      [theme.breakpoints.up("sm")]: {
        width: "calc(100% - 40px)",
        position: "absolute",
        top: "120px",
        zIndex: 101,
      },
      width: "100%",
    },
    link: {
      cursor: "pointer",
    },
  })
);

export default SimSwitchResults;

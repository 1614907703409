import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Main from "../../_app/layouts/Main";
import EditRoleForm from "../components/EditRoleForm";
import { useRoleUpdate, useSingleRole } from "../hooks";
import { permissionCodes, usePermissions } from "../../permission/hooks";
import { Role } from "../types";

export const EditRole = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();

  const { data: role, isFetching: roleFetching, isSuccess: roleSuccess } = useSingleRole(id);
  const { data: permissions, isLoading: permsFetching } = usePermissions(undefined, id);
  const { mutate: executeRoleUpdate, isLoading: isUpdating } = useRoleUpdate({
    onSuccess: () => {
      history.push("/roles");
    }
  });

  const isLoading = roleFetching || permsFetching;
  const onSubmit = (data: Role) => executeRoleUpdate(data);

  return (
    <Main title="Edit Role" data-cy="edit-role-page" accessPermission={permissionCodes.USERS_MANAGE} isLoading={isLoading}>
      {roleSuccess && (
        <Card elevation={1} className={classes.card}>
          <Grid container>
            <EditRoleForm
              role={{ ...role, permissions }}
              handleSubmit={onSubmit}
              submitLabel="Save Changes"
              handleCancel={() => history.goBack()}
              isSubmitting={isUpdating}
            />
          </Grid>
        </Card>
      )}
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(5),
      width: "100%"
    }
  })
);

export default EditRole;

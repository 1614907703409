import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import MUIDataTable, { MUIDataTableState } from "mui-datatables";
import { createStyles, makeStyles, UIAlert, UIBox, UIButton, UILoader, UITheme } from "../../_app/components";
import { rowParser } from "../../_app/components/Table/helpers";
import { useAssetsByCostCentre } from "../../asset/hooks";
import { Asset } from "../../asset/types";

interface Props {
  costCentre: string;
  shouldFetchData: boolean;
  assets?: Asset[];
}

export const CliTable = ({ costCentre, shouldFetchData, assets }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const rowCountOptions = [10, 25, 50];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);

  const { data, isLoading } = useAssetsByCostCentre(costCentre || "unassigned", page, rowCount, { enabled: shouldFetchData });

  const assetData = assets ?? data?.list;
  const hasPagination = Boolean(!assets && data?.list.length); // don't paginate if assets come in as an array via props (search results)

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  const parsed = useMemo(
    () =>
      assetData?.map((item: Asset) => {
        return rowParser([
          item?.cli,
          item?.tag,
          <UIBox className={classes.viewButton}>
            <UIButton
              color="primary"
              variant="text"
              size="small"
              onClick={() => history.push(`/manage-cost-centres/${item.cli}/${item.id}`)}
            >
              View details
            </UIButton>
          </UIBox>,
        ]);
      }) || [],
    [assetData]
  );

  if (isLoading) {
    return (
      <div className={classes.loader}>
        <UILoader />
      </div>
    );
  }
  return !parsed.length ? (
    <UIBox pr={2}>
      <UIAlert severity="info">No CLIs found.</UIAlert>
    </UIBox>
  ) : (
    <MUIDataTable
      title=""
      data={parsed}
      columns={["CLI/Identifier", "Tag", ""]}
      options={{
        onTableChange: handleTableChange,
        pagination: hasPagination,
        page,
        rowsPerPage: rowCount,
        rowsPerPageOptions: rowCountOptions,
        count: assets?.length ?? data?.total,
        download: false,
        elevation: 1,
        print: false,
        responsive: "standard",
        selectToolbarPlacement: "none",
        filter: false,
        serverSide: true,
        viewColumns: false,
        sort: false,
        search: false,
        selectableRows: "none",
        rowHover: true,
        setTableProps: () => ({ size: "small" }),
      }}
    />
  );
};

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      paddingTop: theme.spacing(2),
    },
    viewButton: {
      width: "100%",
      textAlign: "end",
      paddingRight: theme.spacing(2),
    },
  })
);

export default CliTable;

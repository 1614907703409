import React, { useState } from "react";
import Main from "../../_app/layouts/Main";
import { useHistory } from "react-router-dom";
import { Theme, Grid, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import ServiceRequests from "../../dashboard/widgets/ServiceRequests";
import AddIcon from "@mui/icons-material/Add";
import RangeSelector from "../components/RangeSelector";
import { permissionCodes } from "../../permission/hooks";
import { featureFlagsMap } from "../../feature/utils";

export const Support = () => {
  const classes = useStyles();
  const history = useHistory();
  const [range, setRange] = useState("1mo");

  return (
    <Main
      title="Support"
      titleProps={{ style: { marginBottom: "-25px" } }}
      data-cy="support-page"
      featureFlag={featureFlagsMap.SUPPORT + "_HIDDEN"}
      accessPermission={permissionCodes.SUPPORT_ACCESS}
    >
      <Grid container direction="row" spacing={2} className={classes.ctr}>
        <Grid item sm={12} className={classes.actionBar}>
          <RangeSelector selected={range} setRange={setRange} />
          <Button
            variant="contained"
            color="primary"
            data-cy="add-button"
            startIcon={<AddIcon />}
            onClick={() => history.push("/tickets/new")}
          >
            New Ticket
          </Button>
        </Grid>
        <Grid item sm={12} md={6} className={classes.fullWidth}>
          <ServiceRequests />
        </Grid>
        <Grid item sm={12} md={6} className={classes.fullWidth}>
          <ServiceRequests title="Incidents" />
        </Grid>
      </Grid>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {},
    actionBar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-end",
      padding: `0px ${theme.spacing(2)} !important`,
      gap: theme.spacing(2),
    },
    fullWidth: {
      width: "100%",
    },
  })
);

export default Support;

import React, { useState } from "react";
import { Theme, Button, Grid } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Role } from "../types";
import UIConfirm from "../../_app/components/UIConfirm";
import DeleteIcon from "@mui/icons-material/DeleteOutline";

interface Props {
  role: Role;
  deleteRole(user: Role): void;
}

const ManageRolesDeleteButton = ({ role, deleteRole }: Props) => {
  const classes = useStyles();
  const [warning, setWarning] = useState(false);

  return (
    <Grid className={classes.buttonWrap}>
      <Button variant="text" onClick={() => setWarning(true)} startIcon={<DeleteIcon />} color="error">
        Delete
      </Button>
      <UIConfirm
        open={warning}
        setOpen={setWarning}
        onConfirm={() => {
          deleteRole(role);
        }}
      >
        Are you sure you want to delete <b>{role?.name}</b> ?
      </UIConfirm>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonWrap: {
      textAlign: "left",
    },
  })
);

export default ManageRolesDeleteButton;

import { formatCostCentreCode } from "../account/utils";
import { Filter } from "../filter/types";

export const assetFilters = [
  {
    id: "41",
    name: "Account",
    type: "IDENTIFIER",
    resources: "/filters/ASSETS/values/accounts",
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "42",
    name: "CLI/Identifier",
    type: "IDENTIFIER",
    resources: "/filters/ASSETS/values/clis",
    values: [],
    options: {
      BULK_INPUT: true,
      SEARCH_BAR: true,
    },
    showToggle: true,
  },
  {
    id: "43",
    name: "Tag",
    options: {
      BULK_INPUT: false,
      SEARCH_BAR: true,
    },
    resources: "/assets/tags",
    type: "IDENTIFIER",
    values: [],
    showToggle: true,
  },
  {
    id: "44",
    name: "Cost Centre",
    type: "IDENTIFIER",
    resources: "/filters/ASSETS/values/costcentres",
    values: [],
    options: {
      UNASSIGNED: true,
    },
    showToggle: true,
    valueDisplayFunc: formatCostCentreCode,
  },
  {
    id: "420",
    name: "Status",
    options: {},
    type: "BOOLEAN",
    values: [],
    showToggle: true,
    defaultValue: "true",
    label: "Live Only",
    disableDelete: true,
  },
] as Filter[];

export const mobileManagementFilters = [
  {
    id: "31",
    name: "Account",
    type: "IDENTIFIER",
    resources: "/filters/MOBILE_NUMBERS/values/accounts",
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "32",
    name: "CLI/Identifier",
    type: "IDENTIFIER",
    resources: "/filters/MOBILE_NUMBERS/values/clis",
    values: [],
    options: {
      BULK_INPUT: true,
      SEARCH_BAR: true,
    },
    showToggle: true,
  },
  {
    id: "33",
    name: "Tag",
    type: "IDENTIFIER",
    options: {
      BULK_INPUT: false,
      SEARCH_BAR: true,
    },
    resources: "/assets/tags",
    values: [],
    showToggle: true,
  },
  {
    id: "34",
    name: "Cost Centre",
    type: "IDENTIFIER",
    resources: "/filters/MOBILE_NUMBERS/values/costcentres",
    values: [],
    options: {
      UNASSIGNED: true,
    },
    showToggle: true,
    valueDisplayFunc: formatCostCentreCode,
  },
] as Filter[];

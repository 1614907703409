import React, { ChangeEvent, useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Filter } from "../../types";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FilterBox from "./FilterBox";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
}

export default function AmountFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  ...props
}: Props) {
  const classes = useStyles();
  const val1 = selection?.[0]?.split(" ");
  const val2 = selection?.[1]?.split(" ");
  const [value, setValue] = useState(val1 ? val1?.[0] : "none");
  const [inputValue, setInputValue] = useState(val1 ? val1?.[1] : "");
  const [criteriaValue, setCriteriaValue] = useState(val2 ? val2?.[0] : "none");
  const [inputCriteriaValue, setInputCriteriaValue] = useState(val2 ? val2?.[1] : "");

  const [criteriaShow, setCriteria] = useState(!!val2);

  useEffect(() => {
    const firstConditionValues = [value, inputValue];
    const secondConditionValues = [criteriaValue, inputCriteriaValue];

    const firstCondition = firstConditionValues?.join(" ");
    const secondCondition = secondConditionValues?.join(" ");

    const result = [
      ...(firstCondition !== "none " ? [firstCondition] : []),
      ...(secondCondition !== "none " ? [secondCondition] : []),
    ];
    setSelection(result);
  }, [setSelection, value, inputValue, criteriaValue, inputCriteriaValue]);

  const addCriteria = () => {
    if (criteriaShow) {
      setCriteriaValue("none");
      setInputCriteriaValue("");
      setCriteria((oldCriteria) => !oldCriteria);
    } else {
      setCriteria((oldCriteria) => !oldCriteria);
    }
  };

  const handleChange = (event: SelectChangeEvent<any>) => {
    setValue(event.target.value as string);
  };

  const handleCriteriaChange = (event: SelectChangeEvent<any>) => {
    setCriteriaValue(event.target.value as string);
  };

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const validatedValue = value.replace(/[^0-9.]/g, "");
    setInputValue(validatedValue);
  };

  const handleCriteriaInput = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const validatedValue = value.replace(/[^0-9.]/g, "");
    setInputCriteriaValue(validatedValue);
  };

  useEffect(() => {
    if (value === "=") {
      setCriteria(false);
      setCriteriaValue("none");
      setInputCriteriaValue("");
    }
  }, [setCriteria, value]);

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
        disableApply={
          selection.length < 1 ||
          value === "none" ||
          (value !== "none" && inputValue === "") ||
          (criteriaValue === "none" && inputCriteriaValue !== "") ||
          (criteriaValue !== "none" && inputCriteriaValue === "")
        }
      >
        <div>
          <FormControl variant="outlined" size="small" className={classes.formSelect}>
            <Select
              native
              value={value}
              onChange={handleChange}
              inputProps={{
                name: "value",
                id: "value",
              }}
            >
              <option value="none" hidden />
              <option value="equal">=</option>
              <option value="greater_than">&gt;</option>
              <option value="less_than">&lt;</option>
            </Select>
          </FormControl>
          <TextField
            variant="outlined"
            size="small"
            name="inputValue"
            value={inputValue}
            onChange={handleInput}
            className={classes.formInput}
            placeholder="Enter cost"
          />
        </div>

        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Button
            size="small"
            disabled={value === "=" || value === "none"}
            onClick={() => addCriteria()}
            className={classes.criteriaButton}
          >
            {!criteriaShow ? "add another criteria" : "remove criteria"}
          </Button>
        </Grid>

        {criteriaShow && (
          <div>
            <FormControl variant="outlined" size="small" className={classes.formSelect}>
              <Select
                native
                value={criteriaValue}
                onChange={handleCriteriaChange}
                inputProps={{
                  name: "criteriaValue",
                  id: "criteriaValue",
                }}
              >
                {value === "greater_than" ? (
                  <>
                    <option value="none" hidden />
                    <option value="less_than">&lt;</option>
                  </>
                ) : (
                  <>
                    <option value="none" hidden />
                    <option value="greater_than">&gt;</option>
                  </>
                )}
              </Select>
            </FormControl>
            <TextField
              variant="outlined"
              size="small"
              name="inputCriteriaValue"
              value={inputCriteriaValue}
              onChange={handleCriteriaInput}
              placeholder="Enter cost"
              className={classes.formInput}
            />
          </div>
        )}
      </FilterBox>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formSelect: {
      marginLeft: theme.spacing(2),
    },
    formInput: {
      marginInline: theme.spacing(2),
    },
    criteriaButton: {
      margin: theme.spacing(1),
    },
  })
);

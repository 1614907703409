import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { filtersIdMap } from "../../filter/utils";
import UILoader from "../../_app/components/UILoader";
import { ALL, BILLED, PERIOD_LENGTH, UNBILLED, formatPeriodToMonthYear } from "../utils";
import SelectDropdown from "../../form/components/SelectDropdown";
import ToggleButtons from "../../form/components/ToggleButtons";

interface Props {
  billedPeriods: string[];
  shownPeriods: string[];
  isUnbilledShown: boolean;
  setIsUnbilledShown: (isUnbilledShown: boolean) => void;
  isFetching: boolean;
  isSuccess: boolean;
}

export default function BillPeriodFilter({
  billedPeriods,
  shownPeriods,
  isUnbilledShown,
  setIsUnbilledShown,
  isFetching,
  isSuccess,
}: Props) {
  const classes = useStyles();
  const history = useHistory();

  const [billStatus, setBillStatus] = useState(isUnbilledShown ? UNBILLED : BILLED);
  const [billedPeriod, setBilledPeriod] = useState<string>(
    !isUnbilledShown && shownPeriods.length === 1 && !shownPeriods[0].includes(",") ? shownPeriods[0] : ALL,
  );

  useEffect(() => {
    if (shownPeriods[0]?.includes("UNBILLED")) {
      setBillStatus(UNBILLED);
      setIsUnbilledShown(true);
    }
    if (shownPeriods[0]?.includes(",")) setBilledPeriod(ALL);
  }, [shownPeriods]);

  useEffect(() => {
    if (!billedPeriod && Boolean(billedPeriods.length)) setBilledPeriod(ALL);
  }, [billedPeriod, billedPeriods.length]);

  useEffect(() => {
    if (!location.pathname.includes("period-details")) {
      if (billStatus === UNBILLED) {
        return history.push(`/bills?${filtersIdMap?.BILL_PERIOD}=${UNBILLED}`);
      }
      if (billStatus === BILLED) {
        let selectedPeriods: string[] = !!billedPeriod && billedPeriod !== ALL ? [billedPeriod] : [];
        if (billedPeriods.length > 1 && billedPeriod === ALL) {
          selectedPeriods = billedPeriods.slice(1, PERIOD_LENGTH + 1);
        }
        const periodParam = selectedPeriods.map((p) => `${filtersIdMap?.BILL_PERIOD}=${p}`).join("&");
        return history.push(`/bills?${periodParam}`);
      }
    }
  }, [billStatus, billedPeriod]);

  const handleChangeBillStatus = (value: string | null) => {
    if (!!value) {
      setBillStatus(value);
      setIsUnbilledShown(value === UNBILLED);
      setBilledPeriod(ALL);
    }
  };

  if (isFetching) {
    return (
      <Box margin="auto">
        <UILoader />
      </Box>
    );
  } else {
    if (isSuccess) {
      return (
        <Card raised className={classes.root} elevation={2} data-cy="bill-explorer-period-filter">
          <CardContent className={classes.content}>
            <div>
              <Typography variant="caption" paragraph className={classes.filterTitle}>
                Bill Status
              </Typography>
              <ToggleButtons
                value={billStatus}
                onChange={(_, value) => handleChangeBillStatus(value)}
                options={[
                  { label: "Unbilled", value: UNBILLED },
                  { label: "Billed", value: BILLED },
                ]}
                fullWidth
                buttonProps={{ className: classes.toggleButton }}
              />
            </div>
            {!isUnbilledShown && (
              <div className={classes.filterWrap}>
                <Typography variant="caption" paragraph className={classes.filterTitle}>
                  Billing Period
                </Typography>
                <div className={classes.dropdown}>
                  <SelectDropdown
                    name="billedPeriod"
                    value={billedPeriod}
                    data={billedPeriods?.map((value: any) => ({
                      id: value,
                      label: formatPeriodToMonthYear(value),
                      value,
                    }))}
                    infiniteScroll
                    multiple={false}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBilledPeriod(e.target.value)}
                    disabled={billedPeriods.length <= 1}
                  />
                </div>
              </div>
            )}
          </CardContent>
        </Card>
      );
    } else {
      return null;
    }
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(3),
    },
    filterTitle: {
      height: theme.spacing(1.5),
      textTransform: "uppercase",
    },
    filterWrap: {
      width: "100%",
    },
    toggleButton: {
      width: "50%",
      fontWeight: 500,
      borderColor: "#b3b3b3",
      "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
      "&:not(.Mui-selected)": {
        color: theme.palette.text.primary,
      },
    },
    dropdown: {
      width: "100%",
    },
  }),
);

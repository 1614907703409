import api from "../_app/api";
import { WdrStatus, WdrStatusChange, WdrStatusResponse } from "./types";

export const getWdrStatus = async (page?: number, limit?: number, queries?: any[], assets?: string[]) => {
  const { data }: { data: WdrStatusResponse } = await api({
    method: "GET",
    context_injection: true,
    url: "/assets/wdr",
    serialize: true,
    params: { page, limit, queries, "asset-ids": assets },
  });
  return data;
};

export const getWdrStatusForAsset = async (assetId: string) => {
  const { data }: { data: WdrStatus } = await api({
    method: "GET",
    context_injection: false,
    url: `/assets/wdr/${assetId}`,
    serialize: true,
  });
  return data;
};

export const setWdrStatus = async (values: any) => {
  const operations = [];
  for (let entry of values.entries()) {
    operations.push({
      assetId: entry[0],
      capped: entry[1],
    });
  }
  const { data }: { data: string } = await api({
    method: "PUT",
    context_injection: true,
    url: "/assets/wdr",
    serialize: true,
    data: operations,
  });
  return data;
};

export const setWdrStatusForAsset = async (statusChange: WdrStatusChange) => {
  const { data }: { data: string } = await api({
    method: "PUT",
    context_injection: true,
    url: `/assets/wdr/${statusChange.assetId}`,
    serialize: true,
    data: statusChange,
    params: { capped: statusChange.capped },
  });

  return data;
};

import React from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { CircularProgress, CircularProgressProps, LinearProgress } from "@mui/material";

interface Props extends CircularProgressProps {
  linear?: boolean;
}

export const UILoader = (props: Props) => {
  const classes = useStyles();
  if (props.linear) {
    return <LinearProgress color="primary" className={`${classes.ctr} ${props.className}`} />;
  }
  return <CircularProgress color="primary" {...props} className={`${classes.ctr} ${props.className}`} />;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {},
  })
);

export default UILoader;

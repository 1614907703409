import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Alert, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import { useBillLimitPrefs } from "../../../asset/hooks";
import { useStore } from "../../../_app/hooks";
import { useValidation } from "../../../form/hooks";
import { isEmail } from "../../../_app/utils";
import { useHistory } from "react-router-dom";
import UILoader from "../../../_app/components/UILoader";

interface Props {
  callback: (sms: boolean, email: boolean, smsValue?: string, emailValue?: string) => void;
  assetId?: string;
  cli?: string;
}

export const BillLimitAlertsUpdateForm = (props: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { state } = useStore();
  const { callback } = props;
  const isForCli = !!props.assetId;
  const accountCode = state.contextHierarchy?.code;
  const [sms, setSms] = useState(false);
  const [email, setEmail] = useState(false);
  const [smsValue, setSmsValue] = useState<string | undefined>("");
  const [emailValue, setEmailValue] = useState<string | undefined>("");

  const { data: prefs, isFetching, isError } = useBillLimitPrefs(props.assetId);

  useEffect(() => {
    if (!!prefs) {
      setSms(prefs?.sendSms);
      setEmail(prefs?.sendEmail);
      setSmsValue(prefs?.notifyMobileNumber);
      setEmailValue(prefs?.notifyEmailAddress);
    }
  }, [prefs]);

  // Validation
  const validationConfig: any = {
    smsValue: { required: false },
    emailValue: {
      required: false,
      validate: (val: any) => {
        if (val && !isEmail(val)) return "Invalid email";
      }
    }
  };

  const { validationErrors, validateSingle, validateAll } = useValidation(validationConfig, { smsValue, emailValue });

  return (
    <>
      <Alert severity="info" className={classes.info}>
        <div>
          <strong>Bill Limit notification settings
            for {isForCli ? `CLI ${props.cli}` : `account ${accountCode}`}</strong>
        </div>
        {isForCli ? (
          <div>
            Notifications for this bill limit will be automatically sent to the mobile number by SMS. You can add
            additional
            notifications below.
          </div>
        ) : (
          <div>
            Bill limit notifications for all eligible mobile numbers on the account will be automatically sent to the
            mobile
            number by SMS. You can add additional notifications below. These defaults can be overridden via the CLI tab.
          </div>
        )}
        <div>
          Early warning <strong>80%</strong>, Late warning <strong>90%</strong>, Limit breached <strong>100%</strong>.
        </div>
        <div>
          We will use reasonable endeavours to notify you as soon as we become aware that the above levels have been
          breached.
          However, in certain circumstances that are beyond our control, such as delays in call data being received from
          the
          network, the alerts may be a sent late.
        </div>
      </Alert>
      {isFetching && (
        <div className={classes.loader}>
          <UILoader />
        </div>
      )}
      {!isError && !isFetching && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                className={classes.row}
                key="sms"
                label={<Typography variant="subtitle1">SMS alerts sent to mobile number:</Typography>}
                labelPlacement="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={sms}
                    onChange={(ev: any, value: boolean) => {
                      setSms(value);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {sms && (
                <TextField
                  name="smsValue"
                  label="Add phone number"
                  type="text"
                  value={smsValue}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.smsValue)}
                  helperText={validationErrors.smsValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSmsValue(event.target.value);
                    validateSingle(event.target.name, event.target.value);
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControlLabel
                className={classes.row}
                key="email"
                label={<Typography variant="subtitle1">Email alerts sent to:</Typography>}
                labelPlacement="start"
                control={
                  <Checkbox
                    color="primary"
                    checked={email}
                    onChange={(ev: any, value: boolean) => {
                      setEmail(value);
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {email && (
                <TextField
                  name="emailValue"
                  label="Add Email"
                  type="text"
                  value={emailValue}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.emailValue)}
                  helperText={validationErrors.emailValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setEmailValue(event.target.value);
                    validateSingle(event.target.name, event.target.value);
                  }}
                />
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} className={classes.save}>
            <Button
              variant="contained"
              color="primary"
              data-cy="save-button"
              onClick={() => {
                const vErrors = validateAll();
                if (vErrors.length) return;
                callback(sms, email, smsValue, emailValue);
              }}
            >
              Save
            </Button>
            {isForCli && (
              <Button variant="outlined" color="primary" onClick={() => history.goBack()}>
                Cancel
              </Button>
            )}
          </Grid>
        </>
      )}
    </>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginBottom: 10
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "40vh"
    },
    info: {
      alignItems: "center",
      marginBottom: "30px",
      "& div:not(:last-child)": {
        marginBottom: "8px"
      }
    },
    row: {
      width: "100%",
      textAlign: "left",
      justifyContent: "space-between",
      marginLeft: 5
    },
    save: {
      paddingTop: "30px",
      paddingLeft: 4,
      display: "flex",
      gap: 15
    }
  })
);

export default BillLimitAlertsUpdateForm;

import React, { useEffect, useState } from "react";
import { Button, Card, Grid, Theme, Typography } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import PhoneIcon from "@mui/icons-material/PermPhoneMsg";
import Main from "../../_app/layouts/Main";
import { Alert, Skeleton } from "@mui/material";
import { useArticleStructure, useArticleTopic } from "../hooks";
import HelpNavMenu from "../components/HelpNavMenu";
import Tabbed from "../../_app/layouts/Tabbed";
import { upperCasetoCamelCase } from "../../_app/utils/format";
import { useHistory, useParams } from "react-router-dom";
import { permissionCodes } from "../../permission/hooks";
import UIHtml from "../../_app/components/UIHtml";
import { onLinkClickHandler, scrollPage } from "../../_app/utils";
import { featureFlagsMap } from "../../feature/utils";
import { ArticleTopic, ArticleStructure } from "../types";

export const Help = () => {
  const classes = useStyles();
  const history = useHistory();
  const { data: articleStructure, isFetching } = useArticleStructure();
  const { category: categoryParam } = useParams<{ category: string }>();
  const [category, setCategory] = useState<string | undefined>(categoryParam);
  const [topics, setTopics] = useState<ArticleTopic[]>([]);
  const [articleId, setArticleId] = useState<ArticleTopic["articleId"] | undefined>("");

  const { data: article } = useArticleTopic(articleId || "", {
    enabled: Boolean(articleId),
  });

  useEffect(() => {
    const matched = articleStructure?.find((cat) => cat?.name === categoryParam);
    const category = matched || articleStructure?.[0];
    setCategory(category?.name);
    setTopics(category?.topics || []);
  }, [categoryParam, articleStructure, category]);

  useEffect(() => {
    setArticleId(topics?.[0]?.articleId);
  }, [topics]);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    history.push("/help/" + value);
  };

  return (
    <Main
      title={
        <>
          Help Centre
          <Button
            variant="contained"
            color="primary"
            data-cy="contact-us-btn"
            startIcon={<PhoneIcon />}
            onClick={() => history.push("/contact-support")}
          >
            Contact Us
          </Button>
        </>
      }
      data-cy="support-help-page"
      featureFlag={featureFlagsMap.HELP}
      accessPermission={permissionCodes.SUPPORT_ACCESS}
      isLoading={isFetching}
    >
      <Tabbed
        selectedTab={category || articleStructure?.[0]?.name || "Billing"}
        handleTabChange={handleTabChange}
        tabs={
          articleStructure?.map((tab: ArticleStructure) => ({
            value: tab.name,
            label: tab.name,
            testId: `help-tab-${upperCasetoCamelCase(tab.name)}`,
          })) || []
        }
      >
        <Alert severity="info" className={classes.info}>
          For answers to frequently asked questions, access to helpful guides and videos, please visit our Customer Support page.
        </Alert>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start" spacing={2}>
          <Grid item xs={12} md={3}>
            <Grid container justifyContent="center" alignItems="center">
              <Card className={classes.topics} data-cy="topics-nav-card" elevation={1}>
                <Typography variant="h4" className={classes.title}>
                  Topics
                </Typography>
                {!topics?.length && (
                  <>
                    <Skeleton className={classes.title} />
                    <Skeleton className={classes.title} />
                    <Skeleton className={classes.title} />
                  </>
                )}
                {topics?.map((t: ArticleTopic) => (
                  <HelpNavMenu
                    key={t.articleId}
                    topic={t}
                    selectedArticleId={articleId}
                    handleClick={(id) => {
                      setArticleId(id);
                      if (id !== t.articleId) scrollPage();
                    }}
                  />
                ))}
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            {Boolean(article) && (
              <Card className={classes.article} elevation={1}>
                <Typography variant="h3" gutterBottom data-cy="article-title">
                  {article?.title}
                </Typography>
                <Typography variant="body1" gutterBottom data-cy="article-body" component="div">
                  {article && <UIHtml html={article.body.replace(/\n/g, "").replace(/\r/g, "")} />}
                </Typography>
                {Boolean(article?.button) && (
                  <Button
                    color="primary"
                    variant="contained"
                    data-cy="article-button"
                    href={article?.button.url || ""}
                    target="_blank"
                    onClick={(ev) => onLinkClickHandler(ev, history)}
                    data-payload={article?.button.payload ? JSON.stringify(article?.button.payload) : undefined}
                  >
                    {article?.button.label}
                  </Button>
                )}
              </Card>
            )}
          </Grid>
        </Grid>
      </Tabbed>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    topics: {
      width: "100%",
      height: "auto",
      minHeight: "calc(100vh - 320px)",
      [theme.breakpoints.down("md")]: {
        minHeight: "auto",
      },
    },
    article: {
      width: "100%",
      padding: theme.spacing(2),
    },
    title: {
      padding: theme.spacing(2),
    },
    info: {
      width: "fit-content",
      marginBottom: theme.spacing(3),
      alignItems: "center",
      [theme.breakpoints.down("md")]: {
        "& .MuiAlert-message": {
          fontSize: "0.7rem",
        },
      },
    },
  })
);

export default Help;

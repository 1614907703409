import React from "react";
import { IconButton } from "@mui/material";
import DownloadIcon from "@mui/icons-material/CloudDownload";
import Tooltip from "@mui/material/Tooltip";
import { useDocumentDownload } from "../hooks";
import UILoader from "../../_app/components/UILoader";
import { useFeedbackAlerts } from "../../_app/hooks";
import { downloadPresignedFile } from "../../_app/utils";

export interface Props {
  communicationId: string,
  filename: string | null,
}

const DownloadButton = ({ communicationId, filename }: Props) => {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  const { isFetching, refetch } = useDocumentDownload(communicationId,
    {
      enabled: false,
      cacheTime: 0,
      onSuccess: (data: string) => {
        if (data) downloadPresignedFile(data)
      },
      onError: () => {
        setFeedbackAlertError("Could not download file.")
      }
    }
  );

  if (filename == null) {
    return null;
  }

  return (
    isFetching ? (<UILoader />) :
      (<Tooltip title={`Download ${filename}`}>
        <IconButton color="primary" onClick={() => refetch()} data-cy="document-download-link">
          <DownloadIcon />
        </IconButton>
      </Tooltip>)
  );
};

export default DownloadButton;
import React, { useEffect, useState, ReactNode } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Card, CardActions, CardContent, CardHeader, Collapse, Theme, Typography } from "@mui/material";
import { Check } from "@mui/icons-material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { Filter, UsersFilter } from "../types";
import FilterItem from "./FilterItem";
import MyFilters from "./MyFilters";
import AddedFilters from "./AddedFilters";
import MoreFilters from "./MoreFilters";
import UILoader from "../../_app/components/UILoader";
import OptionsButton from "../../form/components/OptionsButton";

interface Props {
  fetchHook?: (usage?: string) => any;
  onApply?: () => any;
  myFilters?: {
    fetchHook?: (usage: string) => any;
    deleteHook?: (filter: UsersFilter) => any;
    saveHook?: (name: string) => any;
    addHook?: (filter: UsersFilter) => any;
  };
  usage?: string;
  title?: string;
  hasReset?: boolean;
  hasDownload?: boolean;
  downloadFileType?: string;
  disableApply?: boolean;
  disableDownload?: any;
  handleDownloadClick?: (selected: string) => any;
  className?: string;
  children?: ReactNode;
}

const FiltersCard = ({
  fetchHook = () => ({ data: null, isLoading: null }),
  onApply,
  myFilters,
  usage,
  title = "Filter",
  hasReset = true,
  hasDownload = false,
  downloadFileType = "",
  disableApply = false,
  disableDownload = false,
  handleDownloadClick = () => null,
  className = "",
  children,
}: Props) => {
  const classes = useStyles();

  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  // Reset filters
  const resetFilters = () => {
    const params: string[] = [];
    queryParams.forEach((value, key) => {
      // TODO: preffix check?
      params.push(key);
    });
    params.forEach((key) => {
      queryParams.delete(key);
    });
    history.replace({
      search: queryParams.toString(),
    });
    onApply?.();
  };

  const [filters, setFilters] = useState([] as any[]);
  const { data: filtersResponse, isLoading } = fetchHook(usage);

  // Filter toggles
  useEffect(() => {
    if (!filters.length) {
      const queryParams: any = [];
      new URLSearchParams(location.search)?.forEach((value, key) => queryParams.push({ key, value }));

      const output: Filter[] =
        filtersResponse?.map((f: Filter) => {
          const match = queryParams.filter((q: any) => q.key === f.id)?.[0];
          if (match) {
            return {
              ...f,
              value: match.value,
              showToggle: true,
            };
          }
          return f;
        }) || [];
      setFilters(output);
    }
  }, [filtersResponse, location, filters.length]);

  const updateFilterVisibility = (filterId: string, value: boolean) => {
    const newFilters: Filter[] = [];
    filters.forEach((filter: Filter) => {
      const newFilter: Filter = filter;
      if (filter.id === filterId) {
        newFilter.showToggle = value;
      }
      newFilters.push(newFilter);
    });
    setFilters(newFilters);
  };

  const [showLoading, setShowLoading] = useState(false);

  // Card Collapse
  const [expanded, setExpanded] = React.useState(true);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const showHeader = !!title || !!myFilters || hasReset || hasDownload;

  return (
    <Card className={`${classes.ctr} ${className}`} elevation={1}>
      {children}
      {showHeader && (
        <CardHeader
          className={classes.header}
          title={
            <div className={classes.titleCtr}>
              {Boolean(title) && <Typography variant="h3">{title}</Typography>}
              {Boolean(myFilters) && (
                <MyFilters
                  fetchHook={myFilters?.fetchHook}
                  deleteHook={myFilters?.deleteHook}
                  saveHook={myFilters?.saveHook}
                  addHook={myFilters?.addHook}
                  usage={usage}
                />
              )}
            </div>
          }
          action={
            <>
              {hasReset && (
                <Button
                  variant="outlined"
                  size="small"
                  color="primary"
                  onClick={resetFilters}
                  data-cy="reset-filters-button"
                  className={classes.button}
                >
                  Reset
                </Button>
              )}
              {hasDownload && (
                <OptionsButton
                  options={[
                    <Button
                      variant="text"
                      size="small"
                      color="primary"
                      data-cy="export-button"
                      disabled={disableDownload}
                      onClick={async () => {
                        setShowLoading(true);
                        await handleDownloadClick(downloadFileType);
                        setShowLoading(false);
                      }}
                      startIcon={showLoading ? <UILoader className={classes.loadingSpinner} /> : <CloudDownloadIcon />}
                    >
                      Export result {downloadFileType ? `as ${downloadFileType.toUpperCase()}` : ""}
                    </Button>,
                  ]}
                />
              )}

              {Boolean(myFilters) && (
                <Button
                  variant="text"
                  size="small"
                  data-cy="expand-button"
                  onClick={handleExpandClick}
                  className={classes.button}
                  endIcon={expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                >
                  {expanded ? "Hide" : "Show"}
                </Button>
              )}
            </>
          }
        />
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent className={classes.content} data-cy="visible-filters-list">
          {isLoading && <UILoader />}
          {filters.map((item: Filter) => {
            if (item.showToggle && !item.options?.HIDDEN) {
              return <FilterItem item={item} key={item.id} />;
            }
            return null;
          })}
          <MoreFilters filters={filters} updateFilterVisibility={updateFilterVisibility} />
        </CardContent>
        <CardActions className={classes.footer}>
          {Boolean(filters.length) && (
            <>
              <AddedFilters filters={filters} />
              {Boolean(onApply) && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onApply}
                  data-cy="apply-filters-button"
                  startIcon={<Check />}
                  disabled={disableApply}
                >
                  Apply
                </Button>
              )}
            </>
          )}
        </CardActions>
      </Collapse>
    </Card>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      marginBottom: 24,
    },
    loadingSpinner: {
      margin: 0,
      padding: 0,
      maxWidth: "20px",
      maxHeight: "20px",
    },
    button: {
      marginLeft: theme.spacing(2),
      fontWeight: 600,
      fontSize: "0.95rem",
    },
    header: {
      [theme.breakpoints.down("md")]: {
        "&": {
          flexDirection: "column",
          gap: theme.spacing(2),
        },
        "& .MuiCardHeader-action": {
          alignSelf: "center",
        },
      },
      "& .MuiCardHeader-action": {
        margin: 0,
        flex: "0 1 auto",
      },
    },
    titleCtr: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
    },
    content: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "10px",
      alignItems: "center",
      padding: `0px ${theme.spacing(2)}`,
    },
    footer: {
      flexDirection: "column",
      alignItems: "flex-end",
      padding: `0px ${theme.spacing(2)} ${theme.spacing(2)}`,
    },
  })
);

export default FiltersCard;

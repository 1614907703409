import React from "react";
import { Button, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { UICard, UICardProps } from "./UICard";

interface Props extends UICardProps {
  actionTitle: string;
  actionFunction?: () => void;
  actionTestId?: string;
  isFetching?: boolean;
  children: React.ReactElement | string | number;
}

export const SingleActionCard = ({
  children,
  actionTitle,
  actionFunction = () => null,
  actionTestId = "single-action-btn",
  isFetching = false,
  ...props
}: Props) => {
  const classes = useStyles();
  return (
    <UICard
      isFetching={isFetching}
      actions={[
        <Button
          variant="contained"
          color="primary"
          onClick={actionFunction}
          className={classes.action}
          data-cy={actionTestId}
          key={actionTestId}
        >
          {actionTitle}
        </Button>,
      ]}
      className={`${classes.root} ${props.className}`}
      {...props}
    >
      {children}
    </UICard>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "auto",
    },
    action: {
      width: "100%",
    },
  })
);

import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import { useIdentityUser } from "../../auth/hooks";
import { useNonCognitoUserUpdate, useUserData } from "../hooks";
import { User } from "../types";
import { IdentityUser } from "../../auth/types";

export const MyDetails = () => {
  const classes = useStyles();
  const { data: identityUser, isLoading: isLoadingIdentityUser } = useIdentityUser();
  const { userLevel, userAccounts } = useUserData();

  const userData: User = {
    ssoId: identityUser?.profile.sub + "" ?? "",
    lastName: identityUser?.profile?.family_name ?? "",
    firstName: identityUser?.profile?.given_name ?? "",
    email: identityUser?.profile?.email ?? "",
    accounts: userAccounts,
    userLevel: userLevel,
  };

  const [requestUpdateBody, setRequestUpdateBody] = useState<User>(userData);
  const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequestUpdateBody({
      ...requestUpdateBody,
      [event.target.name]: event.target.value,
    });
  };

  const { updateUser, isLoading: isUpdating } = useNonCognitoUserUpdate();

  const handleUpdate = () => {
    if (requestUpdateBody?.email) {
      updateUser(identityUser as IdentityUser, requestUpdateBody);
    }
  };

  const isLoading = isLoadingIdentityUser || isUpdating;

  return (
    <Main title="My Details" data-cy="my-details-page" isLoading={isLoading}>
      <Grid item xs={12} md={6} data-cy="my-details-form">
        <Card elevation={1} className={classes.card}>
          <TextField
            key={identityUser?.profile.email}
            className={classes.textField}
            name="email"
            label="Email address/Username"
            type="email"
            defaultValue={identityUser?.profile.email}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <TextField
            key={identityUser?.profile.given_name}
            className={classes.textField}
            name="firstName"
            label="First Name"
            type="text"
            defaultValue={identityUser?.profile.given_name}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <TextField
            key={identityUser?.profile.family_name}
            className={classes.textField}
            name="lastName"
            label="Last Name"
            type="text"
            defaultValue={identityUser?.profile.family_name}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <Button variant="contained" color="primary" onClick={handleUpdate} data-cy="update-button">
            Update
          </Button>
        </Card>
      </Grid>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      padding: theme.spacing(4),
    },
    textField: {
      marginBottom: theme.spacing(4),
    },
  }),
);

export default MyDetails;

import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { scrollPage } from "../utils";

export default function ScrollToTopProvider() {
  const { pathname } = useLocation();
  useEffect(() => {
    scrollPage();
  }, [pathname]);

  return null;
}

import React, { useEffect, useState } from "react";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import { Filter } from "../../types";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { formatDate, parseStringToDate } from "../../../_app/utils/format";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { Typography, TextField } from "@mui/material";
import FilterBox from "./FilterBox";

interface Props {
  item: Filter;
  options?: any;
  selection: any;
  setSelection: any;
  addSelection: any;
  resetSelection?: any;
  close: any;
}

const DEFAULT_TIME = null;

export default function TimeFilter({
  item,
  options,
  selection,
  setSelection,
  addSelection,
  resetSelection,
  close,
  ...props
}: Props) {
  const classes = useStyles();
  const PATTERN = "mm:ss";

  const val1 = selection[0]?.split(" ");
  const val2 = selection[1]?.split(" ");

  const [value, setValue] = useState(val1?.[0] || "none");
  const [criteriaValue, setCriteriaValue] = useState(val2?.[0] || "none");

  const [selectedDate, setSelectedDate] = useState<Date | null>(val1 ? parseStringToDate(val1?.[1], PATTERN) : DEFAULT_TIME);

  const [selectedCriteriaDate, handleCriteriaDateChange] = useState<Date | null>(
    val2 ? parseStringToDate(val2?.[1], PATTERN) : DEFAULT_TIME
  );

  const [criteriaShow, setCriteria] = useState(!!val2);

  const formatDateValue = (date: any) => {
    if (!isNaN(new Date(date)?.getDate())) {
      const dateToIso = new Date(date).toISOString();
      const formatedDate = formatDate(dateToIso, PATTERN);
      return formatedDate;
    }
  };

  const addCriteria = () => {
    setCriteria((oldCriteria) => !oldCriteria);
    if (criteriaShow) {
      setCriteriaValue("none");
      handleCriteriaDateChange(DEFAULT_TIME);
    }
  };

  useEffect(() => {
    const formatLocalTime = formatDateValue(selectedDate);
    const formatCriteriaTime = formatDateValue(selectedCriteriaDate);

    const firstConditionValues = [value, formatLocalTime];
    const secondConditionValues = [criteriaValue, formatCriteriaTime];

    const firstCondition = firstConditionValues.join(" ");
    const secondCondition = secondConditionValues.join(" ");

    const result = [
      ...(firstCondition !== "none 00:00" ? [firstCondition] : []),
      ...(secondCondition !== "none 00:00" ? [secondCondition] : []),
    ];
    setSelection(result);
  }, [setSelection, value, selectedDate, criteriaValue, selectedCriteriaDate]);

  useEffect(() => {
    if (value === "equal") {
      setCriteria(false);
      setCriteriaValue("none");
      handleCriteriaDateChange(DEFAULT_TIME);
    }
  }, [setCriteria, value]);

  const handleDateChange = (value: Date | null) => {
    if (value) setSelectedDate(value);
  };

  const handleFirstCriteriaChange = (event: SelectChangeEvent<any>) => {
    setValue(event.target.value as string);
  };

  const handleSecondCriteriaChange = (event: SelectChangeEvent<any>) => {
    setCriteriaValue(event.target.value as string);
  };

  const renderTimePicker = (value: any, onChange: any, props?: any) => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticTimePicker
          renderInput={(props) => <TextField {...props} inputProps={{ ...props.inputProps, placeholder: "HH:MM" }} />}
          ampm={false}
          autoOk
          openTo="minutes"
          views={["minutes", "seconds"]}
          inputFormat={PATTERN}
          variant="inline"
          inputVariant="outlined"
          value={value}
          onChange={onChange}
          componentsProps={{ actionBar: { actions: [] } }}
          showToolbar
          toolbarTitle={
            <Typography className={classes.timeToolbarLabel} variant="body2">
              Select duration in hours and minutes
            </Typography>
          }
          {...props}
        />
      </LocalizationProvider>
    );
  };

  const renderAddRemoveCriteriaButton = () => {
    return (
      <Button
        size="small"
        disabled={value === "equal" || value === "none"}
        onClick={() => addCriteria()}
        className={classes.criteriaButton}
      >
        {!criteriaShow ? "Add additional criteria" : "Remove additional criteria"}
      </Button>
    );
  };

  return (
    <div {...props}>
      <FilterBox
        item={item}
        resetSelection={resetSelection}
        addSelection={addSelection}
        selection={selection}
        close={close}
        disableApply={
          selection.length < 1 ||
          value === "none" ||
          (value !== "none" && selectedDate === DEFAULT_TIME) ||
          (criteriaValue === "none" && selectedCriteriaDate !== DEFAULT_TIME) ||
          (criteriaValue !== "none" && selectedCriteriaDate === DEFAULT_TIME)
        }
      >
        <div className={classes.ctr}>
          <div>
            <div className={classes.criteriaWrap}>
              <Typography className={classes.timeToolbarLabel} variant="body2">
                Select criteria
              </Typography>
              <FormControl variant="outlined" size="small">
                <Select
                  native
                  value={value}
                  onChange={handleFirstCriteriaChange}
                  inputProps={{
                    name: "value",
                    id: "value",
                  }}
                >
                  <option value="none" hidden />
                  <option value="equal">=</option>
                  <option value="greater_than">&gt;</option>
                  <option value="less_than">&lt;</option>
                </Select>
              </FormControl>
            </div>
            {renderTimePicker(selectedDate, handleDateChange)}
          </div>
          {renderAddRemoveCriteriaButton()}
          {criteriaShow && (
            <div>
              <div className={classes.criteriaWrap}>
                <Typography className={classes.timeToolbarLabel} variant="body2">
                  Select criteria
                </Typography>
                <FormControl variant="outlined" size="small">
                  <Select
                    native
                    value={criteriaValue}
                    onChange={handleSecondCriteriaChange}
                    inputProps={{
                      name: "criteriaValue",
                      id: "criteriaValue",
                    }}
                  >
                    {value === "greater_than" ? (
                      <>
                        <option value="none" hidden />
                        <option value="less_than">&lt;</option>
                      </>
                    ) : (
                      <>
                        <option value="none" hidden />
                        <option value="greater_than">&gt;</option>
                      </>
                    )}
                  </Select>
                </FormControl>
              </div>
              {renderTimePicker(selectedCriteriaDate, handleCriteriaDateChange)}
            </div>
          )}
        </div>
      </FilterBox>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      minWidth: 330,
      paddingBottom: theme.spacing(2),
    },
    criteriaButton: {
      position: "absolute",
      right: theme.spacing(1),
      bottom: theme.spacing(2),
    },
    criteriaWrap: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    timeToolbarLabel: {
      textTransform: "initial",
      marginBottom: theme.spacing(2),
      color: theme.palette.grey[400],
    },
  })
);

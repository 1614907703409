import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MUIDataTable, { MUIDataTableColumnDef, MUIDataTableState } from "mui-datatables";
import Main from "../../_app/layouts/Main";
import { Alert, Button, Grid } from "@mui/material";
import { permissionCodes, useHasPermissions } from "../../permission/hooks";
import { useContactDelete, useContacts } from "../hooks";
import AddIcon from "@mui/icons-material/Add";
import ManageContactsActionButton from "../components/ManageContactsActionsButton";
import { useHasFeature } from "../../feature/hooks";
import { rowParser } from "../../_app/components/Table/helpers";
import { Contact } from "../types";
import { featureFlagsMap } from "../../feature/utils";

interface TableConfig {
  title: string;
  columns: MUIDataTableColumnDef[];
}

const tableConfig: TableConfig = {
  title: "Contacts",
  columns: ["First Name", "Last Name", "Main Number", "Mobile Number", "Type", "Email", { label: " ", name: "Actions" }],
};

export const ManageContacts = () => {
  const classes = useStyles();
  const history = useHistory();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);

  const hasEditPerm = useHasPermissions([permissionCodes.ACCOUNT_CONTACTS, permissionCodes.ALERTS_ACCESS_FEATURE]);
  const hasEditFeature = useHasFeature(featureFlagsMap.CONTACTS_UPDATE);
  const canEdit = hasEditFeature && hasEditPerm;

  const { data: contacts, isFetching } = useContacts(page, rowCount);
  useEffect(() => {
    setPage(contacts?.page || 0);
  }, [contacts]);

  const { mutate: executeUserDelete } = useContactDelete();

  const handleAdd = () => {
    history.push(`contact/new`);
  };

  const handleEdit = (contact: Contact) => {
    history.push({ pathname: `contacts/${contact.id}`, state: contact });
  };

  const handleDelete = (contact: Contact) => {
    executeUserDelete(contact);
  };

  const columns = tableConfig.columns;
  const parsed =
    contacts?.list?.map((c: Contact) => {
      return rowParser([
        c.firstName,
        c.lastName,
        c.mainNumber,
        c.mobileNumber,
        c.typeFriendly,
        c.email,
        canEdit ? <ManageContactsActionButton data={c} editAction={handleEdit} deleteAction={handleDelete} /> : undefined,
      ]);
    }) || [];

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  return (
    <Main
      title={
        <>
          Manage Contacts
          {canEdit && (
            <Button variant="contained" color="primary" data-cy="add-contact-btn" startIcon={<AddIcon />} onClick={handleAdd}>
              <span className={classes.addBtnText}>Add Contact</span>
            </Button>
          )}
        </>
      }
      data-cy="manage-contacts-page"
      accessPermission={[permissionCodes.ACCOUNT_VIEW_CONTACTS, permissionCodes.ALERTS_ACCESS_FEATURE]}
      featureFlag={featureFlagsMap.CONTACTS}
      needSelectedAccount={true}
      isLoading={isFetching}
    >
      <Grid className={classes.ctr}>
        <Alert severity="info" className={classes.info}>
          Manage your account contacts here. These are the individuals who have authority to discuss your account. You can control
          the level of authority they have by setting the appropriate Contact Type.
        </Alert>
        <div className={classes.tableCtr} data-cy="contacts-table">
          <MUIDataTable
            title=""
            data={parsed}
            columns={columns || []}
            options={{
              onTableChange: handleTableChange,
              pagination: true,
              page,
              rowsPerPage: rowCount,
              rowsPerPageOptions: rowCountOptions,
              count: contacts?.total,
              download: false,
              elevation: 1,
              print: false,
              responsive: "standard",
              selectToolbarPlacement: "none",
              filter: false,
              sort: false,
              search: false,
              serverSide: true,
              selectableRows: "none",
              rowHover: true,
              setTableProps: () => ({ size: "small" }),
              viewColumns: false,
              setRowProps: (row) => ({ "data-cy": `contact-id-${row[4]}` }),
            }}
          />
        </div>
      </Grid>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      width: "100%",
      padding: "0",
      marginTop: theme.spacing(1.5),
    },
    tableCtr: {},
    info: {
      width: "fit-content",
      marginBottom: theme.spacing(3),
      alignItems: "center",
    },
    rowButton: {
      "& > span": {
        fontWeight: "normal",
        color: theme.palette.primary.light,
      },
    },
    addBtnText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export default ManageContacts;

import React from "react";
import Tabbed from "../../../_app/layouts/Tabbed";

interface Props {
  selectedTab: string;
  children: any;
}

function UsageAlertsTabBar({ selectedTab, children }: Props) {
  return (
    <Tabbed
      selectedTab={selectedTab}
      tabs={[
        {
          value: "usage-alerts",
          label: "Alerts",
          hidden: false,
        },
        {
          value: "usage-alerts-history",
          label: "Triggered Alerts",
          hidden: false,
        },
      ]}
    >
      {children}
    </Tabbed>
  );
}

export default UsageAlertsTabBar;

import React from "react";
import { Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import logo from "../images/direct-debit-logo.png";

export const PaymentDialogContentUnfilled = () => {
  const classes = useStyles();

  return (
    <div className={classes.unfilledContentWrap} data-cy="payment-details-unfilled">
      <div className={classes.directDebitLogoWrap}>
        <img src={logo} width={120} alt="direct-debit-logo" />
      </div>
      <Typography variant="h2" gutterBottom>
        Switch to Direct Debit
      </Typography>
      <Typography variant="h4">You could save</Typography>
      <div className={classes.priceWrap}>
        <div className={classes.price}>£90.00</div>
        <Typography variant="h4">Per year</Typography>
      </div>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    unfilledContentWrap: {
      height: "410px",
    },
    directDebitLogoWrap: {
      width: "100%",
      marginBottom: "80px",
    },
    priceWrap: {
      display: "flex",
      justifyContent: "center",
      alignItems: "baseline",
    },
    price: {
      fontSize: "85px",
      paddingRight: "10px",
    },
  })
);

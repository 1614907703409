import React from "react";
import TableFooter from "@mui/material/TableFooter";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Button from "@mui/material/Button";
import ExpandMore from "@mui/icons-material/ExpandMore";

import UILoader from "../UILoader";

interface Props {
  hasMore: boolean | undefined;
  disabled?: boolean;
  onChangePage?: any;
  variant?: "text" | "contained" | "outlined";
}

export const LoadMoreFooter = ({ disabled, hasMore, onChangePage, variant = "outlined" }: Props) => {
  const classes = useStyles();
  return (
    <TableFooter className={classes.root}>
      {hasMore && (
        <TableRow className={classes.row}>
          <td>
            <Button
              disabled={disabled}
              startIcon={disabled ? null : <ExpandMore />}
              onClick={onChangePage}
              variant={variant}
              color="primary"
              data-cy="load_more"
            >
              {disabled ? <UILoader size={18} className={classes.isLoading} /> : "Load More"}
            </Button>
          </td>
        </TableRow>
      )}
    </TableFooter>
  );
};

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
    },
    isLoading: {
      margin: "3px 38px",
    },
    row: {
      padding: theme.spacing(1.5),
    },
  })
);

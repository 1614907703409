import React, { useEffect, useMemo, useState } from "react";
import { debounce } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { UIButton, UIInputAdornment, UITextField, UITheme, createStyles, makeStyles } from "../../_app/components";

interface Props {
  initialValue?: string;
  placeholder?: string;
  hasSearchButton?: boolean;
  onSearch: (...args: any[]) => void;
}

const SearchBar = ({ initialValue = "", placeholder, hasSearchButton = true, onSearch }: Props) => {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    setSearchValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    if (!searchValue.length) handleClear();
  }, [searchValue]);

  const debouncedHandleSearch = useMemo(() => debounce(onSearch, 300), []);

  const handleChange = (e: any) => {
    const value = e.target.value;
    setSearchValue(value);
    if (debouncedHandleSearch && !hasSearchButton) {
      debouncedHandleSearch(value);
    }
  };

  const handleClear = () => {
    setSearchValue("");
    onSearch("");
  };

  return (
    <UITextField
      name="search"
      value={searchValue}
      className={classes.textField}
      size="small"
      onChange={handleChange}
      placeholder={placeholder ?? "Search"}
      variant="outlined"
      fullWidth
      InputProps={{
        startAdornment: (
          <UIInputAdornment position="start">
            <SearchIcon />
          </UIInputAdornment>
        ),
        endAdornment: (
          <UIInputAdornment position="end">
            {hasSearchButton ? (
              <UIButton
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => onSearch(searchValue)}
                disabled={!searchValue.length}
              >
                Search
              </UIButton>
            ) : (
              searchValue && (
                <UIButton data-cy="cancel-btn" isIconButton onClick={handleClear}>
                  <ClearIcon />
                </UIButton>
              )
            )}
          </UIInputAdornment>
        ),
      }}
    />
  );
};

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    input: {
      marginLeft: theme.spacing(2),
      marginRight: 0,
      flex: 1,
      height: "40px",
    },
    iconButton: {
      padding: 10,
    },
    textField: {
      "& .MuiOutlinedInput-adornedEnd": {
        height: "40px",
      },
      "& .MuiInputBase-root": {
        paddingRight: 0,
      },
    },
    button: {
      borderRadius: "0 4px 4px 0",
      height: "38px",
      marginRight: "1px",
    },
  })
);

export default SearchBar;

import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import React from "react";
import { useHistory } from "react-router-dom";
import { onLinkClickHandler } from "../utils";

export interface Props {
  html?: string;
}

export const UIHtml = ({ html = "" }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const parsedHtml = html.replace(/\n/g, "<br />");
  return (
    <div
      className={classes.ctr}
      onClick={(ev) => onLinkClickHandler(ev, history)}
      dangerouslySetInnerHTML={{
        __html: parsedHtml,
      }}
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      "& a": {
        color: theme.palette.primary.main,
        textDecoration: "none",
      },
      "& a:hover": {
        color: theme.palette.primary.main,
        textDecoration: "underline",
      },
    },
  })
);

export default UIHtml;

import { useInfiniteQuery, useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { getUsageAnalysis, getUsageAnalysisColumns, getUsageAnalysisSummary, getUsageAnalysisSummaryTotal } from "./api";
import { Query } from "../filter/types";
import { UsageAnalysisColumn, UsageAnalysisResponse, UsageAnalysisSummaryTable, UsageAnalysisSummaryTotal } from "./types";
import { SortParam } from "../_app/components/Table/helpers";

export function useGetUsageAnalysisColumns(tableType: string, options = {}) {
  return useQuery<UsageAnalysisColumn[], AjaxResponse>(
    ["usageAnalysisColumns", { tableType }],
    () => getUsageAnalysisColumns(tableType),
    { ...options }
  );
}

export function useGetUsageAnalysis(limit: number, sort: SortParam[], queries: Query[], options = {}) {
  return useInfiniteQuery<UsageAnalysisResponse, AjaxResponse>(
    ["usageAnalysis", { limit, sort, queries }],
    ({ pageParam }) => getUsageAnalysis(pageParam, limit, sort, queries),
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage?.nextPageToken || undefined;
      },
      retry: 2,
      ...options,
    }
  );
}

export function useGetUsageAnalysisSummary(limit: number, sort: SortParam[], type: string, queries: Query[], options = {}) {
  return useInfiniteQuery<UsageAnalysisSummaryTable, AjaxResponse>(
    ["usageAnalysisSummary", { type, limit, sort, queries }],
    ({ pageParam }) => getUsageAnalysisSummary(pageParam, limit, sort, type, queries),
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage?.nextPageToken || undefined;
      },
      retry: 2,
      ...options,
    }
  );
}

export function useGetUsageAnalysisSummaryTotal(queries: Query[], options = {}) {
  return useQuery<UsageAnalysisSummaryTotal, AjaxResponse>(
    ["usageAnalysisSummaryTotal", { queries }],
    () => getUsageAnalysisSummaryTotal(queries),
    { retry: 2, ...options }
  );
}

export function useGetUsageAnalysisSupportedColumns(tableType: string) {
  const { data: supportedColsAll, isFetching: isFetchingAll } = useGetUsageAnalysisColumns("ALL", {
    enabled: tableType === "ALL",
  });
  const { data: supportedColsCli, isFetching: isFetchingCli } = useGetUsageAnalysisColumns("CLI", {
    enabled: tableType === "CLI",
  });
  const { data: supportedColsAccount, isFetching: isFetchingAccount } = useGetUsageAnalysisColumns("ACCOUNT", {
    enabled: tableType === "ACCOUNT",
  });
  const { data: supportedColsCostCentre, isFetching: isFetchingCC } = useGetUsageAnalysisColumns("COST_CENTRE", {
    enabled: tableType === "COST_CENTRE",
  });

  const isFetching = isFetchingAll || isFetchingCli || isFetchingAccount || isFetchingCC;

  return {
    data: {
      ALL: supportedColsAll,
      CLI: supportedColsCli,
      ACCOUNT: supportedColsAccount,
      COST_CENTRE: supportedColsCostCentre,
    },
    isFetching,
  };
}

import React from "react";
import { Chip } from "@mui/material";
import { Theme } from "@mui/material/styles";

import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

const StatusChip = ({ status = "PENDING", ...rest }) => {
  const classes = useStyles();
  let klass = classes.pending;
  if (status.toUpperCase().includes("COMPLETE")) {
    klass = classes.complete;
  }
  if (status.toUpperCase().includes("FAILED")) {
    klass = classes.failed;
  }

  return (
    <Chip
      className={`${classes.chip} ${klass} ${rest?.className}`}
      label={status?.toLowerCase()}
      data-cy="status-chip"
      size="small"
      {...rest}
    />
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chip: {
      textTransform: "capitalize",
    },
    pending: {
      backgroundColor: theme.palette.grey[100],
      color: theme.palette.grey[500],
    },
    complete: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
    },
    failed: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
    },
  })
);

export default StatusChip;

import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import { Divider, Drawer, Hidden, List, ListItem, ListItemText, Collapse } from "@mui/material";
import { useTheme, Theme, lighten, darken } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Description from "@mui/icons-material/Description";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AccountBox from "@mui/icons-material/AccountBox";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SettingsIcon from "@mui/icons-material/Settings";
import InboxIcon from "@mui/icons-material/Inbox";
import VisibilityIcon from "@mui/icons-material/Visibility";
import NetworkCheck from "@mui/icons-material/NetworkCheck";
import DescriptionIcon from '@mui/icons-material/Description';
import { permissionCodes, useHasPermission, useHasPermissions } from "../../../permission/hooks";
import { useLogo } from "../../../brand/hooks";
import { useHasFeature, useHasAnyFeature } from "../../../feature/hooks";
import { featureFlagsMap } from "../../../feature/utils";
import { useHasAuthority, useUserLevelMap } from "../../../user-level/hooks";
import MasqueradingChip from "./MasqueradingChip";
import { UserLevelFlag } from "../../../user-level/types";
import { getStatementsAndInvoicesLabel } from "../../../billing/utils";

interface MainProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

export const drawerWidth = 264;
const billPages = ["/bills", "/usage", "/services"];
const billDashPages = ["/bill-dashboard-monthly", "/bill-dashboard-trends"];
const statementPages = ["/statements", "/invoices"];
const accountDetailPages = ["/account"];
const assetsPages = ["/assets"];
const mobilePages = ["/mobile-management", "/bars"];
const activateMobilePages = ["/sim-activate", "/sim-disconnect"];
const paymentPages = ["/payment-type", "/card-payment"];
const manageContactsPages = ["/contacts", "/contact/new"];
const manageCostCentresPages = ["/manage-cost-centres", "/update-cost-centres"];
const billLimitAlertsPages = ["/bill-limit-alerts"];
const paymentDetailsPages = ["/payment-type", "/card-payment"];
const alertPages = ["/usage-alerts"];
const bundleAlertsPages = ["/bundle-alerts"];
const supportPages = ["/support", "/tickets", "/line-test", "/help", "/logic-monitor"];

function NavMenu({ mobileOpen, handleDrawerToggle }: MainProps) {
  const classes = useStyles({ drawerWidth });
  const theme = useTheme();
  const logo = useLogo();
  const history = useHistory();
  const userLevels = useUserLevelMap();

  // Feature Flag Check
  const hasStatementsFeature = useHasFeature(featureFlagsMap.STATEMENTS);
  const hasCommunicationsFeature = useHasFeature(featureFlagsMap.COMMUNICATIONS_CENTRE);
  const hasInvoicesFeature = useHasFeature(featureFlagsMap.INVOICES);
  const hasDirectDebitFeature = useHasFeature(featureFlagsMap.DIRECT_DEBIT);
  const hasCardFeature = useHasFeature(featureFlagsMap.CARD_PAYMENT);
  const hasAssetsFeature = useHasFeature(featureFlagsMap.ASSETS);
  const hasReportsGraphsFeature = useHasFeature(featureFlagsMap.REPORT_GRAPHS);
  const hasTicketsFeature = useHasFeature(featureFlagsMap.TICKETS);
  const hasLineTestFeature = useHasFeature(featureFlagsMap.LINE_TEST);
  const hasLogicMonitorFeature = useHasFeature(featureFlagsMap.LOGIC_MONITOR);
  const hasHelpFeature = useHasFeature(featureFlagsMap.HELP);
  const hasUsageAlertsFeature = useHasFeature(featureFlagsMap.USAGE_ALERTS);
  const hasBundleAlertsFeature = useHasFeature(featureFlagsMap.BUNDLE_ALERTS);
  const hasContactsFeature = useHasFeature(featureFlagsMap.CONTACTS);
  const hasCostCentreManagementFeature = useHasFeature(featureFlagsMap.COST_CENTRE_MANAGEMENT);
  const hasMobileManagementFeature = useHasAnyFeature([
    featureFlagsMap.AUTO_SWITCH,
    featureFlagsMap.BILL_LIMIT,
    featureFlagsMap.DATA_ROAMING,
    featureFlagsMap.MOBILE_BARS,
    featureFlagsMap.SIM_LOCK,
    featureFlagsMap.SIM_ORDER,
    featureFlagsMap.SIM_SWAP,
    featureFlagsMap.SIM_UNLOCK,
  ]);
  const hasSimActivateFeature = useHasFeature(featureFlagsMap.SIM_ACTIVATE);
  const hasBillLimitAlertsFeature = useHasFeature(featureFlagsMap.BILL_LIMIT_ALERTS);

  // Permissions Check
  const hasCommunicationsPermission = useHasPermission(permissionCodes.COMMUNICATIONS);
  const hasUserManagePermission = useHasPermission(permissionCodes.USERS_MANAGE);
  const hasBillingManagePermission = useHasPermission(permissionCodes.BILLING_MANAGE);
  const hasAccountManagePermission = useHasPermissions([permissionCodes.ACCOUNT_MANAGE, permissionCodes.ALERTS_ACCESS_FEATURE]);
  const hasContactsViewPermission = useHasPermissions([
    permissionCodes.ACCOUNT_VIEW_CONTACTS,
    permissionCodes.ALERTS_ACCESS_FEATURE,
  ]);
  const hasPaymentsPermission = useHasPermission(permissionCodes.ACCOUNT_PAYMENTS);
  const hasMobileManagementPermission = useHasPermission(permissionCodes.MOBILE_MANAGEMENT);
  const hasAssetsPermission = useHasPermission(permissionCodes.VIEW_ASSETS);
  const hasAlertsPermission = useHasPermission(permissionCodes.ALERTS_ACCESS_FEATURE);
  const hasCostCentreManagementPermission = useHasPermission(permissionCodes.COST_CENTRE_MANAGE);
  const hasSupportPermission = useHasPermission(permissionCodes.SUPPORT_ACCESS);
  const hasLogicMonitorPermission = useHasPermission(permissionCodes.SUPPORT_LOGIC_MONITOR);

  const hasStatementsPermission = Boolean(useHasPermission(permissionCodes.BILLING_STATEMENT));
  const hasInvoicesPermission = Boolean(useHasPermission(permissionCodes.BILLING_STATEMENT));

  // User Level Authority Check
  const hasTicketsLevel = useHasAuthority(UserLevelFlag.Sub);

  const canView: Function = (type: string) => {
    switch (type) {
      case "home":
        return true;
      case "billing":
        return canView("bill-dashboard") || canView("bill-explorer") || canView("statements-invoices");
      case "bill-dashboard":
        return hasReportsGraphsFeature;
      case "bill-explorer":
        return hasBillingManagePermission;
      case "statements-invoices":
        return (hasStatementsFeature && hasStatementsPermission) || (hasInvoicesFeature && hasInvoicesPermission);
      case "asset-management":
        return (
          canView("search-assets") ||
          canView("mobile-management") ||
          canView("mobile-activation") ||
          canView("manage-cost-centres")
        );
      case "search-assets":
        return hasAssetsFeature && hasAssetsPermission;
      case "mobile-management":
        return hasMobileManagementFeature && hasMobileManagementPermission;
      case "mobile-activation":
        return hasSimActivateFeature && hasMobileManagementPermission;
      case "manage-cost-centres":
        return hasCostCentreManagementFeature && hasCostCentreManagementPermission;
      case "bill-limit-alerts":
        return hasBillLimitAlertsFeature && hasMobileManagementPermission;
      case "manage-account":
        return canView("contacts") || canView("account-details") || canView("payments");
      case "contacts":
        return hasContactsFeature && hasContactsViewPermission;
      case "account-details":
        return hasAccountManagePermission;
      case "payments":
        return hasPaymentsPermission && (hasDirectDebitFeature || hasCardFeature);
      case "alerts":
        return canView("usage-alerts") || canView("bundle-alerts") || canView("bill-limit-alerts");
      case "usage-alerts":
        return hasUsageAlertsFeature && hasAlertsPermission;
      case "bundle-alerts":
        return hasBundleAlertsFeature && hasAlertsPermission;
      case "support":
        return (
          canView("support-dashboard") ||
          canView("support-tickets") ||
          canView("line-test") ||
          canView("help") ||
          canView("logic-monitor")
        );
      case "support-dashboard":
        return false;
      case "support-tickets":
        return hasSupportPermission && hasTicketsFeature && hasTicketsLevel;
      case "line-test":
        return hasSupportPermission && hasLineTestFeature;
      case "help":
        return hasSupportPermission && hasHelpFeature;
      case "logic-monitor":
        return hasLogicMonitorFeature && hasLogicMonitorPermission;
      case "manage-users":
        return hasUserManagePermission;
      case "communications":
        return hasCommunicationsPermission && hasCommunicationsFeature;
      default:
        return false;
    }
  };

  const isPageActive = (pages: string[]): boolean => {
    return pages.some((p) => history.location.pathname.startsWith(p));
  };

  const [openBilling, setOpenBilling] = React.useState(isPageActive([...billPages, ...statementPages, ...billDashPages]));
  const [openAccount, setOpenAccount] = React.useState(
    isPageActive([...accountDetailPages, ...manageContactsPages, ...paymentDetailsPages])
  );
  const [openAssets, setOpenAssets] = React.useState(
    isPageActive([...assetsPages, ...mobilePages, ...activateMobilePages, ...manageCostCentresPages])
  );
  const [openSupport, setOpenSupport] = React.useState(isPageActive([...supportPages]));
  const [openAlerts, setOpenAlerts] = React.useState(
    isPageActive([...alertPages, ...bundleAlertsPages, ...billLimitAlertsPages])
  );

  const drawer = (
    <div data-cy="nav-drawer">
      <div className={classes.navbar}>
        <div
          data-cy="nav-drawer-logo"
          style={{
            width: "200px",
            height: "110px",
            background: `url('${logo}') center / contain no-repeat`,
          }}
        />
      </div>
      <MasqueradingChip />
      <List disablePadding={false}>
        {canView("home") && (
          <ListItem className={classes.listItem} data-cy="nav-drawer-item-home" onClick={() => history.push("/")} button>
            <DashboardIcon className={classes.icon} />
            <ListItemText primary="Home" classes={{ primary: classes.itemText }} />
          </ListItem>
        )}
        {canView("billing") && (
          <>
            <Divider className={classes.divider} />
            <ListItem
              button
              className={classes.listItem}
              onClick={() => setOpenBilling(!openBilling)}
              data-cy="nav-drawer-item-billing"
            >
              <Description className={classes.icon} />
              <ListItemText primary="Billing" classes={{ primary: classes.itemText }} />
              {openBilling ? <ExpandMore className={classes.moreIcon} /> : <ChevronRightIcon className={classes.moreIcon} />}
            </ListItem>
            <Collapse in={openBilling} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider className={classes.divider} />
                {canView("bill-dashboard") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to={"/bill-dashboard-monthly"}
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive(billDashPages)}
                    data-cy="nav-drawer-item-bill-dashboard"
                  >
                    <ListItemText primary="Billing Dashboard" classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("bill-explorer") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/bills"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive(billPages)}
                    data-cy="nav-drawer-item-bills"
                  >
                    <ListItemText primary="Bill Explorer" classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("statements-invoices") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to={
                      hasStatementsFeature && hasStatementsPermission
                        ? "/statements"
                        : hasInvoicesFeature && hasInvoicesPermission
                        ? "/invoices"
                        : ""
                    }
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive(statementPages)}
                    data-cy="nav-drawer-item-statements"
                  >
                    <ListItemText
                      primary={getStatementsAndInvoicesLabel(
                        hasStatementsFeature && hasStatementsPermission,
                        hasInvoicesFeature && hasInvoicesPermission
                      )}
                      classes={{ primary: classes.itemTextNested }}
                    />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}
        {canView("asset-management") && (
          <>
            <Divider className={classes.divider} />
            <ListItem
              button
              className={classes.listItem}
              onClick={() => setOpenAssets(!openAssets)}
              data-cy="nav-drawer-item-asset-management"
            >
              <NetworkCheck className={classes.icon} />
              <ListItemText primary={"Asset Management"} classes={{ primary: classes.itemText }} />
              {openAssets ? <ExpandMore className={classes.moreIcon} /> : <ChevronRightIcon className={classes.moreIcon} />}
            </ListItem>
            <Collapse in={openAssets} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider className={classes.divider} />
                {canView("search-assets") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/assets"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive(assetsPages)}
                    data-cy="nav-drawer-item-assets"
                  >
                    <ListItemText primary="Search Assets" classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("mobile-management") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/mobile-management"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive(mobilePages)}
                    data-cy="nav-drawer-item-manage-mobiles"
                  >
                    <ListItemText primary="Mobile Management" classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("mobile-activation") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/sim-activate"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive(activateMobilePages)}
                    data-cy="nav-drawer-item-activate-disconnect-mobiles"
                  >
                    <ListItemText primary="Activate/Disconnect Mobiles" classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("manage-cost-centres") && (
                  <>
                    <ListItem
                      button
                      className={classes.listItemNested}
                      component={NavLink}
                      to="/manage-cost-centres"
                      activeClassName={classes.subItemActive}
                      isActive={() => isPageActive(manageCostCentresPages)}
                      data-cy="nav-drawer-item-manage-cost-centres"
                    >
                      <ListItemText primary="Manage Cost Centres" classes={{ primary: classes.itemTextNested }} />
                    </ListItem>
                  </>
                )}
              </List>
            </Collapse>
          </>
        )}
        {canView("manage-account") && (
          <>
            <Divider className={classes.divider} />
            <ListItem
              button
              className={classes.listItem}
              onClick={() => setOpenAccount(!openAccount)}
              data-cy="nav-drawer-item-account"
            >
              <AccountBox className={classes.icon} />
              <ListItemText primary={"Account Management"} classes={{ primary: classes.itemText }} />
              {openAccount ? <ExpandMore className={classes.moreIcon} /> : <ChevronRightIcon className={classes.moreIcon} />}
            </ListItem>
            <Collapse in={openAccount} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider className={classes.divider} />
                {canView("contacts") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/contacts"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive(manageContactsPages)}
                    data-cy="nav-drawer-item-manage-contacts"
                  >
                    <ListItemText primary="Manage Contacts" classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("account-details") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/account"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive(accountDetailPages)}
                    data-cy="nav-drawer-item-account-details"
                  >
                    <ListItemText primary={`${userLevels?.[30]?.name} Details`} classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("payments") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to={hasDirectDebitFeature ? "/payment-type" : hasCardFeature ? "/card-payment" : ""}
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive(paymentPages)}
                    data-cy="nav-drawer-item-account-payment-details"
                  >
                    <ListItemText primary="Payment Details" classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}
        {canView("alerts") && (
          <>
            <Divider className={classes.divider} />
            <ListItem
              button
              className={classes.listItem}
              onClick={() => setOpenAlerts(!openAlerts)}
              data-cy="nav-drawer-item-alerts"
            >
              <VisibilityIcon className={classes.icon} />
              <ListItemText primary={"Alerts"} classes={{ primary: classes.itemText }} />
              {openAlerts ? <ExpandMore className={classes.moreIcon} /> : <ChevronRightIcon className={classes.moreIcon} />}
            </ListItem>
            <Collapse in={openAlerts} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider className={classes.divider} />
                {canView("usage-alerts") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/usage-alerts"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive([alertPages?.[0]])}
                    data-cy="nav-drawer-item-usage-alerts"
                  >
                    <ListItemText primary="Usage Alerts" classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("bundle-alerts") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/bundle-alerts"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive([bundleAlertsPages?.[0]])}
                    data-cy="nav-drawer-item-bundle-alerts"
                  >
                    <ListItemText primary="Bundle Alerts" classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("bill-limit-alerts") && (
                  <>
                    <ListItem
                      button
                      className={classes.listItemNested}
                      component={NavLink}
                      to="/bill-limit-alerts-cli"
                      activeClassName={classes.subItemActive}
                      isActive={() => isPageActive(billLimitAlertsPages)}
                      data-cy="nav-drawer-item-bill-limit-alerts"
                    >
                      <ListItemText primary="Mobile Bill Limit Alerts" classes={{ primary: classes.itemTextNested }} />
                    </ListItem>
                  </>
                )}
              </List>
            </Collapse>
          </>
        )}
        {canView("support") && (
          <>
            <Divider className={classes.divider} />
            <ListItem
              button
              className={classes.listItem}
              onClick={() => setOpenSupport(!openSupport)}
              data-cy="nav-drawer-item-support"
            >
              <InboxIcon className={classes.icon} />
              <ListItemText primary={"Support"} classes={{ primary: classes.itemText }} />
              {openSupport ? <ExpandMore className={classes.moreIcon} /> : <ChevronRightIcon className={classes.moreIcon} />}
            </ListItem>
            <Collapse in={openSupport} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <Divider className={classes.divider} />
                {canView("support-dashboard") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/support"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive([supportPages?.[0]])}
                    data-cy="nav-drawer-item-support-dashboard"
                  >
                    <ListItemText primary="Dashboard" classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("help") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/help"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive([supportPages?.[3]])}
                    data-cy="nav-drawer-item-help"
                  >
                    <ListItemText primary={"Help"} classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("support-tickets") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/tickets"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive([supportPages?.[1]])}
                    data-cy="nav-drawer-item-tickets"
                  >
                    <ListItemText primary={"Tickets"} classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("line-test") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/line-test"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive([supportPages?.[2]])}
                    data-cy="nav-drawer-item-line-test"
                  >
                    <ListItemText primary={"Line Test"} classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
                {canView("logic-monitor") && (
                  <ListItem
                    button
                    className={classes.listItemNested}
                    component={NavLink}
                    to="/logic-monitor"
                    activeClassName={classes.subItemActive}
                    isActive={() => isPageActive([supportPages?.[4]])}
                    data-cy="nav-drawer-item-logic-monitor"
                  >
                    <ListItemText primary={"Monitoring"} classes={{ primary: classes.itemTextNested }} />
                  </ListItem>
                )}
              </List>
            </Collapse>
          </>
        )}
        {canView("communications") && (
          <>
            <Divider className={classes.divider} />
            <ListItem className={classes.listItem} data-cy="nav-drawer-item-communications" onClick={() => history.push("/communications")} button>
              <DescriptionIcon className={classes.icon} />
              <ListItemText primary="Communications" classes={{ primary: classes.itemText }} />
            </ListItem>
          </>
        )}
        {canView("manage-users") && (
          <>
            <Divider className={classes.divider} />
            <ListItem className={classes.listItem} data-cy="nav-drawer-item-users" onClick={() => history.push("/users")} button>
              <SettingsIcon className={classes.icon} />
              <ListItemText primary="Manage Users" classes={{ primary: classes.itemText }} />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );
  return (
    <nav className={classes.drawer}>
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === "rtl" ? "right" : "left"}
          open={mobileOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
          }}
          variant="permanent"
          open
        >
          {drawer}
        </Drawer>
      </Hidden>
    </nav>
  );
}

const useStyles = makeStyles<Theme, { drawerWidth: number }>((theme: Theme) =>
  createStyles({
    navbar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    listItem: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      color: theme.palette.tertiary.contrastText,
      "&:hover": {
        backgroundColor: darken(theme.palette.tertiary.main, 0.1),
      },
    },
    listItemNested: {
      padding: `${theme.spacing(1)} 30px`,
      backgroundColor: lighten(theme.palette.tertiary.main, 0.3),
      "&:hover": {
        backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
      },
    },
    itemText: {
      fontSize: "14px",
    },
    itemTextNested: {
      fontSize: "14px",
      color: theme.palette.tertiary.contrastText,
    },
    itemActive: {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.15),
    },
    subItemActive: {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
    },
    icon: {
      marginRight: "20px",
      width: "20px",
      height: "20px",
    },
    moreIcon: {
      width: "18px",
      height: "18px",
    },
    divider: {
      backgroundColor: lighten(theme.palette.tertiary.main, 0.2),
    },
    drawer: (props) => ({
      [theme.breakpoints.up("sm")]: {
        width: props.drawerWidth,
        flexShrink: 0,
      },
      color: theme.palette.tertiary.contrastText,
    }),
    drawerPaper: (props) => ({
      width: props.drawerWidth,
      backgroundColor: theme.palette.tertiary.main,
      color: theme.palette.tertiary.contrastText,
    }),
  })
);

export default NavMenu;

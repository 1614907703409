export const genericError = (): string => {
  return "Service currently unavailable, please try again later";
};

export const getAlias = (original: string): string => {
  const aliases: { [key: string]: string } = {
    "Daisy Comms": "MyAccount",
  };
  return aliases[original] || original;
};

export const alertRequestQueuedMessage =
  "Your request has been queued. Please check the status of the alert on the Usage Alerts page for progress.";

import React from "react";
import { Table, TableRow, TableCell, TableBody, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useUsageSummary } from "../../hooks";
import GraphWidget from "./GraphWidget";
import { PeriodParam } from "../../../filter/types";
import { formatPrice } from "../../../billing/utils";
import { featureFlagsMap } from "../../../feature/utils";

interface Props {
  period?: PeriodParam;
}

const UsageSummary = ({ period }: Props) => {
  const classes = useStyles();
  const { data = [], isFetching, isError } = useUsageSummary(period);
  const hasError = isError || data?.length <= 0;

  const renderRow = (row: any, isHeader = false) => {
    const isTotals = row?.product === "Totals";
    const klass = isHeader ? classes.rowHeader : isTotals ? classes.rowTotal : classes.row;
    const col1 = row?.product;
    const col2 = `${isHeader ? row?.serviceCharges : formatPrice(row?.serviceCharges)}`;
    const col3 = `${isHeader ? row?.usageCharges : formatPrice(row?.usageCharges)}`;
    return (
      <TableRow key={row?.product}>
        <TableCell className={klass}>{col1}</TableCell>
        <TableCell className={klass}>{col2}</TableCell>
        <TableCell className={klass}>{col3}</TableCell>
      </TableRow>
    );
  };

  return (
    <GraphWidget
      title="Bill Summary"
      isLoading={isFetching}
      hasError={hasError}
      featureFlag={featureFlagsMap.BILL_SUMMARY_GRAPH}
      bodyPadding={0}
    >
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {renderRow(
            {
              product: "Product",
              serviceCharges: "Service Charge",
              usageCharges: "Usage Charges",
            },
            true
          )}
          {data.map((row) => renderRow(row))}
        </TableBody>
      </Table>
    </GraphWidget>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  table: {},
  row: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  rowHeader: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    fontWeight: "bold",
  },
  rowTotal: {
    padding: `
      ${theme.spacing(1.5)}px
      ${theme.spacing(2)}px
      ${theme.spacing(3)}px
    `,
    color: theme.palette.primary.main,
    fontWeight: "bold",
    borderBottom: "0px",
  },
}));

export default UsageSummary;

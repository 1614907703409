import React from "react";

import { Box, TableSortLabel } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import { SortRequestParam } from "./helpers";

interface SortableHeaderLabelProps {
  label: string;
  name: string;
  sort: SortRequestParam;
}
export const SortableHeaderLabel = ({ label, name, sort }: SortableHeaderLabelProps) => {
  const classes = useStyles();

  return sort.column !== name ? (
    <Box display="flex">
      {label}
      <span className={classes.labelWrap}>
        <TableSortLabel active className={classes.inactiveSortIcon} />
      </span>
    </Box>
  ) : (
    <span className={classes.activeSortIcon}>{label}</span>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    labelWrap: {
      marginBottom: "2px",
      "& .Mui-active .MuiTableSortLabel-icon": {
        color: theme.palette.primary.main,
      },
    },
    inactiveSortIcon: {
      "& .MuiTableSortLabel-icon": {
        color: `${theme.palette.grey[200]} !important`,
      },
    },
    activeSortIcon: {
      "& .MuiTableSortLabel-icon": {
        color: theme.palette.primary.main,
      },
    },
  }),
);

export default SortableHeaderLabel;

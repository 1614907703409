import { User } from "oidc-client-ts";
import {
  AssociateSoftwareTokenCommand,
  GetUserCommand,
  SetUserMFAPreferenceCommand,
  VerifySoftwareTokenCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { cognitoIdentityProviderClient as cognitoClient } from "../_app/lib/cognito";
import api from "../_app/api";
import { getUser, updateUser } from "./sso-api";
import { AuthConfig, ERROR, IdentityUser, SUCCESS, VerifyTotpResponse } from "./types";

export const getIdentityUser = async () => {
  return getUser();
};

export const updateIdentityUser = async (user: IdentityUser) => {
  const updatedUser = new User(user);
  return updateUser(updatedUser);
};

export const getAuthConfig = async () => {
  const { data }: { data: AuthConfig } = await api({
    method: "GET",
    url: "/auth-config/customer-portal",
    context_injection: false,
    skip_bearer_injection: true,
    skip_context_path_injection: true,
    skip_token_refresh: true,
  });

  if (!data?.tokenIssuerUrl || !data?.hostedLoginPageDomain || !data?.customerPortalClientId) {
    throw Error(`Missing some of the mandatory attributes in the returned data: ${data}`);
  }

  return data;
};

export const associateTotp = async (accessToken: string): Promise<string | null> => {
  try {
    const command = new AssociateSoftwareTokenCommand({
      AccessToken: accessToken,
    });
    const response = await cognitoClient.send(command);
    return response.SecretCode ?? null;
  } catch (error) {
    console.error("Error associating TOTP:", error);
    return null;
  }
};

export const verifyTotp = async (accessToken: string, otpCode: string): Promise<VerifyTotpResponse> => {
  try {
    const command = new VerifySoftwareTokenCommand({
      AccessToken: accessToken,
      UserCode: otpCode,
    });
    const response = await cognitoClient.send(command);
    return response.Status === SUCCESS ? SUCCESS : ERROR;
  } catch (error) {
    console.error("Error verifying TOTP:", error);
    return ERROR;
  }
};

export const enableMfa = async (accessToken: string): Promise<boolean> => {
  const command = new SetUserMFAPreferenceCommand({
    SoftwareTokenMfaSettings: { Enabled: true, PreferredMfa: true },
    AccessToken: accessToken,
  });

  try {
    await cognitoClient.send(command);
    return true;
  } catch (error) {
    console.error("Error enabling MFA:", error);
    return false;
  }
};

export const disableMfa = async (accessToken: string): Promise<boolean> => {
  const command = new SetUserMFAPreferenceCommand({
    SoftwareTokenMfaSettings: { Enabled: false },
    AccessToken: accessToken,
  });

  try {
    await cognitoClient.send(command);
    return true;
  } catch (error) {
    console.error("Error disabling MFA:", error);
    return false;
  }
};

export const checkMFAStatus = async (accessToken: string): Promise<boolean> => {
  const command = new GetUserCommand({ AccessToken: accessToken });
  try {
    const response = await cognitoClient.send(command);
    const mfaSettings = response.UserMFASettingList;
    return Boolean(mfaSettings && mfaSettings?.length > 0);
  } catch (error) {
    console.error("Error fetching user MFA settings:", error);
    return false;
  }
};

import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { Grid } from "@mui/material";
import EditContactForm from "../components/EditContactForm";
import { useContactUpdate, useSingleContact } from "../hooks";
import { featureFlagsMap } from "../../feature/utils";
import { UICard } from "../../_app/components";
import { Contact } from "../types";

export const EditContact = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const classes = useStyles();

  const {
    data: contact,
    isFetching: contactFetching,
    isSuccess: contactSuccess,
  } = useSingleContact(id);

  const { mutate: executeContactUpdate, isLoading: isUpdating } = useContactUpdate({
    onSuccess: () => {
      history.push("/contacts");
    }
  });

  const onSubmit = (contact: Contact) => executeContactUpdate(contact);
  const hasPermissionToManageAllContacts = useHasPermission(permissionCodes.ACCOUNT_CONTACTS);

  return (
    <Main
      title="Edit Contact"
      data-cy="edit-contact-page"
      accessPermission={[permissionCodes.ACCOUNT_CONTACTS, permissionCodes.ALERTS_ACCESS_FEATURE]}
      featureFlag={featureFlagsMap.CONTACTS_UPDATE}
      isLoading={contactFetching}
    >
      {contactSuccess && (
        <Grid className={classes.ctr}>
          <UICard>
            <EditContactForm
              contact={contact}
              isSubmitting={isUpdating}
              handleSubmit={onSubmit}
              handleCancel={() => history.goBack()}
              onlyAlerts={!hasPermissionToManageAllContacts}
            />
          </UICard>
        </Grid>
      )}
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      padding: "0",
      marginTop: theme.spacing(1.5)
    }
  })
);

export default EditContact;

import { getTopicById, getArticleStructure } from "./api";
import { useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { HelpArticle, ArticleStructure } from "./types";

export function useArticleTopic(id: string, options = {}) {
  return useQuery<HelpArticle, AjaxResponse>(["articleTopic", id], () => getTopicById(id), {
    ...options,
  });
}

export function useArticleStructure(options = {}) {
  return useQuery<ArticleStructure[], AjaxResponse>(["articleStructure"], () => getArticleStructure(), {
    ...options,
  });
}

import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import Tabbed from "../../_app/layouts/Tabbed";
import UIBack from "../../_app/components/UIBack";
import BarringDetails from "../../bars/components/BarringDetails";
import Details from "../components/details/Details";
import { useSingleAsset } from "../hooks";
import { useHasFeature } from "../../feature/hooks";
import { featureFlagsMap } from "../../feature/utils";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import AssetUsageAlerts from "../components/AssetUsageAlerts";

export const AssetDetails = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const { data: asset, isLoading } = useSingleAsset(id);
  const [selectedTab, setSelectedTab] = useState("details");
  const hasMobileBarsFeature = useHasFeature(featureFlagsMap.MOBILE_BARS);
  const hasMobileManagementPermission = useHasPermission(permissionCodes.MOBILE_MANAGEMENT);
  const hasBars = Boolean(hasMobileBarsFeature && hasMobileManagementPermission && asset?.assetType === "MOBILE");
  const location = useLocation<{ selectedTab?: string }>();

  const hasAlertsAccessFeature = useHasFeature(featureFlagsMap.USAGE_ALERTS);
  const hasUsageAlertsPermission = useHasPermission(permissionCodes.ALERTS_ACCESS_FEATURE);
  const canViewUsageAlerts = hasAlertsAccessFeature && hasUsageAlertsPermission;

  const onTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    setSelectedTab(value);
  };

  // use history state when redirecting to the specific tab
  useEffect(() => {
    if (location.state && location.state.selectedTab !== undefined) {
      setSelectedTab(location.state.selectedTab);
    }
  }, [location.state]);

  const renderContent = () => {
    switch (selectedTab) {
      case "details":
        return <Details showBars={() => setSelectedTab("barring")} hasBars={hasBars} />;
      case "barring":
        return <BarringDetails assetId={asset?.id} />;
      case "usage-alerts":
        return <AssetUsageAlerts assetId={id} />;
      default:
        return null;
    }
  };

  return (
    <Main
      title="Asset Details"
      data-cy="asset-details-page"
      isLoading={isLoading}
      featureFlag={featureFlagsMap.ASSETS}
      accessPermission={permissionCodes.VIEW_ASSETS}
      needSelectedAccount={true}
    >
      <Box width="100%">
        <UIBack btnProps={{ className: classes.back }} />
        <Tabbed
          selectedTab={selectedTab}
          tabs={[
            { value: "details", label: "Details", testId: "details-tab" },
            { value: "barring", label: "Barring", testId: "barring-tab", hidden: !hasBars },
            { value: "usage-alerts", label: "Usage Alerts", testId: "usage-alerts-tab", hidden: !canViewUsageAlerts },
          ]}
          handleTabChange={onTabChange}
        >
          <div className={classes.wrap}>{renderContent()}</div>
        </Tabbed>
      </Box>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    back: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    tabList: {
      paddingBottom: theme.spacing(4),
    },
    wrap: {
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(1),
      },
      [theme.breakpoints.up("sm")]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  }),
);

export default AssetDetails;

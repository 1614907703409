import React, { useEffect } from "react";
import { Route, RouteProps, useHistory } from "react-router-dom";
import { login } from "../sso-api";
import { useIdentityUser } from "../hooks";
import { useHubUserExist } from "../../user/hooks";
import { useMyAccounts } from "../../account/hooks";
import LoadingOverlay from "../../_app/components/LoadingOverlay";
import { getCachedContextHierarchy, getContextPath } from "../../context/utils";

export const PrivateRoute = ({ children, ...rest }: RouteProps) => {
  const history = useHistory();
  const context = getContextPath();

  const { data: identityUser, isFetching: identityUserFetching } = useIdentityUser();
  const hasValidIdentityUser = identityUser && !identityUser?.expired;

  const { data: doesHubUserExist, isFetching: hubUserExistenceFetching } = useHubUserExist();

  const { data: groups } = useMyAccounts({ enabled: Boolean(context) });
  const hasGroupContext = Boolean(getCachedContextHierarchy()?.lastParentId);
  const hasMultipleGroups = groups && groups.length > 1;

  // Log in if we don't have a valid IdP user
  useEffect(() => {
    if (!identityUserFetching && !hasValidIdentityUser) login();
  }, [hasValidIdentityUser, identityUserFetching]);

  // Redirect to the `no-user` page if we don't have a user in Hub
  useEffect(() => {
    if (hasValidIdentityUser && !hubUserExistenceFetching && !doesHubUserExist) {
      setTimeout(() => history.replace("/no-user"));
    }
  }, [hasValidIdentityUser, hubUserExistenceFetching, doesHubUserExist, history]);

  // Redirect to the Group selection if a group was not selected yet
  useEffect(() => {
    if (!hasGroupContext && hasMultipleGroups) {
      setTimeout(() => history.replace("/groups"));
    }
  }, [hasMultipleGroups, hasGroupContext, history]);

  if (doesHubUserExist) {
    return <Route {...rest}>{children}</Route>;
  }

  return <LoadingOverlay />;
};

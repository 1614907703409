import React, { useEffect } from "react";
import { Theme, Typography, Button, CardMedia } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { permissionCodes } from "../../permission/hooks";
import { useLogicMonitor } from "../hooks";
import { useHistory } from "react-router-dom";
import Secondary from "../../_app/layouts/Secondary";
import Main from "../../_app/layouts/Main";
import { UICard } from "../../_app/components/UICard";
import advert from "../../logic-monitor/images/lm-advert.png";
import { featureFlagsMap } from "../../feature/utils";

interface Props {
  openInTab?: boolean;
}

export const LogicMonitor = ({ openInTab = false }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { isReady, isLoading, error, launchInNewTab, iframeProps } = useLogicMonitor();

  useEffect(() => {
    if (isReady && openInTab) {
      launchInNewTab();
      history.goBack();
    }
  }, [isReady, openInTab, launchInNewTab, history]);

  const onExit = () => history.push("/");
  if (error) {
    return (
      <Main
        title="Monitoring"
        data-cy="logic-monitor-page"
        isLoading={isLoading}
        featureFlag={featureFlagsMap.LOGIC_MONITOR}
        accessPermission={permissionCodes.SUPPORT_LOGIC_MONITOR}
      >
        <UICard className={classes.advert}>
          <CardMedia component="img" image={advert} alt="Monitoring Advert" />
        </UICard>
      </Main>
    );
  }

  return (
    <Secondary
      data-cy="logic-monitor-page"
      isLoading={isLoading}
      featureFlag={featureFlagsMap.LOGIC_MONITOR}
      accessPermission={permissionCodes.SUPPORT_LOGIC_MONITOR}
    >
      {Boolean(isReady && !openInTab) && (
        <div className={classes.ctr}>
          <iframe {...iframeProps} title="Monitoring" className={classes.iframe} />
          <div className={classes.exitSnack}>
            <Typography variant="h5">
              LogicMonitor is currently active. Please ensure you save changes before exiting the app.
            </Typography>
            <Button variant="contained" className={classes.exitBtn} onClick={onExit}>
              Exit LogicMonitor
            </Button>
          </div>
        </div>
      )}
    </Secondary>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      flexDirection: "column",
      width: "100vw",
      height: "100vh",
    },
    advert: {
      maxWidth: 800,
      marginTop: theme.spacing(1),
      padding: "0px !important",
      "& > div:first-child": {
        padding: "0px !important",
      },
    },
    iframe: {
      width: "100%",
      height: "calc(100% - 64px)",
      border: "none",
    },
    exitSnack: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(3),
      padding: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
      height: 64,
      color: "#fff",
    },
    exitBtn: {
      backgroundColor: "#36474f",
      color: "#fff",
    },
  })
);

export default LogicMonitor;

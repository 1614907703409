import config from "../config";

export const GTM_NONCE = new Date().getTime();

if (config?.configGoogleTagManager?.gtmId) {
  const TagManager = require("react-gtm-module-nonce");
  TagManager.initialize({
    ...config.configGoogleTagManager,
    nonce: GTM_NONCE,
  });
}

import React, { useEffect, useState, ChangeEvent } from "react";
import { Theme, Paper, TextField, Grid, InputAdornment, Chip, Typography, Tooltip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useAlertMeasures } from "../../hooks";
import { useValidation } from "../../../form/hooks";
import { humanize } from "../../../_app/utils/format";
import { isEmptyObject } from "../../../_app/utils";
import InfoIcon from "@mui/icons-material/Info";
import SelectDropdown from "../../../form/components/SelectDropdown";
import { UsageAlert, AlertMeasure } from "../../types";

interface Props {
  usageAlert: UsageAlert;
  setAlert: Function;
  isEdit?: boolean;
}

export const SelectCriteria = ({ usageAlert, setAlert, isEdit }: Props) => {
  const classes = useStyles();
  const [alertCriteriaParsed, setAlertCriteriaOptions] = useState([] as any);
  const [criteria, setCriteria] = useState({} as any);
  const [name, setName] = useState(usageAlert?.name);

  useEffect(() => {
    if (isEdit && usageAlert?.criteria?.[0] && isEmptyObject(criteria)) {
      setCriteria(usageAlert?.criteria?.[0]);
      setName(usageAlert?.name);
    }
  }, [isEdit, usageAlert, criteria]);

  const { data: alertMeasures } = useAlertMeasures(usageAlert?.product?.toUpperCase(), {
    enabled: !!usageAlert?.product,
  });

  const unit = alertMeasures?.find((m) => m.name === criteria?.measure?.name)?.unit;

  // Validation
  const validationConfig: any = {
    threshold: {
      required: true,
      validate: (val: any) => {
        if (val && (isNaN(val) || val < 0)) return "Invalid threshold";
        if (unit !== "£" && val?.indexOf?.(".") > 0 && !Number.isInteger(val)) {
          return "Whole number required";
        }
      },
    },
  };

  const { validationErrors, validateSingle, validateAll } = useValidation(validationConfig, {
    threshold: usageAlert?.criteria?.[0]?.threshold,
  });

  useEffect(() => {
    if (unit) validateAll();
    // @hack
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  useEffect(() => {
    const criteriaOptions = alertMeasures?.map((action: AlertMeasure) => {
      return {
        id: action.name,
        label: action.value,
        value: action.name,
      };
    });

    setAlertCriteriaOptions(criteriaOptions);
  }, [alertMeasures, usageAlert]);

  const onAlertCriteriaMeasureChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCriteria((criteria: any) => ({
      ...criteria,
      measure: { name: e.target.value, assetType: "", unit: "" },
    }));
  };

  const onAlertCriteriaThresholdChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCriteria((criteria: any) => ({
      ...criteria,
      threshold: e.target.value,
    }));
  };
  useEffect(() => {
    if (!isEmptyObject(criteria)) {
      setAlert((old: any) => {
        return { ...old, criteria: [criteria], name: isEdit ? name : old.name };
      });
    }
  }, [isEdit, name, criteria, setAlert]);

  const tooltipText = `The alert will be sent when the current month's usage exceeds the below criteria.`;
  return (
    <div className={classes.ctr}>
      <Paper className={classes.paper}>
        <div className={classes.tooltip}>
          <Typography variant="h4" gutterBottom>
            When do you want to be alerted?
          </Typography>
          <Tooltip title={tooltipText} placement="right" arrow className={classes.infoIcon}>
            <InfoIcon color="secondary" data-cy="tooltip-criteria" />
          </Tooltip>
        </div>
        <Grid container direction="row" spacing={1}>
          {Boolean(isEdit) && (
            <div className={classes.selectionChips}>
              <Chip
                label={`Level: ${humanize(usageAlert.level, ["CLI"])}`}
                data-cy="level-chip"
                size="small"
                variant="outlined"
              />
              <Chip label={`Product Type: ${humanize(usageAlert.product)}`} data-cy="type-chip" variant="outlined" size="small" />
              {Boolean(usageAlert.cli) && (
                <Chip label={`Identifier: ${usageAlert.cli}`} data-cy="identifier-chip" variant="outlined" size="small" />
              )}
            </div>
          )}
          {Boolean(isEdit) && (
            <Grid item xs={12} md={3}>
              <TextField
                required
                fullWidth
                variant="outlined"
                size="small"
                name="name"
                label="Name"
                value={usageAlert.name || ""}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>
          )}
          <Grid item xs={12} md={3}>
            <SelectDropdown
              ctrClass={classes.textField}
              name="measure"
              label="Alert Criteria"
              value={usageAlert?.criteria?.[0]?.measure?.name || ""}
              data={alertCriteriaParsed}
              onChange={onAlertCriteriaMeasureChange}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              className={classes.textField}
              name="threshold"
              label="Threshold"
              value={usageAlert?.criteria?.[0]?.threshold || ""}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onAlertCriteriaThresholdChange(event);
                validateSingle(event.target.name, event.target.value);
              }}
              error={Boolean(validationErrors.threshold)}
              helperText={validationErrors.threshold}
              InputProps={{
                [unit === "£" ? "startAdornment" : "endAdornment"]: (
                  <InputAdornment position="start">{unit || ""}</InputAdornment>
                ),
              }}
            />
          </Grid>
          {(unit === "Mb" || unit === "MB") && (
            <Grid item alignContent="center">
              <Tooltip title={"1Gb = 1024Mb"} placement="right" arrow>
                <InfoIcon color="secondary" className={classes.icon} data-cy="tooltip-treshold" />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      marginBottom: "30px",
    },
    paper: {
      padding: theme.spacing(3),
    },
    textField: {
      marginBottom: theme.spacing(3),
    },
    alert: {
      width: "max-content",
      marginBottom: 12,
    },
    selectionChips: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      gap: theme.spacing(1),
    },
    tooltip: {
      display: "flex",
      gap: theme.spacing(1.5),
      maxWidth: "400px",
    },
    icon: {
      marginTop: theme.spacing(1),
      marginLeft: theme.spacing(1),
      cursor: "pointer",
    },
    infoIcon: {
      cursor: "pointer",
      marginTop: "-3px",
    },
  })
);

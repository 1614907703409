import React, { ReactNode, useEffect } from "react";
import { CssBaseline } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import UILoader from "../components/UILoader";

interface SecondaryProps {
  children: ReactNode;
  title?: string;
  isLoading?: boolean;
  className?: string;
}

function Public({ title, isLoading, children, className, ...rest }: SecondaryProps) {
  const classes = useStyles();

  useEffect(() => {
    document.title = title || "";
  }, [title]);

  return (
    <div className={classes.root} {...rest}>
      <CssBaseline />
      <div className={`${classes.main} ${className}`}>
        {isLoading ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <>{children}</>
        )}
      </div>
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      minHeight: "100vh",
      width: "100vw",
      backgroundColor: "#ffffff",
    },
    main: {
      minWidth: "100%",
      minHeight: "100vh",
    },
    error: {
      padding: theme.spacing(2),
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
      flex: 1,
    },
  })
);

export default Public;

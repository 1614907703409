import React from "react";
import Main from "../../_app/layouts/Main";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import BillLimitAlertsTabBar from "../components/BillLimitAlerts/BillLimitAlertsTabBar";
import { Grid } from "@mui/material";
import { useHistory, useParams } from "react-router-dom";
import BillLimitAlertsUpdateForm from "../components/BillLimitAlerts/BillLimitAlertsUpdateForm";
import { useSingleAsset, useUpdateBillLimitPrefs } from "../../asset/hooks";
import { useFeedbackAlerts } from "../../_app/hooks";
import { useQueryClient } from "react-query";
import { UICard } from "../../_app/components";
import { featureFlagsMap } from "../../feature/utils";

export const BillLimitAlertsCliDetail = () => {
  const classes = useStyles();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const { data: asset } = useSingleAsset(id);
  const queryClient = useQueryClient();
  const {setFeedbackAlertSuccess} = useFeedbackAlerts();

  const updateBillLimitPrefs = useUpdateBillLimitPrefs({
    onSuccess: (response: string) => {
      setFeedbackAlertSuccess(response)
      history.push("/bill-limit-alerts-cli");
      queryClient.invalidateQueries(["bill-limit-prefs", id]);
    },
  });

  const updatePrefs = (sms: boolean, email: boolean, smsValue?: string, emailValue?: string) => {
    updateBillLimitPrefs.mutate({
      assetIds: [id],
      operations: [
        {
          sendSms: sms,
          sendEmail: email,
          notifyMobileNumber: smsValue,
          notifyEmailAddress: emailValue,
        },
      ],
    });
  };

  return (
    <Main
      title="Bill Limit Alerts"
      data-cy="bill-limit-alerts-cli-page"
      needSelectedAccount
      titleProps={{ className: classes.title }}
      featureFlag={featureFlagsMap.BILL_LIMIT_ALERTS}
    >
      <BillLimitAlertsTabBar selectedTab="bill-limit-alerts-cli">
        <UICard>
          <Grid item>
            <BillLimitAlertsUpdateForm assetId={id} cli={asset?.cli} callback={updatePrefs} />
          </Grid>
        </UICard>
      </BillLimitAlertsTabBar>
    </Main>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      marginBottom: 10,
    },
  })
);

export default BillLimitAlertsCliDetail;

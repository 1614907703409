import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { AxiosResponse } from "axios";
import MUIDataTable, { MUIDataTableState } from "mui-datatables";
import Main from "../../_app/layouts/Main";
import { createStyles, makeStyles, UIAlert, UIButton, UIGrid, UITheme } from "../../_app/components";
import { rowParser } from "../../_app/components/Table/helpers";
import UILoader from "../../_app/components/UILoader";
import FiltersCard from "../../filter/components/FiltersCard";
import { getAssetsCsv } from "../api";
import { useFilterQueries } from "../../filter/hooks";
import { useAssets, useAssetsFilters } from "../hooks";
import { permissionCodes } from "../../permission/hooks";
import { downloadFile, extractFilenameFromHeaders } from "../../_app/utils";
import { filtersIdMap } from "../../filter/utils";
import { formatCostCentreCode } from "../../account/utils";
import { featureFlagsMap } from "../../feature/utils";
import { Asset } from "../types";
import { useFeedbackAlerts } from "../../_app/hooks";

const ASSETS_FILTERS: string = "ASSETS";

export const SearchAssets = () => {
  const classes = useStyles();
  const history = useHistory();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const [canView, setCanView] = useState(false);
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const queries = useFilterQueries();

  const liveOnly = JSON.parse(queries.find((q) => q.id === filtersIdMap.STATUS)?.value ?? "false");

  const { data: assets, isFetching } = useAssets(
    liveOnly,
    page,
    rowCount,
    queries.filter((q) => q.id !== filtersIdMap.STATUS),
    { enabled: canView }
  );

  const handleView = (id: string) => {
    history.push(`/assets/${id}`);
  };

  const handleDownload = async () => {
    try {
      const params = queries || [];
      const response: any = await getAssetsCsv(params);
      const filename = extractFilenameFromHeaders(response) || `asset-export.csv`;
      downloadFile(response?.data, filename);
    } catch (err) {
      setFeedbackAlertError((err as AxiosResponse).data?.message || "Cannot download asset export due to an error.")
    }
  };

  let total = assets?.total || 0;
  const parsed =
    assets?.list?.map((item: Asset) => {
      return rowParser([
        item?.cli,
        item?.tag,
        formatCostCentreCode(item?.costCentre),
        item?.accountName,
        item?.productType,
        item?.status,
        <UIButton variant="text" color="primary" data-cy={`view-asset-${item?.cli}`} onClick={() => handleView(item?.id)}>
          View
        </UIButton>,
      ]);
    }) || [];

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  return (
    <Main
      title="Search Assets"
      data-cy="search-assets-page"
      needSelectedAccount={true}
      featureFlag={featureFlagsMap.ASSETS}
      accessPermission={permissionCodes.VIEW_ASSETS}
      accessGrantedCb={() => setCanView(true)}
    >
      <UIGrid item xs={12} md={12}>
        <UIAlert severity="info" className={classes.info}>
          View all the assets you have with us on this account. See details about your assets and assign them tags/usernames.
        </UIAlert>
        <UIGrid item>
          <FiltersCard
            fetchHook={useAssetsFilters}
            hasReset={true}
            usage={ASSETS_FILTERS}
            disableDownload={assets?.total === 0}
            hasDownload={true}
            downloadFileType="CSV"
            handleDownloadClick={handleDownload}
          />
        </UIGrid>
        {isFetching ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <>
            <div data-cy="asset-selection-table" className={classes.tableCtr}>
              <MUIDataTable
                title=""
                data={parsed}
                columns={[
                  "CLI/Identifier",
                  "Tag",
                  "Cost Centre",
                  "Account",
                  "Product Type",
                  "Status",
                  { label: " ", name: "View" },
                ]}
                options={{
                  onTableChange: handleTableChange,
                  pagination: true,
                  page,
                  rowsPerPage: rowCount,
                  rowsPerPageOptions: rowCountOptions,
                  count: total,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  serverSide: true,
                  viewColumns: false,
                  sort: false,
                  search: false,
                  selectableRows: "none",
                  rowHover: true,
                  setTableProps: () => ({
                    size: "small",
                  }),
                }}
              />
            </div>
          </>
        )}
      </UIGrid>
    </Main>
  );
};

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    tableCtr: {
      width: "100%",
    },
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    info: {
      width: "fit-content",
      alignItems: "center",
      marginBottom: theme.spacing(3),
    },
  })
);

export default SearchAssets;

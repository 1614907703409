import React, { ReactNode } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";

export interface Props {
  show: boolean;
  title: string;
  handleSubmit: () => void;
  handleCancel: () => void;
  cancelLabel: string;
  submitLabel: string;
  children: ReactNode;
}

export const FiltersDialog = (props: Props) => {
  const classes = useStyles();

  return (
    <Dialog open={props.show} onClose={props.handleCancel}>
      <div className={classes.dialog}>
        <DialogTitle>{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{props.children}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <Button onClick={props.handleCancel} data-cy="cancel-filter-dialog-button">
            {props.cancelLabel}
          </Button>
          <Button variant="contained" color="primary" onClick={props.handleSubmit} data-cy="confirm-filter-dialog-button">
            {props.submitLabel}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      margin: theme.spacing(1),
    },
    buttons: {
      margin: theme.spacing(2),
      marginTop: theme.spacing(1),
    },
  })
);

export default FiltersDialog;

import * as Sentry from "@sentry/react";
import { createBrowserHistory } from "history";
import config from "../config";

const history = createBrowserHistory();

Sentry.init({
  dsn: config.configSentry?.dsn,
  environment: process.env.REACT_APP_STAGE,
  integrations: [Sentry.reactRouterV5BrowserTracingIntegration({ history })],
  tracesSampleRate: 0.1,
  ignoreErrors: ["Non-Error promise rejection captured with value: undefined"],
});

import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useHistory } from "react-router-dom";
import { User, UserProfile } from "oidc-client-ts";
import { addMinutes, subMinutes } from "date-fns";
import { updateUser } from "./sso-api";
import { AjaxResponse } from "../_app/api";
import { checkMFAStatus, getIdentityUser } from "./api";
import { decodeJwt } from "../_app/utils";
import { useFeedbackAlerts, useQueryParam } from "../_app/hooks";
import { CONTEXT_HIERARCHY_KEY } from "../context/utils";
import { DecodedJWT, IdentityUser } from "./types";
import { isCognitoUser } from "./utils";

export function useIdentityUser(options?: any) {
  return useQuery<IdentityUser | null, AjaxResponse>(["identityUser"], getIdentityUser, {
    ...options,
  });
}

export function useJWTAuth() {
  const history = useHistory();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const [isLoading, setIsLoading] = useState(true);
  const jwtParam = useQueryParam("jwt");
  const sessionLengthMins = 20;

  const handleSuccess = useCallback(() => {
    setIsLoading(false);
    window.location.replace(window.location.origin);
  }, []);

  const setInSession = useCallback(
    (jwtEncoded: string | string[]) => {
      const cognitoUser = isCognitoUser();
      try {
        const jwt: DecodedJWT = decodeJwt(jwtEncoded);
        if (!jwt) throw new Error("Invalid token.");
        const token = cognitoUser ? { id_token: jwtEncoded } : { access_token: jwtEncoded };
        const user = {
          profile: {
            act: true,
            ...jwt,
          } as Partial<UserProfile>,
          ...token,
          token_type: "Bearer",
          scope: "openid profile roles email HUB_FE_API",
          expires_at: Math.trunc(addMinutes(new Date(), sessionLengthMins).getTime() / 1000),
        } as IdentityUser;
        const updatedUser = new User(user);
        updateUser(updatedUser)
          .then(() => history.push(`/groups`))
          .catch((err) => {
            throw new Error("Impersonation failed.");
          });
        handleSuccess();
      } catch (e) {
        setFeedbackAlertError(e);
      }
    },
    [handleSuccess],
  );

  useEffect(() => {
    if (jwtParam) {
      setInSession(jwtParam);
    }
  }, [jwtParam, setInSession]);

  return { isLoading };
}

export function useJWTLogout() {
  useEffect(() => {
    const sessionKey = `oidc.user:${window.authConfig?.tokenIssuerUrl}:${window.authConfig?.customerPortalClientId}`;
    const existing = JSON.parse(sessionStorage.getItem(sessionKey) || "");
    sessionStorage.setItem(
      sessionKey,
      JSON.stringify({
        ...existing,
        expires_at: Math.trunc(subMinutes(new Date(), 1).getTime() / 1000),
      }),
    );
    localStorage.removeItem(CONTEXT_HIERARCHY_KEY);
    window.location.replace(window.location.origin);
  }, []);
}

export function useMfaEnabled(accessToken: string, options = {}) {
  return useQuery<boolean, AjaxResponse>(["mfa-enabled"], () => checkMFAStatus(accessToken), {
    ...options,
  });
}

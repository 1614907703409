import React from "react";
import { Alert, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Tabbed from "../../../_app/layouts/Tabbed";
import { UserLevelFlag } from "../../../user-level/types";
import { useHasAuthority } from "../../../user-level/hooks";

interface Props {
  selectedTab: string;
  children: any;
}

const BillLimitAlertsTabBar = ({ selectedTab, children }: Props) => {
  const classes = useStyles();
  const hasSubaccountLevel = useHasAuthority(UserLevelFlag.Sub);

  return (
    <div className={classes.wrap}>
      <Alert severity="info" className={classes.info}>
        If you have set a bill limit for any of your mobile numbers, we will send notifications to the number when you have nearly
        reached, or have reached your bill limit. We can alert an additional mobile number or email address here.
      </Alert>
      <Tabbed
        selectedTab={selectedTab}
        tabs={[
          {
            value: "bill-limit-alerts-cli",
            label: "CLI",
            hidden: false,
          },
          {
            value: "bill-limit-alerts-account",
            label: "Default for Account",
            hidden: !hasSubaccountLevel,
          },
        ]}
      >
        {children}
      </Tabbed>
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrap: {
      flexDirection: "column",
      width: "100%",
    },
    info: {
      alignItems: "center",
      marginBottom: theme.spacing(1),
      width: "fit-content",
    },
  })
);

export default BillLimitAlertsTabBar;

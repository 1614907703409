import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { withWidget } from "./components/Widget";
import { useGetBalance, useGetOverdue } from "../../billing/hooks";
import { formatNumber } from "../../_app/utils/format";
import { featureFlagsMap } from "../../feature/utils";
import { Typography, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { UICard } from "../../_app/components/UICard";
import { useHistory } from "react-router-dom";

interface Props {
  hasManage?: boolean;
}

const BalanceOverdue = ({ hasManage = true }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const { data: balance, isFetching } = useGetBalance();
  const { data: overdue, isFetching: isFetchingOver } = useGetOverdue();
  return (
    <UICard isFetching={isFetching || isFetchingOver}>
      <Typography variant="h6">Balance / Overdue</Typography>
      <div className={classes.value}>
        <Typography variant="h2">{formatNumber(balance || 0, 2, "£")}</Typography>
        <Typography variant="h2">
          &nbsp;&nbsp;/&nbsp;&nbsp;
          {formatNumber(overdue || 0, 2, "£")}
        </Typography>
      </div>
      {hasManage && (
        <Button
          className="widget-button"
          color="primary"
          variant="contained"
          onClick={() => history.push("/statements")}
          endIcon={<ArrowForwardIcon />}
        >
          View
        </Button>
      )}
    </UICard>
  );
};

const useStyles = makeStyles((theme) => ({
  value: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "flex-end",
    justifyContent: "center",
  },
}));

export default withWidget(BalanceOverdue, "balance-overdue", featureFlagsMap.BALANCE_WIDGET);

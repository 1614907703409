import React, { useState } from "react";
import { useCostCentreCreate, useCostCentreDelete, useCostCentreUpdate } from "../hooks";
import CostCentreActionButtons from "./CostCentreActionButtons";
import CliTable from "./CliTable";
import {
  createStyles,
  lighten,
  makeStyles,
  UIBox,
  UIGrid,
  UISkeleton,
  UIStyledAccordion,
  UITheme,
  UITypography
} from "../../_app/components";
import { CostCentre } from "../types";
import { UNASSIGNED_CODE } from "../api";

interface Props {
  costCentre: CostCentre;
  nestedDepth?: number;
  hasAdd?: boolean;
  expanded?: boolean;
  [key: string]: any;
}

function CostCentreAccordion({
  costCentre,
  nestedDepth = 0,
  hasAdd = true,
  expanded = false,
  containerProps = {},
  ...props
}: Props) {
  const classes = useStyles();
  const [open, setOpen] = useState(expanded);
  const isUnassigned = costCentre.id === UNASSIGNED_CODE;

  const { mutate: executeCostCentreCreate } = useCostCentreCreate();
  const { mutate: executeCostCentreUpdate } = useCostCentreUpdate();
  const { mutate: executeCostCentreDelete } = useCostCentreDelete();

  const handleExpand = () => {
    setOpen(!open);
  };

  return (
    <UIStyledAccordion
      containerProps={{
        "data-cy": "cost-centre-item",
        ...containerProps,
        expanded: open,
        className: classes.container,
      }}
      onClick={handleExpand}
      headerProps={{
        className: classes.header,
      }}
      headerLeft={
        <div className={classes.left}>
          <UITypography variant="h4" data-cy="cost-centre-data">
            {costCentre.name ?? <UISkeleton width={150} />}
          </UITypography>
        </div>
      }
      headerRight={
        !isUnassigned ? (
          <CostCentreActionButtons
            costCentre={costCentre}
            hasAdd={hasAdd}
            handleDelete={executeCostCentreDelete}
            handleUpdate={executeCostCentreUpdate}
            handleSubmit={executeCostCentreCreate}
          />
        ) : null
      }
      bodyProps={{ className: classes.children }}
      bodyContent={
        <>
          <UIGrid container>
            <UIGrid item xs={12} lg={9}>
              <UIBox pb={3}>
                <CliTable
                  costCentre={isUnassigned ? "" : costCentre.id}
                  shouldFetchData={Boolean(open && !costCentre.assets)}
                  assets={costCentre.assets}
                />
              </UIBox>
            </UIGrid>
          </UIGrid>
          <div className={classes.children}>
            {open &&
              costCentre.subCostCentres &&
              costCentre.subCostCentres
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((subCC: any) => (
                  <CostCentreAccordion
                    key={subCC.id}
                    costCentre={subCC}
                    nestedDepth={nestedDepth + 1}
                    containerProps={{
                      className: `${classes.child} ${
                        nestedDepth === 0 ? classes.child : nestedDepth > 0 ? classes.grandChild : undefined
                      }`,
                    }}
                    hasAdd={!nestedDepth}
                    expanded={expanded}
                  />
                ))}
          </div>
        </>
      }
      {...props}
    />
  );
}

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    container: {
      "& .MuiAccordionDetails-root": {
        paddingRight: 0,
      },
    },
    header: {
      maxHeight: "80px",
      "& .MuiAccordionSummary-expandIconWrapper": {
        marginLeft: theme.spacing(2),
      },
    },
    left: {
      display: "flex",
      flexDirection: "row",
      gap: theme.spacing(3),
      alignItems: "center",
      "& h4": {
        maxHeight: "40px",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      [theme.breakpoints.down("lg")]: {
        marginRight: theme.spacing(3),
      },
    },
    children: {
      display: "flex",
      gap: 20,
      flex: 1,
      flexDirection: "column",
    },
    child: {
      background: lighten(theme.palette.grey[100], 0.6),
    },
    grandChild: {
      "& .MuiAccordionSummary-expandIcon": {
        marginLeft: "-6px",
        marginRight: "-26px",
      },
    },
  })
);

export default CostCentreAccordion;

import { Filter } from "../filter/types";

export const costCentreFilters = [
  {
    id: "51",
    name: "CLI/Identifier",
    type: "IDENTIFIER",
    resources: "/filters/COST_CENTRES/values/clis",
    values: [],
    options: {
      BULK_INPUT: true,
      SEARCH_BAR: true,
    },
    showToggle: true,
  },
  {
    id: "52",
    name: "Account",
    type: "IDENTIFIER",
    resources: "/filters/COST_CENTRES/values/accounts",
    values: [],
    options: {},
    showToggle: true,
  },
  {
    id: "53",
    name: "Tag",
    options: {
      BULK_INPUT: false,
      SEARCH_BAR: true,
    },
    resources: "/assets/tags",
    type: "IDENTIFIER",
    values: [],
    showToggle: true,
  },
  {
    id: "54",
    name: "Cost Centre",
    type: "IDENTIFIER",
    resources: "/filters/COST_CENTRES/values/costcentres",
    values: [],
    options: {
      UNASSIGNED: true,
    },
    showToggle: true,
  },
] as Filter[];

import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { Box, Divider, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import BillPackages from "../components/BillPackages";
import { useBillDetails, useCostsBreakdown, useUnbilledCosts } from "../hooks";
import { UNBILLED, formatPeriodToMonthYear } from "../utils";
import { formatDate, formatNumber } from "../../_app/utils/format";
import { useUserLevelPrefix } from "../../user-level/hooks";
import { BillDetail } from "../types";

interface BillRow {
  name: string;
  value: string | undefined;
  isTotal?: boolean;
  cy?: string;
}

export const PeriodDetails = () => {
  const classes = useStyles();
  const userLevelPrefix = useUserLevelPrefix();
  const { periodId, invoiceId } = useParams<{
    periodId: string;
    invoiceId: string;
  }>();
  const { search } = useLocation();
  const isUnbilled = search.includes(UNBILLED);
  const { data: unbilledCosts, isFetching: isFetchingUnbilled } = useUnbilledCosts({ enabled: isUnbilled });

  const billDetails = useBillDetails([periodId], true, {
    enabled: !isUnbilled,
  });
  const billDetail: BillDetail = (billDetails?.[0]?.data as BillDetail[])?.find(
    (bd) => bd.systemInvoiceRef === invoiceId
  ) as BillDetail;

  const { data: costsBreakdown, isLoading: isLoadingBreakdown } = useCostsBreakdown(periodId, invoiceId, {
    enabled: Boolean(periodId && invoiceId),
  });

  const isLoading = billDetails.some((query) => query.isLoading) || isLoadingBreakdown || isFetchingUnbilled;

  const BillRow = ({ name, value, isTotal, cy }: BillRow) => {
    return (
      <div className={classes.row} data-cy={cy}>
        <div className={isTotal ? classes.isTotal : ""}>{name}</div>
        <div className={isTotal ? classes.isTotal : ""}>{value}</div>
      </div>
    );
  };

  return (
    <Main
      title={`${formatPeriodToMonthYear(periodId)}${isUnbilled ? " (Unbilled)" : ""}`}
      data-cy={`details-page-${periodId}-${invoiceId}`}
      showNav={false}
      showToolbar={false}
      showFooter={false}
      needSelectedAccount={true}
      isLoading={isLoading}
    >
      <>
        <div className={classes.grid}>
          <Box pb={4}>
            {Boolean(!isUnbilled && billDetail) && (
              <>
                <Typography variant="h4">Invoice No.: {billDetail.invoiceNumber}</Typography>
                <Typography variant="h4" paragraph>
                  Invoice Date: {formatDate(billDetail.billDate, "dd MMM yyyy")}
                </Typography>
                <BillRow name="Net" value={formatNumber(billDetail.net, 2, "£")} cy="bill-price" />
                <BillRow name="VAT" value={formatNumber(billDetail.vat, 2, "£")} cy="bill-vat" />
                <Divider />
                <BillRow
                  name={`${userLevelPrefix ? userLevelPrefix + " " : ""}Total`}
                  value={formatNumber(billDetail.gross, 2, "£")}
                  isTotal
                  cy="bill-total"
                />
              </>
            )}
            {isUnbilled && (
              <BillRow
                name="Net"
                value={formatNumber(unbilledCosts?.find((cost) => cost.billingPeriod === periodId)?.net, 2, "£")}
                cy="bill-price"
              />
            )}
          </Box>
          <>
            {costsBreakdown
              ? Object.keys(costsBreakdown).map((packageName) => {
                  const cost = costsBreakdown[packageName];
                  if (!cost) return null;
                  return <BillPackages key={packageName} cost={cost} name={packageName} isUnbilled={isUnbilled} />;
                })
              : null}
          </>
        </div>
      </>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    row: {
      display: "flex",
      justifyContent: "space-between",
      padding: "5px 0px",
      fontSize: "18px",
      fontWeight: 500,
      alignItems: "center",
    },
    isTotal: {
      fontWeight: 700,
      padding: "6px 0px",
    },
    grid: {
      width: "100%",
      padding: 15,
      paddingTop: 30,
      justifyContent: "space-around",
      [theme?.breakpoints?.down("md")]: {
        justifyContent: "flex-start",
      },
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
    },
  })
);

export default PeriodDetails;

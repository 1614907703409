import { useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { getMyPermissions, getPermissions, Permission } from "./api";
import { flatten } from "../_app/utils";
import { useFeedbackAlerts } from "../_app/hooks";

export const permissionCodes = {
  USERS_MANAGE: "USERS_MANAGE",
  BILLING_MANAGE: "BILLING_MANAGE",
  BILLING_DOWNLOAD: "BILLING_DOWNLOAD",
  BILLING_STATEMENT: "BILLING_STATEMENT",
  ACCOUNT_MANAGE: "ACCOUNT_MANAGE",
  ACCOUNT_DPA: "ACCOUNT_DPA",
  ACCOUNT_VIEW_CONTACTS: "ACCOUNT_VIEW_CONTACTS",
  ACCOUNT_CONTACTS: "ACCOUNT_CONTACTS",
  ACCOUNT_PAYMENTS: "ACCOUNT_PAYMENTS",
  ASSET_MANAGEMENT: "ASSET_MANAGEMENT",
  VIEW_ASSETS: "VIEW_ASSETS",
  MOBILE_MANAGEMENT: "MOBILE_MANAGEMENT",
  ALERTS_ACCESS_FEATURE: "ALERTS_ACCESS_FEATURE",
  COST_CENTRE_MANAGE: "COST_CENTRE_MANAGE",
  SUPPORT_ACCESS: "SUPPORT_ACCESS",
  SUPPORT_LOGIC_MONITOR: "SUPPORT_LOGIC_MONITOR",
  COMMUNICATIONS: "COMMUNICATIONS",
};

export function usePermissions(userId?: string, roleId?: string, levelId?: string, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<Permission[], AjaxResponse>(
    ["permissions", userId, roleId, levelId],
    () => getPermissions({ userId, roleId, levelId }),
    {
      ...options,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message || "Failed to fetch permissions");
      }
    }
  );
}

export function useMyPermissions(options = {}) {
  return useQuery<Permission[], AjaxResponse>(["user-permissions"], () => getMyPermissions(), { ...options });
}

export function useHasPermission(targetPermCode: string, options = {}) {
  const { data: permissions } = useMyPermissions(options);
  if (!targetPermCode) return true;
  const perms = permissions || [];
  const flat = perms?.concat(flatten(perms, "permissions"));
  const match = flat?.find((p: any) => p?.code === targetPermCode);
  return Boolean(match?.value);
}

export function useHasPermissions(targetPermCodes: string[], options = {}) {
  const { data: permissions } = useMyPermissions(options);
  if (!targetPermCodes || !targetPermCodes.length) return true;
  const perms = permissions || [];
  const flat = perms?.concat(flatten(perms, "permissions"));
  const match = flat?.find((p: any) => targetPermCodes.indexOf(p?.code) !== -1);
  return Boolean(match?.value);
}

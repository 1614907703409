import React, { useEffect, useState } from "react";
import { SvgIconComponent } from "@mui/icons-material";
import PersonIcon from "@mui/icons-material/Person";
import LangIcon from "@mui/icons-material/Language";
import PlaneIcon from "@mui/icons-material/LocalAirport";
import GenericIcon from "@mui/icons-material/DeviceUnknown";
import DonutIcon from "@mui/icons-material/DonutLarge";
import TextIcon from "@mui/icons-material/Textsms";
import AutoIcon from "@mui/icons-material/BrightnessAuto";
import {
  UICard,
  UIFormControlLabel,
  UIGrid,
  UIRadio,
  UIRadioGroup,
  UITheme,
  UITypography,
  createStyles,
  makeStyles,
} from "../../_app/components";
import { groupBy } from "../../_app/utils";
import { Bar } from "../types";

interface BarValue {
  name: string;
  value: boolean;
}

interface Props {
  bars?: Bar[];
  resetKey?: any;
  network?: string;
  updatedBars?: BarValue[];
  onChange?: (bar: Bar, newVal: boolean | string, disabledBars: Bar[]) => any;
}

const ICON_MAP: {
  [key: string]: SvgIconComponent;
} = {
  Generic: GenericIcon,
  Admin: PersonIcon,
  "International and Premium": LangIcon,
  "Calls and Data": DonutIcon,
  Texts: TextIcon,
  Roaming: PlaneIcon,
  "Auto bars": AutoIcon,
};

function BarsUpdateForm({ bars = [], resetKey, network, onChange = () => null, updatedBars = [] }: Props) {
  const classes = useStyles();
  const [activeWhitelist, setActiveWhitelist] = useState<string[]>([]);
  const [categorised, setCategorised] = useState<any[]>([]);

  useEffect(() => {
    const getCompatibleBars = () => {
      let whitelist: string[] = [];
      let allBars: string[] = [];
      let oneActive = false;
      bars?.forEach((bar: Bar) => {
        allBars.push(bar.name);
        const barUpdated = updatedBars.find((b) => b.name === bar.name);
        if (
          (typeof bar.value === "boolean" && (barUpdated?.value || (!barUpdated && bar.value))) ||
          (barUpdated?.value && bar.value === null)
        ) {
          oneActive = true;
          const compatibleBars = bar.compatibleBars ?? [bar.name];
          whitelist = whitelist.length === 0 ? compatibleBars : whitelist.filter((val) => compatibleBars.includes(val));
        }
      });
      return oneActive ? whitelist : allBars;
    };

    const list = getCompatibleBars();
    if (list.length > 0) setActiveWhitelist(list);

    // categorise bars
    if (bars.length > 0) {
      const grouped = groupBy(bars || [], "group");
      setCategorised(
        Object.values(grouped).sort((a: any, b: any) => {
          const textA = a?.[0]?.group;
          const textB = b?.[0]?.group;
          if (textA === "Auto bars") {
            return 1;
          }
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
      );
    }
  }, [bars, updatedBars]);

  return (
    <UIGrid className={classes.ctr} key={resetKey} container spacing={4}>
      {categorised.map((cat: any[], index: number) => {
        const group = cat[0].group;
        const Icon = ICON_MAP[group] || ICON_MAP["Generic"];
        return (
          <UIGrid item xs={12} md={6} lg={4} key={group} data-cy="bars-card">
            <UICard
              elevation={3}
              height="fit-content"
              className={classes.card}
              headerTitle={
                <UITypography variant="h3" data-cy={group}>
                  {group}
                </UITypography>
              }
              headerProps={{ avatar: <Icon color="primary" />, className: classes.cardHeader }}
            >
              <div className={classes.cardContent}>
                <div className={classes.bar} key="bars-heading">
                  <div className={classes.onOff}>
                    <UITypography variant="h4">Bar</UITypography>
                    <UITypography variant="h4">Unbar</UITypography>
                  </div>
                </div>
                {cat?.map((bar: Bar) => {
                  const isDisabled = Boolean((activeWhitelist && !activeWhitelist.includes(bar.name)) || bar.readonly);
                  return (
                    <div className={classes.bar} key={bar.name} data-cy="bar-item">
                      <UITypography variant="body1" className={classes.barLabel} data-cy={bar.label}>
                        {bar.label}
                      </UITypography>
                      <UIRadioGroup
                        row
                        name={bar.name}
                        value={String(updatedBars.find((b) => b.name === bar.name)?.value ?? bar.value)}
                        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
                          return onChange(
                            {
                              ...bar,
                              network: bar.network || network,
                            },
                            ev.target.value === "true",
                            bars?.filter((bar2) => !bar.compatibleBars?.includes(bar2.name)) || []
                          );
                        }}
                        className={classes.radioGroup}
                      >
                        <UIFormControlLabel
                          control={<UIRadio color="primary" className={classes.radio} />}
                          disabled={isDisabled}
                          value="true"
                          label=""
                          color="primary"
                          className={classes.radioLabel}
                        />
                        <UIFormControlLabel
                          control={<UIRadio color="primary" className={classes.radio} />}
                          disabled={isDisabled}
                          value="false"
                          label=""
                          className={classes.radioLabel}
                        />
                      </UIRadioGroup>
                    </div>
                  );
                })}
              </div>
            </UICard>
          </UIGrid>
        );
      })}
    </UIGrid>
  );
}

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    radioGroup: {
      gap: "16px",
    },
    radioLabel: {
      marginRight: "8px",
    },
    radio: {
      "&.MuiRadio-colorPrimary.Mui-disabled": {
        color: "lightgray",
      },
    },
    ctr: {
      minHeight: "60vh",
      "& .MuiCardHeader-avatar": {
        marginRight: theme.spacing(1),
      },
    },
    card: {
      height: "100%",
    },
    cardHeader: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(2),
    },
    cardContent: {
      paddingBottom: theme.spacing(2),
    },
    bar: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      "&:not(:first-of-type)": {
        "&:hover": {
          backgroundColor: theme.palette.grey[50],
        },
      },
    },
    barLabel: {
      padding: "10px 10px",
      width: "60%",
    },
    onOff: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
      paddingRight: 4,
      gap: "20px",
    },
  })
);

export default BarsUpdateForm;

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import TabPanel from "@mui/lab/TabPanel";
import MUIDataTable, { MUIDataTableState } from "mui-datatables";
import { Role } from "../types";
import Main from "../../_app/layouts/Main";
import { useAllRoles, useRoleDelete } from "../hooks";
import { permissionCodes } from "../../permission/hooks";
import { formatDate } from "../../_app/utils/format";
import ManageRolesDeleteButton from "../components/ManageRolesDeleteButton";
import { rowParser } from "../../_app/components/Table/helpers";
import UILoader from "../../_app/components/UILoader";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Create";
import ManageUsersTabBar from "../../user/components/ManageUsersTabBar";
import { getIsTopLevelContext } from "../../context/utils";

export interface TableConfig {
  title: string;
  columns: string[];
}

const tableConfig: TableConfig = {
  title: "Roles",
  columns: ["Role name", "Created by", "Assigned to", "Created date", "", ""],
};

export const ManageRoles = () => {
  const classes = useStyles();
  const history = useHistory();
  const selectedTab = "roles";
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [parsedWithCreatedBy, setParsed] = useState<any>([]);
  const columns = tableConfig.columns;

  const { data: roles, isFetching, isError } = useAllRoles(page, rowCount);
  const { mutate: executeRoleDelete } = useRoleDelete();

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleRoleEdit = (role: Role) => history.push(`roles/${role?.id}`);
    const handleRoleDelete = (role: Role) => executeRoleDelete(role);

    const parsed = roles?.list?.map((r: Role) => {
      return rowParser([
        r.name,
        r.createdByName,
        `${r.assignedTo || 0} Users`,
        formatDate(r.createdAt.toString(), "dd-MM-yyyy HH:mm"),
        <Button variant="text" className={classes.rowButton} onClick={() => handleRoleEdit(r)} startIcon={<EditIcon />}>
          Edit
        </Button>,
        <ManageRolesDeleteButton role={r} deleteRole={handleRoleDelete} />,
      ]);
    });

    setParsed(parsed);
  }, [roles, executeRoleDelete, setParsed, classes, history]);

  const isTopLevel = getIsTopLevelContext();

  return (
    <Main
      title={
        <>
          Manage Roles
          {!isTopLevel && (
            <Button
              variant="contained"
              color="primary"
              data-cy="add-role-btn"
              startIcon={<AddIcon />}
              onClick={() => history.push("/role/new")}
            >
              <span className={classes.addBtnText}>Add Role</span>
            </Button>
          )}
        </>
      }
      data-cy="manage-roles-page"
      accessPermission={permissionCodes.USERS_MANAGE}
    >
      <ManageUsersTabBar selectedTab={selectedTab} showRoles={true}>
        <TabPanel className={classes.ctr} value={selectedTab}>
          {isFetching && !isError ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <div className={classes.tableCtr} data-cy="roles-table">
              <MUIDataTable
                title={tableConfig.title}
                data={parsedWithCreatedBy || []}
                columns={columns || []}
                options={{
                  onTableChange: handleTableChange,
                  pagination: true,
                  page,
                  rowsPerPage: rowCount,
                  rowsPerPageOptions: rowCountOptions,
                  count: roles?.total,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  sort: false,
                  search: false,
                  serverSide: true,
                  selectableRows: "none",
                  rowHover: true,
                  setTableProps: () => ({ size: "small" }),
                  viewColumns: false,
                  setRowProps: (row) => ({
                    "data-cy": `role-id-${row[0]}`,
                  }),
                }}
              />
            </div>
          )}
        </TabPanel>
      </ManageUsersTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      padding: "0",
    },
    tableCtr: {
      "& div:nth-child(3)": {
        padding: "0px",
      },
      "& td:nth-child(10)": {
        textAlign: "right",
        padding: "0px",
      },
      "& td:nth-child(12)": {
        textAlign: "right",
      },
    },
    rowButton: {
      fontWeight: "normal",
      color: theme.palette.tertiary.main,
    },
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    addBtnText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);

export default ManageRoles;

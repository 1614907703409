import { useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { User } from "../user/types";
import { getAllUserLevels } from "./api";
import { UserLevel, UserLevelFlag } from "./types";
import { useUserData } from "../user/hooks";

export function useAllUserLevels(options = {}) {
  return useQuery<UserLevel[], AjaxResponse>(["user-levels"], () => getAllUserLevels(), {
    keepPreviousData: true,
    ...options,
  });
}

export function useLeafUserLevel() {
  const { data: levels } = useAllUserLevels();
  return levels?.filter((l) => l.flag !== UserLevelFlag.Individual).reduce((max, cell) => Math.max(max, cell.id), 0) || 0;
}

export function useSubUserLevel() {
  return useUserLevel(UserLevelFlag.Sub);
}

export function useIndividualUserLevel() {
  return useUserLevel(UserLevelFlag.Individual);
}

export function useHeadUserLevel() {
  return useUserLevel(UserLevelFlag.Head);
}

export function useIsHeadOrLoweUserLevel(account: any) {
  const headLevel = useHeadUserLevel();
  return account?.id && headLevel && account?.level?.id >= headLevel?.id;
}

export function useUserLevel(level?: UserLevelFlag) {
  const { data: levels } = useAllUserLevels();
  return levels?.find((l) => l?.flag === level);
}

export function useTopUserLevel() {
  const { data: levels } = useAllUserLevels();
  return [...(levels || [])]?.sort((a, b) => a?.id - b?.id)?.[0];
}

export function useLowestUserLevel() {
  const { data: levels } = useAllUserLevels();
  return [...(levels || [])]?.sort((a, b) => b?.id - a?.id)?.[0];
}

export function useHasAuthority(levelFlag?: UserLevelFlag, user?: User) {
  const { userLevel } = useUserData();
  const minimumLevel = useUserLevel(levelFlag)?.id || 0;
  const doCheck = (user: any) => user?.userLevel <= minimumLevel;
  if (!levelFlag) return true;
  return doCheck(user || { userLevel });
}

export function useUserLevelPrefix() {
  const { userLevel } = useUserData();
  const levelsMap = useUserLevelMap();
  const sub = useSubUserLevel()?.id || 0;
  const indv = useIndividualUserLevel()?.id;
  if (userLevel === indv) return "My";
  return userLevel >= sub ? levelsMap?.[userLevel]?.name || "" : "";
}

export function useUserLevelMap() {
  const { data: userLevels } = useAllUserLevels();
  const map: any = {};
  userLevels?.forEach((level) => (map[level?.id] = level));
  return map;
}

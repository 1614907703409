import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import MUIDataTable, { MUIDataTableState } from "mui-datatables";
import Main from "../../_app/layouts/Main";
import { Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Ticket } from "../types";
import { useTickets } from "../hooks";
import { useHasFeature } from "../../feature/hooks";
import { formatDate } from "../../_app/utils/format";
import { permissionCodes } from "../../permission/hooks";
import { rowParser } from "../../_app/components/Table/helpers";
import UIHtml from "../../_app/components/UIHtml";
import { UserLevelFlag } from "../../user-level/types";
import { featureFlagsMap } from "../../feature/utils";

export const Tickets = () => {
  const classes = useStyles();
  const history = useHistory();
  const hasSupportDC = useHasFeature(featureFlagsMap.SUPPORT_DC);
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const canEdit = true;

  const { data: tickets, isFetching } = useTickets(page, rowCount);

  const handleAction = (action: string, ticket?: Ticket) => {
    if (!canEdit) return;
    switch (action) {
      case "add":
        if (hasSupportDC) {
          return history.push("/tickets/new-advanced");
        }
        history.push(`/tickets/new`);
        break;
      case "view":
      case "edit":
        history.push({
          pathname: `/tickets/details/${ticket?.id}`,
          state: ticket,
        });
        break;
      default:
        break;
    }
  };

  const columns = ["Account", "Our Ref", "Your Ref", "Subject", "Description", "Created", "Resolved", "Status", ""];
  const parsed =
    tickets?.list?.map((t: Ticket) => {
      return rowParser([
        t.accountName,
        t.ourRef,
        t.yourRef,
        t.subject,
        <UIHtml html={t.description} />,
        formatDate(t.created, "dd/MM/yyyy HH:mm:ss"),
        formatDate(t.resolved, "dd/MM/yyyy HH:mm:ss"),
        t.status,
        <Button
          color="primary"
          variant="contained"
          size="small"
          className={classes.viewLink}
          onClick={() => handleAction("view", t)}
        >
          View
        </Button>,
      ]);
    }) || [];

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  return (
    <Main
      title={
        <>
          Tickets
          {canEdit && (
            <Button
              variant="contained"
              color="primary"
              data-cy="add-ticket-btn"
              startIcon={<AddIcon />}
              onClick={() => handleAction("add")}
            >
              <span className={classes.addBtnText}>Create New Ticket</span>
            </Button>
          )}
        </>
      }
      data-cy="tickets-page"
      isLoading={isFetching}
      featureFlag={featureFlagsMap.TICKETS}
      levelFlag={UserLevelFlag.Sub}
      accessPermission={permissionCodes.SUPPORT_ACCESS}
      needSelectedAccount={true}
    >
      <Grid className={classes.ctr}>
        <div className={classes.tableCtr} data-cy="tickets-table">
          <MUIDataTable
            title=""
            data={parsed}
            columns={columns || []}
            options={{
              onTableChange: handleTableChange,
              pagination: true,
              page,
              rowsPerPage: rowCount,
              rowsPerPageOptions: rowCountOptions,
              count: tickets?.total,
              download: false,
              elevation: 1,
              print: false,
              responsive: "standard",
              selectToolbarPlacement: "none",
              filter: false,
              sort: false,
              search: false,
              selectableRows: "none",
              serverSide: true,
              rowHover: true,
              setTableProps: () => ({ size: "small" }),
              viewColumns: false,
              setRowProps: (row) => {
                return {
                  "data-cy": `ticket-id-${row?.[2]}`,
                };
              },
            }}
          />
        </div>
      </Grid>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      width: "100%",
      padding: "0",
      marginTop: theme.spacing(1.5),
    },
    tableCtr: {
      "& tr td": {
        maxWidth: "20vw",
        wordBreak: "break-word",
      },
      "& tr td:nth-child(6n)": {
        maxWidth: "130px",
        wordBreak: "break-all",
      },
      "& tr td:nth-child(7n)": {
        maxWidth: "130px",
        wordBreak: "break-all",
      },
      "& tr td:nth-child(8n)": {
        minWidth: "116px",
      },
    },
    addBtn: {
      position: "absolute",
      top: "95px",
      right: "35px",
    },
    addBtnText: {
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
    viewLink: { marginLeft: "-12px" },
  })
);

export default Tickets;

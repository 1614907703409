import React from "react";
import { Grid, Typography, Link } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useFooterLinks, useFooterWidget1, useFooterWidget2 } from "../../../brand/hooks";

function Footer() {
  const classes = useStyles();
  const links = useFooterLinks();
  const content1 = useFooterWidget1();
  const content2 = useFooterWidget2();

  return (
    <footer className={classes.root} data-cy="footer">
      <Grid>
        {links?.map((link: any, i: number) => (
          <React.Fragment key={link?.label}>
            <Link variant="caption" color="inherit" href={link?.url || undefined}>
              {link?.label}
            </Link>
            {i !== links?.length - 1 && <Typography variant="caption"> | </Typography>}
          </React.Fragment>
        ))}
      </Grid>
      <Grid>
        {Boolean(content1) && (
          <Typography variant="caption">
            {content1}
            <br />
          </Typography>
        )}
        {Boolean(content2) && <Typography variant="caption">{content2}</Typography>}
      </Grid>
    </footer>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: "#f0f4f8",
      textAlign: "center",
      padding: theme.spacing(2),
      "& > div": {
        padding: "10px",
      },
      "& a": {
        textDecoration: "none",
        color: theme.palette.secondary.main,
      },
    },
  })
);

export default Footer;

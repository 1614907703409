export const formatTableTypeParam = (tableType: string): string => {
  switch (tableType) {
    case "CLI":
      return "cli";
    case "ACCOUNT":
      return "account";
    case "COST_CENTRE":
      return "costcentre";
    default:
      return "";
  }
};

import api from "../_app/api";
import { convertFiltersToQueries } from "../filter/api";
import { GetBarsResponse, Bar, BarUpdateParams } from "./types";

export const getBars = async (params: any = {}) => {
  const { data }: { data: GetBarsResponse["bars"] } = await api({
    method: "GET",
    context_injection: true,
    url: `/assets/bars`,
    params,
  });
  return data;
};

export const getBarsByAssetId = async (assetId: string) => {
  const { data }: { data: GetBarsResponse } = await api({
    method: "GET",
    context_injection: true,
    url: `/assets/${assetId}/bars`,
  });
  return data;
};

export const getBarById = async (barId: string, assetId: string) => {
  const { data }: { data: Bar } = await api({
    method: "GET",
    context_injection: true,
    url: `/assets/${assetId}/bars/${barId}`,
  });
  return data;
};

export const updateBars = async (params: BarUpdateParams) => {
  const parsedParams = convertFiltersToQueries(params);
  const { data }: { data: any } = await api({
    method: "PUT",
    context_injection: true,
    url: "/assets/bars",
    data: parsedParams,
  });
  return data;
};

export const getBarsForClis = async (clis: any[]) => {
  return [
    {
      cli: "07664488336",
      account: "YWJ35776",
      bars: [
        { id: "1", label: "Stolen", value: false },
        {
          id: "2",
          label: "GPRS",
          value: false,
          disabled: true,
          disabledReason: "Bar not supported for this cli",
        },
        { id: "3", label: "Incoming Calls", value: false },
        { id: "4", label: "Incoming SMS", value: true },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: true },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: true },
        { id: "9", label: "International Roaming", value: false },
      ],
    },
    {
      cli: "07005577993",
      account: "YWJ35776",
      bars: [
        { id: "1", label: "Stolen", value: true },
        { id: "2", label: "GPRS", value: false },
        { id: "3", label: "Incoming Calls", value: false },
        { id: "4", label: "Incoming SMS", value: false },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: false },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: false },
        { id: "9", label: "International Roaming", value: true },
      ],
    },
    {
      cli: "07994466113",
      account: "YWJ35776",
      bars: [
        { id: "1", label: "Stolen", value: true },
        { id: "2", label: "GPRS", value: true },
        { id: "3", label: "Incoming Calls", value: true },
        { id: "4", label: "Incoming SMS", value: true },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: false, disabled: true },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: true },
        { id: "9", label: "International Roaming", value: true },
      ],
    },
    {
      cli: "07553322441",
      account: "ABC312345",
      bars: [
        { id: "1", label: "Stolen", value: true },
        { id: "2", label: "GPRS", value: false },
        { id: "3", label: "Incoming Calls", value: false },
        { id: "4", label: "Incoming SMS", value: false },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: false },
        { id: "7", label: "Outgoing MMS", value: true },
      ],
    },
    {
      cli: "07675567567",
      account: "ABC312345",
      bars: [
        { id: "1", label: "Stolen", value: false },
        { id: "2", label: "GPRS", value: true },
        { id: "3", label: "Incoming Calls", value: false },
        { id: "4", label: "Incoming SMS", value: true },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: true },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: true },
        {
          id: "9",
          label: "International Roaming",
          value: false,
          disabled: true,
          disabledReason: "Bar not supported for this cli",
        },
      ],
    },
    {
      cli: "07896785786",
      account: "ABC312345",
      bars: [
        { id: "1", label: "Stolen", value: false },
        { id: "2", label: "GPRS", value: false },
        { id: "3", label: "Incoming Calls", value: false },
        {
          id: "4",
          label: "Incoming SMS",
          value: false,
          disabled: true,
          disabledReason: "Bar not supported for this cli",
        },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: true },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: false },
        { id: "9", label: "International Roaming", value: true },
      ],
    },
    {
      cli: "079978867567",
      account: "ABC312345",
      bars: [
        { id: "1", label: "Stolen", value: true },
        { id: "2", label: "GPRS", value: true },
        { id: "3", label: "Incoming Calls", value: true },
        { id: "6", label: "Premium Call", value: false },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: true },
        { id: "9", label: "International Roaming", value: true },
      ],
    },
    {
      cli: "07333545666",
      account: "ABC312345",
      bars: [
        { id: "1", label: "Stolen", value: true },
        { id: "2", label: "GPRS", value: false },
        { id: "3", label: "Incoming Calls", value: false },
        { id: "4", label: "Incoming SMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: false },
        { id: "9", label: "International Roaming", value: true },
      ],
    },
  ];
};

import React from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useCostByProduct, useLineChartOptions } from "../../hooks";
import { Line } from "react-chartjs-2";
import GraphWidget from "./GraphWidget";
import { useHasFeature } from "../../../feature/hooks";
import { Query } from "../../../filter/types";
import { formatPrice } from "../../../billing/utils";
import { featureFlagsMap } from "../../../feature/utils";

const featureFlag = featureFlagsMap.USAGE_BY_PRODUCT_GRAPH;

interface Props {
  queries: Query[];
}

const CostByProduct = ({ queries }: Props) => {
  const classes = useStyles();
  const {
    data = [],
    isFetching,
    isError,
  } = useCostByProduct(queries, {
    enabled: useHasFeature(featureFlag),
  });
  const hasError = isError || data?.length <= 0;

  const options = useLineChartOptions(
    data.unit,
    undefined,
    (context: any) => {
      return formatPrice({
        gross: context?.raw,
        net: context?.raw,
        vat: 0,
        unit: data.unit,
      });
    },
    true
  );

  return (
    <GraphWidget title="Cost by Product" isLoading={isFetching} hasError={hasError} featureFlag={featureFlag} width={12}>
      <div className={classes.horizontalLineWrap}>
        <Line data={data} options={options} />
      </div>
    </GraphWidget>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  horizontalLineWrap: {
    position: "relative",
    minHeight: 410,
    width: "100%",
  },
}));

export default CostByProduct;

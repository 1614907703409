import { isBefore, isAfter, isSameDay } from "date-fns";

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function isEqual(val1: any, val2: any) {
  return val1 === val2;
}

export function isEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

/**
 * @param number UK mobile number
 */
export function isMobileNumber(number: string) {
  const re = /^(\+44)? ?07(\d{3} ?\d{3} ?\d{3})$/;
  return re.test(String(number));
}

export function isEmpty(val: any) {
  if (typeof val === "number") return false;
  if (Array.isArray(val)) return !val.length;
  return !val;
}

export function isEmptyObject(obj: any) {
  return Object.keys(obj).length === 0;
}
export function isDate(val: any) {
  return Boolean(Date.parse(val));
}

export function uniq(a: any[]) {
  return Array.from(new Set(a));
}

export function flatten(arr: any = [], nestedKey?: string) {
  const func = (a: any, b: any) => {
    let target;
    if (Array.isArray(b)) target = b;
    else if (nestedKey && Array.isArray(b?.[nestedKey])) target = b?.[nestedKey];
    return a.concat(target ? flatten(target, nestedKey) : b);
  };
  return arr.reduce(func, []);
}

export function percentage(percent: number, total: number) {
  return (percent / 100) * total;
}

export function randomFromInterval(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1) + min);
}

export function bufferToObject(buffer: any) {
  return JSON.parse(Buffer.from(buffer).toString("utf8"));
}

export const findMatchingById = (id: string, list: any[]) => {
  return list?.find((item) => item?.id === id);
};

export function errorRespParse(resp: any) {
  if (resp?.request?.responseType === "arraybuffer") {
    return bufferToObject(resp?.data);
  }
  return resp;
}

export function extractFilenameFromHeaders(response: any) {
  const regex = /filename="(.*?)"/g;
  const key = response?.headers?.["content-disposition"];
  const filename = regex.exec(key)?.[1];
  return filename;
}

export function downloadFile(data: any, filename: string, filetype?: string) {
  const link = document.createElement("a");
  const file = filetype ? `${filename}.${filetype}` : filename;
  link.href = window.URL.createObjectURL(new Blob([data]));
  link.setAttribute("download", file);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export function downloadPresignedFile(url: string) {
  if (url) window.location.href = url;
  return;
}


export function loadScript(src: string, async: boolean = false, id?: string, type?: string, onLoad?: any) {
  const tag = document.createElement("script");
  tag.async = async;
  tag.src = src;
  if (id) tag.id = id;
  if (type) tag.type = type;
  if (onLoad) tag.addEventListener("load", onLoad);
  document.body.appendChild(tag);
}

export function getUrlHost(url?: string) {
  if (!url) return "";
  return new URL(url).host;
}

export function groupBy(array: any[], key: any) {
  const output = array?.reduce(function (r: any, a: any) {
    r[a?.[key]] = r?.[a?.[key]] || [];
    r?.[a?.[key]].push(a);
    return r;
  }, Object.create(null));
  return output;
}

export function isArray(a: any) {
  return !!a && a?.constructor === Array;
}

export function isObject(a: any) {
  return !!a && a?.constructor === Object;
}

export function decodeJwt(token: any) {
  try {
    return JSON.parse(atob(token.split(".")[1]));
  } catch (e) {
    return null;
  }
}

export function isBetween(date: number | Date, from: number | Date, to: number | Date, inclusive: boolean = true) {
  const isBetween = isBefore(from, date) && isAfter(to, date);
  const isSame = isSameDay(date, from) || isSameDay(date, to);
  return isBetween || (inclusive && isSame);
}

export const onLinkClickHandler = (ev: React.MouseEvent<HTMLElement, MouseEvent>, history?: any) => {
  ev.preventDefault();
  const findHrefTarget = (ev: any): HTMLAnchorElement => {
    if (ev.currentTarget.href) return ev.currentTarget;
    if (ev.target.href) return ev.target;
    if (ev.target.parentElement.href) return ev.target.parentElement;
    return ev.target;
  };
  const target = findHrefTarget(ev);
  const link = target.href;
  if (link) {
    const url = new URL(link);
    if (url.origin === window.location.origin && history) {
      history.push({
        pathname: url.pathname,
        state: {
          payload: JSON.parse(target?.getAttribute?.("data-payload") || "{}"),
        },
      });
    } else {
      const whitelist: string[] = ["http:", "https:", "ftp:", "mailto:", "tel:"];
      if (whitelist.includes(url.protocol)) window.open(link, "_blank");
    }
  }
  return;
};

export function scrollPage(x: number = 0, y: number = 0) {
  if (document && document.body && document.body.scrollTo) {
    return document.body.scrollTo(x, y);
  }
}

export class TwoWayMap {
  map: { [key: PropertyKey]: any };
  reverseMap: { [key: PropertyKey]: any };
  constructor(map: { [key: PropertyKey]: any }) {
    this.map = map;
    this.reverseMap = {};
    for (const key in map) {
      const value = map[key];
      this.reverseMap[value] = key;
    }
  }
  get(key: any) {
    return this.map[key];
  }
  revGet(key: any) {
    return this.reverseMap[key];
  }
}

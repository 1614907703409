import React from "react";
import Main from "../../_app/layouts/Main";
import CustomerService from "../../account/components/AccountDetails/CustomerService";

export const ContactSupport = () => {
  return (
    <Main title="Contact Support" data-cy="contact-support-page" needSelectedAccount={true}>
      <CustomerService />
    </Main>
  );
};
export default ContactSupport;

import React, { useState } from "react";
import { Grid, Box, Typography, Theme, CircularProgress, Alert } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { CheckCircleRounded, RemoveCircle } from "@mui/icons-material";
import BarGroups from "./BarGroups";
import UIDialog from "../../_app/components/UIDialog";
import { useMedia, useReset } from "../../_app/hooks";
import { useBarsUpdate } from "../hooks";
import { BarUpdate } from "../types";
import { useMobileNetworks } from "../../asset/hooks";

interface Props {
  assetId?: string;
}

export const BarringDetails = ({ assetId = "" }: Props) => {
  const classes = useStyles();
  const { isBelowSm } = useMedia();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [updates, setUpdates] = useState<BarUpdate[]>([]);
  const [resetKey, reset] = useReset("bars-form");
  const { mutate: executeBarsUpdate, data: successMessage, isLoading, isSuccess, isError, reset: resetUpdate } = useBarsUpdate();
  const { data: networks } = useMobileNetworks([assetId]);

  const onUpdate = () => {
    if (!updates.length) return;
    executeBarsUpdate({
      assetIds: [assetId],
      operations: updates.map((u) => ({
        name: u.name,
        value: u.value,
        network: u.network ?? networks?.[0],
      })),
    });
    onReset();
  };

  const onReset = () => {
    reset();
    setUpdates([]);
  };

  const onClose = () => {
    resetUpdate();
    setShowConfirmDialog(false);
  };

  const getDialogTitle = () => {
    if (isSuccess) return "Your request has been sent";
    if (isError) return "Request failed";
    return "Confirm changes";
  };

  return (
    <div data-cy="barring-details">
      <BarGroups
        assetId={assetId}
        updates={updates}
        setUpdates={setUpdates}
        disableApply={!updates.length}
        resetKey={resetKey}
        onReset={onReset}
        onApply={() => setShowConfirmDialog(true)}
      />
      {Boolean(showConfirmDialog) && (
        <UIDialog
          title={getDialogTitle()}
          open={showConfirmDialog}
          actions={
            Boolean(isSuccess || isError)
              ? [
                  {
                    label: "Close",
                    props: {
                      onClick: onClose,
                      variant: "outlined",
                      color: "primary",
                    },
                  },
                ]
              : [
                  {
                    label: "Cancel",
                    props: {
                      onClick: () => setShowConfirmDialog(false),
                      variant: "outlined",
                      color: "primary",
                      disabled: isLoading,
                    },
                  },
                  {
                    label: "Confirm",
                    props: {
                      onClick: onUpdate,
                      variant: "contained",
                      color: "primary",
                      disabled: isLoading,
                      startIcon: isLoading ? <CircularProgress color="inherit" size={18} /> : undefined,
                    },
                  },
                ]
          }
          maxWidth="sm"
        >
          <>
            {updates.map((u) => (
              <Grid container key={u.label}>
                <Grid item xs={isBelowSm ? 6 : 5}>
                  <Box display="flex" alignItems="center" pb={0.5}>
                    {u.value ? (
                      <>
                        <CheckCircleRounded className={classes.checkIcon} fontSize="small" />
                        <Typography variant="h6">Add Bar</Typography>
                      </>
                    ) : (
                      <>
                        <RemoveCircle className={classes.removeIcon} fontSize="small" />
                        <Typography variant="h6">Remove Bar</Typography>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={isBelowSm ? 6 : 7}>
                  <Typography noWrap>{u.label}</Typography>
                </Grid>
              </Grid>
            ))}
            {isSuccess ? (
              <Alert severity="success" className={classes.alert}>
                {successMessage}
              </Alert>
            ) : null}
            {isError ? <Alert severity="error">Updating bars failed.</Alert> : null}
          </>
        </UIDialog>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    checkIcon: {
      color: theme.palette.success.main,
      marginRight: theme.spacing(1),
    },
    removeIcon: {
      color: theme.palette.error.main,
      marginRight: theme.spacing(1),
    },
    alert: {
      alignItems: "center",
    },
  })
);

export default BarringDetails;

import { Drawer, IconButton, Theme, useMediaQuery } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import CloseIcon from "@mui/icons-material/Close";
import React, { ReactNode } from "react";

interface Props {
  children: ReactNode;
  [key: string]: any;
}

function Drawered({
  open = true,
  anchor = "right",
  width = 70,
  onClose = (props: any) => props.history.goBack(),
  showCloseButton = true,
  children,
  ...rest
}: Props) {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: any) => theme.breakpoints.down("md"));
  const close = () => onClose(rest);
  return (
    <Drawer anchor={anchor} open={open} onClose={close}>
      {Boolean(showCloseButton) && (
        <IconButton className={classes.closeBtn} onClick={close} data-cy="close-btn" size="large">
          <CloseIcon />
        </IconButton>
      )}
      <div
        style={{
          width: isSmallScreen ? "95vw" : width + "vw",
        }}
      >
        {children}
      </div>
    </Drawer>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeBtn: {
      position: "absolute",
      right: 6,
      top: 13,
      "& svg": {
        fontSize: "2rem",
      },
    },
  })
);

export default Drawered;

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import MUIDataTable, { MUIDataTableState } from "mui-datatables";
import { Button } from "@mui/material";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import { rowParser } from "../../_app/components/Table/helpers";
import UILoader from "../../_app/components/UILoader";
import StatusChip from "../../_app/components/StatusChip";
import FiltersCard from "../../filter/components/FiltersCard";
import BillLimitAlertsTabBar from "../components/BillLimitAlerts/BillLimitAlertsTabBar";
import { useMobileAssets } from "../../asset/hooks";
import { useAllFilters, useFilterQueries } from "../../filter/hooks";
import { featureFlagsMap } from "../../feature/utils";

export const BillLimitAlertsCli = () => {
  const classes = useStyles();
  const history = useHistory();
  const [canView, setCanView] = useState(false);
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const queries = useFilterQueries();
  const eligible = true;
  const { data: clis, isFetching } = useMobileAssets(page, rowCount, queries, undefined, eligible, { enabled: canView });

  const handleTableChange = (action: string, state: MUIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
    }
  };

  return (
    <Main
      title="Bill Limit Alerts"
      data-cy="bill-limit-alerts-cli-page"
      needSelectedAccount
      titleProps={{ className: classes.title }}
      featureFlag={featureFlagsMap.BILL_LIMIT_ALERTS}
      accessGrantedCb={() => setCanView(true)}
    >
      <BillLimitAlertsTabBar selectedTab="bill-limit-alerts-cli">
        <>
          {isFetching ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <>
              <FiltersCard fetchHook={useAllFilters} hasReset={false} hasDownload={false} usage="BILL_LIMIT" />
              <div data-cy="bill-limit-alerts-cli-table">
                <MUIDataTable
                  title={""}
                  columns={[
                    "CLI/Identifier",
                    "",
                    "Tag",
                    {
                      label: "",
                      name: "",
                      options: {
                        setCellProps: () => ({
                          style: { textAlign: "right" },
                        }),
                      },
                    },
                  ]}
                  data={
                    clis?.list?.map((item) => {
                      return rowParser([
                        item.cli,
                        !item.eligible ? <StatusChip status="FAILED" label="Not Eligible" /> : " ",
                        item.tag,
                        <Button
                          variant="text"
                          color="primary"
                          data-cy="edit"
                          disabled={!item.eligible}
                          onClick={() => {
                            history.push(`/bill-limit-alerts-cli/${item.id}`);
                          }}
                        >
                          Edit
                        </Button>,
                      ]);
                    }) || []
                  }
                  options={{
                    page,
                    rowsPerPage: rowCount,
                    rowsPerPageOptions: rowCountOptions,
                    count: clis?.total,
                    onTableChange: handleTableChange,
                    fixedHeader: true,
                    pagination: true,
                    download: false,
                    elevation: 1,
                    print: false,
                    selectToolbarPlacement: "none",
                    filter: false,
                    sort: false,
                    search: false,
                    serverSide: true,
                    selectableRows: "none",
                    rowHover: true,
                    setTableProps: () => ({ size: "small" }),
                    viewColumns: false,
                  }}
                />
              </div>
            </>
          )}
        </>
      </BillLimitAlertsTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginBottom: 10,
    },
    loader: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "40vh",
    },
  })
);

export default BillLimitAlertsCli;

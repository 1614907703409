import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { TabPanel } from "@mui/lab";
import MUIDataTable from "mui-datatables";
import Main from "../../_app/layouts/Main";
import { createStyles, makeStyles, UIAlert, UIButton, UIGrid, UILoader, UITheme } from "../../_app/components";
import { rowParser } from "../../_app/components/Table/helpers";
import SelectAllHeader from "../../_app/components/Table/SelectAllHeader";
import MobileManagementTabBar from "../components/MobileManagementTabBar";
import SelectDropdown from "../../form/components/SelectDropdown";
import FiltersCard from "../../filter/components/FiltersCard";
import { useMobileAssets, useMobileManagementFilters } from "../hooks";
import { useFilterQueries } from "../../filter/hooks";
import { useHasFeature, useGetFeatureProperty } from "../../feature/hooks";
import { permissionCodes } from "../../permission/hooks";
import { useReset } from "../../_app/hooks";
import { IdValueQuery } from "../../_app/api";
import { capitalize } from "../../_app/utils/format";
import { featureFlagsMap } from "../../feature/utils";
import { formatCostCentreCode } from "../../account/utils";
import { MobileAsset } from "../types";

export interface SelectedParams {
  assets?: MobileAsset[];
  asset: MobileAsset;
  filters?: IdValueQuery[];
  from?: string;
}
const MOBILE_NUMBERS_FILTERS: string = "MOBILE_NUMBERS";

export const MobileManagement = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [canView, setCanView] = useState(false);
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [selectedAction, setSelectedAction] = useState<any>(undefined);
  const [actionKey, resetAction] = useReset();
  const queries = useFilterQueries();
  const eligible = false;

  const { data: assetsResp, isFetching } = useMobileAssets(page, rowCount, queries, undefined, eligible, { enabled: canView });
  const assets = assetsResp?.list;
  const mobileBarsLimit = useGetFeatureProperty(featureFlagsMap.MOBILE_BARS, "MAX_COUNT");
  const autoSwitchLimit = useGetFeatureProperty(featureFlagsMap.AUTO_SWITCH, "MAX_COUNT");
  const dataRoamingLimit = useGetFeatureProperty(featureFlagsMap.DATA_ROAMING, "MAX_COUNT");
  const simSwapLimit = useGetFeatureProperty(featureFlagsMap.SIM_SWAP, "MAX_COUNT");
  const billLimitLimit = useGetFeatureProperty(featureFlagsMap.BILL_LIMIT, "MAX_COUNT");
  const simLockLimit = useGetFeatureProperty(featureFlagsMap.SIM_LOCK, "MAX_COUNT");
  const simUnlockLimit = useGetFeatureProperty(featureFlagsMap.SIM_UNLOCK, "MAX_COUNT") || 1;
  const simOrderLimit = useGetFeatureProperty(featureFlagsMap.SIM_ORDER, "MAX_COUNT");

  const getSelectedParams = (singleAsset = false): SelectedParams => {
    const selected = selectedRows.reduce((acc, r) => {
      if (assets?.[r]) acc.push(assets?.[r]);
      return acc;
    }, []);
    return {
      filters: selectAll ? queries : undefined,
      assets: !selectAll ? selected : undefined,
      asset: singleAsset && selected.length === 1 ? selected[0] : undefined,
      from: "Mobile Management",
    };
  };

  const actions = [
    {
      id: 1,
      enabled: useHasFeature(featureFlagsMap.MOBILE_BARS),
      value: "Manage Bars",
      action: () =>
        history.push({
          pathname: "/mobile-management/bars/update",
          state: getSelectedParams(),
          search: location.search,
        }),
    },
    {
      id: 2,
      enabled: useHasFeature(featureFlagsMap.AUTO_SWITCH),
      value: "Request PAC/STAC",
      action: () => {
        history.push({
          pathname: "/mobile-management/sim-switch",
          state: getSelectedParams(),
          search: location.search,
        });
      },
    },
    {
      id: 3,
      enabled: useHasFeature(featureFlagsMap.DATA_ROAMING),
      value: "Worldwide Data Roaming",
      action: () => {
        history.push({
          pathname: "/mobile-management/data-roaming",
          state: getSelectedParams(),
          search: location.search,
        });
      },
    },
    {
      id: 4,
      enabled: useHasFeature(featureFlagsMap.SIM_SWAP),
      value: "Perform SIM Swap",
      action: () =>
        history.push({
          pathname: "/mobile-management/sim-swap",
          state: getSelectedParams(),
          search: location.search,
        }),
    },
    {
      id: 5,
      enabled: useHasFeature(featureFlagsMap.BILL_LIMIT),
      value: "Update Mobile Bill Limit",
      action: () => {
        history.push({
          pathname: "/mobile-management/bill-limit",
          state: getSelectedParams(),
          search: location.search,
        });
      },
    },
    {
      id: 6,
      enabled: useHasFeature(featureFlagsMap.SIM_LOCK),
      value: "Block Lost/Stolen SIM",
      action: () =>
        history.push({
          pathname: "/mobile-management/sim-lock",
          state: getSelectedParams(true),
          search: location.search,
        }),
    },
    {
      id: 7,
      enabled: useHasFeature(featureFlagsMap.SIM_UNLOCK),
      value: "Unlock SIM",
      action: () =>
        history.push({
          pathname: "/mobile-management/sim-unlock",
          state: getSelectedParams(true),
          search: location.search,
        }),
    },
    {
      id: 8,
      enabled: useHasFeature(featureFlagsMap.SIM_ORDER),
      value: "Order New SIM",
      action: () => {
        history.push({
          pathname: "/mobile-management/sim-order",
          state: getSelectedParams(true),
          search: location.search,
        });
      },
    },
  ];

  const actionError = (actionId: number) => {
    const maxLimitMap: { [key: number]: any } = {
      1: mobileBarsLimit,
      2: autoSwitchLimit,
      3: dataRoamingLimit,
      4: simSwapLimit,
      5: billLimitLimit,
      6: simLockLimit,
      7: simUnlockLimit,
      8: simOrderLimit,
    };
    const max = maxLimitMap[actionId];
    if (selectedRows.length === 0) {
      return `Requires an asset to be selected`;
    }
    if (max && (selectAll ? assetsResp?.total || Infinity : selectedRows.length) > max) {
      return `Can only perform action on a maximum of ${max} assets`;
    }
    return "";
  };

  const handleTableChange = (action: string, state: any) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      case "rowSelectionChange":
        setSelectAll(false);
        // @hack - force to bottom of execution que
        setTimeout(() => {
          setSelectedRows(state.selectedRows.data?.map((r: any) => r?.index));
        });
        break;
    }
  };

  const parsed = useMemo(
    () =>
      assets?.map((item: MobileAsset) => {
        return rowParser([item.cli, item.tag, capitalize(item.network), formatCostCentreCode(item.costCentre), item.accountCode]);
      }) || [],
    [assets]
  );

  useEffect(() => {
    if (selectAll) {
      setSelectedRows(parsed.map((_, i) => i));
    } else {
      setSelectedRows([]);
    }
  }, [selectAll, parsed]);

  const getActionFromValue = (value: string) => actions.find((a) => a?.value === value);

  return (
    <Main
      title="Mobile Management"
      data-cy="mobile-management-page"
      needSelectedAccount={true}
      featureFlag={featureFlagsMap.ASSETS}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      accessGrantedCb={() => setCanView(true)}
    >
      <MobileManagementTabBar selectedTab="mobile-management">
        <TabPanel value="mobile-management" className={classes.ctr}>
          <UIGrid item xs={12} md={12}>
            <UIGrid item>
              <FiltersCard
                title="Filter"
                fetchHook={useMobileManagementFilters}
                hasReset={true}
                hasDownload={false}
                usage={MOBILE_NUMBERS_FILTERS}
              />
            </UIGrid>
            {isFetching ? (
              <div className={classes.loader}>
                <UILoader />
              </div>
            ) : (
              <>
                <UIAlert severity="info" className={classes.info}>
                  {`${
                    Boolean(selectedAction && actionError(selectedAction?.id))
                      ? actionError(selectedAction?.id)
                      : "Select asset(s) then select the action you want to perform."
                  }`}
                </UIAlert>
                <SelectAllHeader
                  value={selectAll}
                  onChange={() => setSelectAll(!selectAll)}
                  disabled={isFetching}
                  selectedRows={selectedRows || []}
                >
                  <SelectDropdown
                    key={actionKey}
                    ctrClass={classes.actionDropdown}
                    name="action-selection"
                    label="Select Actions"
                    onChange={(e: any) => {
                      const action = getActionFromValue(e.target.value);
                      setSelectedAction(action);
                    }}
                    data={actions
                      .filter((a) => Boolean(a.enabled))
                      .map((a) => {
                        const { action, ...option } = a;
                        return {
                          ...option,
                          label: <span className={actionError(option?.id) ? classes.actionDisabled : ""}>{option.value}</span>,
                        };
                      })}
                  />
                  <UIButton
                    variant="contained"
                    color="primary"
                    disabled={!selectedAction || Boolean(actionError(selectedAction?.id))}
                    onClick={() => {
                      if (selectedAction && !Boolean(actionError(selectedAction?.id))) {
                        selectedAction?.action();
                        setSelectedAction(undefined);
                        resetAction();
                      }
                    }}
                    data-cy="apply-action-btn"
                  >
                    Apply
                  </UIButton>
                </SelectAllHeader>
                <div data-cy="mobile-selection-table" className={classes.tableCtr}>
                  <MUIDataTable
                    title=""
                    data={parsed}
                    columns={["CLI/Identifier", "Tag", "Network", "Cost Centre", "Account"]}
                    options={{
                      page,
                      rowsPerPage: rowCount,
                      count: assetsResp?.total,
                      rowsPerPageOptions: rowCountOptions,
                      onTableChange: handleTableChange,
                      rowsSelected: selectedRows,
                      pagination: true,
                      download: false,
                      elevation: 1,
                      print: false,
                      responsive: "standard",
                      selectToolbarPlacement: "none",
                      filter: false,
                      viewColumns: false,
                      sort: false,
                      search: false,
                      serverSide: true,
                      rowHover: true,
                      selectableRowsHeader: false,
                      setTableProps: () => ({
                        size: "small",
                      }),
                      setRowProps: (row) => ({
                        "data-cy": `row-id-${row?.[0]}`,
                      }),
                      fixedHeader: false,
                      fixedSelectColumn: false,
                    }}
                  />
                </div>
              </>
            )}
          </UIGrid>
        </TabPanel>
      </MobileManagementTabBar>
    </Main>
  );
};

const useStyles = makeStyles((theme: UITheme) =>
  createStyles({
    ctr: {
      padding: 0,
    },
    loader: {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "calc(50vh - 200px)",
    },
    info: {
      width: "fit-content",
      marginBottom: theme.spacing(3),
    },
    actionDropdown: {
      width: "fit-content",
      minWidth: 250,
    },
    actionDisabled: {
      color: theme.palette.action.disabled,
    },
    tableCtr: {
      marginTop: 0.5,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
      "& div:first-child": {
        marginTop: 0,
      },
      "& tr td:last-child": {
        width: "auto",
      },
      "& td": {
        width: "1%",
        whiteSpace: "nowrap",
      },
    },
  })
);

export default MobileManagement;

import React from "react";
import { Route, Switch } from "react-router-dom";
import { AuthCallback, SilentAuthCallback } from "../auth/components/AuthCallback";
import { Home } from "../dashboard/screens/Home";
import ManageUsers from "../user/screens/ManageUsers";
import ManageRoles from "../role/screens/ManageRoles";
import AddUser from "../user/screens/AddUser";
import EditUser from "../user/screens/EditUser";
import MyDetails from "../user/screens/MyDetails";
import Page404 from "./screens/Page404";
import EditRole from "../role/screens/EditRole";
import AddRole from "../role/screens/AddRole";
import BillExplorer from "../billing/screens/BillExplorer";
import PeriodDetails from "../billing/screens/PeriodDetails";
import UsageAnalysis from "../usage-analysis/screens/UsageAnalysis";
import ServiceAnalysis from "../service-analysis/screens/ServiceAnalysis";
import AccountDetails from "../account/screens/AccountDetails";
import Statements from "../billing/screens/Statements";
import Invoices from "../billing/screens/Invoices";
import Groups from "../context/screens/Groups";
import PaymentType from "../payment/screens/PaymentType";
import Logout from "../auth/screens/Logout";
import NoUser from "../auth/screens/NoUser";
import CardPayment from "../payment/screens/CardPayment";
import ManageContacts from "../contacts/screens/ManageContacts";
import EditContact from "../contacts/screens/EditContact";
import AddContact from "../contacts/screens/AddContact";
import SearchAssets from "../asset/screens/SearchAssets";
import AssetDetails from "../asset/screens/AssetDetails";
import BillDashMonthly from "../bill-dash/screens/BillDashMonthly";
import BillDashTrends from "../bill-dash/screens/BillDashTrends";
import Support from "../support/screens/Support";
import EditUsageAlert from "../alerts/screens/EditUsageAlert";
import ContactSupport from "../support/screens/ContactSupport";
import MobileManagement from "../asset/screens/MobileManagement";
import SimSwap from "../sim/screens/SimSwap";
import BarsUpdate from "../bars/screens/BarsUpdate";
import BillLimit from "../asset/screens/BillLimit";
import SimSwitchResults from "../sim/screens/SimSwitchResults";
import SimUnlock from "../sim/screens/SimUnlock";
import SimActivate from "../sim/screens/SimActivate";
import SimDisconnect from "../sim/screens/SimDisconnect";
import SimOrder from "../sim/screens/SimOrder";
import DataRoaming from "../data-roaming/screens/DataRoaming";
import SimLock from "../sim/screens/SimLock";
import UpdateCostCentres from "../account/screens/UpdateCostCentres";
import ManageCostCentres from "../account/screens/ManageCostCentres";
import CliDetails from "../account/components/CliDetails";
import BillLimitAlertsAccount from "../alerts/screens/BillLimitAlertsAccount";
import BillLimitAlertsCli from "../alerts/screens/BillLimitAlertsCli";
import BillLimitAlertsCliDetail from "../alerts/screens/BillLimitAlertsCliDetail";
import Drawered from "./layouts/Drawered";
import Modaled from "./layouts/Modaled";
import SimSwitch from "../sim/screens/SimSwitch";
import LineTest from "../asset/screens/LineTest";
import TicketDetails from "../support/screens/TicketDetails";
import AddNewTicket from "../support/screens/AddNewTicket";
import Tickets from "../support/screens/Tickets";
import AddTicketAdvanced from "../support/screens/AddTicketAdvanced";
import UsageAlerts from "../alerts/screens/UsageAlerts";
import UsageAlertsHistory from "../alerts/screens/UsageAlertsHistory";
import BundleAlertsIndividual from "../alerts/screens/BundleAlertsIndividual";
import BundleAlertsAccount from "../alerts/screens/BundleAlertsAccount";
import LogicMonitor from "../logic-monitor/screens/LogicMonitor";
import MobileManagementHistory from "../asset/screens/MobileManagementHistory";
import Help from "../help/screens/Help";
import JWTLogin from "../auth/screens/JWTLogin";
import Register from "../user/screens/Register";
import { PrivateRoute } from "../auth/components/PrivateRoute";
import { filtersIdMap } from "../filter/utils";
import Communications from "../communications/screens/Communications";
import MyDetailsCognito from "../user/screens/MyDetailsCognito";
import { isCognitoUser } from "../auth/utils";

function Routes() {
  return (
    <Switch>
      <Route path="/register">
        <Register />
      </Route>
      <Route path="/logout">
        <Logout />
      </Route>
      <Route path="/no-user">
        <NoUser />
      </Route>
      <Route path="/oauth_callback">
        <AuthCallback />
      </Route>
      <Route path="/silent_oauth_callback">
        <SilentAuthCallback />
      </Route>
      <PrivateRoute path="/profile">
        {isCognitoUser() ? <MyDetailsCognito /> : <MyDetails />}
      </PrivateRoute>
      <PrivateRoute path="/groups" exact>
        <Groups />
      </PrivateRoute>
      <PrivateRoute path="/account">
        <AccountDetails />
      </PrivateRoute>
      <PrivateRoute path="/users" exact>
        <ManageUsers />
      </PrivateRoute>
      <PrivateRoute path="/users/:id" exact>
        <EditUser />
      </PrivateRoute>
      <PrivateRoute path="/user/new" exact>
        <AddUser />
      </PrivateRoute>
      <PrivateRoute path="/roles" exact>
        <ManageRoles />
      </PrivateRoute>
      <PrivateRoute path="/roles/:id" exact>
        <EditRole />
      </PrivateRoute>
      <PrivateRoute path="/role/new" exact>
        <AddRole />
      </PrivateRoute>
      <PrivateRoute path="/bills">
        <BillExplorer />
        <PrivateRoute
          exact
          path="/bills/period-details/:periodId/:invoiceId"
          render={(props: any) => (
            <Drawered
              {...props}
              width={40}
              onClose={() => {
                if (props.location?.state?.fromHome)
                  return props.history.push(`/bills?${filtersIdMap?.BILL_PERIOD}=${props.match?.params?.periodId}`);
                else return props.history.goBack();
              }}
            >
              <PeriodDetails />
            </Drawered>
          )}
        />
      </PrivateRoute>
      <PrivateRoute path="/usage" exact>
        <UsageAnalysis />
      </PrivateRoute>
      <PrivateRoute path="/services" exact>
        <ServiceAnalysis />
      </PrivateRoute>
      <PrivateRoute path="/statements" exact>
        <Statements />
      </PrivateRoute>
      <PrivateRoute path="/invoices" exact>
        <Invoices />
      </PrivateRoute>
      <PrivateRoute path="/payment-type" exact>
        <PaymentType />
      </PrivateRoute>
      <PrivateRoute path="/card-payment" exact>
        <CardPayment />
      </PrivateRoute>
      <PrivateRoute path="/contacts" exact>
        <ManageContacts />
      </PrivateRoute>
      <PrivateRoute path="/contacts/:id" exact>
        <EditContact />
      </PrivateRoute>
      <PrivateRoute path="/contact/new" exact>
        <AddContact />
      </PrivateRoute>
      <PrivateRoute path="/assets" exact>
        <SearchAssets />
      </PrivateRoute>
      <PrivateRoute path="/assets/:id" exact>
        <AssetDetails />
      </PrivateRoute>
      <PrivateRoute path="/bill-dashboard-monthly" exact>
        <BillDashMonthly />
      </PrivateRoute>
      <PrivateRoute path="/bill-dashboard-trends" exact>
        <BillDashTrends />
      </PrivateRoute>
      <PrivateRoute path="/support" exact>
        <Support />
      </PrivateRoute>
      <PrivateRoute path="/tickets/new-advanced" exact>
        <AddTicketAdvanced />
      </PrivateRoute>
      <PrivateRoute path="/tickets">
        <Tickets />
        <PrivateRoute
          exact
          path="/tickets/new"
          render={(props: any) => (
            <Modaled {...props} onClose={(props: any) => props?.history?.push("/tickets")}>
              <AddNewTicket />
            </Modaled>
          )}
        />
        <PrivateRoute
          exact
          path="/tickets/details/:id"
          render={(props: any) => (
            <Drawered {...props} width={68} onClose={(props: any) => props?.history?.push("/tickets")}>
              <TicketDetails />
            </Drawered>
          )}
        />
      </PrivateRoute>
      <PrivateRoute path="/line-test" exact>
        <LineTest />
      </PrivateRoute>
      <PrivateRoute path="/usage-alerts" exact>
        <UsageAlerts />
      </PrivateRoute>
      <PrivateRoute path="/usage-alerts-history" exact>
        <UsageAlertsHistory />
      </PrivateRoute>
      <PrivateRoute path="/usage-alerts/new" exact>
        <EditUsageAlert />
      </PrivateRoute>
      <PrivateRoute path="/usage-alerts/:id" exact>
        <EditUsageAlert />
      </PrivateRoute>
      <PrivateRoute path="/bundle-alerts" exact>
        <BundleAlertsIndividual />
      </PrivateRoute>
      <PrivateRoute path="/bundle-alerts-account" exact>
        <BundleAlertsAccount />
      </PrivateRoute>
      <PrivateRoute path="/mobile-management-history" exact>
        <MobileManagementHistory />
      </PrivateRoute>
      <PrivateRoute path="/mobile-management">
        <MobileManagement />
        <PrivateRoute
          exact
          path="/mobile-management/bars/update"
          render={(props: any) => (
            <Drawered {...props} width={70}>
              <BarsUpdate />
            </Drawered>
          )}
        />
        <PrivateRoute
          exact
          path="/mobile-management/sim-switch"
          render={(props: any) => (
            <Drawered {...props} width={50}>
              <SimSwitch />
            </Drawered>
          )}
        />
        <PrivateRoute
          exact
          path="/mobile-management/sim-switch/results"
          render={(props: any) => {
            return (
              <Drawered
                {...props}
                onClose={(props: any) => {
                  props.history.push({
                    pathname: "/mobile-management",
                    search: props.location.search
                  });
                }}
              >
                <SimSwitchResults />
              </Drawered>
            );
          }}
        />
        <PrivateRoute
          exact
          path="/mobile-management/data-roaming"
          render={(props: any) => (
            <Drawered {...props}>
              <DataRoaming />
            </Drawered>
          )}
        />
        <PrivateRoute
          exact
          path="/mobile-management/sim-swap"
          render={(props: any) => (
            <Drawered {...props} width={78}>
              <SimSwap />
            </Drawered>
          )}
        />
        <PrivateRoute
          exact
          path="/mobile-management/bill-limit"
          render={(props: any) => (
            <Drawered {...props}>
              <BillLimit />
            </Drawered>
          )}
        />
        <PrivateRoute
          exact
          path="/mobile-management/sim-lock"
          render={(props: any) => (
            <Drawered {...props} width={45}>
              <SimLock />
            </Drawered>
          )}
        />
        <PrivateRoute
          exact
          path="/mobile-management/sim-unlock"
          render={(props: any) => (
            <Drawered {...props} width={50}>
              <SimUnlock />
            </Drawered>
          )}
        />
        <PrivateRoute
          exact
          path="/mobile-management/sim-order"
          render={(props: any) => (
            <Drawered {...props} width={78}>
              <SimOrder />
            </Drawered>
          )}
        />
      </PrivateRoute>
      <PrivateRoute path="/sim-activate" exact>
        <SimActivate />
      </PrivateRoute>
      <PrivateRoute path="/sim-disconnect" exact>
        <SimDisconnect />
      </PrivateRoute>
      <PrivateRoute path="/contact-support" exact>
        <ContactSupport />
      </PrivateRoute>
      <PrivateRoute path="/manage-cost-centres">
        <ManageCostCentres />
        <PrivateRoute
          exact
          path="/manage-cost-centres/:cli/:id"
          render={(props: any) => (
            <Drawered {...props} width={40}>
              <CliDetails />
            </Drawered>
          )}
        />
      </PrivateRoute>
      <PrivateRoute path="/update-cost-centres" exact>
        <UpdateCostCentres />
      </PrivateRoute>
      <PrivateRoute path="/bill-limit-alerts-account" exact>
        <BillLimitAlertsAccount />
      </PrivateRoute>
      <PrivateRoute path="/bill-limit-alerts-cli" exact>
        <BillLimitAlertsCli />
      </PrivateRoute>
      <PrivateRoute path="/bill-limit-alerts-cli/:id" exact>
        <BillLimitAlertsCliDetail />
      </PrivateRoute>
      <PrivateRoute path="/help/:category?" exact>
        <Help />
        <PrivateRoute
          exact
          path="/help/new-ticket"
          render={(props: any) => (
            <Modaled {...props} onClose={(props: any) => props?.history?.goBack()}>
              <AddNewTicket />
            </Modaled>
          )}
        />
      </PrivateRoute>
      <PrivateRoute path="/logic-monitor" exact>
        <LogicMonitor />
      </PrivateRoute>
      <PrivateRoute path="/communications" exact>
        <Communications />
      </PrivateRoute>
      <PrivateRoute path="/" exact>
        <Home />
      </PrivateRoute>
      <Route path="/jwt-login" component={JWTLogin} />
      <Route path="*" component={Page404} />
    </Switch>
  );
}

export default Routes;

import React from "react";
import { Grid, Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { UICard } from "../../../_app/components/UICard";

interface Props {
  title: string;
  children: JSX.Element;
}

export const Details = ({ title, children }: Props) => {
  const classes = useStyles();
  return (
    <Grid item xs={12} md={6}>
      <UICard elevation={3} height="fit-content" padding="8px 8px 0 8px" className={classes.card}>
        <Typography variant="h3" paragraph>
          {title}
        </Typography>
        <Grid container>
          <Grid item xs={12}>
            <Grid container>{children}</Grid>
          </Grid>
        </Grid>
      </UICard>
    </Grid>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      height: "auto",
    },
  }),
);

export default Details;

import React from "react";
// @ts-ignore
import { CKEditor } from "@ckeditor/ckeditor5-react";
// @ts-ignore
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Theme, Typography } from "@mui/material";

import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";

interface Props {
  onChange: (value: string) => void;
  placeholder?: string;
  rows?: number;
  value?: string;
  helperText?: string;
  error?: boolean;
}

function UIRichTextEditor({ onChange, placeholder, rows = 3, value, helperText, error }: Props) {
  const classes = useStyles();
  return (
    <div className={`${classes.ctr} ${error && classes.error}`}>
      <CKEditor
        config={{
          placeholder: placeholder,
          toolbar: [
            "undo",
            "redo",
            "|",
            "heading",
            "|",
            "bold",
            "italic",
            "|",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "|",
            "blockQuote",
            "insertTable",
            "|",
          ],
        }}
        data={value}
        editor={ClassicEditor}
        onChange={(event: any, editor: any) => onChange(editor.getData())}
        onReady={(editor: any) => {
          if (editor) {
            // Change editor upload adapter
            editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
              return;
            };

            // Change editor height
            editor.editing.view.change((writer: any) => {
              writer.setStyle("min-height", `${rows * 40}px`, editor.editing.view.document.getRoot());
            });
          }
        }}
      />
      {Boolean(helperText) && (
        <Typography color={error ? "error" : "inherit"} className={classes.helperText}>
          {helperText}
        </Typography>
      )}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      "& .ck-content.ck-rounded-corners": {
        borderBottomLeftRadius: "5px !important",
        borderBottomRightRadius: "5px !important",
      },
      "& .ck-toolbar": {
        borderTopLeftRadius: "5px !important",
        borderTopRightRadius: "5px !important",
      },
    },
    error: {
      "& .ck-content": {
        borderLeftColor: `${theme.palette.error.main} !important`,
        borderRightColor: `${theme.palette.error.main} !important`,
        borderBottomColor: `${theme.palette.error.main} !important`,
      },
      "& .ck-toolbar": {
        borderLeftColor: `${theme.palette.error.main} !important`,
        borderRightColor: `${theme.palette.error.main} !important`,
        borderTopColor: `${theme.palette.error.main} !important`,
      },
    },
    helperText: {
      fontSize: "0.75rem",
      marginLeft: 14,
      marginRight: 14,
      marginTop: 4,
    },
  })
);

export default UIRichTextEditor;

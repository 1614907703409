import React, { useState } from "react";
import Button, { ButtonProps } from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Theme, List, ListItem } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

interface Props {
  options: JSX.Element[];
  className?: string;
  variant?: "text" | "contained" | "outlined";
  renderButton?: (props: ButtonProps) => JSX.Element;
}

export default function OptionsButton({
  options = [],
  className = "",
  variant = "text",
  renderButton = (props) => (
    <Button {...props} endIcon={<MoreHorizIcon />}>
      Options
    </Button>
  ),
}: Props) {
  const classes = useStyles();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOptionsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOptionsOpen(false);
  };

  const buttonProps: ButtonProps & { "data-cy": string } = {
    "data-cy": "options-button",
    ref: anchorRef,
    variant: variant,
    size: "small",
    className: `${classes.button} ${className}`,
    onClick: handleToggle,
  };

  return (
    <>
      {renderButton(buttonProps)}
      <Popper
        open={optionsOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement="bottom-end"
        className={classes.popper}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <List>
                  {options.map((item, i) => {
                    return <ListItem key={item.props?.children ?? i}>{item}</ListItem>;
                  })}
                </List>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      marginLeft: theme.spacing(2),
      fontWeight: 600,
      fontSize: "0.95rem",
      minWidth: "fit-content",
    },
    popper: {
      zIndex: 101,
    },
  })
);

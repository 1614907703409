import { Brand } from "./types";
import affinityLogo from "../_app/images/logo-affinity.png";

export const DEFAULT_BRAND: Brand = {
  name: "MyAccount",
  logoUrl: affinityLogo,
  faviconUrl: "",
  widgetArea1: "",
  widgetArea2: "",
  loginBg:
    "https://aurora-sso-aurora-live-asset.s3.eu-west-2.amazonaws.com/content/images/4441CA00-6F81-4649-9F72-04DED8B9CF0C/brand_bg.jpeg",
  poweredByImg: "https://aurora-sso-aurora-uat-asset.s3.eu-west-2.amazonaws.com/content/images/aurora/footer_logo.png",

  primaryColour: "#7a5aff",
  primaryTextColour: "#ffffff",
  secondaryColour: "#4ec4b0",
  secondaryTextColour: "#ffffff",
  tertiaryColour: "#524b65",
  tertiaryTextColour: "#ffffff"
};

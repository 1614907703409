import React, { useEffect, useState } from "react";
import { Theme, Card, CardContent, Typography, Grid, CardHeader, Avatar, Divider } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { groupBy } from "../../_app/utils";
import { Alert } from "@mui/material";
import { CheckCircleRounded, RemoveCircle } from "@mui/icons-material";
import { BarUpdate } from "../types";

interface Props {
  updates: BarUpdate[];
}

function BarsUpdateConfirm({ updates, ...props }: Props) {
  const classes = useStyles();
  const [parsed, setParsed] = useState<BarUpdate[][]>([]);

  useEffect(() => {
    const parsed = groupBy(updates || [], "network");
    setParsed(Object.values(parsed));
  }, [updates]);

  return (
    <div className={classes.ctr}>
      <Alert severity="info">Please confirm you wish to make the below changes.</Alert>
      {parsed.map((group, i) => {
        const network = group?.[0]?.network;
        return (
          <Card className={classes.card} key={network || i}>
            {Boolean(network) && (
              <CardHeader
                avatar={<Avatar src={require(`../../_app/images/logo-${network?.toLowerCase()}.png`)} variant="square" />}
              />
            )}
            <CardContent>
              <Grid className={classes.cardContent} container>
                <Grid container direction="row" alignItems="center" spacing={1}>
                  <Grid item sm={3}>
                    <Typography variant="h5">Bars</Typography>
                  </Grid>
                  <Grid item sm={9}>
                    <Typography variant="h5">Action</Typography>
                  </Grid>
                </Grid>
                {group.map((bar: BarUpdate) => (
                  <React.Fragment key={bar.name}>
                    <Divider className={classes.divider} />
                    <Grid container direction="row" alignItems="center" spacing={1} key={bar.name}>
                      <Grid item sm={3}>
                        {bar.label}
                      </Grid>
                      <Grid item sm={9}>
                        <Grid container direction="row" alignItems="center">
                          {bar.value ? (
                            <>
                              <CheckCircleRounded className={classes.checkIcon} fontSize="small" />
                              Add Bar
                            </>
                          ) : (
                            <>
                              <RemoveCircle className={classes.removeIcon} fontSize="small" />
                              Remove Bar
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </React.Fragment>
                ))}
              </Grid>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {},
    card: {
      borderLeft: "3px solid",
      borderLeftColor: theme.palette.primary.main,
      marginTop: "1rem",
      paddingLeft: theme.spacing(1),
      width: "100%",
      "& .MuiAvatar-img": {
        objectFit: "contain",
      },
    },
    cardContent: {
      gap: theme.spacing(1.5),
    },
    divider: {
      width: "100%",
    },
    checkIcon: {
      color: theme.palette.success.main,
      marginRight: theme.spacing(1),
    },
    removeIcon: {
      color: theme.palette.error.main,
      marginRight: theme.spacing(1),
    },
  })
);

export default BarsUpdateConfirm;

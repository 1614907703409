import React from "react";
import { createStyles, makeStyles, UITextField } from "../../_app/components";

interface Props {
  value?: string;
  title?: string;
  hideLabel?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  onChange?: any;
  [key: string]: any;
}

const UITextInput = ({ value, title, hideLabel = false, autoFocus = true, placeholder, onChange, ...props }: Props) => {
  const classes = useStyles();
  return (
    <UITextField
      variant="outlined"
      size="small"
      label={hideLabel ? null : title}
      name={title}
      value={value}
      onChange={(ev) => onChange(ev.target.value)}
      placeholder={placeholder}
      className={classes.ctr}
      fullWidth
      autoFocus={autoFocus}
      {...props}
    />
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    ctr: {},
  })
);

export default UITextInput;

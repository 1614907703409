import React from "react";
import { useHasFeature } from "../../../feature/hooks";
import { Grid, GridSize } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

export interface Props {
  children?: React.ReactNode;
  md?: GridSize;
}

export const withWidget =
  <P extends object>(
    WrappedComponent: React.ComponentType<P>,
    cy?: string,
    featureFlag?: string,
    overrides?: any
  ): React.FC<P & Props> =>
  ({ md = 6, ...props }: Props) => {
    const classes = useStyles();
    const hasFeatureFlag = useHasFeature(featureFlag);
    if (featureFlag && !hasFeatureFlag) return null;
    return (
      <Grid item xs={12} md={md} data-cy={cy} className={classes.widget} {...(overrides || {})}>
        <WrappedComponent {...(props as P)} />
      </Grid>
    );
  };

const useStyles = makeStyles((theme) => ({
  widget: {
    "& .widget-button": {
      width: "fit-content",
    },
    "& .MuiPaper-root": {
      minHeight: 240,
    },
  },
}));

import React from "react";
import { useHistory } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Main from "../../_app/layouts/Main";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { Grid } from "@mui/material";
import { useContactCreate } from "../hooks";
import EditContactForm from "../components/EditContactForm";
import { featureFlagsMap } from "../../feature/utils";
import { UICard } from "../../_app/components";
import { Contact } from "../types";

export const AddContact = () => {
  const classes = useStyles();
  const history = useHistory();

  const { mutate: executeContactCreate, error: createError, isLoading } = useContactCreate(
    {
      onSuccess: () => {
        history.push("/contacts");
      },
    }
  );

  const addContact = (contact: Contact) => {
    executeContactCreate(contact);
  };

  const contact = createError?.config?.data ? JSON.parse(createError.config.data) : {};

  const hasPermissionToManageAllContacts = useHasPermission(permissionCodes.ACCOUNT_CONTACTS);

  return (
    <Main
      title="Add Contact"
      data-cy="add-contact-page"
      accessPermission={[permissionCodes.ACCOUNT_CONTACTS, permissionCodes.ALERTS_ACCESS_FEATURE]}
      featureFlag={featureFlagsMap.CONTACTS_UPDATE}
      isLoading={isLoading}
    >
      <Grid className={classes.ctr}>
        <UICard>
          <EditContactForm
            contact={contact}
            isSubmitting={isLoading}
            handleSubmit={addContact}
            handleCancel={() => history.goBack()}
            onlyAlerts={!hasPermissionToManageAllContacts}
          />
        </UICard>
      </Grid>
    </Main>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      padding: "0",
      marginTop: theme.spacing(1.5),
    },
  })
);

export default AddContact;

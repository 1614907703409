import { Theme, Chip } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Filter } from "../types";
import { formatCurrency } from "../../_app/utils/format";
import { filtersIdMap, formatMultipleBillPeriods } from "../utils";
import { formatCostCentreCode } from "../../account/utils";
import { formatPeriodToMonthYear } from "../../billing/utils";

interface Props {
  filters: Filter[];
}
const AddedFilters = ({ filters }: Props) => {
  const classes = useStyles();
  const [items, setItems] = useState<any[]>([]);
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const newItems: any[] = [];
    const params = new URLSearchParams(location.search);
    params.forEach((value, key) => {
      const filter = filters?.find((filter: Filter) => filter.id === key && !filter.options?.HIDDEN);
      const name = filter?.name;
      const valueDisplayFunc = filter?.valueDisplayFunc;
      newItems.push({
        ...filter,
        key,
        name,
        value,
        valueDisplayFunc,
      });
    });
    setItems(newItems);
  }, [location, filters]);

  const deleteFilter = (item: any) => {
    queryParams.delete(item?.key);
    history.replace({
      search: queryParams.toString(),
    });
  };

  return (
    <ul className={classes.ctr} data-cy="added-filters">
      {items.map((item) => {
        let label: string;
        if (item.valueDisplayFunc) {
          item.value = item.valueDisplayFunc(item.value);
        }
        const filterChipLabelPrefix = item?.label ?? item?.name ?? "";
        switch (item?.key) {
          case "10": // COST
          case "27": // COST
            label = `${filterChipLabelPrefix} - ${item?.value}`.split("_")?.join(" ").split(",")?.join(" and ");
            label = label.replace(/\b\d+\b/g, (match) => formatCurrency(match));
            label = label.replace("equal", "equals");
            break;
          case filtersIdMap.BILL_PERIOD: // PERIOD
          case filtersIdMap.SERVICES_BILL_PERIOD: // PERIOD
          case "70": // PERIOD
            label = `${filterChipLabelPrefix} - ${formatMultipleBillPeriods(item.value)}`;
            break;
          case "80": {// PERIOD RANGE
            const parts = item.value.split(" - ");
            label = `${filterChipLabelPrefix} - ${formatPeriodToMonthYear(parts[0])} - ${formatPeriodToMonthYear(parts[1])}`;
            break;
          }
          case "6": // TIME
            label = `${filterChipLabelPrefix} - ${item?.value}`.split("_")?.join(" ").split(",")?.join(" and ");
            label = label.replace("equal", "equals");
            break;
          case "4": // DATE
            label = `${filterChipLabelPrefix} - ${item?.value}`.split(",")?.join(" to ");
            break;
          case "3":
          case "23":
          case "34":
          case "44":
          case "54": {
            let costCenters = item?.value.split(",");
            let costCentersTitle = `${filterChipLabelPrefix} - `;
            let costCentersLabels: string[] = [];
            costCenters.forEach((cc: string) => {
              costCentersLabels.push(formatCostCentreCode(cc));
            });
            label = costCentersTitle + costCentersLabels?.join(", ");
            break;
          }
          case "420":
            label = item.value === "true" ? `${filterChipLabelPrefix} - ${item.label}` : "";
            break;
          default:
            label = `${filterChipLabelPrefix} - ${item?.value}`.split("_")?.join(" ");
        }
        if (!filterChipLabelPrefix || !label) return null;
        return (
          <li className={classes.chip} key={item?.key}>
            <Chip
              variant="outlined"
              size="small"
              label={label}
              onDelete={!item.disableDelete ? () => deleteFilter(item) : undefined}
              className={classes.chip}
              data-cy={`${"added-filter-" + item?.key}`}
            />
          </li>
        );
      })}
    </ul>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      display: "flex",
      minHeight: "32px",
      flexDirection: "row",
      flexWrap: "wrap",
      gap: "6px",
      width: "100%",
      listStyleType: "none",
      padding: "3px",
      marginLeft: "0px",
      marginRight: "0px",
      border: "1px solid",
      borderColor: theme.palette.grey[100],
      backgroundColor: theme.palette.grey[50],
      borderRadius: theme.spacing(2),
      alignContent: "center",
    },
    chip: {
      maxWidth: "100%",
    },
  })
);

export default AddedFilters;

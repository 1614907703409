import React, { createContext, Dispatch, useReducer } from "react";
import { Brand } from "../../brand/types";
import { Flag } from "../../feature/types";
import HierarchyContextProvider, { ContextHierarchy } from "../providers/HierarchyContextProvider";
import Reducer from "./Reducer";

export interface StoreState {
  error: AppAlert | null;
  success: AppAlert | null;
  alert: AppAlert | null;
  contextHierarchy: ContextHierarchy | null;
  brand: Brand | null;
  features: { [key: string]: Flag } | null;
}

export interface AppAlert {
  message?: string;
  duration?: number;
}

export interface Action {
  type: string;
  payload?: any;
  skipStorage?: boolean;
}

const initialState: StoreState = {
  error: null,
  success: null,
  alert: null,
  contextHierarchy: null,
  brand: null,
  features: null,
};

const Store = ({ children }: any) => {
  const [state, dispatch]: [any, Dispatch<Action>] = useReducer(Reducer, initialState);
  return (
    <StoreContext.Provider value={[state, dispatch]}>
      <HierarchyContextProvider dispatch={dispatch}>{children}</HierarchyContextProvider>
    </StoreContext.Provider>
  );
};

export const StoreContext = createContext<any>(initialState);
export default Store;

export const contactTypesMap: { [key: string]: string } = {
  CONTRACT_SIGNATORY: "Contract Signatory",
  KEY_DECISION_MAKER: "Key Decision Maker",
  TECHNICAL_CONTACT: "Technical Contact",
  FINANCIAL_CONTACT: "Financial Contact",
  THIRD_PARTY_CONSULTANT: "3rd Party Consultant",
  BUSINESS_PARTNER_CONTACT: "Business Partner Contact",
  PA_RECEPTIONIST: "PA/Receptionist",
  ALERT: "Alert",
};

export const alertContactTypesMap: { [key: string]: string } = {
  ALERT: "Alert",
};

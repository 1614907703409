import React, { ReactNode } from "react";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Paper } from "@mui/material";

interface Props {
  children?: ReactNode;
}

const TableHeader = ({ children }: Props) => {
  const classes = useStyles();
  return <Paper className={classes.actionsCtr}>{children}</Paper>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionsCtr: {
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",
      gap: theme.spacing(2),
      padding: `${theme.spacing(1)} 26px`,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      [theme.breakpoints.down("md")]: {
        "&": {
          flexDirection: "column",
        },
      },
    },
  })
);

export default TableHeader;

import React, { cloneElement, ReactNode } from "react";
import { Stack, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { UICard } from "../../../_app/components";
import { withWidget } from "../components/Widget";
import { useHistory } from "react-router-dom";

interface Props {
  icon: any;
  title: string;
  link: string;
  children: ReactNode;
}

const NavWidget = ({ icon, title, children, link }: Props) => {
  const classes = useStyles();
  const history = useHistory();
  const styledIcon = cloneElement(icon, { className: classes.icon });
  return (
    <UICard onClick={() => history.push(link)} hasHover>
      <Stack direction="column" justifyContent="center" alignItems="center" spacing={2}>
        {styledIcon}
        <Typography variant="h3" align="center">
          {title}
        </Typography>
      </Stack>
      <div className={classes.ctr}>{children}</div>
    </UICard>
  );
};

const useStyles = makeStyles((theme) => ({
  ctr: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    flexDirection: "column",
    flex: 1,
  },
  icon: {
    fontSize: 55,
    color: theme.palette.primary.main,
  },
}));

export default withWidget(NavWidget, "nav-widget");

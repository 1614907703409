import React from "react";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useServiceCostByType, useVerticalBarChartOptions } from "../../hooks";
import { Bar } from "react-chartjs-2";
import GraphWidget from "./GraphWidget";
import { useHasFeature } from "../../../feature/hooks";
import { Query } from "../../../filter/types";
import { humanize } from "../../../_app/utils/format";
import { useLocation } from "react-router-dom";
import { useAllFilters } from "../../../filter/hooks";
import FilterItem from "../../../filter/components/FilterItem";
import AddedFilters from "../../../filter/components/AddedFilters";
import { formatPrice } from "../../../billing/utils";
import { featureFlagsMap } from "../../../feature/utils";

const featureFlag = featureFlagsMap.SERVICE_BY_CATEGORY_GRAPH;
const CLASSIFICATIION_FILTER_ID = "82";

interface Props {
  queries: Query[];
}

const ServiceCostByType = ({ queries }: Props) => {
  const classes = useStyles();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const hasClassificationParam = queryParams.has(CLASSIFICATIION_FILTER_ID);
  const {
    data = [],
    isFetching,
    isError,
  } = useServiceCostByType(queries, {
    enabled: useHasFeature(featureFlag),
  });
  const { data: filtersResponse } = useAllFilters("GRAPHS_TRENDS", {
    enabled: useHasFeature(featureFlag),
  });
  const classificationFilter = {
    ...filtersResponse?.find((filter) => filter.id === CLASSIFICATIION_FILTER_ID),
    options: {
      HIDDEN: false,
    },
    id: CLASSIFICATIION_FILTER_ID,
    name: "Product",
    valueDisplayFunc: humanize,
  };
  const hasError = isError || data?.length <= 0;

  const options = useVerticalBarChartOptions(
    data.unit,
    undefined,
    (context: any) => {
      return formatPrice({
        gross: context?.raw,
        net: context?.raw,
        vat: 0,
        unit: data.unit,
      });
    },
    {
      scales: {
        xAxes: {
          stacked: true,
        },
        yAxes: {
          stacked: true,
          ticks: {
            callback: (value: any) => data.unit + value,
          },
        },
      },
    }
  );

  return (
    <GraphWidget
      title="Cost by Service Category"
      filter={
        <>
          {!hasClassificationParam && <FilterItem item={classificationFilter} />}
          {hasClassificationParam && <AddedFilters filters={[classificationFilter]} />}
        </>
      }
      isLoading={isFetching}
      hasError={hasError}
      featureFlag={featureFlag}
      width={12}
    >
      <div className={classes.horizontalBarWrap}>
        <Bar data={data} options={options} />
      </div>
    </GraphWidget>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  horizontalBarWrap: {
    position: "relative",
    minHeight: 410,
    width: "100%",
  },
}));

export default ServiceCostByType;

import React from "react";
import { Theme, Paper, Typography, Tooltip, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Alert from "@mui/material/Alert";
import AlertAssetSelection from "./AlertAssetSelection";
import { useProductTypes } from "../../hooks";
import InfoIcon from "@mui/icons-material/Info";
import { UICard } from "../../../_app/components/UICard";
import SelectDropdown from "../../../form/components/SelectDropdown";
import { ProductType } from "../../types";

export const SelectAssets = ({
  hasLevelOptions,
  hasProductOptions,
  usageAlert,
  onInputChange,
  defaultSelected,
  setSelectedAssets,
}: any) => {
  const classes = useStyles();
  const levelOptions = [
    { id: "ACCOUNT", label: "Account", value: "ACCOUNT" },
    { id: "CLI", label: "CLI", value: "CLI" },
  ];

  const { data: productTypes } = useProductTypes();

  const productOptions =
    productTypes?.map((product: ProductType) => {
      return { id: product.id, label: product.label, value: product.id };
    }) || [];

  const tooltipText = `If you want to be alerted when the whole account exceeds the criteria you specify,
              select 'Account'. If you want to be alerted when each selected
              number exceeds your criteria then select 'CLI'.`;
  return (
    <div>
      <Paper className={classes.levelTypeCtr}>
        <div className={classes.tooltip}>
          <Typography variant="h4" gutterBottom>
            What level of alert do you want to create?
          </Typography>
          <Tooltip title={tooltipText} placement="right" arrow>
            <InfoIcon color="secondary" data-cy="tooltip-alert" className={classes.infoIcon} />
          </Tooltip>
        </div>

        <div className={classes.selectBox}>
          <TextField
            required
            fullWidth
            variant="outlined"
            size="small"
            name="name"
            label="Name"
            value={usageAlert.name || ""}
            onChange={onInputChange}
          />
          {Boolean(hasLevelOptions && levelOptions.length) && (
            <SelectDropdown
              required
              name="level"
              label="Select Level"
              value={usageAlert.level || ""}
              data={levelOptions}
              onChange={onInputChange}
            />
          )}
          {hasProductOptions && (
            <SelectDropdown
              required
              name="product"
              label="Select Product Type"
              value={usageAlert.product || ""}
              data={productOptions}
              onChange={onInputChange}
            />
          )}
        </div>
      </Paper>
      {usageAlert?.level === "CLI" && usageAlert.product !== undefined && (
        <>
          <UICard className={classes.assetsCard}>
            <Typography variant="h3" className={classes.title}>
              Select Assets
            </Typography>
            <Alert severity="warning" className={classes.alert}>
              This alert will not be applied to numbers added to the account in the future
            </Alert>
            <AlertAssetSelection
              setSelectedAssets={setSelectedAssets}
              defaultSelected={defaultSelected}
              usageAlert={usageAlert}
            />
          </UICard>
        </>
      )}
    </div>
  );
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    levelTypeCtr: {
      padding: 22,
      margin: 1,
      marginBottom: 15,
    },
    selectBox: {
      display: "flex",
      gap: theme.spacing(1.5),
      maxWidth: "600px",
    },
    tooltip: {
      display: "flex",
      gap: theme.spacing(1.5),
      maxWidth: "400px",
    },
    infoIcon: {
      cursor: "pointer",
      marginTop: "-3px",
    },
    alert: {
      width: "max-content",
      marginBottom: 15,
    },
    assetsCard: {
      marginBottom: theme.spacing(1.5),
    },
    title: {
      marginBottom: theme.spacing(2),
    },
  })
);

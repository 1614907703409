import React, { ChangeEvent } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Grid, lighten, Tab, Theme } from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { TabContext, TabList } from "@mui/lab";

interface ITab {
  value: string;
  label?: string;
  hidden?: boolean;
  testId?: string;
}

interface Props {
  selectedTab: string;
  tabs: ITab[];
  persistParams?: boolean;
  handleTabChange?: ((event: ChangeEvent<{}>, value: any) => void) | undefined;
  children?: any;
}

function Tabbed({ selectedTab, tabs = [], persistParams, handleTabChange, children }: Props) {
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const onTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    history.push({
      ...(persistParams ? location : {}),
      pathname: "/" + value,
    });
  };
  const parsedTabs = tabs.filter((t) => !t.hidden);
  return (
    <TabContext value={selectedTab}>
      <Grid item md={12} className={classes.ctr}>
        {parsedTabs.length > 1 && (
          <TabList onChange={handleTabChange || onTabChange} indicatorColor="primary" textColor="primary" variant="scrollable">
            {parsedTabs.map((tab) => (
              <Tab
                key={tab.value || tab.label}
                value={tab.value}
                label={tab.label || tab.value}
                data-cy={tab.testId || `${tab.value}-tab`}
                className={classes.tab}
              />
            ))}
          </TabList>
        )}
      </Grid>
      {Boolean(children) && (
        <Grid item xs={12} md={12} className={classes.content}>
          {children}
        </Grid>
      )}
    </TabContext>
  );
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ctr: {
      marginBottom: theme.spacing(3),
      display: "flex",
      flex: 1,
      justifyContent: "space-between",
    },
    tab: {
      backgroundColor: "#fff",
      minWidth: 200,
      "&:hover": {
        background: lighten(theme.palette.primary.main, 0.98),
        borderBottom: "2px solid " + lighten(theme.palette.primary.main, 0.35),
      },
    },
    link: {
      textDecoration: "none",
    },
    content: {
      padding: "0px !important",
    },
  })
);

export default Tabbed;

import React from "react";
import { useHistory } from "react-router-dom";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { filtersIdMap } from "../../filter/utils";
import Tabbed from "../../_app/layouts/Tabbed";
import { useAllParamValues } from "../../_app/hooks";

interface Props {
  selectedTab: string;
  children: any;
}

function ManageBillingTabBar({ selectedTab, children }: Props) {
  const history = useHistory();
  const periods = useAllParamValues(selectedTab === "services" ? filtersIdMap?.SERVICES_BILL_PERIOD : filtersIdMap?.BILL_PERIOD);

  const handleTabChange = (event: React.ChangeEvent<{}>, value: any) => {
    let path = "/" + value;
    if (periods) {
      if (value === "services") path += `?${filtersIdMap?.SERVICES_BILL_PERIOD}=${periods.join(",")}`;
      else if (periods[0] !== "UNBILLED") {
        path += `?${filtersIdMap?.BILL_PERIOD}=${periods.join(",")}`;
      }
    }
    history.push(path);
  };

  const hasPermissionToUsage = useHasPermission(permissionCodes.BILLING_MANAGE);

  return (
    <Tabbed
      selectedTab={selectedTab}
      handleTabChange={handleTabChange}
      tabs={[
        {
          value: "bills",
          label: "Bill Summary",
          hidden: false,
        },
        {
          value: "usage",
          label: "Usage Analysis",
          hidden: !hasPermissionToUsage,
        },
        {
          value: "services",
          label: "Service Analysis",
          hidden: false,
        },
      ]}
    >
      {children}
    </Tabbed>
  );
}

export default ManageBillingTabBar;

import React from "react";
import Tabbed from "../../_app/layouts/Tabbed";

interface Props {
  selectedTab: string;
  children: any;
}

function ManageSimActivationTabBar({ selectedTab, children }: Props) {
  return (
    <Tabbed
      selectedTab={selectedTab}
      tabs={[
        {
          value: "sim-activate",
          label: "Activate SIMs",
        },
        {
          value: "sim-disconnect",
          label: "Disconnect Mobiles",
        },
      ]}
    >
      {children}
    </Tabbed>
  );
}

export default ManageSimActivationTabBar;

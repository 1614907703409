import { useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import { getPaymentDetails, postPaymentDetails, putPaymentDetails } from "./api";
import { PaymentDetails } from "./types";
import { useFeedbackAlerts } from "../_app/hooks";

export function usePaymentDetails(options = {}) {
  return useQuery<PaymentDetails, AjaxResponse>(["paymentDetails"], () => getPaymentDetails(), {
    staleTime: 0,
    ...options,
  });
}

export function usePaymentDetailsUpdate(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<PaymentDetails, AjaxResponse, any>((details) => putPaymentDetails(details), {
    onSuccess: () => {
      queryClient.invalidateQueries(["paymentDetails"]);
      setFeedbackAlertSuccess("Payment Details changed.");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
    ...options,
  });
}

export function usePaymentDetailsCreate(options = {}) {
  const queryClient = useQueryClient();
  return useMutation<PaymentDetails, AjaxResponse, any>((details) => postPaymentDetails(details), {
    onSuccess: () => {
      queryClient.invalidateQueries(["paymentDetails"]);
    },
    ...options,
  });
}

import React, { useEffect, useState, ReactNode } from "react";
import actions from "../store/Actions";
import { useStore } from "../hooks";
import LoadingOverlay from "../components/LoadingOverlay";
import { getFeatureFlags } from "../../feature/api";
import { useIdentityUser } from "../../auth/hooks";

function FeatureFlagsProvider({ children }: { children: ReactNode }) {
  const [isLoading, setIsLoading] = useState(false);
  const { state, dispatch } = useStore();
  const parentContextId = state.contextHierarchy?.lastParentId;
  const { data: user } = useIdentityUser();

  useEffect(() => {
    let isUnmounted = false;
    if (parentContextId && user) {
      setIsLoading(true);
      getFeatureFlags()
        .then((data) => {
          if (isUnmounted) return;
          dispatch({
            type: actions.SET_FEATURES,
            payload: data?.reduce((map: any, flag: any) => ({ ...map, [flag?.uid]: flag }), {}),
          });
          setIsLoading(false);
        })
        .catch(() => {
          if (isUnmounted) return;
          setIsLoading(false);
        });
    }
    return () => {
      isUnmounted = true;
    };
  }, [dispatch, parentContextId, user?.profile.sub]);

  if (isLoading) return <LoadingOverlay />;
  return <>{children}</>;
}

export default FeatureFlagsProvider;
